import React from 'react'
import Lottie from 'react-lottie-player'

import lottieJson from '../../../assets/animations/loading.json'
import { ILoadingProps } from './types'

export default function Loading(props: ILoadingProps) {
	const { width = 150, height = 150 } = props

	return (
		<Lottie loop animationData={lottieJson} play style={{ width, height }} />
	)
}
