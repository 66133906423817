import styled, { css } from 'styled-components'
import { toRem } from '../../shared/styles/function'

export const ProgramsContainer = styled.div`
	header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 32px;

		div {
			display: flex;
			align-items: center;

			.search-input {
				margin-left: 48px;
			}
		}
	}
`

export const TitlePage = styled.span`
	${({ theme }) => css`
		color: ${theme.colors.dark_graffiti};
		font-family: ${theme.fonts.radikal_light};
		font-size: ${toRem(24)};
	`}
`

export const TableWrapper = styled.div`
	margin-top: 16px;
`

export const ImageWrapper = styled.div`
	${({ theme }) => css`
		width: 46px;
		height: 46px;
		overflow: hidden;
		border-radius: 2px;
		background: ${theme.colors.light_gray};

		.report-image {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	`}
`
export const TableImagePlaceholder = styled.div`
	${({ theme }) => css`
		width: 46px;
		height: 46px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 2px;
		background: ${theme.colors.off_white};
		border: 1px solid ${theme.colors.light_gray};
		border-radius: 4px;

		.placeholder-icon {
			width: ${toRem(20)};
			height: ${toRem(20)};
			color: ${theme.colors.greenish_grey_light};
		}
	`}
`

export const PresenterWrapper = styled.div`
	padding: 20px 0px;
	display: flex;

	${({ theme }) => css`
		h4 {
			font-size: ${toRem(12)};
			color: ${theme.colors.dark_graffiti};
			margin: 6px;
			border: 1px solid ${theme.colors.dark_graffiti};
			padding: 4px 10px;
			border-radius: 13px;
			text-transform: lowercase;
			font-family: ${theme.fonts.radikal_regular};

			&::first-letter {
				text-transform: uppercase;
			}
		}
	`}
`

export const DetailButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	background: transparent;
	border: none;
	cursor: pointer;
	padding: 10px;
	border-radius: 50%;
	transition: 0.4s;
	color: ${({theme}) => css`${theme.colors.dark_graffiti}`};

	:hover{
		background-color: ${({theme}) => css`${theme.colors.off_white}`};
		color: ${({theme}) => css`${theme.colors.pure_black}`};
	}
`

export const LiveBadgeWrapper = styled.div`
	display: flex;
	align-items: center;
`

export const LiveBadge = styled.p`
	${({ theme }) => css`
		font-size: 11px;
		margin-left: 6px;
		background: ${theme.colors.green};
		padding: 5px 8px;
		border-radius: 16px;
		font-family: ${theme.fonts.radikal_medium};
	`}
`
