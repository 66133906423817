import styled from 'styled-components'
import { theme } from '../../styles/theme'

export const PrimarySelectWrapper = styled.div`
	background-color: ${theme.colors.white};
	width:100%;
	.select-container {
		width:100%;
	}
`
export const SelectChipStyles = {
	backgroundColor: theme.colors.white,
	color: theme.colors.dark_graffiti,
	fontSize: 12,
	fontWeight: 500,
}