import styled, { css } from 'styled-components'
import { toRem } from '../../styles/function'

export const TexTAreaWrapper = styled.div`
	width: 100%;
`

export const TextArea = styled.textarea`
	${(props) => css`
		width: 100%;
		text-decoration: none;
		border: 1px solid
			${props.value ? props.theme.colors.green : props.theme.colors.off_white};
		border-radius: 4px;
		background-color: ${props.theme.colors.greenish_white};
		padding: 16px 16px;
		resize: none;
		overflow: hidden;
		font-family: ${props.theme.fonts.radikal_regular};
		color: ${props.theme.colors.greenish_grey};
		font-size: ${toRem(12)};

		&:focus {
			border: 1px solid ${props.theme.colors.green};
		}
	`}
`
