import { useEffect } from 'react'

export function useRouteChangeListener() {
	const handleBeforeUnload = (event: any) => {
		event.preventDefault()
		event.returnValue = '' // Alguns navegadores requerem que você defina um valor para mostrar o diálogo
	}

	useEffect(() => {
		// Adiciona o ouvinte para o evento 'beforeunload'
		window.addEventListener('beforeunload', handleBeforeUnload)

		// Remove o ouvinte quando o componente é desmontado (para evitar vazamento de memória)
		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload)
		}
	}, [])
}
