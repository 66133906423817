import styled, { css } from 'styled-components'
import { toRem } from '../../shared/styles/function'
import { theme } from '../../shared/styles/theme'

export const NewProgramWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
`
export const ModalContentWrapper = styled.div`
	width: 430px;
`

export const ModalContent = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;

	.button-wrapper-secondary {
		display: flex;
		justify-content: center;
		margin-top: 24px;

		.delete-program-button {
			background: none;
			border: none;
			color: ${theme.colors.dark_graffiti};
			font-size: ${toRem(12)};
			cursor: pointer;
			font-family: ${theme.fonts.radikal_regular};
		}
	}
`
export const Separator = styled.div`
	${({ theme }) => css`
		width: 100%;
		height: 1px;
		background: ${theme.colors.light_gray};
		margin-top: 58px;
	`}
`

export const ModalTitle = styled.h1`
	${({ theme }) => css`
		font-size: ${toRem(24)};
		color: ${theme.colors.dark_graffiti};
		font-family: ${theme.fonts.radikal_light};
		font-weight: 300;
		margin-top: 16px;
	`};
`

export const Header = styled.div`
	display: flex;
	flex-direction: column;
`

export const TooltipWrapper = styled.div`
	${({ theme }) => css`
		display: flex;
		justify-content: space-between;

		h4 {
			color: ${theme.colors.dark_graffiti};
			font-weight: 400;
		}
	`}
`

export const GoBackButton = styled.span`
	${({ theme }) => css`
		font-size: 14px;
		color: ${theme.colors.dark_graffiti};
		margin-top: 1px;
	`}
`

export const DescriptionLine = styled.span`
	${({ theme }) => css`
		color: ${theme.colors.greenish_grey_medium};
		font-size: 12px;
	`}
`

export const LineWrapper = styled.div`
	${({ theme }) => css`
		width: 100%;

		.program-description {
			margin-top: 16px;
		}
		img {
			width: 18px;
			height: 18px;
		}

		h4 {
			color: ${theme.colors.dark_graffiti};
			font-weight: 400;
		}
	`}
`

export const InputProgramWrapper = styled.div`
	width: 100%;

	.program-name {
		margin-top: 8px;
	}
	.program-description {
		margin-top: 8px;
	}
`
export const UploadImageButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin-top: 16px;
	img {
		width: 72px;
		height: 72px;
	}
`

export const InputWrapper = styled.div`
	${({ theme }) => css`
		width: 100%;

		h4 {
			color: ${theme.colors.dark_graffiti};
			font-size: ${toRem(18)};
			font-weight: 400;
			margin-bottom: 16px;
		}

		p {
			color: ${theme.colors.greenish_grey_medium};
			font-size: ${toRem(16)};
			font-weight: 400;
			margin-bottom: 32px;
		}
	`}
`

export interface IDateTimeSelect {
	index: number
}

export const DateTimeSelectWrapper = styled.div<IDateTimeSelect>`
	width: 100%;
	${(props) =>
		props.index >= 1 &&
		css`
			margin-top: 32px;
		`}
`

export const ButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 36px;
`

export const DeleteButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 16px;
	margin-bottom: 120px;
`

export const ErrorsWrapper = styled.div`
	margin-top: 36px;
`
