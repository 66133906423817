import React, { createElement } from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { useTypedSelector } from '../../shared/hooks/useTypedSelector'
import { UserRolesEnum } from '../../shared/interfaces/user'
import { redirectRoute } from '../../shared/util/redirect'
import Page from '../Page'
import { routesData } from './data'

function RouteNavigation() {
	const { token, user } = useTypedSelector(['token', 'user'])

	function userHasPermission(accessType: UserRolesEnum[]): boolean {
		if (!accessType || accessType.length === 0) return true

		const hasPermission = accessType.some((type) => user.roles.includes(type))

		return hasPermission
	}

	return (
		<BrowserRouter>
			<Switch>
				{routesData.map(
					({ accessTypes, component, noWrapper, path, ...props }) => {
						const isPublic = !accessTypes
						if (accessTypes && userHasPermission(accessTypes)) {
							return (
								<Route key={path} exact path={path}>
									{noWrapper ? (
										<>{createElement(component, props)}</>
									) : (
										<Page>{createElement(component, props)}</Page>
									)}
								</Route>
							)
						} else if (isPublic) {
							return (
								<Route key={path} exact path={path}>
									{createElement(component, props)}
								</Route>
							)
						} else {
							return null
						}
					},
				)}

				<Redirect
					from={'*'}
					to={redirectRoute(user.roles, token.accessToken)}
				/>
			</Switch>
		</BrowserRouter>
	)
}

export default RouteNavigation
