import styled, { css } from 'styled-components'
import { toRem } from '../../styles/function'
import { theme } from '../../styles/theme'

export const UploadImageContainer = styled.div`
	width:100%;
	height: 88px;

	.image-artwork{
		display: none;
	}

	.trash-icon{
		transition: 0.4s;
	
		&:hover {
			color: ${theme.colors.pure_black};
		}
	}
`

export const UploadImageButtonWrapper = styled.div`
	${({ theme }) => css`
		border: 1px solid #99FFC5;
		background: ${theme.colors.green_shade};
		border-radius: 4px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		cursor: pointer;
		width:100%;
		height: 100%;
		
		.upload-icon {
			margin: 32px;
			width: 24px;
			height: 24px;
			color: ${theme.colors.green_medium};
			stroke-width: 1.5px;
		}

		img {
			width: 72px;
			height: 72px;
		}
	`}
`

interface ImagePreviewImageProps {
	showInfo: boolean
}

export const ImagePreviewWrapper = styled.div<ImagePreviewImageProps>`
	${(props) => css`
		width: 100%;
		height: 88px;
		padding: 0px 8px;
		

		background: ${props.theme.colors.greenish_white};
		border: 1px solid ${props.theme.colors.light_gray};

		display: flex;
		align-items: center;
		justify-content: ${props.showInfo ? 'space-between' : 'center'}} 

		img {
			min-width: 72px;
			min-height: 72px;
			object-fit: cover;
		}

		.image-container {
			display: flex;
			align-items: center;

			min-width: 72px;
			min-height: 72px;

			.text-content-wrapper {
				margin-left: 12px;
			}

			h2 {
				color: ${props.theme.colors.greenish_grey};
				font-size: ${toRem(14)};
			}

			h3 {
				color: ${props.theme.colors.greenish_grey};
				font-size: ${toRem(9)};
				margin-top: 4px;
			}
		}
		.trash-icon {
			color: ${props.theme.colors.dark_graffiti};
			cursor: pointer;
			margin-right: 29px
		}

		button {
			background: transparent;
			border: none;
		}
	`}
`

export const ButtonTitle = styled.p`
	${({ theme }) => css`
		font-size: ${toRem(16)};
		color: ${theme.colors.green_medium};
		font-weight: 400;
	`}
`

export const ButtonSubtitle = styled.p`
	${({ theme }) => css`
		font-size: ${toRem(9)};
		margin-top: 6px;
		text-transform: uppercase;
		color: ${theme.colors.green_medium};
		letter-spacing: 0.08em;
		font-weight: 500;
	`}
`
