import React, { Fragment, useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import CallendarIcon from '../../assets/icons/calendar.svg'

import {
	useAccomplishPromotion,
	useDeletePromotion,
	usePromotionDetail,
	useUpdatePromotionStatus,
} from '../../shared/service/promotions.service'
import {
	PermissionsTypeEnum,
	usePermission,
} from '../../shared/hooks/usePermission'
import GoBackButton from '../../shared/components/GoBackButton'

import * as S from './styles'
import { PrizeDrawCardHeader } from './PrizeDrawCardHeader'
import { format, parseISO } from 'date-fns'
import { RenderTextElement } from '../../shared/components/RenderTextElement'
import { Button, GenericConfirmationModal } from '../../shared/components'
import Switch from '../../shared/components/Switch'
import { PromotionStatus } from '../../shared/interfaces/promotion'
import { ParticipantsAndWinnersTabs } from './ParticipantsAndWinnersTabs'
import { useModal } from '../../shared/hooks/useModal'
import { handleSuccessToaster } from '../../shared/util/toaster'
import { routesEnum } from '../Routes/enum'

export default function PromotionDetails() {
	const { promotionID } = useParams<{ promotionID: string }>()
	const { data: promotion, refetch: refetchPromotion } =
		usePromotionDetail(promotionID)

	const updatePromotionStatus = useUpdatePromotionStatus()
	const deletePromotionMutation = useDeletePromotion()
	const accomplishPromotionMutation = useAccomplishPromotion()

	const { PROMOTIONS: PROMOTION_PERMISSIONS } = usePermission()

	const history = useHistory()

	const handleAccomplishPromotion = useCallback(async () => {
		const response = await accomplishPromotionMutation.mutateAsync({
			promotionID: promotionID,
		})

		if (!response) return

		handleSuccessToaster('Promoção finalizada com sucesso')
		history.push(routesEnum.PROMOTIONS)
	}, [])

	const handleDeletePromotion = useCallback(async () => {
		const response = await deletePromotionMutation.mutateAsync({
			promotionId: promotionID,
		})

		if (!response) return

		handleSuccessToaster('Promoção excluída com sucesso')
		history.push(routesEnum.PROMOTIONS)
	}, [])

	const accomplishModal = useModal({
		onConfirm: handleAccomplishPromotion,
	})
	const deleteModal = useModal({
		onConfirm: handleDeletePromotion,
	})

	const handleToggleSwitchStatus = useCallback(
		async (currentStatus: PromotionStatus) => {
			await updatePromotionStatus.mutateAsync({
				id: promotionID,
				status:
					currentStatus === PromotionStatus.ACTIVE
						? PromotionStatus.INACTIVE
						: PromotionStatus.ACTIVE,
			})
			refetchPromotion()
		},
		[],
	)

	const handleGoToEdition = useCallback(() => {
		history.push(`/promotions/EDITPromotion/${promotionID}`)
	}, [])

	if (!promotion) return null

	const userCanToggleStatus = PROMOTION_PERMISSIONS.includes(
		PermissionsTypeEnum.ACTIVATE_OR_DEACTIVATE,
	)
	const userCanEdit = PROMOTION_PERMISSIONS.includes(PermissionsTypeEnum.EDIT)
	const userCanEditWithParticipants = PROMOTION_PERMISSIONS.includes(
		PermissionsTypeEnum.EDIT_WITH_PARTICIPANTS,
	)
	const enableEdition =
		!promotion.accomplishedAt &&
		(userCanEditWithParticipants ||
			(userCanEdit && !promotion.participants.length))
	const userCanAccomplish =
		PROMOTION_PERMISSIONS.includes(PermissionsTypeEnum.ACCOMPLISH) &&
		!promotion.accomplishedAt
	const userCanDelete = PROMOTION_PERMISSIONS.includes(
		PermissionsTypeEnum.DELETE,
	)
	const hasParticipants = !!promotion.participants.length
	const enableDelete = userCanDelete && !hasParticipants

	return (
		<Fragment>
			<S.PageContainer>
				<GoBackButton />

				<PrizeDrawCardHeader promotion={promotion} />

				<S.PromotionDetailsCard>
					<img
						src={promotion.artworkURL}
						alt=''
						className='promotion-artwork'
					/>

					<div className='details-container'>
						<div className='card-details-header'>
							<h1 className='title'>{promotion.title}</h1>
							<div className='edit-button-wrapper'>
								{!enableEdition && !promotion.accomplishedAt && (
									<span
										className='edit-button-message'
										dangerouslySetInnerHTML={{
											__html: promotion.participants.length
												? 'Não é possível editar uma<br/>promoção com participantes'
												: 'Você não tem<br/>permissão para editar',
										}}
									/>
								)}
								<Button.Root
									theme='light'
									shape='round'
									spacing='14x14'
									onClick={handleGoToEdition}
									disabled={!enableEdition}
									data-testid='button-edit-promotion'
								>
									<Button.Icon name='pan' />
								</Button.Root>
							</div>
						</div>
						<div className='period-label'>
							<img src={CallendarIcon} alt='' className='calendar-icon' />
							<span className='period'>
								Período{' '}
								{format(parseISO(String(promotion.startDate)), 'dd/MM/yyyy')}{' '}
								até {format(parseISO(String(promotion.endDate)), 'dd/MM/yyyy')}
							</span>
						</div>
						<div className='description'>
							<RenderTextElement data={JSON.parse(promotion.description)} />
						</div>
					</div>
				</S.PromotionDetailsCard>

				<S.ChangePromotionStatusCard>
					<span className='card-title'>Status da Promoção</span>

					<span className='status-label'>
						Promoção{' '}
						{promotion.status === PromotionStatus.ACTIVE ? 'Ativa' : 'Inativa'}
					</span>
					<Switch
						checked={promotion.status === PromotionStatus.ACTIVE}
						onChange={() => handleToggleSwitchStatus(promotion.status)}
						disabled={!userCanToggleStatus}
					/>
				</S.ChangePromotionStatusCard>

				<ParticipantsAndWinnersTabs promotionID={promotionID} />

				<S.ActionButtons>
					<Button.Root
						theme='light'
						spacing='12x24'
						onClick={accomplishModal.toggleVisibility}
						disabled={!userCanAccomplish}
						data-testid='button-accomplish-promotion'
					>
						<Button.Text text='Finalizar Promoção' />
					</Button.Root>
					<div className='divider-row'>
						<div className='divider' />
					</div>
					<Button.Root
						theme='light'
						spacing='12x24'
						onClick={deleteModal.toggleVisibility}
						disabled={!enableDelete}
						data-testid='button-delete-promotion'
					>
						<Button.Text text='Excluir Promoção' />
					</Button.Root>

					{hasParticipants && (
						<span className='cant-delete-message'>
							Não é possível excluir uma promoção quando há participantes.
						</span>
					)}
				</S.ActionButtons>
			</S.PageContainer>
			<GenericConfirmationModal
				title='Finalizar Promoção'
				description='Deseja realmente finalizar esta promoção? Esta ação não poderá ser
						desfeita após a confirmação de finalização.'
				confirmButtonText='Finalizar Promoção'
				cancelButtonText='Manter'
				{...accomplishModal}
			/>
			<GenericConfirmationModal
				title='Excluir Promoção'
				description='Deseja realmente excluir esta promoção? Esta ação não poderá ser desfeita após a confirmação de exclusão.'
				confirmButtonText='Excluir Promoção'
				cancelButtonText='Manter'
				{...deleteModal}
			/>
		</Fragment>
	)
}
