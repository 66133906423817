import React from 'react'
import { ImageWrapper, TableImagePlaceholder } from './styles'
import { Image } from 'react-feather'
import Switch from '../../shared/components/Switch'

export function renderImage(photoURL: string) {
	return (
		<ImageWrapper>
			{photoURL ? (
				<img className='report-image' src={photoURL} alt='profile image' />
			) : (
				<TableImagePlaceholder>
					<Image className='placeholder-icon' />
				</TableImagePlaceholder>
			)}
		</ImageWrapper>
	)
}

export function renderTextTable(str: string) {
	return <p>{str}</p>
}

interface ISwitchProps {
	productID: string
	isActive: boolean
	handleSwitchValueUpdate(productID: string, value: boolean): Promise<void>
}

export function renderSwitchTable({
	productID,
	isActive,
	handleSwitchValueUpdate,
}: ISwitchProps) {
	async function handleSwitchChange() {
		await handleSwitchValueUpdate(productID, !isActive)
	}

	return <Switch onChange={handleSwitchChange} checked={isActive} />
}
