import styled, { css } from 'styled-components'

export const Container = styled.div`
	${({ theme }) => css`
		max-width: 409px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-around;

		span.button-icon {
			flex: 1;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 4px;
			border-radius: 4px;
			transition: 0.5s;

			&:hover {
				cursor: pointer;
				background-color: ${theme.colors.light_gray};
			}

			&.disabled:hover {
				cursor: default;
				background-color: ${theme.colors.white};
			}
		}
	`}
`
