import { useEffect, useState } from 'react'
import { Droppable } from 'react-beautiful-dnd'

// eslint-disable-next-line react/prop-types
export const StrictModeDroppable = ({ children, ...props }) => {
	const [enabled, setEnabled] = useState(false)

	useEffect(() => {
		const animation = requestAnimationFrame(() => setEnabled(true))

		return () => {
			cancelAnimationFrame(animation)
			setEnabled(false)
		}
	}, [])
	if (!enabled) {
		return null
	}

	// eslint-disable-next-line react/react-in-jsx-scope
	return <Droppable {...props}>{children}</Droppable>
}
