import React, { useCallback, useState } from 'react'
import { createElement } from 'react'
import { handleSuccessToaster } from '../../shared/util/toaster'
import { IParticipantDrwnProps, IViewProps } from './types'
import Banners from './view'
import { useHistory, useLocation } from 'react-router-dom'
import {
	fetchPrizeDrawn,
	useConcludePromotion,
} from '../../shared/service/promotions.service'
import { IUser } from '../../shared/interfaces/user'
import { useModal } from '../../shared/hooks/useModal'
import { useRouteChangeListener } from '../../shared/hooks/useRouteChangeListener'

function ParticipantsDrawnContainer(): JSX.Element {
	useRouteChangeListener()

	const location = useLocation<IParticipantDrwnProps>()
	const promotionDetails = location.state.promotionDetails

	const [participantDrawn, setParticipantDrawn] = useState<IUser>(
		location.state.participantDrawn,
	)
	const [isRedoingRaffle, setIsRedoingRaffle] = useState(false)

	const concludePromotion = useConcludePromotion()

	const history = useHistory()

	const handleRedoRaffle = useCallback(async () => {
		try {
			setIsRedoingRaffle(true)
			raffleCounterModal.toggleVisibility()
			const participantDrawnResponse = await fetchPrizeDrawn(
				promotionDetails._id,
			)
			if (participantDrawnResponse) {
				setParticipantDrawn(participantDrawnResponse)
			}
		} catch {
			raffleCounterModal.toggleVisibility()
			setIsRedoingRaffle(false)
		}
	}, [])

	const onCompletePrizeDrawnCount = useCallback(async () => {
		if (!raffleCounterModal.isVisible || !participantDrawn) return
		raffleCounterModal.toggleVisibility()
		setIsRedoingRaffle(false)
	}, [participantDrawn])

	const handleConcludePromotion = useCallback(() => {
		// eslint-disable-next-line @typescript-eslint/no-extra-semi
		;(async () => {
			const response = await concludePromotion.mutateAsync({
				promotionId: promotionDetails._id,
				participantId: participantDrawn._id,
			})

			if (response) {
				handleSuccessToaster('Sorteio concluído com sucesso!')
				history.replace(`/promotion/${promotionDetails._id}`)
			}
		})()
	}, [])

	const redoRaffleConfirmationModal = useModal({
		onConfirm: handleRedoRaffle,
	})
	const raffleCounterModal = useModal({
		onConfirm: onCompletePrizeDrawnCount,
	})

	const viewProps: IViewProps = {
		promotionDetails,
		participantDrawn,
		redoRaffleConfirmationModal,
		isRedoingRaffle,
		raffleCounterModal,
		handleConcludePromotion,
	}

	return createElement(Banners, viewProps)
}

export default ParticipantsDrawnContainer
