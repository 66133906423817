import React, { StrictMode } from 'react'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import RouteNavigation from './modules/Routes'
import { persistor, store } from './shared/store'
import { GlobalStyle } from './shared/styles/globalStyles'
import { theme } from './shared/styles/theme'
import { PersistGate } from 'redux-persist/integration/react'

import { ToastContainer } from 'react-toastify'

import { QueryClient, QueryClientProvider } from 'react-query'

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: 2,
			refetchOnWindowFocus: true,
			refetchOnMount: true,
			refetchOnReconnect: true,
			refetchIntervalInBackground: false,
		},
	},
})

function App() {
	return (
		<StrictMode>
			<Provider store={store}>
				<PersistGate persistor={persistor}>
					<ThemeProvider theme={theme}>
						<GlobalStyle />
						<QueryClientProvider client={queryClient}>
							<RouteNavigation />
						</QueryClientProvider>
						<ToastContainer />
					</ThemeProvider>
				</PersistGate>
			</Provider>
		</StrictMode>
	)
}

export default App
