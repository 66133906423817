import React, { useState } from 'react'
import {
	DeleteButton,
	DeleteWrapper,
	FinalizedTagText,
	ImageWrapper,
	RoleWrapper,
	TableImagePlaceholder,
	TagWrapper,
	TagWrapper2,
	TagWrapper3,
	UserTableStatus,
} from './styles'
import { ROLES_ENUM, ROLES_ENUM_STATUS } from '../../shared/util/translate'

import { Image } from 'react-feather'
import { UserRolesEnum } from '../../shared/interfaces/user'
import { IRenderSwitchProps } from './types'
import Switch from '../../shared/components/Switch'
import DeleteModal from '../../shared/components/DeleteModal'
import { handleSuccessToaster } from '../../shared/util/toaster'
import { format } from 'date-fns'
import { Trash2 } from 'react-feather'
import { IPromotion } from '../../shared/interfaces/promotion'

export function renderTextTable(str: string) {
	return <p>{str}</p>
}

export function renderPeriodTable(start: Date, end: Date) {
	return (
		<p>
			{format(new Date(start), 'dd/MM/yyyy')} até{' '}
			{format(new Date(end), 'dd/MM/yyyy')}
		</p>
	)
}

export function renderNumberTable(number: number) {
	return <p>{number}</p>
}

export function renderSortTag(
	accomplishedAt: Date | undefined,
	users: string[],
) {
	const endDate = format(
		new Date(accomplishedAt || new Date()),
		// eslint-disable-next-line quotes
		"dd/MM/yyyy, 'às' HH:mm",
	)
	if (accomplishedAt != undefined) {
		return (
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					flexWrap: 'wrap',
					gap: '8px',
				}}
			>
				<TagWrapper>
					<p>Finalizado</p>
				</TagWrapper>

				<FinalizedTagText>
					Finalizado em<br></br>
					{endDate}
				</FinalizedTagText>
			</div>
		)
	} else {
		if (users.length != 0) {
			return (
				<div>
					<TagWrapper3>
						<p>Em Andamento</p>
					</TagWrapper3>
				</div>
			)
		} else {
			return (
				<div>
					<TagWrapper2>
						<p>Pendente </p>
					</TagWrapper2>
				</div>
			)
		}
	}
}
export function renderRolesTable(roles: UserRolesEnum[]) {
	return (
		<RoleWrapper>
			{roles.map((role, i) => (
				<h4 key={i}>{ROLES_ENUM[role]}</h4>
			))}
		</RoleWrapper>
	)
}

export function renderSwitch(props: IRenderSwitchProps) {
	const {
		promotion,
		handleSwitchRefetch,
		userCanActivateOrDeactivate = true,
	} = props

	async function handleSwitchChange() {
		await handleSwitchRefetch(promotion)
	}

	const isChecked = promotion.status === 'ACTIVE' ? true : false

	return (
		<Switch
			onChange={handleSwitchChange}
			checked={isChecked}
			disabled={!userCanActivateOrDeactivate}
			data-testid='switch-status'
		/>
	)
}

export function renderUserSort(status: string) {
	return (
		<UserTableStatus status={status}>
			<div></div>
			<h4>{ROLES_ENUM_STATUS[status]}</h4>
		</UserTableStatus>
	)
}

export function renderImage(photoURL: string) {
	return (
		<ImageWrapper>
			{photoURL ? (
				<img className='report-image' src={photoURL} alt='profile image' />
			) : (
				<TableImagePlaceholder>
					<Image className='placeholder-icon' />
				</TableImagePlaceholder>
			)}
		</ImageWrapper>
	)
}

export function renderDeletePromotion({
	promotion,
	setDeleteModalOpen,
	setSelectedPromotionId,
	userCanDelete,
}: {
	promotion: IPromotion
	setDeleteModalOpen: React.Dispatch<React.SetStateAction<boolean>>
	setSelectedPromotionId: React.Dispatch<React.SetStateAction<string>>
	userCanDelete: boolean
}) {
	const disabled = !userCanDelete || promotion.participants.length > 0
	return (
		<DeleteWrapper>
			<DeleteButton
				disabled={disabled}
				onClick={async () => {
					setSelectedPromotionId(promotion._id)
					setDeleteModalOpen(true)
				}}
				data-testid='button-delete'
			>
				<Trash2 style={{ strokeWidth: 1.5 }} />
			</DeleteButton>
		</DeleteWrapper>
	)
}
