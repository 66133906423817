import React from 'react'
import { ImageWrapper, TableImagePlaceholder } from './styles'
import { Image } from 'react-feather'

import * as S from './styles'
import { IRenderCheckBoxProps, IRenderSwitchProps } from './types'
import { IAudioResponse } from '../../shared/service/audio.service'
import Switch from '../../shared/components/Switch'

export function renderImage(photoURL: string) {
	return (
		<ImageWrapper>
			{photoURL ? (
				<img className='report-image' src={photoURL} alt='profile image' />
			) : (
				<TableImagePlaceholder>
					<Image className='placeholder-icon' />
				</TableImagePlaceholder>
			)}
		</ImageWrapper>
	)
}
export function renderTextTable(str: string) {
	return <p>{str}</p>
}

export function renderCheckbox(props: IRenderCheckBoxProps) {
	const { audio, toBeDeleted, handleSelect } = props

	const checked = toBeDeleted.includes(audio._id)

	return (
		<S.CheckboxContainer>
			<input
				className='checkbox'
				type='checkbox'
				name='checkbox'
				id={audio._id}
				onChange={(event) => handleSelect(event, audio)}
				checked={checked}
				data-testid='checkbox-audio'
			/>
		</S.CheckboxContainer>
	)
}

export function renderSwitch(props: IRenderSwitchProps) {
	const { audio, handleSwitchRefetch } = props

	async function handleSwitchChange() {
		await handleSwitchRefetch(audio._id)
	}

	const isChecked = audio.played

	return <Switch onChange={handleSwitchChange} checked={isChecked} />
}

export function renderAudioPlayer(audio: IAudioResponse) {
	return (
		<S.PlayerAudioTrueStyles audioPlayed={audio.played}>
			<audio className='player-true' src={audio.audio} controls>
				O seu navegador não suporta o elemento <code>audio</code>.
			</audio>
		</S.PlayerAudioTrueStyles>
	)
}

export function audioRenderAvatar(audio: IAudioResponse) {
	return (
		<S.AvatarWrapper>
			<div className='imageBox'>{renderImage(audio.user.photoURL)}</div>
			<div>
				<p>{audio.user.name}</p>
			</div>
		</S.AvatarWrapper>
	)
}
