import React from 'react'
import { Button } from '../../shared/components'
import ProductModal from '../../shared/components/ProductModal'
import SecondaryInput from '../../shared/components/SecondaryInput'
import Table from '../../shared/components/Table'
import { EShopWrapper, TableWrapper, TitlePage } from './styles'
import { IViewProps } from './types'

function EShop(props: IViewProps) {
	const {
		handleStringChange,
		searchString,
		tableColumns,
		isLoading,
		tableRows,
		modalIsOpen,
		handleModalClose,
		currentProduct,
		handleRefetch,
		handleOpenModal,
		modalAction,
		handleSortColumn,
		sortBy,
		navProps,
		productPage,
	} = props

	return (
		<EShopWrapper>
			<>
				<header>
					<div>
						<TitlePage data-testid='title-page'>E-Shop</TitlePage>
						<SecondaryInput
							className='search-input'
							placeholder='Buscar Produto'
							value={searchString}
							onChange={handleStringChange}
						/>
					</div>

					<Button.Root onClick={handleOpenModal} spacing={'12x24'} data-testid='button-new-product'>
						<Button.Text text='Novo'/>
					</Button.Root>
				</header>

				<TableWrapper>
					<Table
						pageSize={5}
						columns={tableColumns as any}
						data={tableRows}
						isLoading={isLoading}
						getSelectedColumnAcessorAndOrder={handleSortColumn}
						sortBy={sortBy}
						navProps={navProps}
						extractPage={{
							page: productPage.page,
							totalDocs: productPage.totalDocs,
						}}
					/>
				</TableWrapper>

				<ProductModal
					onRequestClose={handleModalClose}
					isOpen={modalIsOpen}
					action={modalAction}
					currentProduct={currentProduct}
					refetch={handleRefetch}
				/>
			</>
		</EShopWrapper>
	)
}

export { EShop }
