import React from 'react'
import { useHistory } from 'react-router-dom'

import ArrowLeft from '../../../assets/icons/left-arrow.svg'

import * as S from './styles'

export default function GoBackButton() {
	const history = useHistory()

	return (
		<S.Container onClick={() => history.goBack()} className='go-back-button'>
			<img src={ArrowLeft} alt='' />
			<span>Voltar</span>
		</S.Container>
	)
}
