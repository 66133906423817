import styled, { css } from 'styled-components'

export const ViewErrorWrapper = styled.div``

export const ErrorText = styled.p`
	${({ theme }) => css`
		color: ${theme.colors.greenish_grey};
		font-size: 14px;
	`}
`
