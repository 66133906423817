import React from 'react'
import { ImageWrapper, TableImagePlaceholder } from './styles'
import { Image } from 'react-feather'

export function renderImage(photoURL?: string) {
	return (
		<ImageWrapper>
			{photoURL ? (
				<img className='report-image' src={photoURL} alt='profile image' />
			) : (
				<TableImagePlaceholder>
					<Image className='placeholder-icon' />
				</TableImagePlaceholder>
			)}
		</ImageWrapper>
	)
}
