import { useState } from 'react'

export interface IUseModalReturn {
	toggleVisibility: () => void
	isVisible: boolean
	onConfirm: () => void
	onCancel: () => void
	isLoading: boolean
}

export function useModal(props?: {
	onConfirm?: () => Promise<void>
	onCancel?: () => void
	initialStateOfVisibility?: boolean
}): IUseModalReturn {
	const [isVisible, setIsVisible] = useState(!!props?.initialStateOfVisibility)
	const [isLoading, setIsLoading] = useState(false)

	function toggleVisibility() {
		setIsVisible(!isVisible)
	}

	function onConfirm() {
		// eslint-disable-next-line @typescript-eslint/no-extra-semi
		;(async () => {
			try {
				setIsLoading(true)
				if (props?.onConfirm) await props.onConfirm()
			} finally {
				toggleVisibility()
				setIsLoading(false)
			}
		})()
	}

	function onCancel() {
		if (props?.onCancel) props.onCancel()
		toggleVisibility()
	}

	return {
		toggleVisibility,
		isVisible,
		isLoading,
		onConfirm,
		onCancel,
	}
}
