import React from 'react'
import { IBeatCoinsProps } from './types'

import PointsCoin from '../../../assets/icons/PointsCoin.svg'

import * as S from './styles'
import { formatNumber } from '../../util/number'

function BeatCoinsCard(props: IBeatCoinsProps) {
	const { points = 0 } = props

	return (
		<S.BeatCoinsCardWrapper>
			<div>
				<img src={PointsCoin} alt='PointsCoin'/>
			</div>
			<S.Title>Beatcoins</S.Title>
			<S.PointsBadge>
				<S.PointsBadgeText>{formatNumber(points)}</S.PointsBadgeText>
			</S.PointsBadge>
		</S.BeatCoinsCardWrapper>
	)
}

export { BeatCoinsCard }
