/* eslint-disable */
import {
	ButtonRouteWrapper,
	LoggoutSeparator,
	NavButtonHover,
	RouteWrapper,
	SideNavWrapper,
} from './styles'
import { IProps } from './types'

import {
	Clipboard,
	Disc,
	Gift,
	Headphones,
	Image,
	Mic,
	Play,
	Radio,
	ShoppingBag,
	Users,
} from 'react-feather'
import { NavLink, useHistory, useLocation } from 'react-router-dom'
import LogoutImage from '../../assets/icons/logout.svg'
import { useTypedSelector } from '../../shared/hooks/useTypedSelector'
import { logout } from '../../shared/util/session'
import { routesEnum } from '../Routes/enum'
import { IRouteData } from '../Routes/types'

export default function SideNav({
	isOpen,
	handleSetIsOpen,
	routes,
}: IProps): JSX.Element {
	const history = useHistory()
	const location = useLocation()

	function redirect() {
		const { LOGIN } = routesEnum

		logout()
		history.push(LOGIN)
	}

	const { user } = useTypedSelector(['token', 'user'])

	function listAllowedMenus(): IRouteData[] {
		const menus = routes.filter((route) => {
			const listIsPresenters =
				user.roles.length === 1 && user.roles.includes('PRESENTER')
			if (
				route.noWrapper ||
				route.path === routesEnum.LISTENER_DETAIL ||
				route.path === routesEnum.NEW_PROGRAM ||
				route.path === routesEnum.PROGRAM_DETAIL ||
				route.path === routesEnum.NEW_USER ||
				route.path === routesEnum.NEW_PROMOTION ||
				route.path === routesEnum.EDIT_PROMOTION ||
				route.path === routesEnum.EDIT_USER ||
				route.path === routesEnum.PARTICIPANT_DRAWN ||
				route.path === routesEnum.PROMOTION_DETAIL ||
				route.path === routesEnum.PURCHASE_DETAIL
			) {
				return false
			}
			if (listIsPresenters) {
				if (
					route.path === routesEnum.EDIT_USER ||
					route.path === routesEnum.NEW_PROGRAM ||
					route.path === routesEnum.NEW_USER ||
					route.path === routesEnum.BANNERS ||
					route.path === routesEnum.BONUS ||
					route.path === routesEnum.E_SHOP
				) {
					return false
				}
			}

			return route
		})

		return menus
	}

	const sideNavRoutes = listAllowedMenus()

	const icons = {
		[routesEnum.PROMOTIONS]: Gift,
		[routesEnum.BANNERS]: Image,
		[routesEnum.USERS]: Users,
		[routesEnum.PROGRAMS]: Radio,
		[routesEnum.PURCHASE]: Clipboard,
		[routesEnum.MUSICS]: Disc,
		[routesEnum.BONUS]: Play,
		[routesEnum.E_SHOP]: ShoppingBag,
		[routesEnum.LISTENERS]: Headphones,
		[routesEnum.LISTENER_DETAIL]: Headphones,
		[routesEnum.AUDIO]: Mic,
		[routesEnum.REGULATION]: Clipboard,
	}

	return (
		<SideNavWrapper className={isOpen ? 'active' : ''}>
			<div className='background' onClick={handleSetIsOpen} />
			<div className='menu-wrapper'>
				<div className='spacer' />
				<div className='nav-wrapper'>
					<nav>
						<div className='upper-part'>
							{sideNavRoutes.map((route) => {
								const Icon = icons[route.path]

								return (
									<div key={route.key}>
										<RouteWrapper
											isActive={route.path === location.pathname}
											className='routes-wrapper'
										>
											<ButtonRouteWrapper>
												<div className='icon-indicator' />
												<NavLink
													className='nav-link'
													to={route.path}
													style={{
														display: 'flex',
														justifyContent: 'center',
														alignItems: 'center',
														gap: '8px',
														width: '100%',
														height: '100%',
													}}
												>
													<NavButtonHover>
														<Icon
															className='icon'
															style={{ width: '22px', height: '22px' }}
														/>

														<p>{route.name}</p>
													</NavButtonHover>
												</NavLink>
											</ButtonRouteWrapper>
										</RouteWrapper>

										{route.path === routesEnum.PURCHASE && <LoggoutSeparator />}
									</div>
								)
							})}
						</div>

						<div>
							<LoggoutSeparator />
							<button onClick={redirect} className='bottom-part'>
								<ul className='nav-bottom'>
									<li>Sair</li>
								</ul>
								<img src={LogoutImage} alt='logout' />
							</button>
						</div>
					</nav>
				</div>
			</div>
		</SideNavWrapper>
	)
}
