import styled, { css } from 'styled-components'
import { toRem } from '../../shared/styles/function'

export const BannersContainer = styled.div``

export const Top = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-top: 40px;
`

export const TitlePage = styled.h1`
	${({ theme }) => css`
		font-family: ${theme.fonts.radikal_light};;
		font-weight: 300;
		font-size: 24px;
		line-height: 32px;
		color: ${theme.colors.dark_graffiti};
	`}
`

export const InputWrapper = styled.div`
	margin-left: 48px;
`

export const ButtonWrapper = styled.div``

export const TitleInputWrapper = styled.div`
	display: flex;
	align-items: center;
`

export const ButtonNewBanner = styled.button``

export const TableWrapper = styled.div`
	margin-top: 16px;
`

export const PositionWrapper = styled.div``

export const PositionContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`

export const PositionIcon = styled.img`
	width: 46px;
	height: 46px;
	cursor: grab;
`

export const PositionNumber = styled.p`
	${({ theme }) => css`
		margin-top: 2px;
		display: flex;
		align-items: center;
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 22px;
		color: ${theme.colors.dark_graffiti};
		margin-right: 80px;
	`}
`

export const TitleWrapper = styled.div`
	display: flex;
	align-items: center;
`

export const Image = styled.img`
	width: 152px;
	height: 46px;
	border-radius: 4px;
	object-fit: cover;
	margin-right: 16px;
`

export const Title = styled.h1`
	${({ theme }) => css`
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 22px;
		color: ${theme.colors.dark_graffiti};
	`}
`

export const DeleteWrapper = styled.div``

export const DeleteButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: transparent;
	border: none;
	cursor: pointer;
	padding: 10px;
	border-radius: 50%;
	transition: 0.4s;
	color: ${({theme}) => css`${theme.colors.dark_graffiti}`};

	:hover{
		background-color: ${({theme}) => css`${theme.colors.off_white}`};
		color: ${({theme}) => css`${theme.colors.pure_black}`};
	}
`

export const BannerCard = styled.div`
	${({ theme }) => css`
		display: flex;
		width: 100%;
		height: 82px;
		background-color: ${theme.colors.white};
		margin-bottom: 2px;
		justify-content: space-between;
		padding: 0px 30px;
	`}
`

export const OrderWrapper = styled.div`
	display: flex;
	align-items: center;
	width: 450px;
	@media screen and (max-width: 768px) {
		width: 370px;
	}
`

export const DragNDropIcon = styled.img`
	width: 46px;
	height: 46px;
	cursor: grab;
`

export const BannerInfoWrapper = styled.div`
	display: flex;
	align-items: center;
`
export const SwitchWrapper = styled.div`
	display: flex;
	align-items: center;
`

export const DeleteEditWrapper = styled.div`
	display: flex;
	align-items: center;
`

export const EditIcon = styled.img`
	width: 22px;
	height: 22px;
`

export const EditButton = styled.button`
	margin-left: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: transparent;
	border: none;
	cursor: pointer;
	padding: 10px;
	border-radius: 50%;
	transition: 0.4s;
	color: ${({theme}) => css`${theme.colors.dark_graffiti}`};

	:hover{
		background-color: ${({theme}) => css`${theme.colors.off_white}`};
		color: ${({theme}) => css`${theme.colors.pure_black}`};
	}
`

export const BannerContent = styled.div`
	padding-bottom: 40px;
`

export const BannerContentWrapper = styled.div`
	margin-top: 16px;
`

export const BannerContentHeader = styled.div`
	${({ theme }) => css`
		display: flex;
		align-items: center;
		justify-content: flex-start;
		width: 100%;
		height: 62px;
		background-color: ${theme.colors.off_white};
	`}
`

export const PositionTitle = styled.p`
	${({ theme }) => css`
		margin-left: 76px;
		color: #5a615d;
	`}
`

export const TitleTitle = styled.p`
	${({ theme }) => css`
		margin-left: 200px;
		color: #5a615d;
	`}
`

export const StatusTitle = styled.p`
	${({ theme }) => css`
		margin-left: 375px;
		color: #5a615d;
		@media screen and (max-width: 1200px) {
			margin-left: 270px;
		}
		@media screen and (max-width: 992px) {
			margin-left: 158px;
		}
		@media screen and (max-width: 768px) {
			margin-left: 2px;
		}
	`}
`
