import React, { createElement, useEffect, useState } from 'react'
import { useDebounce } from 'use-debounce'
import { IProgram, IProgramPage } from '../../shared/interfaces/program'
import {
	useProgramsNow,
	useProgramsPage,
} from '../../shared/service/program.service'
import {
	renderImage,
	renderPresenters,
	renderTextTable,
	renderTextTableBadge,
} from './table'
import { ITableColumn, ITableRow, IViewProps } from './types'

import { DetailButton } from './styles'
import { Programs } from './view'

import { useHistory } from 'react-router-dom'
import {
	IAcessorAndOrder,
	ITableNavProps,
} from '../../shared/components/Table/types'
import { ModalAction } from '../../shared/interfaces/modal'
import { routesEnum } from '../Routes/enum'
import { useTypedSelector } from '../../shared/hooks/useTypedSelector'
import { MoreHorizontal } from 'react-feather'

function ProgramsContainer(): JSX.Element {
	const [modalIsOpen, setModalIsOpen] = useState(false)
	const [searchString, setSearchString] = useState('')
	const [tableRows, setTableRows] = useState<ITableRow[]>([])
	const [modalProgram, setModalProgram] = useState<IProgram | null>(null)

	const { user } = useTypedSelector(['token', 'user'])
	const listIsPresenters =
		user.roles.length === 1 && user.roles.includes('PRESENTER')

	const [searchDebounce] = useDebounce(searchString, 1000)

	const [sortBy, setSortBy] = useState<IAcessorAndOrder>({
		acessor: 'title',
		order: 1,
	})

	const [programPage, setProgramPage] = useState<IProgramPage>({
		page: 1,
		numberOfPages: 0,
		totalDocs: 0,
	})

	const { data, status, refetch } = useProgramsPage({
		maxItemsInPage: 5,
		pageIndex: programPage.page,
		searchString: searchDebounce,
		order: sortBy.order,
		sort: sortBy.acessor,
	})

	const history = useHistory()

	const { data: currentProgramDoc } = useProgramsNow()

	function handleModalOpen() {
		history.push(routesEnum.NEW_PROGRAM)
	}

	function handleUserChange(event: React.ChangeEvent<HTMLInputElement>) {
		setSearchString(event.target.value)
	}

	function handleModalClose() {
		setModalIsOpen(false)
		setModalProgram(null)
	}

	function handleRefetch() {
		refetch()
	}

	function renderEditIcon(program: IProgram) {
		if (listIsPresenters) {
			return null
		}

		function handleEdit() {
			history.push(`program/${program._id}`)
		}

		return (
			<DetailButton onClick={handleEdit} data-testid='button-details'>
				<MoreHorizontal style={{ strokeWidth: 1.5 }} />
			</DetailButton>
		)
	}

	function handleSortColumn(acessor: string, order: number) {
		if (order === 0) {
			setSortBy({
				acessor: 'title',
				order: -1,
			})
			return
		}
		setSortBy({
			acessor,
			order,
		})
	}

	function goToPage(pageIndex: number) {
		setProgramPage((state) => ({ ...state, page: pageIndex + 1 }))
	}

	const renderTableElements = (program: IProgram) => {
		return {
			image: renderImage(program.photoURL || ''),
			title:
				currentProgramDoc && currentProgramDoc._id === program._id
					? renderTextTableBadge(program.title)
					: renderTextTable(program.title),
			presenters: renderPresenters(program.presenters),
			editIcon: renderEditIcon(program),
		}
	}

	const tableColumns: ITableColumn[] = [
		{ Header: '', accessor: 'image', disableSortBy: true },
		{ Header: 'Nome', accessor: 'title', sortType: 'basic' },
		{ Header: 'Apresentadores', accessor: 'presenters', sortType: 'basic' },
		{ Header: '', accessor: 'editIcon', sortType: 'basic' },
	]

	const navProps: ITableNavProps = {
		nextPage: (pageIndex) => goToPage(pageIndex),
		previousPage: (pageIndex) => goToPage(pageIndex),
		gotoPage: (pageIndex) => goToPage(pageIndex),
		pageCount: programPage.numberOfPages,
		pageIndex: programPage.page - 1,
		totalDocs: programPage.totalDocs,
	}

	function parseData() {
		if (!data) return

		const rows = data?.docs.map(renderTableElements)

		if (!rows) return

		setTableRows(rows)

		setProgramPage({
			numberOfPages: data?.totalPages,
			page: data?.page || 1,
			totalDocs: data?.totalDocs,
		})
	}

	useEffect(parseData, [data, searchDebounce, currentProgramDoc])

	const viewProps: IViewProps = {
		handleModalOpen,
		handleUserChange,
		searchString,
		isLoading: status === 'loading',
		tableColumns,
		tableRows,
		handleModalClose,
		modalIsOpen,
		modalAction: modalProgram ? ModalAction.EDIT : ModalAction.CREATE,
		modalProgram,
		handleRefetch,
		handleSortColumn,
		sortBy,
		navProps,
		programPage,
	}

	return createElement(Programs, viewProps)
}

export default ProgramsContainer
