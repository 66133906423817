import styled, { css } from 'styled-components'
import { toRem } from '../../styles/function'

export const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		width: '429px',
		height: '445px',
		padding: '0px',
	},
}

export const DeleteModalStyles = styled.div`
	width: 100%;
	height: 100%;
	position: fixed;

	display: flex;
	align-items: center;
	justify-content: center;

	background: rgba(0, 0, 0, 0.56);
	color: black;
	top: 0;
	left: 0;
	z-index: 999;
`

export const DeleteModalContainer = styled.div`
	${({ theme }) => css`
		width: 427px;
		background-color: rgba(255, 255, 255);
		border-radius: 8px;
		
		border: 0.5px solid ${theme.colors.greenish_grey_light};
	`}
`

export const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 40px 24px 32px;

	.close-icon {
		color: ${({ theme }) => theme.colors.dark_graffiti};
		width: 24px;
		height: 24px;
		cursor: pointer;
		transition: 0.4s;
		:hover{
			color: ${({ theme }) => theme.colors.pure_black};
		}
	}
`
export const ModalTitle = styled.h1`
	${({ theme }) => css`
		font-size: ${toRem(24)};
		color: ${theme.colors.dark_graffiti};
		font-family: ${theme.fonts.radikal_light};
		font-weight: 400;
	`};
`
export const ModalSeparator = styled.div`
	background: ${({ theme }) => theme.colors.light_gray};
	height: 1px;
	width: 100%;
`

export const Content = styled.div`
	${({ theme }) => css`
		display: flex;
		align-items: center;
		justify-content: center;
		line-height: 24px;
		padding: 48px 24px;
		
		p {
			font-family: ${theme.fonts.radikal_regular};
			font-size: 16px;
			color: ${theme.colors.dark_graffiti};
			text-align: center;
		}
	`}
`

export const ButtonsContainer =	styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 56px;
	flex-direction: column;
`
