import styled, { css } from 'styled-components'

interface ITertiaryProps {
	primaryTheme: boolean
}

export const TertiaryButtonContainer = styled.button<ITertiaryProps>`
	${(props) => css`
		display: flex;
		align-items: center;
		justify-content: center;
		width: 46px;
		height: 46px;
		cursor: pointer;
		border: none;
		border-radius: 4px;
		background-color: ${props.primaryTheme
			? props.theme.colors.green
			: props.theme.colors.greenish_grey_medium};
		transition: 0.4s;

		.plus-icon {
			color: ${props.theme.colors.dark_graffiti};
		}

		.minus-icon {
			color: ${props.theme.colors.off_white};
		}

		:hover{ 
			background-color: ${props.primaryTheme
				? props.theme.colors.green_medium
				: props.theme.colors.greenish_grey};
		}
	`}
`
