import React from 'react'
import { Button } from '../../shared/components'
import SecondaryInput from '../../shared/components/SecondaryInput'
import Table from '../../shared/components/Table'

import { TableWrapper, TitlePage, UsersContainer } from './styles'
import { IViewProps } from './types'
import { useTypedSelector } from '../../shared/hooks/useTypedSelector'

export function Users(props: IViewProps) {
	const {
		handleUserChange,
		searchString,
		tableColumns,
		tableRows,
		isLoading,
		handleModalOpen,
		handleSortColumn,
		sortBy,
		navProps,
		userPage,
	} = props
	const { user } = useTypedSelector(['token', 'user'])
	const listIsPresenters =
		user.roles.length === 1 && user.roles.includes('PRESENTER')
	return (
		<UsersContainer>
			<>
				<header>
					<div>
						<TitlePage data-testid='title-page'>Usuários</TitlePage>
						<SecondaryInput
							className='search-input'
							placeholder='Buscar Usuário'
							value={searchString}
							onChange={handleUserChange}
						/>
					</div>
					{listIsPresenters ? null : (
						<Button.Root 
							onClick={handleModalOpen} 
							spacing={'12x24'} 
							data-testid='button-new-user'
						>
							<Button.Text text='Novo'/>
						</Button.Root>
					)}
				</header>

				<TableWrapper>
					<Table
						pageSize={5}
						columns={tableColumns as any}
						data={tableRows}
						isLoading={isLoading}
						getSelectedColumnAcessorAndOrder={handleSortColumn}
						sortBy={sortBy}
						navProps={navProps}
						extractPage={{ page: userPage.page, totalDocs: userPage.totalDocs }}
					/>
				</TableWrapper>
			</>
		</UsersContainer>
	)
}
