import { createElement, useEffect, useState } from 'react'

import {
	ETableAudioAcessor,
	IRouteParams,
	ITableColumn,
	IViewProps,
} from './types'
import { UserDetail } from './view'

import { useHistory, useParams } from 'react-router-dom'
import {
	ExtractTypeEarn,
	ExtractTypeSpend,
} from '../../shared/interfaces/extract'
import { IUserDetail } from '../../shared/interfaces/user'
import { useExtractPage } from '../../shared/service/extract.service'
import {
	useCustomerDetail,
	useMutationDeleteListener,
} from '../../shared/service/user.service'
import { routesEnum } from '../Routes/enum'
import { renderPoints, renderRescueSwitch, renderTextTable } from './table'

import { format } from 'date-fns'
import {
	IAcessorAndOrder,
	ITableNavProps,
} from '../../shared/components/Table/types'
import { IMusic } from '../../shared/interfaces/music'
import { useMutationUpdateRedeemProduct } from '../../shared/service/product.service'
import { handleSuccessToaster } from '../../shared/util/toaster'

function UserDetailContainer(): JSX.Element {
	const [isVisible, setIsVisible] = useState(false)
	const handleScroll = () => {
		const scrollTop = window.pageYOffset

		if (scrollTop > 300) {
			setIsVisible(true)
		} else {
			setIsVisible(false)
		}
	}
	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		})
	}
	window.addEventListener('scroll', handleScroll)

	const history = useHistory()

	const [deleteModalOpen, setDeleteModalOpen] = useState(false)
	const deleteUserMutation = useMutationDeleteListener()

	const params: IRouteParams = useParams()

	const [tableRows, setTableRows] = useState<any>([])

	const [sortBy, setSortBy] = useState<IAcessorAndOrder>({
		acessorToSend: 'createdAt',
		acessor: 'createdAt',
		order: -1,
	})

	const [extractPage, setExtractPage] = useState({
		page: 1,
		numberOfPages: 0,
		totalDocs: 0,
	})
	const {
		data,
		status: tableStatus,
		refetch,
	} = useExtractPage({
		maxItemsInPage: 5,
		pageIndex: extractPage.page,
		order: sortBy.order,
		sort: sortBy.acessorToSend,
		userID: params.userID,
	})
	const { mutateAsync: updateRedeemProduct } = useMutationUpdateRedeemProduct()

	async function handleChangeSwitch(productID: string) {
		const response = await updateRedeemProduct({ productID })

		if (!response) return

		await refetch()
		if (!response.redeemed) {
			handleSuccessToaster('Produto resgatado com sucesso!')
		}
	}

	const tableColumns: ITableColumn[] = [
		{ Header: 'Data', accessor: 'createdAt', sortType: 'basic' },
		{ Header: 'Detalhes', accessor: 'description', disableSortBy: true },
		{ Header: 'Resultado', accessor: 'result', disableSortBy: true },
		{ Header: 'Resgatado?', accessor: 'rescue', disableSortBy: true },
	]

	const { data: userDetail, status } = useCustomerDetail({
		customerID: params.userID,
	})

	function handleGoBack() {
		history.push(routesEnum.LISTENERS)
	}

	const renderTableElements = (extract: any) => {
		if (extract.type === ExtractTypeEarn.EARN_PLAYED_MUSIC) {
			const music = extract.referenceTypeID as IMusic

			const lastPlayDate = `${music.lastPlayed.toLocaleString().split(',')[0]}`

			return {
				createdAt: renderTextTable(
					format(new Date(lastPlayDate), 'dd/MM/yyyy, HH:mm'),
				),
				result: renderPoints(extract.beatcoins, extract.type),
				description: renderTextTable(extract.description),
			}
		} else {
			return {
				createdAt: renderTextTable(
					format(new Date(extract.createdAt), 'dd/MM/yyyy, HH:mm'),
				),
				result: renderPoints(extract.beatcoins, extract.type),
				description: renderTextTable(extract.description),
				rescue:
					extract.type === ExtractTypeSpend.SPEND_ESHOP
						? renderRescueSwitch({
								redeemed: !!extract.redeemed,
								onChange: () => handleChangeSwitch(extract._id as string),
								data: extract,

								// eslint-disable-next-line no-mixed-spaces-and-tabs
						  })
						: null,
			}
		}
	}

	function handleOpenModal() {
		setDeleteModalOpen(true)
		scrollToTop()
	}

	function handleCloseModal() {
		setDeleteModalOpen(false)
	}

	async function handleDeleteUser() {
		const deleteUserResponse = await deleteUserMutation.mutateAsync({
			userID: params.userID,
		})

		if (!deleteUserResponse) return

		handleCloseModal()
		handleSuccessToaster('Ouvinte excluido com sucesso!')
		history.push(routesEnum.LISTENERS)
	}

	function handleData() {
		if (!data) return

		const documentsData = data.pages[0].docs

		const rows = documentsData.map(renderTableElements)

		setTableRows(rows)

		setExtractPage({
			numberOfPages: data.pages[0].totalPages,
			page: data.pages[0].page || 1,
			totalDocs: data.pages[0].totalDocs,
		})
	}

	function handleSortColumn(acessor: string, order: number) {
		let acessorToSend = acessor
		if (order === 0) {
			setSortBy({
				acessorToSend: 'createdAt',
				acessor: 'createdAt',
				order: -1,
			})
			return
		}

		if (acessor === ETableAudioAcessor.date) {
			acessorToSend = 'date'
		}
		setSortBy({
			acessorToSend,
			acessor,
			order,
		})
	}

	function goToPage(pageIndex: number) {
		setExtractPage((state) => ({ ...state, page: pageIndex + 1 }))
	}

	const navProps: ITableNavProps = {
		nextPage: (pageIndex) => goToPage(pageIndex),
		previousPage: (pageIndex) => goToPage(pageIndex),
		gotoPage: (pageIndex) => goToPage(pageIndex),
		pageCount: extractPage.numberOfPages,
		pageIndex: extractPage.page - 1,
		totalDocs: extractPage.totalDocs,
	}
	useEffect(handleData, [data, params.userID])

	const userDocument = userDetail as IUserDetail

	const viewProps: IViewProps = {
		tableColumns,
		handleGoBack,
		isLoading: status === 'loading',
		data: userDocument,
		tableRows,
		tableLoading: tableStatus === 'loading',
		navProps,
		extractPage,
		sortBy,
		handleSortColumn,
		handleDeleteUser,
		handleCloseModal,
		handleOpenModal,
		deleteModalOpen,
	}

	return createElement(UserDetail, viewProps)
}

export default UserDetailContainer
