import styled, { css } from 'styled-components'
import { toRem } from '../../shared/styles/function'

export const ListenerWrapper = styled.div`
	header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 32px;

		div {
			display: flex;
			align-items: center;

			.search-input {
				margin-left: 48px;
			}
		}
	}
`
export const TableWrapper = styled.div`
	margin-top: 16px;
`

export const DetailButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	background: transparent;
	border: none;
	cursor: pointer;
	padding: 10px;
	border-radius: 50%;
	transition: 0.4s;
	color: ${({ theme }) =>
		css`
			${theme.colors.dark_graffiti}
		`};

	:hover {
		background-color: ${({ theme }) =>
			css`
				${theme.colors.off_white}
			`};
		color: ${({ theme }) =>
			css`
				${theme.colors.pure_black}
			`};
	}
`

export const TabWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`

export const TabsContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 40px;
	margin-top: 40px;
`

export const ReloadButtonWrapper = styled.div`
	display: flex;
	align-items: center;
`

export const Tab = styled.div`
	display: flex;
	gap: 16px;
	cursor: pointer;
`

export const ActiveBorder = styled.div`
	${({ theme }) => css`
		display: flex;
		margin-top: 20px;
		width: calc(100% - 40px);
		height: 2px;
		background-color: ${theme.colors.green};
	`}
`
export const InactiveBorder = styled.div`
	display: flex;
	margin-top: 20px;
	width: 100%;
	height: 2px;
	background-color: transparent;
`

export const TabWrapperContainer = styled.div`
	${({ theme }) => css`
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	`}
`
export const TabActiveText = styled.p`
	${({ theme }) => css`
		font-size: 24px;
		font-style: normal;
		font-weight: 300;
		line-height: 32px;
		color: ${theme.colors.dark_graffiti};
	`}
`

export const TabInactiveText = styled.p`
	${({ theme }) => css`
		font-size: 24px;
		font-style: normal;
		font-weight: 300;
		line-height: 32px;
		color: ${theme.colors.greenish_grey_medium};
	`}
`

export const NumberActiveContainer = styled.div`
	${({ theme }) => css`
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 4px 8px;
		background-color: ${theme.colors.green};
		border-radius: 19px;
	`}
`

export const NumberInactiveContainer = styled.div`
	${({ theme }) => css`
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 4px 8px;
		background-color: ${theme.colors.light_gray};
		border-radius: 19px;
	`}
`

export const NumberText = styled.p`
	${({ theme }) => css`
		margin-top: 4px;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 22px;
		color: ${theme.colors.dark_graffiti};
	`}
`
export const NumberTextInactive = styled.p`
	${({ theme }) => css`
		margin-top: 4px;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 22px;
		color: ${theme.colors.greenish_grey_medium};
	`}
`

export const ImageWrapper = styled.div`
	${({ theme }) => css`
		width: 46px;
		height: 46px;
		overflow: hidden;
		border-radius: 2px;
		background: ${theme.colors.light_gray};

		.report-image {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	`}
`

export const TableImagePlaceholder = styled.div`
	${({ theme }) => css`
		width: 46px;
		height: 46px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 2px;
		background: ${theme.colors.off_white};
		border: 1px solid ${theme.colors.light_gray};
		border-radius: 4px;

		.placeholder-icon {
			width: ${toRem(20)};
			height: ${toRem(20)};
			color: ${theme.colors.greenish_grey_light};
		}
	`}
`

interface IButtonStatusProps {
	isActive: boolean
}

export const WithDrawButtonWrapper  = styled.button<IButtonStatusProps>`
${({theme, isActive}) => css`
		background: ${isActive ? theme.colors.green : theme.colors.light_gray};
		border:none;
		padding: 4px 8px;
		border-radius:4px;
		font-size:12px;
		color: ${isActive ? theme.colors.dark_graffiti : theme.colors.greenish_grey};
		font-family: ${theme.fonts.radikal_regular};
		cursor:${isActive ? 'pointer' : 'not-allowed'};

`}
`