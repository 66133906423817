import React from 'react'
import { Modal } from '../../../shared/components'
import { IUseModalReturn } from '../../../shared/hooks/useModal'
import * as S from './styles'

interface IRaffleConfirmationModalProps extends IUseModalReturn {
	promotionTitle: string
}

export function RaffleConfirmationModal({
	isVisible,
	onCancel,
	onConfirm,
	isLoading,
	promotionTitle,
}: IRaffleConfirmationModalProps) {
	return (
		<Modal.Root
			isVisible={isVisible}
			title='Sortear Ouvinte'
			onClose={onCancel}
		>
			<Modal.AnyElement>
				<S.ParagraphWithPromotionTitle>
					Você clicou para sortear um ouvinte para a promoção{' '}
					<span className='promotion-title'>{promotionTitle}.</span>
				</S.ParagraphWithPromotionTitle>
			</Modal.AnyElement>
			<Modal.ContentSpacing size={24} />
			<Modal.ParagraphText text='Deseja confirmar esta ação? Após a confirmação, iremos sortear de forma aleatória.' />
			<Modal.ContentSpacing size={56} />
			<Modal.Button
				theme='dark'
				spacing='12x24'
				onClick={onConfirm}
				isLoading={isLoading}
				text='Realizar Sorteio'
			/>
			<Modal.ContentSpacing size={16} />
			<Modal.Button
				theme='light'
				spacing='12x24'
				onClick={onCancel}
				text='Cancelar'
			/>
		</Modal.Root>
	)
}
