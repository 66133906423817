import React, { Fragment } from 'react'
import Modal from 'react-modal'
import * as S from './styles'
import { IPurchaseModalProps } from './types'
import ModalRoot from '../CustomModal/ModalRoot'

function PurchaseModal(props: IPurchaseModalProps) {
  const {isActive,onRequestClose, data,handleSubmit} = props

  if(!data) return <Fragment/>

  return (
    <ModalRoot onClose={onRequestClose} isVisible={isActive} title='Confirmar Entrega?'>
      <S.Image src={data.referenceTypeID.photoURL} alt="" />
      <S.ParagraphContent>Deseja confirmar a entrega do produto <span>{data.referenceTypeID.title}</span> para o ouvinte <span>{data.user.name}</span> ?</S.ParagraphContent>
      <S.Button onClick={handleSubmit}>Confirmar</S.Button>
      <S.ShawedButton onClick={onRequestClose}>Cancelar</S.ShawedButton>
    </ModalRoot>
    
  )
}

export { PurchaseModal }