import { grid } from '../../styles/variables'
import styled, { css } from 'styled-components'
import {
	ellipsis,
	fontType,
	mediaQuery,
	toRem,
	transition,
} from '../../styles/function'

export const LoadingContent = styled.section`
	display: flex;
	margin-top: ${toRem(50)};
	justify-content: center;
`

export const TableWrapper = styled.div<any>`
	${({ theme }) => css`
		${fontType(0)};
		font-size: ${toRem(12)};
		overflow-x: auto;
		padding-bottom: 48px;
		width: 100%;

		table {
			&.loading {
				opacity: 0.4;
			}

			border-collapse: collapse;
			&.separate {
				border-collapse: separate;
			}

			width: 99.9%;
			min-width: ${grid.sm}px;
			thead {
				background: ${({ theme }) => theme.colors.off_white};

				tr {
					th {
						text-align: left;
						color: ${({ theme }) => theme.colors.greenish_grey};
						font-size: ${toRem(14)};
						${fontType(0)};
						padding: 20px 16px;

						div{
							display: flex;
							align-items: center;

							.icon-sorting{
								margin-left: 4px;
								color: ${({ theme }) => theme.colors.dark_graffiti};
								font-size: 16px;
							}
						}
					}
				}
			}
			tbody {
				tr {
					border-bottom: 2px solid #f5f5f5;
					background: ${({ theme }) => theme.colors.white};

					&:nth-child(odd) {
						background: ${theme.colors.white};
					}

					td {
						padding: 16px;
						vertical-align: middle;
						font-size: ${toRem(14)};
						color: ${({ theme }) => theme.colors.dark_graffiti};
						${fontType(0)};
					}
				}
			}

			.ellipsis {
				${ellipsis()}
				max-width: 150px;

				${mediaQuery('sm')(`
					width: 200px;
				`)}

				${mediaQuery('xs')(`
					width: 50px;
				`)}
			}
		}

		.table-footer {
			margin-top: 16px;

			.result-span {
				display: inline-block;
				margin-top: 12px;
				margin-bottom: 18px;
				font-size: ${toRem(12)};
				${fontType(0)}
				color: ${({ theme }) => theme.colors.greenish_grey_medium};
			}
			
			.pagination {
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 4px;
				color: ${({ theme }) => theme.colors.dark_graffiti};
				margin-top: 24px;
				
				
				.button {
					border: none;
					background: none;
					cursor: pointer;
					height: 22px;
					color: ${theme.colors.dark_graffiti};
					margin-bottom: 2px;

					${transition()};

					&[disabled] {
						color: ${theme.colors.light_gray};
					}
				}

				.page-numbers {
					display: flex;
					align-items: center;
					gap: 2px;

					.page-number {
						padding: 4px 8px 2px;
						border-radius: 4px;
						color: ${({ theme }) => theme.colors.dark_graffiti};

						${transition()};

						cursor: pointer;

						font-size: ${toRem(12)};
						font-family: ${({ theme }) => theme.fonts.radikal_medium};

						&.active {
							background: ${({ theme }) => theme.colors.dark_graffiti};
							color: white;
						}
					}
				}
			}
		}
	`}
`

export const PlaceholderWrapper = styled.div`
	${({ theme }) => css`
		margin-top: ${toRem(50)};
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		img {
			width: 390px;
			height: 128px;
		}
		p {
			margin-top: 40px;
			text-align: center;
			color: ${theme.colors.greenish_grey_medium};
			font-size: 16px;
			${fontType(0)}
		}
	`}
`
