import React from 'react'
import { ErrorText, ViewErrorWrapper } from './styles'
import { IViewErrorProps } from './types'

function ViewErrors(props: IViewErrorProps) {
	const { data } = props

	return (
		<ViewErrorWrapper>
			{data.map((message) => {
				return <ErrorText key={message}>* {message}</ErrorText>
			})}
		</ViewErrorWrapper>
	)
}

export default ViewErrors
