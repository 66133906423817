import {
	QueryFunctionContext,
	UseMutationResult,
	UseQueryResult,
	useMutation,
	useQuery,
} from 'react-query'
import axiosInstance from './axiosInstance'
import {
	IPaginationResponse,
	PaginateParticipantsListRequest,
	PaginatedRequest,
} from '../interfaces/pagination'

import { QUERY_KEYS } from '../util/reactQuery'
import { AxiosError } from 'axios'
import { IError } from '../interfaces/error'
import { IUser } from '../interfaces/user'
import { IPromotion, IPromotionRequest } from '../interfaces/promotion'

// eslint-disable-next-line @typescript-eslint/ban-types
export type IFetchPromotionRequest = PaginatedRequest<{}>

async function fetchPromotion(
	context: QueryFunctionContext<[unknown, IFetchPromotionRequest]>,
): Promise<IPaginationResponse<IPromotion>> {
	const [_, params] = context.queryKey
	const { maxItemsInPage, pageIndex, searchString, order, sort } = params
	const response = await axiosInstance.post('/promotion/get-page', {
		pageIndex,
		searchString,
		order,
		sort,
		maxItemsInPage,
	})

	return response.data
}

export function usePromotionsPage({
	maxItemsInPage,
	pageIndex,
	searchString,
	order,
	sort,
}: IFetchPromotionRequest): UseQueryResult<IPaginationResponse<IPromotion>> {
	const result = useQuery(
		[
			QUERY_KEYS.PROMOTIONS,
			{ maxItemsInPage, pageIndex, searchString, order, sort },
		],
		fetchPromotion,
	)
	return result
}

export async function updatePromotion(
	promotionID: string,
	artworkURL: string,
	title: string,
	startDate: Date,
	endDate: Date,
	description: string,
	stockQuantity: number,
	regulation: string,
): Promise<IPromotion> {
	const response = await axiosInstance.patch(`/promotion/${promotionID}`, {
		promotionID: promotionID,
		title: title,
		artworkURL: artworkURL,
		startDate: startDate,
		endDate: endDate,
		description: description,
		stockQuantity: stockQuantity,
		regulation: regulation,
	})
	return response.data
}

export const useUpdatePromotion = () => {
	return useMutation((data: IPromotion) => {
		const {
			_id,
			artworkURL,
			title,
			startDate,
			endDate,
			description,
			stockQuantity,
			regulation,
		} = data

		return updatePromotion(
			_id,
			artworkURL,
			title,
			startDate,
			endDate,
			description,
			stockQuantity,
			regulation,
		)
	})
}

interface IDeletePromotion {
	promotionId: string
}

export async function deletePromotion({ promotionId }: IDeletePromotion) {
	const response = await axiosInstance.delete(`/promotion/${promotionId}`)
	return response.data
}

export function useDeletePromotion(): UseMutationResult<
	IPromotion,
	AxiosError<IError>,
	IDeletePromotion
> {
	return useMutation(deletePromotion)
}

interface IUpdateStatus {
	id: string
	status: 'ACTIVE' | 'INACTIVE'
}

export async function updateStatus({ id, status }: IUpdateStatus) {
	const response = await axiosInstance.patch(
		`/promotion/change-status/${id}/${status}`,
	)
	return response.data
}

export function useUpdatePromotionStatus(): UseMutationResult<
	IPromotion,
	AxiosError<IError>,
	IUpdateStatus
> {
	return useMutation(updateStatus)
}

interface IPromotionProps {
	promotionID: string
}

async function promotionAccomplish({ promotionID }: IPromotionProps) {
	const response = await axiosInstance.patch(
		`/promotion/accomplish/${promotionID}`,
	)

	return response.data
}

export function useAccomplishPromotion(): UseMutationResult<
	IPromotion,
	AxiosError<IError>,
	any
> {
	return useMutation(promotionAccomplish)
}

async function fetchPromotionDetail(promotionId: string): Promise<IPromotion> {
	const response = await axiosInstance.get(`/promotion/${promotionId}`)

	return response.data
}

export function usePromotionDetail(
	promotionId: string,
): UseQueryResult<IPromotion> {
	return useQuery(
		[QUERY_KEYS.PROMOTION_DETAIL, promotionId],
		async () => fetchPromotionDetail(promotionId),
	)
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type IFetchParticipantsListRequest = PaginateParticipantsListRequest<{}>

async function patchPromotion(id: string) {
	const { data } = await axiosInstance.patch(`/promotion/accomplish/${id}`)
	return data
}

// Hook function
export function usePatchPromotion() {
	return useMutation(patchPromotion)
}

async function checkValidParticipants(
	context: QueryFunctionContext<[unknown, string]>
): Promise<{ hasValidParticipants: boolean }> {
	const [, promotionId] = context.queryKey

	const response = await axiosInstance.get(`/promotion/check-valid-participants/${promotionId}`)

	return response.data
}

export function useCheckValidParticipants(
	promotionId: string,
) {
	return useQuery(
		[QUERY_KEYS.PROMOTION_CHECK_VALID_PARTICIPANTS, promotionId],
		checkValidParticipants,
	)
}

export async function fetchPrizeDrawn(promotionId: string): Promise<IUser> {
	const response = await axiosInstance.get(
		`/promotion/prize-draw/${promotionId}`,
	)
	return response.data.userDocument
}

interface IConcludePromotionParams {
	promotionId: string
	participantId: string
}

async function concludePromotion(params: IConcludePromotionParams): Promise<IPromotion> {
	const response = await axiosInstance.get(`/promotion/confirm-prize-draw/${params.promotionId}/${params.participantId}`)

	return response.data
}

export function useConcludePromotion() {
	return useMutation(concludePromotion)
}

export interface IParticipantsPage {
	pageIndex: number
	maxItemsInPage: number
	promotionID: string
	searchString?: string
	sort?: string
	order?: number
	onlyWinners?: boolean
}

async function fetchParticipantsPage(
	context: QueryFunctionContext<[unknown, IParticipantsPage]>,
): Promise<IPaginationResponse<IUser>> {
	const [, params] = context.queryKey
	const { maxItemsInPage, pageIndex, searchString = '', order, sort, promotionID, onlyWinners = false } =
		params
	const response = await axiosInstance.post(
		`/promotion/participants/${promotionID}?onlyWinners=${onlyWinners}`,
		{
			pageIndex,
			searchString,
			order,
			sort,
			maxItemsInPage,
		},
	)
	return response.data
}

export function usePaginateParticipants(
	params: IParticipantsPage,
): UseQueryResult<IPaginationResponse<any>> { // TODO RESPONSE TYPE UseQueryResult<IPaginationResponse<IParticipants>>
	return useQuery(
		[QUERY_KEYS.PROMOTION_PARTICIPANTS, { ...params }],
		fetchParticipantsPage,
	)
}

async function createPromotion(props: IPromotionRequest): Promise<IPromotion> {
	const response = await axiosInstance.post('/promotion', { ...props })

	return response.data
}

export function useCreatePromotion() {
	return useMutation(createPromotion)
}
