import React from 'react'
import { FloatLabelInputWrapper } from './styles'
import { IFloatLabelDateInputProps } from './types'

import calendarIcon from '../../../assets/images/calendar.svg'

import NumberFormat from 'react-number-format'
import { Input } from '../Input'

function FloatLabelDateInput({
	label,
	className,
	setDate,
	date,
	...rest
}: IFloatLabelDateInputProps) {
	return (
		<FloatLabelInputWrapper className={className}>
			<NumberFormat
				className='fli-input'
				required
				format='##:##'
				mask='_'
				placeholder={label}
				value={date}
				onValueChange={setDate}
				customInput={Input}
				{...rest}
			/>

			<img src={calendarIcon} alt='calendar icon' className='icon' />
		</FloatLabelInputWrapper>
	)
}

export { FloatLabelDateInput }
