import React from 'react'
import { ReactComponent as XIcon } from '../../../assets/icons/x.svg'

import * as S from './styles'
import { ICustomModalProps } from './types'

function ModalRoot(props: ICustomModalProps) {
	const { isVisible, title, onClose, children } = props

	if (!isVisible) return null

	const handleOutsideClick = (event: any) => {
		if (event.target.id === 'modal' && onClose) onClose()
	}

	return (
		<S.ModalBackground id='modal' onClick={handleOutsideClick}>
			<S.ModalContainer>
				<S.Header>
					<h1 className='title'>{title}</h1>
					{!!onClose && <XIcon className='close-icon' onClick={onClose} />}
				</S.Header>
				<S.Content>{children}</S.Content>
			</S.ModalContainer>
		</S.ModalBackground>
	)
}

export default ModalRoot
