import styled, { css } from 'styled-components'
import { ButtonShapeTypes, ButtonThemeTypes } from './types'

export const ButtonRoot = styled.button<{ buttonTheme: ButtonThemeTypes, buttonShape: ButtonShapeTypes }>`
    background: none;
    border: none;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;

    border-radius: ${({ buttonShape }) => buttonShape === 'round' ? 50 : 4}px;

    ${({ buttonTheme, theme }) => {
        switch(buttonTheme) {
            case 'light':
                return css`
                    background-color: ${theme.colors.white};
                    transition: 0.2s;
                    &:hover {
                        background-color: ${theme.colors.off_white};
                        cursor: pointer;
                    }
                    &:disabled {
                        background-color: ${theme.colors.light_gray};
                        cursor: default;
                        span.button-text, svg.button-icon {
                            color: ${theme.colors.greenish_grey_light};
                            stroke: ${theme.colors.greenish_grey_light};
                        }
                    }
                    span.button-text, svg.button-icon {
                        color: ${theme.colors.dark_graffiti};
                        stroke: ${theme.colors.dark_graffiti};
                    }
                `
            case 'dark':
                return css`
                    background-color: ${theme.colors.greenish_grey_medium};
                    transition: 0.2s;
                    &:hover {
                        background-color: ${theme.colors.greenish_grey};
                        cursor: pointer;
                        color: ${theme.colors.greenish_grey_light};
                    }
                    &:disabled {
						background-color: ${theme.colors.light_gray};
                        cursor: default;
                        span.button-text, svg.button-icon {
                            color: ${theme.colors.greenish_grey_light};
                            stroke: ${theme.colors.greenish_grey_light};
                        }
                    }
                    span.button-text, svg.button-icon {
                        color: ${theme.colors.off_white};
                        stroke: ${theme.colors.off_white};
                    }
                `
			case 'delete':
				return css`
					background-color: ${theme.colors.greenish_grey_medium};
					transition: 0.2s;
					&:hover {
						background-color: ${theme.colors.red};
                        cursor: pointer;
                    }
                    &:disabled {
                        background-color: ${theme.colors.light_gray};
                        cursor: default;
                        span.button-text, svg.button-icon {
                            color: ${theme.colors.greenish_grey_light};
                            stroke: ${theme.colors.greenish_grey_light};
                        }
                    }
                    span.button-text, svg.button-icon {
                        color: ${theme.colors.off_white};
                        stroke: ${theme.colors.off_white};
                    }
                `
			case 'pure_black':
				return css`
					background-color: ${theme.colors.pure_black};
					transition: 0.2s;
					&:hover {
						background-color: ${theme.colors.black_graffiti};
                        cursor: pointer;
                    }
                    &:disabled {
                        background-color: ${theme.colors.light_gray};
                        cursor: default;
                        span.button-text, svg.button-icon {
                            color: ${theme.colors.greenish_grey_light};
                            stroke: ${theme.colors.greenish_grey_light};
                        }
                    }
                    span.button-text, svg.button-icon {
                        color: ${theme.colors.green};
                        stroke: ${theme.colors.green};
                    }
                `
            default:
                return css`
                    background-color: ${theme.colors.green};
                    transition: 0.2s;
                    &:hover {
                        background-color: ${theme.colors.green_medium};
                        cursor: pointer;
                    }
                    &:disabled {
                        background-color: ${theme.colors.light_gray};
                        cursor: default;
                        span.button-text, svg.button-icon {
                            color: ${theme.colors.greenish_grey_light};
                            stroke: ${theme.colors.greenish_grey_light};
                        }
                    }
                    span.button-text, svg.button-icon {
                        color: ${theme.colors.dark_graffiti};
                        stroke: ${theme.colors.dark_graffiti};
                    }
                `
        }
    }}
`

export const ButtonText = styled.span`
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    font-style: normal;
    font-family: ${({ theme }) => theme.fonts.radikal_regular};
`
