import styled from 'styled-components'
import { theme } from '../../styles/theme'

export const SelectStyles = {
	backgroundColor: theme.colors.white,
}

export const SelectChipStyles = {
	backgroundColor: theme.colors.greenish_dark,
	color: theme.colors.greenish_dark_shade,
	fontSize: 12,
	fontWeight: 500,
}

export const SelectWrapper = styled.div`
	
`
