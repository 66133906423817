import React, { useCallback, useState } from 'react'

import * as S from './styles'
import SecondaryInput from '../../../shared/components/SecondaryInput'
import Table from '../../../shared/components/Table'
import { useTablePagination } from '../../../shared/hooks/useTablePagination'
import { IParticipants } from '../../Promotions/types'
import { renderImage, renderTextTable } from '../../Users/table'
import { usePaginateParticipants } from '../../../shared/service/promotions.service'

type TabType = 'participants' | 'winners'

interface IParticipantsAndWinnersTabsProps {
	promotionID: string
}

const tableColumns = [
	{ Header: '', accessor: 'image', disableSortBy: true },
	{
		Header: 'Nome',
		accessor: 'name',
		sortType: 'basic',
	},
	{
		Header: 'E-mail',
		accessor: 'email',
		sortType: 'basic',
	},
	{
		Header: 'CPF',
		accessor: 'personalFiscalID',
		sortType: 'basic',
	},
]

const tableRowsFormatter = (participantsDocs: IParticipants[]) =>
	participantsDocs.map((participant) => ({
		image: renderImage(participant.photoURL),
		name: renderTextTable(participant.name),
		email: renderTextTable(participant.email),
		personalFiscalID: renderTextTable(participant.personalFiscalID || ''),
	}))

export function ParticipantsAndWinnersTabs({
	promotionID,
}: IParticipantsAndWinnersTabsProps) {
	const [activeTab, setActiveTab] = useState<TabType>('participants')

	const promotionParticipantsTablePagination = useTablePagination({
		serviceHook: usePaginateParticipants,
		serviceAdditionalParams: {
			promotionID,
			onlyWinners: false,
		},
		tableFormatting: {
			tableColumns,
			tableRowsFormatter,
		},
		serviceParamsBase: {
			maxItemsInPage: 5,
		},
	})

	const promotionWinnersTablePagination = useTablePagination({
		serviceHook: usePaginateParticipants,
		serviceAdditionalParams: {
			promotionID,
			onlyWinners: true,
		},
		tableFormatting: {
			tableColumns,
			tableRowsFormatter,
		},
	})

	const currentTablePagination =
		activeTab === 'participants'
			? promotionParticipantsTablePagination
			: promotionWinnersTablePagination

	const handleChangeTab = useCallback((tab: TabType) => {
		setActiveTab(tab)
	}, [])

	const handleChangeSearchString = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			currentTablePagination.setSearchString(e.target.value)
		},
		[currentTablePagination],
	)

	return (
		<S.Container>
			<S.Tabs>
				<button
					className={`tab ${activeTab === 'participants' ? 'active' : ''} ${
						!promotionParticipantsTablePagination.pageProps.totalDocs
							? 'no-data'
							: ''
					}`}
					onClick={() => handleChangeTab('participants')}
				>
					<span className='tab-title'>Participantes</span>
					<span className='tab-quantity'>
						{promotionParticipantsTablePagination.pageProps.totalDocs}
					</span>
				</button>
				<button
					className={`tab ${activeTab === 'winners' ? 'active' : ''} ${
						!promotionWinnersTablePagination.pageProps.totalDocs
							? 'no-data'
							: ''
					}`}
					onClick={() => handleChangeTab('winners')}
				>
					<span className='tab-title'>Sorteados</span>
					<span className='tab-quantity'>
						{promotionWinnersTablePagination.pageProps.totalDocs}
					</span>
				</button>
			</S.Tabs>
			<div className='search-input-wrapper'>
				<SecondaryInput
					className='search-input'
					placeholder='Buscar Ouvinte'
					value={currentTablePagination.searchString}
					onChange={handleChangeSearchString}
				/>
			</div>

			<div
				className={
					!currentTablePagination.pageProps.totalDocs
						? 'table-placeholder-wrapper'
						: ''
				}
			>
				<Table
					data={currentTablePagination.tableRows}
					columns={currentTablePagination.tableColumns as any}
					navProps={currentTablePagination.navProps}
					extractPage={currentTablePagination.pageProps}
					isLoading={currentTablePagination.isLoading}
					placeholder={
						currentTablePagination.searchString
							? 'Nenhum ouvinte encontrado.'
							: activeTab === 'participants'
							? 'Ainda não há participantes nesta promoção.'
							: 'Ainda não há participantes sorteados nesta promoção.'
					}
				/>
			</div>
		</S.Container>
	)
}
