import React, { createElement, useEffect, useState } from 'react'
import { IProductPage, Product } from '../../shared/interfaces/product'
import {
	useMutationChangeProductStatus,
	useMutationUpdateProduct,
	useProductsPage,
} from '../../shared/service/product.service'
import { DetailButton } from './styles'
import { renderImage, renderSwitchTable, renderTextTable } from './table'
import { ITableColumn, ITableRow, IViewProps } from './types'

import { format } from 'date-fns'

import { useDebounce } from 'use-debounce'
import {
	IAcessorAndOrder,
	ITableNavProps,
} from '../../shared/components/Table/types'
import { ModalAction } from '../../shared/interfaces/modal'
import { formatNumber } from '../../shared/util/number'
import { handleSuccessToaster } from '../../shared/util/toaster'
import { EShop } from './view'
import { MoreHorizontal } from 'react-feather'

function EShopContainer(): JSX.Element {
	const [searchString, setSearchString] = useState('')
	const [tableRows, setTableRows] = useState<ITableRow[]>([])
	const [searchDebounce] = useDebounce(searchString, 1000)
	const [modalIsOpen, setModalIsOpen] = useState(false)
	const [currentProduct, setCurrentProduct] = useState<Product | null>(null)

	const [sortBy, setSortBy] = useState<IAcessorAndOrder>({
		acessor: 'title',
		order: 1,
	})

	const [productPage, setProductPage] = useState<IProductPage>({
		page: 1,
		numberOfPages: 0,
		totalDocs: 0,
	})

	const { data, status, refetch } = useProductsPage({
		maxItemsInPage: 5,
		pageIndex: productPage.page,
		searchString: searchDebounce,
		order: sortBy.order,
		sort: sortBy.acessor,
	})
	const updateProductStatus = useMutationChangeProductStatus()

	const tableColumns: ITableColumn[] = [
		{ Header: '', accessor: 'image', disableSortBy: true },
		{ Header: 'Nome', accessor: 'title', sortType: 'basic' },
		{ Header: 'Valor', accessor: 'price', sortType: 'basic' },
		{ Header: 'Período', accessor: 'period', sortType: 'basic' },
		{ Header: 'Estoque', accessor: 'inventory', sortType: 'basic' },
		{
			Header: 'Produto Ativo?',
			accessor: 'isActive',
			sortType: 'basic',
			disableSortBy: true,
		},
		{
			Header: '',
			accessor: 'editIcon',
			sortType: 'basic',
			disableSortBy: true,
		},
	]

	function handleStringChange(event: React.ChangeEvent<HTMLInputElement>) {
		setSearchString(event.target.value)
	}

	function handleModalClose() {
		setModalIsOpen(false)
		setCurrentProduct(null)
		refetch()
	}

	async function handleSwitchValueUpdate(productID: string) {
		const updateResponse = await updateProductStatus.mutateAsync({
			id: productID,
		})

		if (!updateResponse) return
		handleSuccessToaster('Produto atualizado com sucesso!')
		refetch()
	}

	function renderEditIcon(product: Product) {
		function handleEdit() {
			setCurrentProduct(product)
			setModalIsOpen(true)
		}

		return (
			<DetailButton onClick={handleEdit} data-testid='button-details'>
				<MoreHorizontal style={{ strokeWidth: 1.5 }} />
			</DetailButton>
		)
	}

	function handleSortColumn(acessor: string, order: number) {
		if (order === 0) {
			setSortBy({
				acessor: 'name',
				order: -1,
			})
			return
		}
		setSortBy({
			acessor,
			order,
		})
	}

	function goToPage(pageIndex: number) {
		setProductPage((state) => ({ ...state, page: pageIndex + 1 }))
	}

	const navProps: ITableNavProps = {
		nextPage: (pageIndex) => goToPage(pageIndex),
		previousPage: (pageIndex) => goToPage(pageIndex),
		gotoPage: (pageIndex) => goToPage(pageIndex),
		pageCount: productPage.numberOfPages,
		pageIndex: productPage.page - 1,
		totalDocs: productPage.totalDocs,
	}

	const renderTableElements = (product: Product) => {
		const productPrice = formatNumber(product.price)
		const productInventory = formatNumber(product.inventory)

		const formatedEndDate = format(
			new Date(product.period.endDate),
			'dd/MM/yyyy',
		)

		return {
			image: renderImage(product.photoURL),
			title: renderTextTable(product.title),
			price: renderTextTable(productPrice),
			inventory: renderTextTable(productInventory),
			period: renderTextTable(`Ativo até ${formatedEndDate}, às 23:59`),
			isActive: renderSwitchTable({
				productID: product._id,
				isActive: product.isActive,
				handleSwitchValueUpdate,
			}),
			editIcon: renderEditIcon(product),
		}
	}

	function handleRefetch() {
		refetch()
	}

	function handleOpenModal() {
		setModalIsOpen(true)
	}

	function parseData() {
		if (!data) return

		const rows = data?.docs.map(renderTableElements)

		if (!rows) return

		setTableRows(rows)

		setProductPage({
			numberOfPages: data?.totalPages,
			page: data?.page || 1,
			totalDocs: data?.totalDocs,
		})
	}

	useEffect(parseData, [data, searchDebounce])

	const viewProps: IViewProps = {
		handleStringChange,
		searchString,
		tableColumns,
		isLoading: status === 'loading',
		tableRows,
		modalIsOpen,
		handleModalClose,
		currentProduct,
		handleRefetch,
		handleOpenModal,
		handleSortColumn,
		sortBy,
		navProps,
		productPage,
		modalAction: currentProduct ? ModalAction.EDIT : ModalAction.CREATE,
	}

	return createElement(EShop, viewProps)
}

export default EShopContainer
