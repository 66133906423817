/* eslint-disable */
import { Button } from '../../shared/components'
import SecondaryInput from '../../shared/components/SecondaryInput'
import Table from '../../shared/components/Table'
import { useTypedSelector } from '../../shared/hooks/useTypedSelector'
import { ProgramsContainer, TableWrapper, TitlePage } from './styles'
import { IViewProps } from './types'

export function Programs(props: IViewProps) {
	const {
		handleModalOpen,
		handleUserChange,
		searchString,
		isLoading,
		tableColumns,
		tableRows,
		handleModalClose,
		modalIsOpen,
		modalAction,
		modalProgram,
		handleRefetch,
		handleSortColumn,
		sortBy,
		navProps,
		programPage,
	} = props
	const { user } = useTypedSelector(['token', 'user'])
	const listIsPresenters =
		user.roles.length === 1 && user.roles.includes('PRESENTER')

	return (
		<ProgramsContainer>
			<header>
				<div>
					<TitlePage data-testid='title-page'>Programas</TitlePage>
					<SecondaryInput
						className='search-input'
						placeholder='Buscar Programa'
						value={searchString}
						onChange={handleUserChange}
					/>
				</div>
				{listIsPresenters ? null : (
					<Button.Root onClick={handleModalOpen} spacing={'12x24'} data-testid='button-new-program'>
						<Button.Text text='Novo'/>
					</Button.Root>
				)}
			</header>

			<TableWrapper>
				<Table
					pageSize={5}
					columns={tableColumns as any}
					data={tableRows}
					isLoading={isLoading}
					getSelectedColumnAcessorAndOrder={handleSortColumn}
					sortBy={sortBy}
					navProps={navProps}
					extractPage={{
						page: programPage.page,
						totalDocs: programPage.totalDocs,
					}}
				/>
			</TableWrapper>
		</ProgramsContainer>
	)
}
