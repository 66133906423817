import React from 'react'
import { Modal } from '../../../shared/components'
import { IUseModalReturn } from '../../../shared/hooks/useModal'

export function RedoRaffleConfirmationModal({
	isVisible,
	onCancel,
	onConfirm,
	isLoading,
}: IUseModalReturn) {
	return (
		<Modal.Root
			isVisible={isVisible}
			title='Sortear Novamente'
			onClose={onCancel}
		>
			<Modal.ParagraphText text='Deseja realizar um novo sorteio? Ao sortear novamente, o ouvinte sorteado anteriormente será desconsiderado.' />
			<Modal.ContentSpacing size={56} />
			<Modal.Button
				theme='dark'
				spacing='12x24'
				onClick={onConfirm}
				isLoading={isLoading}
				text='Sortear Novamente'
			/>
			<Modal.ContentSpacing size={16} />
			<Modal.Button
				theme='light'
				spacing='12x24'
				onClick={onCancel}
				text='Cancelar'
			/>
		</Modal.Root>
	)
}
