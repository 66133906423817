import React from 'react'
import ModalMusic from '../../shared/components/ModalMusic'
import SecondaryInput from '../../shared/components/SecondaryInput'
import Table from '../../shared/components/Table'
import { TitlePage } from '../Users/styles'

import { MusicsContainer, TableWrapper } from './styles'
import { IViewProps } from './types'

function Musics(props: IViewProps) {
	const {
		searchString,
		handleSearchChange,
		navProps,
		tableColumns,
		tableRows,
		loading,
		handleSortColumn,
		handleCloseModal,
		modalIsOpen,
		currentMusic,
		sortBy,
		musicPage,
	} = props

	return (
		<MusicsContainer>
			<header>
				<div>
					<TitlePage>Músicas</TitlePage>
					<SecondaryInput
						className='search-input'
						placeholder='Buscar Música'
						value={searchString}
						onChange={handleSearchChange}
					/>
				</div>
			</header>

			<TableWrapper>
				<Table
					pageSize={5}
					isLoading={loading}
					getSelectedColumnAcessorAndOrder={handleSortColumn}
					data={tableRows}
					sortBy={sortBy}
					columns={tableColumns as any}
					navProps={navProps}
					extractPage={{ page: musicPage.page, totalDocs: musicPage.totalDocs }}
				/>
			</TableWrapper>
			<ModalMusic
				isOpen={modalIsOpen}
				currentMusic={currentMusic}
				onRequestClose={handleCloseModal}
			/>
		</MusicsContainer>
	)
}

export default Musics
