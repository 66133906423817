import {
	ChangeEvent,
	FormEvent,
	createElement,
	useEffect,
	useState,
} from 'react'
import { useHistory } from 'react-router-dom'
import {
	PROGRAM_DATE,
	programDateValidation,
} from '../../shared/components/ProgramModal/util'
import { IImageUpload } from '../../shared/interfaces/image'
import { IScheduleDate, ProgramPayload } from '../../shared/interfaces/program'
import { ISelectOption } from '../../shared/interfaces/select'
import { useMutationCreateProgram } from '../../shared/service/program.service'
import {
	useMutationPhotoUpload,
	usePresentersPage,
} from '../../shared/service/user.service'
import { INITIAL_STATE_IMAGE_UPLOAD } from '../../shared/util/image'
import { unravelArrayJSON } from '../../shared/util/select'
import { handleSuccessToaster } from '../../shared/util/toaster'
import { routesEnum } from '../Routes/enum'
import { IViewProps } from './types'
import { NewProgram } from './view'

function NewProgramContainer(): JSX.Element {
	const [image, setImage] = useState<IImageUpload>(INITIAL_STATE_IMAGE_UPLOAD)
	const [artworkImage, setImageArtwork] = useState<IImageUpload>(
		INITIAL_STATE_IMAGE_UPLOAD,
	)
	const [programName, setProgramName] = useState('')
	const [selectedPresenters, setSelectedPresenters] = useState<string[]>([])
	const [description, setDescription] = useState('')
	const [viewErrorsData, setViewErrorsData] = useState<string[]>([])
	const [isLoading, setIsLoading] = useState(false)
	const [canSubmit, setCanSubmit] = useState(false)
	const [programDates, setProgramDates] = useState<IScheduleDate[]>([
		PROGRAM_DATE,
	])

	const { data } = usePresentersPage()
	const createProgramMutation = useMutationCreateProgram()
	const photoUploadMutation = useMutationPhotoUpload()

	const history = useHistory()

	function handleProgramChange(event: React.ChangeEvent<HTMLInputElement>) {
		setProgramName(event.target.value)
	}

	function handleProgramDescription(
		event: React.ChangeEvent<HTMLInputElement>,
	) {
		setDescription(event.target.value)
	}

	async function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
		const selectedImage = event.target?.files?.item(0)

		if (!selectedImage) return

		const imageURL = URL.createObjectURL(selectedImage)

		setImage({ url: imageURL, file: selectedImage, overwrite: true })

		event.target.value = ''
	}

	async function handleInputArtWorkChange(
		event: ChangeEvent<HTMLInputElement>,
	) {
		const selectedImage = event.target?.files?.item(0)

		if (!selectedImage) return

		const imageURL = URL.createObjectURL(selectedImage)

		setImageArtwork({ url: imageURL, file: selectedImage, overwrite: true })

		event.target.value = ''
	}

	const presentersData: ISelectOption[] = data
		? data.map((presenter) => ({
				value: presenter._id,
				label: presenter.name,
				// eslint-disable-next-line no-mixed-spaces-and-tabs
		  }))
		: []

	function handlePlusProgramDate() {
		setProgramDates([...programDates, PROGRAM_DATE])
	}

	function handleGoBack() {
		history.goBack()
	}

	async function handleSubmit(event: FormEvent<HTMLButtonElement>) {
		event.preventDefault()

		const errorsCount = handleViewError()

		if (errorsCount) return

		try {
			setIsLoading(true)

			let coverImage = ''
			let artWorkImageCover = ''

			if (image.file) {
				coverImage = await photoUploadMutation.mutateAsync({ file: image.file })
			}

			if (artworkImage.file) {
				artWorkImageCover = await photoUploadMutation.mutateAsync({
					file: artworkImage.file,
				})
			}

			const presenters = unravelArrayJSON(selectedPresenters)

			const presentersID = presenters.map((presenter) => presenter.value)

			const payload: ProgramPayload = {
				title: programName,
				presenters: presentersID,
				scheduleDates: programDates,
				description,
				artworkURL: artWorkImageCover,
			}

			if (image.overwrite) {
				payload.photoURL = coverImage
			}

			if (artworkImage.overwrite) {
				payload.artworkURL = artWorkImageCover
			}

			const createProgramresponse = await createProgramMutation.mutateAsync(
				payload,
			)
			if (!createProgramresponse) return

			handleSuccessToaster('Programa criado com sucesso!')
			history.push(routesEnum.PROGRAMS)
		} finally {
			setIsLoading(false)
		}
	}

	function handleViewError() {
		const errors: string[] = []

		const programSchedule = programDateValidation(programDates)

		if (!programName) {
			errors.push('necessário preencher o campo de nome do programa')
		}

		if (!selectedPresenters.length) {
			errors.push('necessário selecionar os presentadores')
		}

		if (programSchedule) {
			errors.push('horário de programas não validos')
		}

		if (!artworkImage) {
			errors.push('necessário fazer upload de uma ArtWork')
		}

		if (!image) {
			errors.push('necessário fazer upload de uma Miniatura')
		}

		setViewErrorsData(errors)

		return errors.length
	}

	function handleSelectPresentersChange(selectedOptions: string[]) {
		setSelectedPresenters(selectedOptions)
	}

	function cleanImageSeletor() {
		setImage({ ...INITIAL_STATE_IMAGE_UPLOAD, overwrite: true })
	}

	function cleanImageArtWorkSeletor() {
		setImageArtwork({ ...INITIAL_STATE_IMAGE_UPLOAD, overwrite: true })
	}

	function handleLessProgramDate(index: number) {
		const listUpdated = [...programDates]
		listUpdated.splice(index, 1)
		setProgramDates(listUpdated)
	}

	const changeProgramDate = (
		index: number,
		programSelectedDate: IScheduleDate,
	) => {
		programDates[index] = programSelectedDate
		setProgramDates([...programDates])
	}

	function handleChangeCanSubmit() {
		const isProgramEnable = !!programName.trim()

		const isPresentersEnable = !!selectedPresenters.length

		const isProgramDateEnable = !!programDates.length

		const isArtWorkEnable = !!artworkImage.url

		const isImageEnable = !!image.url

		setCanSubmit(
			isProgramEnable &&
				isPresentersEnable &&
				isProgramDateEnable &&
				isArtWorkEnable &&
				isImageEnable,
		)
	}

	useEffect(handleChangeCanSubmit, [
		programName,
		selectedPresenters,
		programDates,
		artworkImage,
		image,
	])

	const viewProps: IViewProps = {
		handleProgramChange,
		image,
		handleInputChange,
		cleanImageSeletor,
		handleSelectPresentersChange,
		presentersData,
		selectedPresenters,
		programDates,
		handlePlusProgramDate,
		handleLessProgramDate,
		changeProgramDate,
		description,
		programName,
		handleProgramDescription,
		viewErrorsData,
		canSubmit,
		isLoading,
		handleSubmit,
		handleGoBack,
		handleInputArtWorkChange,
		artworkImage,
		cleanImageArtWorkSeletor,
	}

	return createElement(NewProgram, viewProps)
}

export default NewProgramContainer
