import { createElement, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useMutationActiveUser } from '../../shared/service/user.service'
import { handleSuccessToaster } from '../../shared/util/toaster'
import { IRouteParams } from '../ChangePassword/types'
import { routesEnum } from '../Routes/enum'
import { IViewProps } from './types'
import { Activate } from './view'
import { checkPassword, checkPasswordMatch } from '../../shared/util/validators'

function ActivateUserContainer(): JSX.Element {
	const [loading, setLoading] = useState(false)

	const [primaryPassword, setPrimaryPassword] = useState('')
	const [secondaryPassword, setSecondaryPassword] = useState('')

	const [canSubmit, setCanSubmit] = useState(false)

	const params: IRouteParams = useParams()

	const activateUserMutation = useMutationActiveUser()

	const history = useHistory()

	function handleSubmit(event: React.SyntheticEvent<Element, Event>) {
		event.preventDefault()
	}

	function handlePrimaryPasswordChange(
		event: React.ChangeEvent<HTMLInputElement>,
	) {
		setPrimaryPassword(event.target.value)
	}

	function handleSecondaryPasswordChange(
		event: React.ChangeEvent<HTMLInputElement>,
	) {
		setSecondaryPassword(event.target.value)
	}

	async function handleRegisterPassword() {
		const { resetToken } = params

		setLoading(true)

		try {
			await activateUserMutation.mutateAsync({
				resetToken,
				password: primaryPassword,
			})

			handleSuccessToaster('Senha cadastrada com sucesso!')
			history.push(routesEnum.LOGIN)
		} finally {
			setLoading(false)
		}
	}

	function handleFormValidation() {
		const isPrimaryValidPassword = checkPassword(primaryPassword)

		const isPasswordMatch = checkPasswordMatch(
			primaryPassword,
			secondaryPassword,
		)

		setCanSubmit(isPrimaryValidPassword && isPasswordMatch)
	}

	useEffect(handleFormValidation, [primaryPassword, secondaryPassword])

	const viewProps: IViewProps = {
		handleSubmit,
		handlePrimaryPasswordChange,
		handleSecondaryPasswordChange,
		primaryPassword,
		secondaryPassword,
		loading,
		handleRegisterPassword,
		canSubmit,
	}

	return createElement(Activate, viewProps)
}

export default ActivateUserContainer
