import React, { createElement, useEffect, useState } from 'react'
import { IUser } from '../../shared/interfaces/user'
import { useCustomerUsersPage } from '../../shared/service/user.service'
import { DetailButton } from './styles'
import { renderTextTable } from './table'
import {
	ETableAudioAcessor,
	ITableColumn,
	IUsersPage,
	IViewProps,
} from './types'

import { Listeners } from './view'

import { format } from 'date-fns'
import { useHistory } from 'react-router-dom'
import {
	IAcessorAndOrder,
	ITableNavProps,
} from '../../shared/components/Table/types'
import { formatNumber } from '../../shared/util/number'
import { useTypedSelector } from '../../shared/hooks/useTypedSelector'
import { MoreHorizontal } from 'react-feather'

function ListenersContainer(): JSX.Element {
	const [searchString, setSearchString] = useState('')
	const history = useHistory()

	const [sortBy, setSortBy] = useState<IAcessorAndOrder>({
		acessorToSend: 'createdAt',
		acessor: 'createdAt',
		order: -1,
	})

	const [usersPage, setUsersPage] = useState<IUsersPage>({
		page: 1,
		numberOfPages: 0,
		totalDocs: 0,
	})

	const { data, status } = useCustomerUsersPage({
		maxItemsInPage: 5,
		searchString: searchString,
		pageIndex: usersPage.page,
		order: sortBy.order,
		sort: sortBy.acessorToSend,
	})

	const [tableRows, setTableRows] = useState<any>([])

	function handleListenerChange(event: React.ChangeEvent<HTMLInputElement>) {
		setSearchString(event.target.value)
	}

	const tableColumns: ITableColumn[] = [
		{ Header: 'Nome', accessor: 'name', sortType: 'basic' },
		{ Header: 'E-mail', accessor: 'email', sortType: 'basic' },
		{ Header: 'Cadastro', accessor: 'createdAt', sortType: 'basic' },
		{ Header: 'Pontos', accessor: 'totalBeatCoins', sortType: 'basic' },
		{
			Header: '',
			accessor: 'editIcon',
			sortType: 'basic',
			disableSortBy: true,
		},
	]

	function renderEditIcon(user: IUser) {
		function handleEdit() {
			history.push(`/user/detail/${user._id}`)
		}

		return (
			<DetailButton onClick={handleEdit} data-testid='button-details'>
				<MoreHorizontal style={{ strokeWidth: 1.5 }} />
			</DetailButton>
		)
	}

	function handleSortColumn(acessor: string, order: number) {
		let acessorToSend = acessor
		if (order === 0) {
			setSortBy({
				acessorToSend: 'createdAt',
				acessor: 'createdAt',
				order: -1,
			})
			return
		}

		if (acessor === ETableAudioAcessor.name) {
			acessorToSend = 'name'
		}
		setSortBy({
			acessorToSend,
			acessor,
			order,
		})
	}

	const renderTableElements = (user: IUser) => {
		return {
			name: renderTextTable(user.name),
			email: renderTextTable(user.email),
			createdAt: renderTextTable(
				format(new Date(user?.createdAt as string), 'dd/MM/yyyy, HH:mm'),
			),
			totalBeatCoins: renderTextTable(
				user.totalBeatCoins ? formatNumber(user.totalBeatCoins) : '0',
			),
			editIcon: renderEditIcon(user),
		}
	}

	function goToPage(pageIndex: number) {
		setUsersPage((state) => ({ ...state, page: pageIndex + 1 }))
	}

	const navProps: ITableNavProps = {
		nextPage: (pageIndex) => goToPage(pageIndex),
		previousPage: (pageIndex) => goToPage(pageIndex),
		gotoPage: (pageIndex) => goToPage(pageIndex),
		pageCount: usersPage.numberOfPages,
		pageIndex: usersPage.page - 1,
		totalDocs: usersPage.totalDocs,
	}

	function handleData() {
		if (!data) return

		const documentsData = data.pages[0].docs

		const rows = documentsData.map(renderTableElements)

		setTableRows(rows)

		setUsersPage({
			numberOfPages: data.pages[0].totalPages,
			page: data.pages[0].page || 1,
			totalDocs: data.pages[0].totalDocs,
		})
	}

	useEffect(handleData, [data])

	const viewProps: IViewProps = {
		searchString,
		handleListenerChange,
		handleSortColumn,
		tableColumns,
		isLoading: status === 'loading',
		tableRows,
		navProps,
		usersPage,
		sortBy,
	}

	return createElement(Listeners, viewProps)
}

export default ListenersContainer
