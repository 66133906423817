import { DefaultTheme } from 'styled-components'

export const theme: DefaultTheme = {
	colors: {
		yellow: '#FACC00',
		green: '#05F26C',
		green_medium: '#00B850',
		blue: '#07F2F2',
		green_shade: '#CBFFE2',
		dark_green: '#31aa66',
		greenish_dark: '#9FE1BC',
		greenish_dark_shade: '#3F5E4D',
		purple: '#340040',
		pure_black: '#000000',
		black_graffiti: '#131413',
		dark_graffiti: '#383D3A',
		middle_green:'#D6FFE8',
		greenish_grey: '#5A615D',
		greenish_grey_medium: '#717A75',
		greenish_grey_light: '#B0B8B3',
		light_gray: '#D7E0DA',
		off_white: '#EBF0ED',

		greenish_white: '#F5FAF7',
		white: '#FFFFFF',

		background: '#F7FAF8',

		red: '#E42013',
	},
	fonts: {
		radikal_regular: 'Regular',
		radikal_bold: 'Bold',
		radikal_light: 'Light',
		radikal_medium: 'Medium',
	},
}
