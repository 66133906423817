import { QueryFunctionContext, useQuery, UseQueryResult } from 'react-query'
import {
	IDeleteProgramPayload,
	IProgram,
	IProgramPayload,
	IUpdateProgramPayload,
	ProgramPayload,
} from '../interfaces/program'
import { QUERY_KEYS } from '../util/reactQuery'
import axiosInstance from './axiosInstance'

import { AxiosError, AxiosResponse } from 'axios'
import { useMutation, UseMutationResult } from 'react-query'
import { IError } from '../interfaces/error'
import { IPaginationResponse, PaginatedRequest } from '../interfaces/pagination'

// eslint-disable-next-line @typescript-eslint/ban-types
export type IFetchProgramRequest = PaginatedRequest<{}>

async function fetchProgramsPage(
	context: QueryFunctionContext<[unknown, IFetchProgramRequest]>,
): Promise<IPaginationResponse<IProgram>> {
	const [_, params] = context.queryKey
	const { maxItemsInPage, pageIndex, searchString, order, sort } = params
	const response = await axiosInstance.post('/program/get-page', {
		pageIndex,
		searchString,
		order,
		sort,
		maxItemsInPage,
	})

	return response.data
}

export function useProgramsPage({
	maxItemsInPage,
	pageIndex,
	searchString,
	order,
	sort,
}: IFetchProgramRequest): UseQueryResult<IPaginationResponse<IProgram>> {
	const result = useQuery(
		[
			QUERY_KEYS.PROGRAMS_PAGE,
			{ maxItemsInPage, pageIndex, searchString, order, sort },
		],
		fetchProgramsPage,
	)
	return result
}

async function fetchProgramsNow(): Promise<IProgram | undefined> {
	const response = await axiosInstance.get('/now')

	return response.data.data.program
}

export function useProgramsNow(): UseQueryResult<IProgram | undefined> {
	return useQuery([QUERY_KEYS.PROGRAM_NOW], async () => fetchProgramsNow(), {
		enabled: true,
	})
}

async function fetchProgramDetail(programID: string): Promise<IProgram> {
	const response = await axiosInstance.get(`/program/${programID}`)

	return response.data
}

export function useProgramsDetail({
	programID,
}: {
	programID: string
}): UseQueryResult<IProgram | undefined> {
	return useQuery(
		[QUERY_KEYS.PROGRAM_DETAIL],
		async () => fetchProgramDetail(programID),
		{
			enabled: true,
		},
	)
}

async function createProgram(payload: ProgramPayload): Promise<IProgram> {
	const response = await axiosInstance.post('/program', payload)

	return response.data
}

export function useMutationCreateProgram(): UseMutationResult<
	IProgram,
	AxiosError<IError>,
	IProgramPayload
> {
	return useMutation(createProgram)
}

async function updateProgram(
	payload: IUpdateProgramPayload,
): Promise<IProgram> {
	const response = await axiosInstance.patch(
		`/program/${payload.programID}`,
		payload.program,
	)

	return response.data
}

export function useMutationUpdateProgram(): UseMutationResult<
	IProgram,
	AxiosError<IError>,
	IUpdateProgramPayload
> {
	return useMutation(updateProgram)
}

async function deleteProgram(payload: IDeleteProgramPayload) {
	return axiosInstance.delete(`/program/${payload.programID}`)
}

export function useDeleteProgram(): UseMutationResult<
	AxiosResponse<any, any>,
	AxiosError<IError>,
	IDeleteProgramPayload
> {
	return useMutation(deleteProgram)
}

export function useMutationDeleteProgram(): UseMutationResult<
	AxiosResponse<any, any>,
	AxiosError<IError>,
	IDeleteProgramPayload
> {
	return useMutation(deleteProgram)
}
