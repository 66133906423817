import React from 'react'
import { IViewProps } from './types'

import { Button } from '../../shared/components/Button'
import SecondaryInput from '../../shared/components/SecondaryInput'
import Table from '../../shared/components/Table'
import { TitlePage } from '../Users/styles'
import * as S from './styles'
import { TabMenu } from '../../shared/components/TabMenu'
import { PurchaseModal } from '../../shared/components/PurchaseModal'

function Purchases(props: IViewProps) {
	const {
		searchString,
		handleListenerChange,
		handleSortColumn,
		tableColumns,
		isLoading,
		tableRows,
		navProps,
		purchasePage,
		sortBy,
		handleChangeTab,
		tabOption,
		optionsTab,
		handleGetPage,
		handleRefetch,
		purchaseModal,
		handleCloseModal,
		currentProduct,
		handleSubmitRedeemed,
		goToPage
	} = props

	return (
		<S.ListenerWrapper>
			<header>
				<div>
					<TitlePage data-testid='title-page'>Compras E-Shop</TitlePage>
					<SecondaryInput
						className='search-input'
						placeholder='Buscar por produto, ouvinte...'
						value={searchString}
						onChange={handleListenerChange}
					/>
				</div>
			</header>

			<S.TabWrapper>
				<TabMenu handleChangeTab={handleChangeTab} currentTab={tabOption} options={optionsTab}/>
				<S.ReloadButtonWrapper>
					<Button onClick={handleGetPage} label='Atualizar Lista' />
				</S.ReloadButtonWrapper>
			</S.TabWrapper>

			<S.TableWrapper>
				<Table
					columns={tableColumns as any}
					data={tableRows}
					isLoading={isLoading}
					navProps={navProps}
					extractPage={{ page: purchasePage.page, totalDocs: purchasePage.totalDocs }}
					getSelectedColumnAcessorAndOrder={handleSortColumn}
					sortBy={sortBy}
					onPageChange={goToPage}
				/>
			</S.TableWrapper>
			<PurchaseModal handleSubmit={handleSubmitRedeemed} data={currentProduct} isActive={purchaseModal} onRequestClose={handleCloseModal} />
		</S.ListenerWrapper>
	)
}

export { Purchases }
