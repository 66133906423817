export const routesEnum = {
	LOGIN: '/login',
	USERS: '/users',
	CHANGE_PASSWORD: '/change-password/:resetToken',
	RECOVERY_PASSWORD: '/recover',
	PROGRAMS: '/programs',
	ACTIVATE_USER: '/activate-user/:resetToken',
	MUSICS: '/musics',
	BONUS: '/bonusWord',
	E_SHOP: '/shop',
	LISTENERS: '/listeners',
	LISTENER_DETAIL: '/user/detail/:userID',
	AUDIO: '/audio',
	BANNERS: '/banners',
	NEW_PROGRAM: '/programs/newProgram',
	PROGRAM_DETAIL: '/program/:programID',
	NEW_USER: '/users/newUser',
	EDIT_USER: '/users/editUser/:userID',
	PROMOTIONS: '/promotions',
	NEW_PROMOTION: '/promotions/newPromotion',
	EDIT_PROMOTION: '/promotions/EDITPromotion/:promotionID',
	PARTICIPANT_DRAWN: '/promotions/drawn/:promotionID',
	PROMOTION_DETAIL: '/promotion/:promotionID',
	REGULATION: '/regulation',
	PURCHASE: '/purchases',
	PURCHASE_DETAIL: '/purchase/:purchaseID'
}
