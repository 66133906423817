import { ISelectOption } from '../interfaces/select'

export function unravelArray(array: any): ISelectOption[] {
	return array.map((value: any) => {
		return {
			value,
			label: value,
		}
	})
}

export function unravelSelectArrayString(array: ISelectOption[]) {
	return array.map((item) => JSON.stringify(item))
}

export function unravelArrayJSON(array: string[]): ISelectOption[] {
	return array.map((item) => JSON.parse(item))
}

export function unravelObject(str: string): ISelectOption {
	return {
		value: str,
		label: str,
	}
}

export function unravelParseArray(array: any): ISelectOption[] {
	return array.map((value: any) => {
		if (typeof value === 'string') {
			const optionParsed: ISelectOption = JSON.parse(value)

			return optionParsed
		}

		return value
	})
}
