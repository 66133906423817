import React, { Fragment } from 'react'
import * as S from './styles'
import { IViewProps } from './types'
import { UploadImageButton } from '../../shared/components/UploadImageButton'
import { Input } from '../../shared/components/Input'
import ArrowLeft from '../../assets/icons/left-arrow.svg'
import { DateInputWrapper } from '../../shared/components/ProductModal/styles'
import DateInputPicker from '../../shared/components/DateInputPicker'
import TextEditorBox from '../../shared/components/TextEditorBox'
import { Button, GoBackButton } from '../../shared/components'

function NewPromotionView(props: IViewProps) {
	const {
		handleChangePromotionName,
		handleInputChange,
		cleanImageSeletor,
		image,
		promotionName,
		handleGoBack,
		rangeDate,
		handleChangeRangeDate,
		description,
		handlePromotionDescription,
		regulation,
		handlePromotionRegulation,
		handleSubmit,
		handleChangePromotionAmount,
		promotionAmount,
		canSubmit,
		setDescriptionReturnIsEmpty,
		setRegulationReturnIsEmpty,
		dateIsEmpty,
		isLoading,
	} = props

	return (
		<Fragment>
			<S.Header>
				<GoBackButton />
				<S.ModalTitle>Cadastrar Nova Promoção</S.ModalTitle>
			</S.Header>
			<S.ContentContainerWrapper>
				<S.ContentContainer>
					<S.UploadImageButtonWrapper>
						<UploadImageButton
							label='Upload da Artwork da Promoção'
							onChange={handleInputChange}
							imageClean={cleanImageSeletor}
							showInfo={true}
							image={image}
						/>
					</S.UploadImageButtonWrapper>
				</S.ContentContainer>
			</S.ContentContainerWrapper>

			<S.ContentContainerWrapper>
				<S.ContentContainer>
					<S.FormWrapper>
						<Input
							className='name-input'
							value={promotionName}
							onChange={handleChangePromotionName}
							placeholder='Título da Promoção'
							data-testid='input-promotion-title'
						/>
					</S.FormWrapper>
					<DateInputWrapper>
						<DateInputPicker
							dateRange={rangeDate}
							handleOnChange={handleChangeRangeDate}
							placeholder='Período de Disponibilidade'
							style={{ backgroundColor: '#FFF' }}
							data-testid='input-availability-period'
						/>
					</DateInputWrapper>
					<S.FormWrapper style={{ marginBottom: '32px' }}>
						<S.TextBoxWrapper>
							<TextEditorBox
								minHeight='100%'
								minWidth='430px'
								value={description}
								onChange={handlePromotionDescription}
								disabled={false}
								placeholder='Descrição da promoção'
								returnIsEmpty={setDescriptionReturnIsEmpty}
								data-testid='input-promotion-description'
							/>
						</S.TextBoxWrapper>
					</S.FormWrapper>
					<S.ModalSeparator />
					<S.FormWrapper style={{ marginBottom: '48px' }}>
						<S.LineWrapper style={{ marginTop: '32px', marginBottom: '32px' }}>
							<h4>Regras da promoção</h4>
						</S.LineWrapper>

						<S.SwitchWrapper>
							<p>Quantidade de sorteios nesta promoção</p>
							<S.StockInputWrapper>
								<Input
									className='inventory'
									value={promotionAmount}
									type='number'
									placeholder='0           Qnt'
									onChange={handleChangePromotionAmount}
									data-testid='input-quantity-invetory'
								/>
							</S.StockInputWrapper>
						</S.SwitchWrapper>
						<S.TextBoxWrapper>
							<TextEditorBox
								minHeight='100%'
								minWidth='430px'
								value={regulation}
								onChange={handlePromotionRegulation}
								placeholder='Regulamento para participação'
								returnIsEmpty={setRegulationReturnIsEmpty}
							/>
						</S.TextBoxWrapper>
					</S.FormWrapper>

					<S.SubmitButtonWrapper>
						<Button.Root
							disabled={!canSubmit}
							isLoading={isLoading}
							onClick={handleSubmit}
							spacing='12x24'
						>
							<Button.Text text='Cadastrar Promoção' />
						</Button.Root>
					</S.SubmitButtonWrapper>
				</S.ContentContainer>
			</S.ContentContainerWrapper>
		</Fragment>
	)
}

export { NewPromotionView }
