import { IError } from '../interfaces/error'
import { logout } from './session'
import { errorMessages } from './messages'
import { handleErrorToaster } from './toaster'

function handleError(data: any): void {
	const error = data?.response?.data as any

	const result = !!error.length

	if (error.length) {
		error.forEach((message: string) => {
			handleErrorToaster(message)
		})
	} else if (!error?.message) {
		handleErrorToaster(errorMessages.serverUnavailable)
	} else if (error.statusCode === 401) {
		handleErrorToaster(error.message)
		logout()
	} else if (error.statusCode === 420) {
		handleErrorToaster(error.message)
	} else if (!/^5\d{2}/.test(error.statusCode.toString())) {
		handleErrorToaster(error.message)
	}
}

export default handleError
