import styled, { css } from 'styled-components'

export const ParagraphWithPromotionTitle = styled.p`
    ${({ theme }) => css`
        font-family: ${theme.fonts.radikal_regular};
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: ${theme.colors.dark_graffiti};
        text-align: center;

        span.promotion-title {
            color: ${theme.colors.greenish_grey_medium};
        }
    `}
`
