import {
	IAcessorAndOrder,
	ITableNavProps,
} from '../../shared/components/Table/types'
import {
	IPurchaseNumbers,
	PurchaseAnalyticsData,
} from '../../shared/interfaces/extract'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ITableColumn {}

export interface IUsersPage {
	page: number
	numberOfPages: number
	totalDocs: number
}
export enum ETableAudioAcessor {
	name = 'name',
	email = 'email',
	createdAt = 'createdAt',
	points = 'points',
	editIcon = 'editIcon',
}

export interface IOptionTabProps {
	title: PurchaseAnalyticsData
	value: number
}

export interface IViewProps {
	searchString: string
	handleListenerChange(event: React.ChangeEvent<HTMLInputElement>): void
	handleSortColumn(acessor: string, order: number): void
	tableColumns: ITableColumn[]
	isLoading: boolean
	tableRows: any
	navProps: ITableNavProps
	purchasePage: IUsersPage
	sortBy: IAcessorAndOrder
	purchaseCount?: IPurchaseNumbers
	handleChangeTab(str: PurchaseAnalyticsData): void
	tabOption: PurchaseAnalyticsData
	optionsTab: IOptionTabProps[]
	handleGetPage(): void
	handleRefetch(): void
	purchaseModal: boolean
	handleCloseModal(): void
	currentProduct: any
	handleSubmitRedeemed(): void
	goToPage(pageIndex: number): void
}
