import styled, { css } from 'styled-components'

export const Container = styled.button`
	${({theme}) => css`
        background: none;
		border: none;

		display: flex;
		align-items: center;
        justify-content: flex-start;

		margin-top: 32px;
		margin-bottom: 20px;

        &:hover {
			cursor: pointer;
		}


        & > img {
            margin: 0 2px 4px 0;
			transition: 0.2s;
			transition-delay: 0.1s;
			margin-right: 4px;
			position: relative;
        }

		&:hover img {
			transform: translateX(-2px);
		}

		& > span {
			color: ${theme.colors.dark_graffiti};
			font-family: ${theme.fonts.radikal_regular};
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
		}
	`}
`