import { ButtonRoot } from './ButtonRoot'
import { ButtonText } from './ButtonText'
import { ButtonIcon } from './ButtonIcon'

const Button = {
	Root: ButtonRoot,
	Text: ButtonText,
	Icon: ButtonIcon,
}

export default Button
