export enum ProgramStatus {
	ACTIVE = 'ACTIVE',
	DELETED = 'DELETED',
}

export enum DayOfWeek {
	MONDAY = 'MONDAY',
	TUESDAY = 'TUESDAY',
	WEDNESDAY = 'WEDNESDAY',
	THURSDAY = 'THURSDAY',
	FRIDAY = 'FRIDAY',
	SATURDAY = 'SATURDAY',
	SUNDAY = 'SUNDAY',
}

export interface IScheduleDate {
	day: DayOfWeek | string
	timeStart: string
	timeEnd: string
}

export interface IPresenters {
	_id: string
	name: string
}

export interface IProgramPayload {
	title: string
	presenters: string[]
	photoURL?: string
	scheduleDates: IScheduleDate[]
	description?:string
	artworkURL?: string
}

export interface IUpdateProgramPayload {
	programID: string
	program: ProgramPayload
}

export interface IDeleteProgramPayload {
	programID: string
}

export interface IProgram {
	_id: string
	title: string
	presenters: IPresenters[]
	photoURL?: string
	status: ProgramStatus
	scheduleDates: IScheduleDate[]
	createdAt: string
	updatedAt: string
	description?:string
	artworkURL?: string
}

export interface IProgramPage {
	page: number
	numberOfPages: number
	totalDocs: number
}

export type DayOfWeekEnum = keyof typeof DayOfWeek
export type ProgramPayload = IProgramPayload
export type IFetchProgramsResponse = IProgram[]
