import styled, { css } from 'styled-components'

export const HeaderWrapper = styled.header`
	${({ theme }) => css`
		background-color: ${theme.colors.white};
		height: 88px;
		display: flex;
		z-index: 20;
		position: fixed;
		top: 0;
		width: 100%;

		.separator {
			width: 1px;
			height: 100%;
			background: ${theme.colors.off_white};
		}

		box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.14);
	`}
`
export const ContainerHeaderWrapper = styled.div`
	flex: 1;
	display: flex;
	padding: 20px 32px;
	justify-content: space-between;
	align-items: center;

	img {
		width: 117px;
		height: 48px;
	}

	div {
		display: flex;
		align-items: center;

		.account-info {
			margin-right: 14px;
		}
	}
`
export const ImageWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100px;

	button {
		border: none;
		background: transparent;
		cursor: pointer;
	}

	span {
		border: 1.2px solid #383d3a;
	}

	img {
		width: 24px;
		height: 24px;
	}
`
export const MenuWrapper = styled.div`
	cursor: pointer;
`

export const MenuButtonWrapper = styled.button<{ isOpen: boolean }>`
	${({ isOpen }) => css`
		border: none;
		background: transparent;
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;

		${isOpen &&
		css`
			animation: spin 0.3s ease-in-out;
		`}

		${!isOpen &&
		css`
			animation: spinReverse 0.3s ease-in-out;
		`}
		@keyframes spin {
			0% {
				transform: rotateZ(0deg);
			}

			50% {
				transform: rotateZ(45deg);
			}

			100% {
				transform: rotateZ(90deg);
			}
		}

		@keyframes spinReverse {
			0% {
				transform: rotateZ(90deg);
			}

			50% {
				transform: rotateZ(45deg);
			}

			100% {
				transform: rotateZ(0deg);
			}
		}
	`}
`

export const IconImage = styled.img`
	width: 22px;
	height: 22px;
`
