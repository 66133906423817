import {
	IAcessorAndOrder,
	ITableNavProps,
} from '../../shared/components/Table/types'
import { IAudioPage } from '../../shared/interfaces/audio'
import { ModalActionEnum } from '../../shared/interfaces/modal'
import { ISelectOption } from '../../shared/interfaces/select'
import { IAudioResponse } from '../../shared/service/audio.service'

export interface IFilterProps {
	label: string
	value: string
}

export interface AudioDeleteProps {
	currentAudio: string
	refetch(): void
}
export interface AudioModalProps {
	onRequestClose: () => void
	action: ModalActionEnum
	currentAudio: string
	refetch(): void
	isOpen: boolean
}

export interface IViewProps {
	searchString: string
	handleSearchChange(event: React.ChangeEvent<HTMLInputElement>): void
	isLoading: boolean
	handleProgramSelectChange(event: React.ChangeEvent<HTMLSelectElement>): void
	handleSortColumn(acessor: string, order: number): void
	tableRows: ITableRow[]
	sortBy: IAcessorAndOrder
	tableColumns: ITableColumn[]
	navProps: ITableNavProps
	programOptions?: ISelectOption[]
	handleDeleteAudios(): Promise<void>
	handleOpenDeleteModal(): void
	handleCloseDeleteModal(): void
	deleteModalIsActive: boolean
	audioPage: IAudioPage
}

export interface IListItem {
	name: string
	img: string
	program: string
	date: string
	id: number
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ITableColumn {}

export interface ITableRow {
	name: JSX.Element
}

export interface IDeleteProps {
	_id: string[]
	value: boolean[]
}

export interface IHandleSwitchProps {
	checked: boolean
	audio: IAudioResponse
	handleRefetch(): void
}

export interface IRenderSwitchProps {
	audio: IAudioResponse
	handleSwitchRefetch(audioID: string): Promise<void>
}

export interface IRenderCheckBoxProps {
	handleSelect(
		event: React.ChangeEvent<HTMLInputElement>,
		audio: IAudioResponse,
	): void
	audio: IAudioResponse
	toBeDeleted: string[]
}

export enum ETableAudioAcessor {
	name = 'name',
	program = 'program',
	createdAt = 'createdAt',
	audios = 'audios',
	switch = 'switch',
}
