import styled, { css } from 'styled-components'

export const ListenerWrapper = styled.div`
	header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 32px;

		div {
			display: flex;
			align-items: center;

			.search-input {
				margin-left: 48px;
			}
		}
	}
`
export const TableWrapper = styled.div`
	margin-top: 16px;
`

export const DetailButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	background: transparent;
	border: none;
	cursor: pointer;
	padding: 10px;
	border-radius: 50%;
	transition: 0.4s;
	color: ${({theme}) => css`${theme.colors.dark_graffiti}`};

	:hover{
		background-color: ${({theme}) => css`${theme.colors.off_white}`};
		color: ${({theme}) => css`${theme.colors.pure_black}`};
	}
`
