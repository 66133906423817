import styled, { css } from 'styled-components'
import { toRem } from '../../styles/function'

export const ImageProfileWrapper = styled.div`
	${({ theme }) => css`
		background-color: ${theme.colors.greenish_white};
		width: 56px;
		height: 56px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		border-width: 5px;
		border-color: ${theme.colors.off_white};
		border-style: solid;

		span {
			font-size: ${toRem(14)};
			color: ${theme.colors.greenish_grey_light};
			font-weight: 700;
			font-family: ${theme.fonts.radikal_regular};
		}
	`}
`
