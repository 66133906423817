import styled, { css } from 'styled-components'

export const BonusWordWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: 16px;
`

export const BonusWordContainer = styled.div`
	${({ theme }) => css`
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		padding-left: 24px;
		background: ${theme.colors.white};
		width: 429px;
		height: 194px;
		border-top: 2px solid ${theme.colors.green};

		span {
			font-size: 14px;
			font-weight: 400;
			color: ${theme.colors.greenish_grey};
		}
	`}
`
export const WordWrapper = styled.div`
	${({ theme }) => css`
			display: flex;
			flex-direction: column;

			span {
				margin-bottom: 16px;
			}
			
			div {
				display: flex;
				align-items: center;
				
				h1 {
					font-family: ${theme.fonts.radikal_regular};
					font-weight: 300;
					text-transform: uppercase;
					color: ${theme.colors.dark_graffiti};
				}
			}
		`}
`

export const StopButton = styled.button`
	${({ theme }) => css`
		background: ${theme.colors.red};
		border-radius: 13px;
		padding: 4px 8px;
		color: ${theme.colors.greenish_white};
		font-size: 12px;
		font-weight: bold;
		margin-left: 16px;
		cursor: pointer;
		border: none;
		transition: 0.2s;

		&:hover{
			background: #C81C11;
		}
	`}
`

export const RewardWrapper = styled.div`
	display: flex;
	align-items: center;
	margin-top: 22px;
`

export const RewardLabel = styled.span`
	${({ theme }) => css`
		color: ${theme.colors.greenish_grey};
		font-size: 14px;
		margin-right: 12px;
	`}
`

export const RewardCoins = styled.div`
	${({ theme }) => css`
		color: ${theme.colors.dark_graffiti};
		font-size: 12px;
		border: 1px solid ${theme.colors.dark_graffiti};
		border-radius: 13px;
		padding: 4px 8px 2px;
	`}
`

export const HistWrapper = styled.div`
	${({ theme }) => css`
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: 194px;
		background: ${theme.colors.white};
		border-top: 2px solid ${theme.colors.green};
		margin-left: 16px;
		width: 207px;

		span {
			color: ${theme.colors.greenish_grey_medium};
			font-size: 14px;
			margin-top: 12.5px;
		}
	`}
`

export const BeatCoinsGenerate = styled.div`
	${({ theme }) => css`
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: 194px;
		background: ${theme.colors.white};
		border-top: 2px solid ${theme.colors.green};
		margin-left: 16px;
		width: 207px;
	`}
`

export const CardNumber = styled.h1`
	${({ theme }) => css`
		font-size: 40px;
		color: ${theme.colors.dark_graffiti};
		font-family: ${theme.fonts.radikal_regular};
		margin-top: 16px;
		font-weight: 300;
	`}
`

export const CardTitle = styled.span`
	${({ theme }) => css`
		color: ${theme.colors.greenish_grey_medium};
		font-size: 14px;
		margin-top: 12.5px;
	`}
`
