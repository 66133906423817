import { routesEnum } from './enum'

import { IRouteData } from './types'

import ActivateUserContainer from '../Activate'
import Audio from '../Audio'
import Banners from '../Banners'
import ChangePassword from '../ChangePassword'
import EBonus from '../EBonus'
import EShop from '../EShop'
import EditPromotion from '../EditPromotion'
import EditUser from '../EditUser'
import Listeners from '../Listeners'
import Login from '../Login'
import Musics from '../Musics'
import NewProgram from '../NewProgram'
import NewPromotion from '../NewPromotion'
import NewUser from '../NewUser'
import ParticipantDrawn from '../ParticipantDrawn'
import ProgramDetail from '../ProgramDetail'
import Programs from '../Programs'
import PromotionDetails from '../PromotionDetails'
import Promotions from '../Promotions'
import PurchaseScreen from '../Purchase'
import RecoverPassword from '../RecoverPassword'
import Regulation from '../Regulation'
import UserDetail from '../UserDetail'
import Users from '../Users'
import PurchaseDetail from '../PurchaseDetail'

export const routesData: IRouteData[] = [
	{
		component: Login,
		key: routesEnum.LOGIN,
		name: 'Login',
		path: routesEnum.LOGIN,
		noWrapper: true,
	},
	{
		component: Users,
		key: routesEnum.USERS,
		name: 'Usuários',
		path: routesEnum.USERS,
		accessTypes: ['ADMIN', 'EDITOR', 'PRESENTER'],
	},
	{
		component: PurchaseScreen,
		key: routesEnum.PURCHASE,
		name: 'Compras E-Shop',
		path: routesEnum.PURCHASE,
		accessTypes: ['ADMIN', 'EDITOR','PRESENTER'],
	},
	{
		component: Promotions,
		key: routesEnum.PROMOTIONS,
		name: 'Promoções',
		path: routesEnum.PROMOTIONS,
		noWrapper: false,
		accessTypes: ['ADMIN', 'EDITOR', 'PRESENTER'],
	},
	{
		component: Banners,
		key: routesEnum.BANNERS,
		name: 'Banners',
		path: routesEnum.BANNERS,
		accessTypes: ['ADMIN', 'EDITOR'],
	},
	
	{
		component: Programs,
		key: routesEnum.PROGRAMS,
		name: 'Programas',
		path: routesEnum.PROGRAMS,
		accessTypes: ['ADMIN', 'EDITOR', 'PRESENTER'],
	},
	{
		component: ChangePassword,
		key: routesEnum.CHANGE_PASSWORD,
		name: 'Cadastro de Senha',
		path: routesEnum.CHANGE_PASSWORD,
		noWrapper: true,
	},
	{
		component: RecoverPassword,
		key: routesEnum.RECOVERY_PASSWORD,
		name: 'Recuperar Senha',
		path: routesEnum.RECOVERY_PASSWORD,
		noWrapper: true,
	},
	{
		component: ActivateUserContainer,
		key: routesEnum.ACTIVATE_USER,
		name: 'Ativar Usuário',
		path: routesEnum.ACTIVATE_USER,
		noWrapper: true,
	},

	{
		component: UserDetail,
		key: routesEnum.LISTENER_DETAIL,
		name: 'Detalhe de Ouvínte',
		path: routesEnum.LISTENER_DETAIL,
		accessTypes: ['ADMIN', 'EDITOR', 'PRESENTER'],
	},
	{
		component: Musics,
		key: routesEnum.MUSICS,
		name: 'Músicas',
		path: routesEnum.MUSICS,
		accessTypes: ['ADMIN', 'EDITOR', 'PRESENTER'],
	},
	{
		component: EBonus,
		key: routesEnum.BONUS,
		name: 'E-Bônus',
		path: routesEnum.BONUS,
		accessTypes: ['ADMIN', 'EDITOR'],
	},
	{
		component: EShop,
		key: routesEnum.E_SHOP,
		name: 'E-Shop',
		path: routesEnum.E_SHOP,
		accessTypes: ['ADMIN', 'EDITOR'],
	},
	{
		component: Listeners,
		key: routesEnum.LISTENERS,
		name: 'Ouvintes',
		path: routesEnum.LISTENERS,
		accessTypes: ['ADMIN', 'EDITOR', 'PRESENTER'],
	},

	{
		component: Audio,
		key: routesEnum.AUDIO,
		name: 'Áudios',
		path: routesEnum.AUDIO,
		accessTypes: ['ADMIN', 'EDITOR', 'PRESENTER'],
	},
	{
		component: NewProgram,
		key: routesEnum.NEW_PROGRAM,
		name: 'Novo Programa',
		path: routesEnum.NEW_PROGRAM,
		noWrapper: false,
		accessTypes: ['ADMIN', 'EDITOR'],
	},
	{
		component: ProgramDetail,
		key: routesEnum.PROGRAM_DETAIL,
		name: 'Detalhe de Programa',
		path: routesEnum.PROGRAM_DETAIL,
		noWrapper: false,
		accessTypes: ['ADMIN', 'EDITOR', 'PRESENTER'],
	},
	{
		component: PurchaseDetail,
		key: routesEnum.PURCHASE_DETAIL,
		name: 'Detalhe de Compra',
		path: routesEnum.PURCHASE_DETAIL,
		noWrapper: false,
		accessTypes: ['ADMIN', 'EDITOR', 'PRESENTER'],
	},
	{
		component: NewUser,
		key: routesEnum.NEW_USER,
		name: 'Criar Usuário',
		path: routesEnum.NEW_USER,
		noWrapper: false,
		accessTypes: ['ADMIN', 'EDITOR'],
	},
	{
		component: EditUser,
		key: routesEnum.EDIT_USER,
		name: 'Editar Usuário',
		path: routesEnum.EDIT_USER,
		noWrapper: false,
		accessTypes: ['ADMIN', 'EDITOR'],
	},
	{
		component: PromotionDetails,
		key: routesEnum.PROMOTION_DETAIL,
		name: 'Detalhe de Promoção',
		path: routesEnum.PROMOTION_DETAIL,
		noWrapper: false,
		accessTypes: ['ADMIN', 'EDITOR', 'PRESENTER'],
	},

	{
		component: NewPromotion,
		key: routesEnum.NEW_PROMOTION,
		name: 'Nova Promoção',
		path: routesEnum.NEW_PROMOTION,
		noWrapper: false,
		accessTypes: ['ADMIN', 'EDITOR', 'PRESENTER'],
	},

	{
		component: EditPromotion,
		key: routesEnum.EDIT_PROMOTION,
		name: 'Editar Promoção',
		path: routesEnum.EDIT_PROMOTION,
		noWrapper: false,
		accessTypes: ['ADMIN', 'EDITOR', 'PRESENTER'],
	},

	{
		component: ParticipantDrawn,
		key: routesEnum.PARTICIPANT_DRAWN,
		name: 'Participante sorteado',
		path: routesEnum.PARTICIPANT_DRAWN,
		noWrapper: false,
		accessTypes: ['ADMIN', 'EDITOR', 'PRESENTER'],
	},

	{
		component: Regulation,
		key: routesEnum.REGULATION,
		name: 'Regulamento',
		path: routesEnum.REGULATION,
		noWrapper: false,
		accessTypes: ['ADMIN', 'EDITOR', 'PRESENTER'],
	},
]
