import styled, { css } from 'styled-components'
import { toRem } from '../../styles/function'

interface IButtonStyleProps {
    disabled: boolean
}

export const ButtonContainer = styled.button<IButtonStyleProps>`
    ${(props) => css`
        border: none;
        padding: 12px 24px;
        border-radius: 4px;
        height: 46px;
		transition: 0.4s;

        background: ${props.disabled
            ? props.theme.colors.light_gray
            : props.theme.colors.green};

        color: ${props.disabled
            ? props.theme.colors.greenish_grey_light
            : props.theme.colors.dark_graffiti};

        cursor: ${props.disabled ? 'default' : 'pointer'};

		:hover{
			box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
		}
	
		:active{
			box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;	}
		`}
`

export const Title = styled.span`
    ${(props) => css`
        font-family: ${props.theme.fonts.radikal_regular};
        font-size: ${toRem(14)};
    `}
`