import styled, { css } from 'styled-components'

export const NewBonusWordWrapper = styled.div`
	${({ theme }) => css`
		width: 100%;

		border-radius: 4px;
		background: ${theme.colors.white};
		margin-top: 16px;
	`}
`
export const NewBonusWordContainer = styled.div`
	${({ theme }) => css`
		padding: 50px 24px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		width: 100%;

		p {
			font-size: 18px;
			color: ${theme.colors.dark_graffiti};
		}

		div {
			display: flex;

			.points-input {
				width: 188px;
				margin-right: 16px;
			}

			.bonus-word-input {
				width: 376px;
				margin-right: 16px;
			}
		}
	`}
`
