import { ButtonHTMLAttributes } from 'react'

export enum TiertiaryButtonTheme {
	PRIMARY = 'PRIMARY',
	SECONDARY = 'SECONDARY',
}

export interface ITertiryButtonProps extends ButtonHTMLAttributes<HTMLElement> {
	theme?: ButtonThemeEnum
}

export type ButtonThemeEnum = keyof typeof TiertiaryButtonTheme
