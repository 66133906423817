import React, { createElement, useEffect, useState } from 'react'
import { useDebounce } from 'use-debounce'
import { IMusic, IMusicPage } from '../../shared/interfaces/music'
import { useMusicsPage } from '../../shared/service/music.service'
import { DetailButton } from './styles'
import { renderImage } from './table'
import {
	ETableAudioAcessor,
	ITableColumn,
	ITableRow,
	IViewProps,
} from './types'
import Musics from './view'

const MUSIC_PER_PAGE = 5

import {
	IAcessorAndOrder,
	ITableNavProps,
} from '../../shared/components/Table/types'
import { useTypedSelector } from '../../shared/hooks/useTypedSelector'
import { MoreHorizontal } from 'react-feather'

function MusicsContainer(): JSX.Element {
	const [searchString, setSearchString] = useState('')
	const [tableRows, setTableRows] = useState<any>([])
	const [searchDebounce] = useDebounce(searchString, 1000)
	const [modalIsOpen, setModalIsOpen] = useState(false)
	const [currentMusic, setCurrentMusic] = useState<IMusic | null>(null)
	const { user } = useTypedSelector(['token', 'user'])
	const listIsPresenters =
		user.roles.length === 1 && user.roles.includes('PRESENTER')
	const [sortBy, setSortBy] = useState<IAcessorAndOrder>({
		acessorToSend: 'totalPlayed',
		acessor: 'totalPlayed',
		order: -1,
	})
	const [musicPage, setMusicPage] = useState<IMusicPage>({
		page: 1,
		numberOfPages: 0,
		totalDocs: 0,
	})

	const {
		data: musicData,
		status: currentStatus,
		refetch,
	} = useMusicsPage({
		maxItemsInPage: MUSIC_PER_PAGE,
		pageIndex: musicPage.page,
		searchString: searchDebounce,
		order: sortBy.order,
		sort: sortBy.acessorToSend,
	})

	function handleSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
		setSearchString(event.target.value)
	}

	function goToPage(pageIndex: number) {
		setMusicPage((state) => ({ ...state, page: pageIndex }))
	}

	const tableColumns: ITableColumn[] = [
		{
			Header: '',
			accessor: 'image',
			sortType: 'basic',
			disableSortBy: true,
		},
		{
			Header: 'Nome',
			accessor: 'name',
			sortType: 'basic',
		},
		{
			Header: 'Artista',
			accessor: 'interpreter',
			sortType: 'basic',
		},
		{
			Header: 'Total Plays',
			accessor: 'totalPlayed',
		},
		{
			Header: '',
			accessor: 'editIcon',
			disableSortBy: true,
		},
	]

	function handleSortColumn(acessor: string, order: number) {
		let acessorToSend = acessor
		if (order === 0) {
			setSortBy({
				acessorToSend: 'totalPlayed',
				acessor: 'totalPlayed',
				order: -1,
			})
			return
		}

		if (acessor === ETableAudioAcessor.name) {
			acessorToSend = 'title'
		}
		setSortBy({
			acessorToSend,
			acessor,
			order,
		})
	}

	function renderEditIcon(music: IMusic) {
		if (listIsPresenters) {
			return null
		}

		function handleEdit() {
			setModalIsOpen(true)
			setCurrentMusic(music)
		}

		return (
			<DetailButton onClick={handleEdit} data-testid='button-details'>
				<MoreHorizontal style={{ strokeWidth: 1.5 }} />
			</DetailButton>
		)
	}

	function parseData() {
		if (!musicData) return

		const rows = musicData.docs.map((music) => {
			return {
				image: renderImage(music.photoURL),
				name: music.title,
				interpreter: music.interpreter,
				totalPlayed: music.totalPlayed.toLocaleString('pt-BR'),
				editIcon: renderEditIcon(music),
			}
		})

		setTableRows(rows)

		setMusicPage({
			numberOfPages: musicData.totalPages,
			page: musicData.page || 1,
			totalDocs: musicData.totalDocs,
		})
	}

	function handleCloseModal() {
		setModalIsOpen(false)
		setCurrentMusic(null)
	}

	const navProps: ITableNavProps = {
		nextPage: (pageIndex) => goToPage(pageIndex + 1),
		previousPage: (pageIndex) => goToPage(pageIndex - 1),
		gotoPage: (pageIndex) => goToPage(pageIndex),
		pageCount: musicPage.numberOfPages,
		pageIndex: musicPage.page - 1,
		totalDocs: musicPage.totalDocs,
	}

	function handleResetPage() {
		setMusicPage((props) => ({ ...props, page: 1 }))
	}

	useEffect(parseData, [musicData, refetch])
	useEffect(handleResetPage, [searchDebounce])

	const viewProps: IViewProps = {
		searchString,
		handleSearchChange,
		navProps,
		tableColumns,
		tableRows,
		loading: currentStatus === 'loading',
		handleSortColumn,
		handleCloseModal,
		modalIsOpen,
		currentMusic,
		sortBy,
		musicPage,
	}

	return createElement(Musics, viewProps)
}

export default MusicsContainer
