export enum UserStatus {
	ACTIVE = 'ACTIVE',
	DELETED = 'DELETED',
	PENDING = 'PENDING',
}

export interface IUserPage {
	page: number
	numberOfPages: number
	totalDocs: number
}
export enum UserRoles {
	ADMIN = 'ADMIN',
	EDITOR = 'EDITOR',
	PRESENTER = 'PRESENTER',
}

export const UserRolesData = ['ADMIN', 'EDITOR', 'PRESENTER']

export interface IIUserPayload {
	name: string
	email: string
	roles: string[]
	photoURL?: string
	description?: string
	socialNetwork?: { facebook: string; instagram: string; twitter: string }
}

export interface IInviteUserPayload {
	userID: string
}

export interface IRecoverPasswordPayload {
	email: string
}

export interface IPhotoUploadPayload {
	file: File | null
}

export interface IUpdateUserPayload {
	userID: string
	data: UserPayload
}

export interface IDeleteUserPayload {
	userID: string
}

export interface IActivateUserPayload {
	resetToken: string
	password: string
}

export interface IUpdatePasswordPayload {
	resetToken: string
	password: string
}

export interface IUserRequestProps {
	maxItemsInPage: number
	searchString: string
	sort?: string
	order?: number
}

export interface IUser {
	_id: string
	name: string
	email: string
	status: string
	roles: UserRolesEnum[]
	photoURL: string
	createdAt?: string // ISO Date
	phoneNumber?: string
	personalFiscalID?: string
	totalBeatCoins?: number
	socialNetwork?: { facebook: string; instagram: string; twitter: string }
	description?: string
}

export type UserRolesEnum = keyof typeof UserRoles
export type UserStatusEnum = keyof typeof UserStatus

export type UserPayload = IIUserPayload
export type IFetchUsersResponse = IUser[]

export interface IUserDetail {
	userDocument: IUser
	totalBeatCoins: number
}
