import React, { createElement, useEffect, useState } from 'react'
import { ITableColumn, ITableRow, IViewProps } from './types'
import { Promotions } from './view'

import {
	renderDeletePromotion,
	renderImage,
	renderNumberTable,
	renderSortTag,
	renderSwitch,
	renderPeriodTable,
	renderTextTable,
} from './table'

import { useDebounce } from 'use-debounce'
import { IUserPage } from '../../shared/interfaces/user'
import { DetailButton } from './styles'

import {
	IAcessorAndOrder,
	ITableNavProps,
} from '../../shared/components/Table/types'
import { handleSuccessToaster } from '../../shared/util/toaster'
import { routesEnum } from '../Routes/enum'
import { useHistory } from 'react-router-dom'
import {
	useDeletePromotion,
	useUpdatePromotionStatus,
	usePromotionsPage,
} from '../../shared/service/promotions.service'
import { IPromotion } from '../../shared/interfaces/promotion'
import {
	PermissionsTypeEnum,
	usePermission,
} from '../../shared/hooks/usePermission'
import { MoreHorizontal } from 'react-feather'

function PromotionsContainer(): JSX.Element {
	const { PROMOTIONS: PROMOTION_PERMISSIONS } = usePermission()

	const [searchString, setSearchString] = useState('')
	const [tableRows, setTableRows] = useState<ITableRow[]>([])
	const [searchDebounce] = useDebounce(searchString, 1000)
	const [deleteModalOpen, setDeleteModalOpen] = useState(false)
	const [selectedPromotionId, setSelectedPromotionId] = useState('')
	const deletePromotionMutation = useDeletePromotion()
	const updateStatusMutation = useUpdatePromotionStatus()

	const [sortBy, setSortBy] = useState<IAcessorAndOrder>({
		acessor: 'createdAt',
		order: -1,
	})

	const [userPage, setUserPage] = useState<IUserPage>({
		page: 1,
		numberOfPages: 0,
		totalDocs: 0,
	})

	const {
		status: userFetchStatus,
		data: PromotionsData,
		refetch,
	} = usePromotionsPage({
		maxItemsInPage: 5,
		pageIndex: userPage.page,
		searchString: searchDebounce,
		order: sortBy.order,
		sort: sortBy.acessor,
	})

	const history = useHistory()

	function handleUserChange(event: React.ChangeEvent<HTMLInputElement>) {
		setSearchString(event.target.value)
	}

	function handleModalOpen() {
		history.push(routesEnum.NEW_PROMOTION)
	}

	function paginationRefetch() {
		refetch()
	}

	function renderEditIcon(promotion: IPromotion) {
		function handleEdit() {
			history.push(`promotion/${promotion._id}`)
		}

		return (
			<DetailButton onClick={handleEdit} data-testid='button-details'>
				<MoreHorizontal style={{ strokeWidth: 1.5 }} />
			</DetailButton>
		)
	}

	function handleSortColumn(acessor: string, order: number) {
		if (order === 0) {
			setSortBy({
				acessor: acessor,
				order: order,
			})
			return
		}
		setSortBy({
			acessor,
			order,
		})
	}

	function goToPage(pageIndex: number) {
		setUserPage((state) => ({ ...state, page: pageIndex + 1 }))
	}

	function handleCloseModal() {
		setDeleteModalOpen(false)
	}

	async function handleSubmit() {
		await deletePromotionMutation.mutateAsync({
			promotionId: selectedPromotionId,
		})
		refetch()
		setDeleteModalOpen(false)
		handleSuccessToaster('Promoção excluída com sucesso')
	}

	async function handleSwitchRefetch(promotion: IPromotion) {
		await updateStatusMutation.mutateAsync({
			id: promotion._id,
			status: promotion.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE',
		})

		refetch()
	}

	const tableColumns: ITableColumn[] = [
		{ Header: '', accessor: 'image', disableSortBy: true },
		{ Header: 'Nome da Promoção', accessor: 'title', sortType: 'basic' },
		{ Header: 'Período', accessor: 'period' },
		{
			Header: 'Participantes',
			accessor: 'participants',
			sortType: 'number',
		},

		{
			Header: 'Sorteio',
			accessor: 'prizeDrawStatus',
			sortType: 'basic',
		},
		{
			Header: 'Status',
			accessor: 'status',
			sortType: 'boolean',
		},

		{
			Header: '',
			accessor: 'deleteIcon',
			sortType: 'basic',
			disableSortBy: true,
		},
		{
			Header: '',
			accessor: 'editIcon',
			sortType: 'basic',
			disableSortBy: true,
		},
	]

	const navProps: ITableNavProps = {
		nextPage: (pageIndex) => goToPage(pageIndex),
		previousPage: (pageIndex) => goToPage(pageIndex),
		gotoPage: (pageIndex) => goToPage(pageIndex),
		pageCount: userPage.numberOfPages,
		pageIndex: userPage.page - 1,
		totalDocs: userPage.totalDocs,
	}

	const renderTableElements = (promotion: IPromotion) => {
		return {
			image: renderImage(promotion.artworkURL),
			title: renderTextTable(promotion.title),
			period: renderPeriodTable(promotion.startDate, promotion.endDate),
			participants: renderNumberTable(promotion.participants?.length || 0),
			prizeDrawStatus: renderSortTag(
				promotion.accomplishedAt,
				promotion.participants || [],
			),
			status: renderSwitch({
				promotion,
				handleSwitchRefetch,
				userCanActivateOrDeactivate: PROMOTION_PERMISSIONS.includes(
					PermissionsTypeEnum.ACTIVATE_OR_DEACTIVATE,
				),
			}),
			editIcon: renderEditIcon(promotion),
			deleteIcon: renderDeletePromotion({
				promotion,
				setDeleteModalOpen,
				setSelectedPromotionId,
				userCanDelete: PROMOTION_PERMISSIONS.includes(
					PermissionsTypeEnum.DELETE,
				),
			}),
		}
	}

	function parseData() {
		if (!PromotionsData) return

		const rows = PromotionsData?.docs.map(renderTableElements)

		if (!rows) return

		setTableRows(rows)

		setUserPage({
			numberOfPages: PromotionsData?.totalPages,
			page: PromotionsData?.page || 1,
			totalDocs: PromotionsData?.totalDocs,
		})
	}

	useEffect(parseData, [PromotionsData, searchDebounce, PROMOTION_PERMISSIONS])

	const viewProps: IViewProps = {
		handleUserChange,
		searchString,
		tableColumns,
		tableRows,
		isLoading: userFetchStatus === 'loading',
		handleModalOpen,
		paginationRefetch,
		handleSortColumn,
		sortBy,
		navProps,
		userPage,
		deleteModalOpen,
		handleCloseModal,
		handleSubmit,
	}

	return createElement(Promotions, viewProps)
}

export default PromotionsContainer
