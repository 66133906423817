import React, { Fragment, useCallback, useMemo, useState } from 'react'
import { format } from 'date-fns'
import prBR from 'date-fns/locale/pt-BR'

import { IPromotion } from '../../../shared/interfaces/promotion'
import {
	PermissionsTypeEnum,
	usePermission,
} from '../../../shared/hooks/usePermission'

import * as S from './styles'
import {
	fetchPrizeDrawn,
	useCheckValidParticipants,
	usePaginateParticipants,
} from '../../../shared/service/promotions.service'
import { Button, Modal } from '../../../shared/components'
import { RaffleConfirmationModal } from '../RaffleConfirmationModal'
import { useModal } from '../../../shared/hooks/useModal'
import { useHistory } from 'react-router-dom'
import RaffleCountModal from '../../../shared/components/RaffleCountModal'
import { IUser } from '../../../shared/interfaces/user'

interface IPrizeDrawCardHeaderProps {
	promotion: IPromotion
}

export function PrizeDrawCardHeader({ promotion }: IPrizeDrawCardHeaderProps) {
	const [participantDrawn, setParticipantDrawn] = useState<IUser>()

	const history = useHistory()

	const { PROMOTIONS: PROMOTION_PERMISSIONS } = usePermission()
	const userCanRaffle = useMemo(
		() => PROMOTION_PERMISSIONS.includes(PermissionsTypeEnum.RAFFLE),
		[PROMOTION_PERMISSIONS],
	)
	const { data, isLoading: isCheckingValidParticipants } =
		useCheckValidParticipants(promotion._id)
	const hasValidParticipants = !!data?.hasValidParticipants
	const hasRaffleStockQuantity = promotion.stockQuantity > 0

	const handleRaffle = useCallback(async () => {
		try {
			raffleCounterModal.toggleVisibility()
			const participantDrawnResponse = await fetchPrizeDrawn(promotion._id)
			if (setParticipantDrawn) {
				setParticipantDrawn(participantDrawnResponse)
			}
		} catch {
			raffleCounterModal.toggleVisibility()
		}
	}, [])

	const onCompletePrizeDrawnCount = useCallback(async () => {
		if (!raffleCounterModal.isVisible || !participantDrawn) return
		raffleCounterModal.toggleVisibility()
		history.push(`/promotions/drawn/${promotion._id}`, {
			promotionDetails: promotion,
			participantDrawn,
		})
	}, [participantDrawn])

	const raffleConfirmationModal = useModal({
		onConfirm: handleRaffle,
	})
	const raffleCounterModal = useModal({
		onConfirm: onCompletePrizeDrawnCount,
	})
	const invalidParticipantsInfoModal = useModal()

	const participantImagesQuantityToShow = promotion.accomplishedAt ? 5 : 6

	const { data: participantsPage } = usePaginateParticipants({
		promotionID: promotion._id,
		maxItemsInPage: participantImagesQuantityToShow,
		pageIndex: 1,
		searchString: '',
		sort: 'photoURL',
		order: -1,
		onlyWinners: !!promotion.accomplishedAt,
	})
	const totalParticipants = participantsPage?.totalDocs || 0
	const participantImages = participantsPage?.docs || []

	const participantImagesToShow = useMemo(() => {
		const participantsImages = participantImages
			.filter((participant) => !!participant.photoURL)
			.map((participant) => participant.photoURL)
		if (!participantsImages.length) return []

		if (promotion.accomplishedAt)
			return participantsImages.slice(0, participantImagesQuantityToShow)

		const participantImagesToShowAcc = []
		while (
			participantImagesToShowAcc.length < participantImagesQuantityToShow
		) {
			participantImagesToShowAcc.push(...participantsImages)
		}

		return participantImagesToShowAcc.slice(0, participantImagesQuantityToShow)
	}, [participantImages])

	if (promotion.accomplishedAt)
		return (
			<S.CardAndDividerWrapper>
				<S.FinishedPrizeDrawCard>
					<span className='title'>Esta promoção já foi finalizada!</span>
					<span className='subtitle'>
						Promoção finalizada em{' '}
						<span
							className='finish-date'
							dangerouslySetInnerHTML={{
								__html: format(
									new Date(promotion.accomplishedAt),
									// eslint-disable-next-line quotes
									`dd' de <span class="finish-date-month">'MMMM'</span>, 'yyyy', às 'HH':'mm`,
									{
										locale: prBR,
									},
								),
							}}
						></span>
					</span>
					<div className='winners'>
						<div className='winners-images'>
							{React.Children.toArray(
								participantImagesToShow.map((photoURL) => (
									<img src={photoURL} className='participant-image' alt='' />
								)),
							)}
						</div>
						<span className='winners-label'>
							{totalParticipants} ouvintes foram sorteados
						</span>
					</div>
				</S.FinishedPrizeDrawCard>
				<S.Divider />
			</S.CardAndDividerWrapper>
		)

	if (promotion.participants.length < 2 || isCheckingValidParticipants)
		return null

	if (!hasValidParticipants || !hasRaffleStockQuantity)
		return (
			<Fragment>
				<S.CardAndDividerWrapper>
					<S.RaffleCard>
						<div className='participants-images-slide'>
							{React.Children.toArray(
								participantImagesToShow.map((photoURL) => (
									<img src={photoURL} className='participant-image' alt='' />
								)),
							)}
						</div>
						<span className='title'>
							Esta promoção já possui {totalParticipants} participantes.
							<br />
							Bora realizar o sorteio?
						</span>
						<span className='invalid-participants-message'>
							{!hasValidParticipants ? (
								<>
									Esta promoção não possui
									<br />
									participantes válidos.
								</>
							) : (
								<>
									A quantidade de sorteios nesta
									<br />
									promoção está zerada.
								</>
							)}
						</span>

						<div className='invalid-participants-info-button'>
							<Button.Root
								theme='pure_black'
								shape='round'
								spacing={[0, 0, 0, 0]}
								onClick={invalidParticipantsInfoModal.toggleVisibility}
							>
								<Button.Icon name='question' />
							</Button.Root>
						</div>
						<Button.Root theme='pure_black' spacing='12x24' disabled>
							<Button.Text text='Realizar Sorteio' />
						</Button.Root>
					</S.RaffleCard>
					<S.Divider />
				</S.CardAndDividerWrapper>
				<Modal.Root
					isVisible={invalidParticipantsInfoModal.isVisible}
					title={
						!hasValidParticipants ? 'Participantes válidos' : 'Sorteios Zerados'
					}
					onClose={invalidParticipantsInfoModal.onCancel}
				>
					<Modal.ParagraphText
						text={
							!hasValidParticipants
								? 'Participantes válidos são aqueles que ainda não foram sorteados nesta ou em qualquer outra promoção nos últimos 90 dias.'
								: 'A quantidade de sorteios nesta promoção está zerada. Para aumentar, solicite a edição para um administrador.'
						}
					/>
					<Modal.ContentSpacing size={56} />
					<Modal.Button
						theme='light'
						spacing='12x24'
						text='Fechar'
						onClick={invalidParticipantsInfoModal.onCancel}
					/>
				</Modal.Root>
			</Fragment>
		)

	if (!userCanRaffle) return null

	return (
		<Fragment>
			<S.CardAndDividerWrapper>
				<S.RaffleCard>
					<span className='title'>
						Esta promoção já possui {totalParticipants} participantes.
						<br />
						Bora realizar o sorteio?
					</span>
					<div className='participants-images-slide'>
						{React.Children.toArray(
							participantImagesToShow.map((photoURL) => (
								<img src={photoURL} className='participant-image' alt='' />
							)),
						)}
					</div>
					<Button.Root
						theme='pure_black'
						spacing='12x24'
						onClick={raffleConfirmationModal.toggleVisibility}
					>
						<Button.Text text='Realizar Sorteio' />
					</Button.Root>
				</S.RaffleCard>
				<S.Divider />
			</S.CardAndDividerWrapper>
			<RaffleCountModal {...raffleCounterModal} />
			<RaffleConfirmationModal
				{...raffleConfirmationModal}
				promotionTitle={promotion.title}
			/>
		</Fragment>
	)
}
