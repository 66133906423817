import React from 'react'
import { useSlate } from 'slate-react'

import { ReactComponent as FormatBold } from '../../../../assets/icons/format-bold.svg'
import { ReactComponent as FormatItalic } from '../../../../assets/icons/format-italic.svg'
import { ReactComponent as FormatUnderline } from '../../../../assets/icons/format-underline.svg'
import { ReactComponent as FormatH1 } from '../../../../assets/icons/format-h-1.svg'
import { ReactComponent as FormatH2 } from '../../../../assets/icons/format-h-2.svg'
import { ReactComponent as FormatQuote } from '../../../../assets/icons/format-quote.svg'
import { ReactComponent as FormatBulletList } from '../../../../assets/icons/format-bullet-list.svg'
import { ReactComponent as FormatNumberList } from '../../../../assets/icons/format-number-list.svg'
import { ReactComponent as FormatAlignLeft } from '../../../../assets/icons/format-align-left.svg'
import { ReactComponent as FormatAlignCenter } from '../../../../assets/icons/format-align-center.svg'
import { ReactComponent as FormatAlignRight } from '../../../../assets/icons/format-align-right.svg'
import { ReactComponent as FormatAlignJustfied } from '../../../../assets/icons/format-align-justified.svg'

import { theme } from '../../../styles/theme'
import { AllChildrenTypesAndStyles, CustomEditor } from '../slate'
import * as S from './styles'
import Button from './Button'
import { isBlockActive, isMarkActive, toggleBlock, toggleMark } from '../utils'

const buttons: {
	name: AllChildrenTypesAndStyles
	type: 'block' | 'mark'
	icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
}[] = [
	{
		name: 'format-bold',
		type: 'mark',
		icon: FormatBold,
	},
	{
		name: 'format-italic',
		type: 'mark',
		icon: FormatItalic,
	},
	{
		name: 'format-underline',
		type: 'mark',
		icon: FormatUnderline,
	},
	{
		name: 'format-h1',
		type: 'block',
		icon: FormatH1,
	},
	{
		name: 'format-h2',
		type: 'block',
		icon: FormatH2,
	},
	{
		name: 'format-quote',
		type: 'block',
		icon: FormatQuote,
	},
	{
		name: 'format-bullet-list',
		type: 'block',
		icon: FormatBulletList,
	},
	{
		name: 'format-numbered-list',
		type: 'block',
		icon: FormatNumberList,
	},
	{
		name: 'format-left',
		type: 'block',
		icon: FormatAlignLeft,
	},
	{
		name: 'format-center',
		type: 'block',
		icon: FormatAlignCenter,
	},
	{
		name: 'format-right',
		type: 'block',
		icon: FormatAlignRight,
	},
	{
		name: 'format-justified',
		type: 'block',
		icon: FormatAlignJustfied,
	},
]

interface IEditorBarProps {
	disabled?: boolean
}

export function EditionBar({ disabled }: IEditorBarProps) {
	function isActive(
		editor: CustomEditor,
		name: AllChildrenTypesAndStyles,
		type: 'block' | 'mark',
	) {
		if (type === 'block') {
			return isBlockActive(editor, name)
		} else {
			return isMarkActive(editor, name)
		}
	}

	function handleClickButton(
		editor: CustomEditor,
		name: AllChildrenTypesAndStyles,
		type: 'block' | 'mark',
	) {
		if (type === 'block') {
			toggleBlock(editor, name)
		} else {
			toggleMark(editor, name)
		}
	}

	return (
		<S.Container>
			{buttons.map(({ name, type, icon: Icon }) => {
				const editor = useSlate()
				return (
					<Button
						key={name}
						onMouseDown={(event) => {
							event.preventDefault()
							if (!disabled) handleClickButton(editor, name, type)
						}}
						className={`${disabled ? 'disabled' : ''} button-icon`}
					>
						<Icon
							stroke={
								isActive(editor, name, type)
									? theme.colors.green_medium
									: theme.colors.greenish_grey_medium
							}
						/>
					</Button>
				)
			})}
		</S.Container>
	)
}
