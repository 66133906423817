import React, { AllHTMLAttributes, LegacyRef, ReactNode } from 'react'

interface IButtonProps extends AllHTMLAttributes<HTMLSpanElement> {
	children: ReactNode
}

export const Button = (
	{ children, ...rest }: IButtonProps,
	ref: LegacyRef<HTMLSpanElement>,
) => {
	return (
		<span ref={ref} {...rest}>
			{children}
		</span>
	)
}

export default React.forwardRef(Button)
