import styled, { css } from 'styled-components'

import facebookIcon from '../../assets/icons/facebook-icon.svg'
import facebookGreenIcon from '../../assets/icons/facebook-green-icon.svg'
import instagramIcon from '../../assets/icons/instagram-icon.svg'
import instagramGreenIcon from '../../assets/icons/instagram-green-icon.svg'
import twitterIcon from '../../assets/icons/twitter-icon.svg'
import twitterGreenIcon from '../../assets/icons/twitter-green-icon.svg'

import { theme } from '../../shared/styles/theme'
import { toRem } from '../../shared/styles/function'

export const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		width: '429px',
		height: '650px',
		padding: '0px',
	},
	overlay: {
		zIndex: 21,
	},
}

export const ModalTitle = styled.h1`
	${({ theme }) => css`
		font-size: ${toRem(24)};
		color: ${theme.colors.dark_graffiti};
		font-family: ${theme.fonts.radikal_light};
		font-weight: 300;
	`};
`

export const Header = styled.div`
	display: flex;
	flex-direction: column;

	.close-icon {
		color: ${({ theme }) => theme.colors.dark_graffiti};
		width: 24px;
		height: 24px;
		cursor: pointer;
	}
`

export const ModalSeparator = styled.div`
	background: ${({ theme }) => theme.colors.light_gray};
	height: 1px;
	width: 100%;
	position: absolute;
	left: 0;
`

export const FormWrapper = styled.div`
	${({ theme }) => css`
		margin-top: 32px;

		.name-input {
			background-color: white;
		}

		.email-input {
			margin-top: 16px;
			background-color: white;
		}

		.role-select {
			margin-top: 16px;
			margin-bottom: 56px;
			background-color: white;
		}
		.description-input {
			background-color: white;
		}
		.facebook-input, .instagram-input, .twitter-input  {
			margin-bottom: 16px;
			background-repeat: no-repeat;
			background-position: left;
			background-position-x: 10px;
			padding: 12px 42px;
			background-color: white;

			&.facebook-input {
				background-image: url(${facebookIcon});
				&.input-filled, &:focus {
					background-image: url(${facebookGreenIcon});
				}
			}
			&.instagram-input {
				background-image: url(${instagramIcon});
				&.input-filled, &:focus {
					background-image: url(${instagramGreenIcon});
				}
			}
			&.twitter-input {
				background-image: url(${twitterIcon});
				&.input-filled, &:focus {
					background-image: url(${twitterGreenIcon});
				}
			}
		}

		display: flex;
		flex-direction: column;
		justify-content: center;

		.button-wrapper-secondary {
			display: flex;
			justify-content: center;
			margin-top: 24px;

			.delete-user-button {
				background: none;
				border: none;
				color: ${theme.colors.dark_graffiti};
				font-size: ${toRem(12)};
				cursor: pointer;
				font-family: ${theme.fonts.radikal_regular};
			}
		}
	`}
`

export const EditButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 56px;
	/* margin-bottom: 120px; */

	.loading {
		height: 50px;
	}
`

export const DeleteButtonWrapper = styled.div`
	${({ theme }) => css`
		display: flex;
		justify-content: center;
		margin-top: 16px;
		margin-bottom: 120px;

		button {
			background-color: ${theme.colors.background};
			transition: all 0.4s;
			:hover {
				background-color: ${theme.colors.light_gray};
			}
		}

		.loading {
			height: 50px;
		}
	`}
`

export const UserTypeWrapper = styled.div`
	padding: 32px 0px;
	.select {
		background-color: white;
	}
`

export const UploadImageButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 48px;
`

export const DeleteModal = styled.div`
	width: 100%;
	height: 100%;

	display: flex;
	align-items: center;

	background: rgba(0, 0, 0, 0.56);
	color: black;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 10;
`
export const DeleteModalContainer = styled.div`
	width: 100%;
	height: 60%;
	background-color: rgba(255, 255, 255);
	border-radius: 8px;

	border: 0.5px solid ${theme.colors.greenish_grey_light};
	.x {
		cursor: pointer;
	}
	.title {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 20px;
		border-bottom: 1px solid ${theme.colors.greenish_grey_light};
		.title-text {
			font-family: ${theme.fonts.radikal_light};
			font-size: 24px;
			color: #383d3a;
		}
	}
	.description {
		display: flex;
		align-items: center;
		justify-content: center;

		padding: 40px 60px;
		margin-bottom: 20px;
		.description-text {
			font-family: ${theme.fonts.radikal_regular};
			font-size: 16px;
			color: #383d3a;
		}
	}

	.buttons {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.delete {
		background: ${theme.colors.greenish_grey_light};
		border-radius: 4px;
		color: ${theme.colors.greenish_white};
		transition: 0.2s;
		:hover {
			background: ${theme.colors.red};
		}
	}
	button {
		margin-bottom: 16px;
		display: flex;
		padding: 12px 24px;

		border: none;
		background: none;
		cursor: pointer;
	}
`

export const ContentContainer = styled.div`
	width: 100%;
	max-width: 430px;
`

export const ContentContainerWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
`

export const PresenterInfos = styled.h1`
	${({ theme }) => css`
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		color: ${theme.colors.dark_graffiti};
		margin-top: 32px;
		margin-bottom: 16px;
	`}
`

export const PresenterInfosContainer = styled.div``
export const InputCaractersContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
`
export const InputCaracters = styled.p`
	${({ theme }) => css`
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 18px;
		color: ${theme.colors.greenish_grey_medium};
		margin-top: 8px;
	`}
`
