import styled, { css } from 'styled-components'
import { toRem } from '../../styles/function'

export const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		width: '429px',
		height: '540px',
		padding: '0px',
	},
	overlay: {
		zIndex: 21,
		backgroundColor: 'rgba(0, 0, 0, 0.56)'
	},
}

export const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 40px 20px;

	.close-icon {
		color: ${({ theme }) => theme.colors.dark_graffiti};
		width: 24px;
		height: 24px;
		cursor: pointer;
		transition: 0.4s;

		:hover{
			color: ${({ theme }) => theme.colors.pure_black};
		}
	}
`
export const ModalTitle = styled.h1`
	${({ theme }) => css`
		font-size: ${toRem(24)};
		color: ${theme.colors.dark_graffiti};
		font-family: ${theme.fonts.radikal_light};
		font-weight: 300;
	`};
`

export const ModalSeparator = styled.div`
	background: ${({ theme }) => theme.colors.light_gray};
	height: 1px;
	width: 100%;
`

export const UploadImageButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 48px;
	padding-left: 24px;
	padding-right: 24px;
`

export const InputWrapper = styled.div`
	padding: 0px 24px;
	margin-top: 32px;
`

export const ButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 56px;
`
