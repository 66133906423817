import React, { useEffect, useState } from 'react'
import {
	DatePickerWrapper,
	InputWrapper,
	PrimaryInputContainer,
} from './styles'
import { IPrimaryDateTimeInputProps } from './types'

import { Calendar as CalendarIcon } from 'react-feather'

import { DateRange, RangeKeyDict } from 'react-date-range'

import { format } from 'date-fns'
import { theme } from '../../styles/theme'

function DateInputPicker(props: IPrimaryDateTimeInputProps) {
	const {
		type,
		disabled = false,
		handleOnChange,
		placeholder,
		dateRange,
		...rest
	} = props

	const [isFocused, setFocused] = useState(false)

	const [startDate, setStartDate] = useState<string | null>(null)
	const [endDate, setEndDate] = useState<string | null>(null)

	const [rangeTouched, setRangeTouched] = useState({
		startTouched: true,
		endTouched: true,
	})

	const hasValue = !!startDate && !!endDate

	function handleOnBlur() {
		setFocused(false)
	}

	function handleOnFocus() {
		setFocused(true)
	}

	function handleFormatData() {
		setStartDate(
			dateRange.startDate ? format(dateRange.startDate, 'dd/MM/yyyy') : null,
		)
		setEndDate(
			dateRange.endDate ? format(dateRange.endDate, 'dd/MM/yyyy') : null,
		)
	}

	function handleIsFocused() {
		if (!isFocused) return

		if (rangeTouched.startTouched && rangeTouched.endTouched) {
			setRangeTouched({ startTouched: false, endTouched: false })
		}
	}

	function onChange(rangesByKey: RangeKeyDict) {
		if (!rangeTouched.startTouched) {
			setRangeTouched((state) => ({ ...state, startTouched: true }))

			// handleOnChange({ startDate: rangesByKey.range1.startDate })
		} else if (rangeTouched.startTouched) {
			setRangeTouched((state) => ({ ...state, endTouched: true }))
			// handleOnChange({
			// 	startDate: rangesByKey.range1.startDate,
			// 	endDate: rangesByKey.range1.endDate,
			// })
		}
		handleOnChange({
			startDate: rangesByKey.range1.startDate,
			endDate: rangesByKey.range1.endDate,
		})
	}

	useEffect(handleIsFocused, [isFocused])
	useEffect(handleFormatData, [dateRange])

	const showRangePicker = !rangeTouched.startTouched || !rangeTouched.endTouched

	return (
		<DatePickerWrapper>
			<InputWrapper>
				<PrimaryInputContainer
					activeBorder={disabled ? false : isFocused || !!hasValue}
					disabled={disabled}
					value={hasValue ? `${startDate} até ${endDate}` : ''}
					onBlur={handleOnBlur}
					placeholder={placeholder}
					onFocus={handleOnFocus}
					{...rest}
				/>

				<CalendarIcon className='calendar-icon' />
			</InputWrapper>

			{showRangePicker && (
				<DateRange
					className='date-picker'
					ranges={[dateRange as any]}
					onChange={onChange}
					rangeColors={[theme.colors.green]}
					showMonthAndYearPickers={false}
				/>
			)}
		</DatePickerWrapper>
	)
}

export default DateInputPicker
