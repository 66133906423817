/* eslint-disable */
import * as S from './styles'
import { IViewProps } from './types'

import 'react-tooltip/dist/react-tooltip.css'
import ButtonIcon from '../../assets/icons/Button.svg'
import ArrowLeft from '../../assets/icons/left-arrow.svg'
import { Button, GoBackButton } from '../../shared/components'
import DateTimeSelect from '../../shared/components/DateTimeSelect'
import { Input } from '../../shared/components/Input'
import SecondarySelect from '../../shared/components/SecondarySelect'
import { UploadImageButton } from '../../shared/components/UploadImageButton'
import ViewErrors from '../../shared/components/ViewErrors'

import { Tooltip as ReactTooltip } from 'react-tooltip'

import TooltipIcon from '../../assets/icons/tooltip-button.svg'
import { SecondaryUploadImageButton } from '../../shared/components/SecondaryUploadImageButton'

function NewProgram(props: IViewProps) {
	const {
		handleProgramChange,
		selectedPresenters,
		image,
		handleInputChange,
		presentersData,
		cleanImageSeletor,
		handleSelectPresentersChange,
		programDates,
		handlePlusProgramDate,
		handleLessProgramDate,
		changeProgramDate,
		description,
		programName,
		handleProgramDescription,
		viewErrorsData,
		canSubmit,
		isLoading,
		handleSubmit,
		handleGoBack,
		artworkImage,
		cleanImageArtWorkSeletor,
		handleInputArtWorkChange,
	} = props

	return (
		<>
			<S.NewProgramWrapper>
				<S.Header>
					<GoBackButton />
					<S.ModalTitle>Cadastrar Novo Programa</S.ModalTitle>
				</S.Header>

				<S.ModalContent>
					<S.ModalContentWrapper>
						<S.LineWrapper style={{ marginTop: 32 }}>
							<S.TooltipWrapper>
								<h4>Upload da artwork do programa</h4>
								<img id='artwork-tooltip' src={TooltipIcon} alt='' />
							</S.TooltipWrapper>

							<S.UploadImageButtonWrapper>
								<SecondaryUploadImageButton
									label='Upload da Artwork do Programa'
									onChange={handleInputArtWorkChange}
									imageSizeLabel='Resolução Máxima 1200x1200'
									imageClean={cleanImageArtWorkSeletor}
									showInfo
									image={artworkImage}
								/>
							</S.UploadImageButtonWrapper>
						</S.LineWrapper>
						<S.LineWrapper style={{ marginTop: 32 }}>
							<S.TooltipWrapper>
								<h4>Upload da miniatura do programa*</h4>
								<img id='banner-tooltip' src={TooltipIcon} alt='' />
							</S.TooltipWrapper>
							<S.DescriptionLine>
								*A miniatura deve ser somente o logo do programa.
							</S.DescriptionLine>
							<S.UploadImageButtonWrapper>
								<UploadImageButton
									label='Upload da Foto da Capa'
									onChange={handleInputChange}
									imageSizeLabel='Resolução Máxima 200x200'
									imageClean={cleanImageSeletor}
									showInfo
									image={image}
								/>
							</S.UploadImageButtonWrapper>
						</S.LineWrapper>
						<S.LineWrapper style={{ marginTop: 32 }}>
							<Input
								className='program-name'
								value={programName}
								type='text'
								placeholder='Nome do Programa'
								onChange={handleProgramChange}
							/>
						</S.LineWrapper>
						<S.LineWrapper style={{ marginTop: 32 }}>
							<SecondarySelect
								label='Apresentadores'
								onChange={handleSelectPresentersChange}
								data={presentersData}
								value={selectedPresenters}
							/>
						</S.LineWrapper>
						<S.LineWrapper style={{ marginTop: 32 }}>
							<h4>Descrição do Programa</h4>

							<Input
								className='program-description'
								value={description}
								type='text'
								placeholder='Descreva sobre o programa'
								onChange={handleProgramDescription}
								maxLength={200}
							/>
						</S.LineWrapper>
					</S.ModalContentWrapper>

					<S.Separator />

					<S.ModalContentWrapper>
						<S.LineWrapper style={{ marginTop: 48 }}>
							<S.InputWrapper>
								<h4>Selecione os dias e horários de apresentação</h4>
								<p>
									Clique em{' '}
									<img width={24} height={24} src={ButtonIcon} alt='' /> para
									adicionar mais de um Dia e Horário para programas que se
									repetem durante a semana{' '}
								</p>

								{programDates.map((program, index) => (
									<S.DateTimeSelectWrapper key={index} index={index}>
										<DateTimeSelect
											index={index}
											dayOfWeek={program.day}
											timeStart={program.timeStart}
											timeEnd={program.timeEnd}
											handlePlusProgramDate={handlePlusProgramDate}
											length={programDates.length}
											removeProgramDate={handleLessProgramDate}
											onUpdate={changeProgramDate}
										/>
									</S.DateTimeSelectWrapper>
								))}
							</S.InputWrapper>
						</S.LineWrapper>
					</S.ModalContentWrapper>

					<S.ErrorsWrapper>
						<ViewErrors data={viewErrorsData} />
					</S.ErrorsWrapper>
					<S.ButtonWrapper>
						<Button.Root
							disabled={!canSubmit}
							isLoading={isLoading}
							onClick={handleSubmit}
							spacing={'12x24'}
						>
							<Button.Text text='Cadastrar Programa' />
						</Button.Root>
					</S.ButtonWrapper>
				</S.ModalContent>
			</S.NewProgramWrapper>
			<ReactTooltip
				anchorSelect='#artwork-tooltip'
				place='bottom'
				content='Esta imagem será exibida na tela de Programas.'
			/>

			<ReactTooltip
				anchorSelect='#banner-tooltip'
				place='bottom'
				content='Esta miniatura será exibida no perfil dos apresentadores.'
			/>
		</>
	)
}

export { NewProgram }
