import React from 'react'
import {
	ImageWrapper,
	RoleWrapper,
	TableImagePlaceholder,
	UserTableStatus,
} from './styles'
import { ROLES_ENUM, ROLES_ENUM_STATUS } from '../../shared/util/translate'
import { UserRolesEnum } from '../../shared/interfaces/user'

import { Image } from 'react-feather'

export function renderTextTable(str: string) {
	return <p>{str}</p>
}

export function renderRolesTable(roles: UserRolesEnum[]) {
	return (
		<RoleWrapper>
			{roles.map((role, i) => (
				<h4 key={i}>{ROLES_ENUM[role]}</h4>
			))}
		</RoleWrapper>
	)
}

export function renderUserStatus(status: string) {
	return (
		<UserTableStatus status={status}>
			<div></div>
			<h4>{ROLES_ENUM_STATUS[status]}</h4>
		</UserTableStatus>
	)
}

export function renderImage(photoURL: string) {
	return (
		<ImageWrapper>
			{photoURL ? (
				<img className='report-image' src={photoURL} alt='profile image' />
			) : (
				<TableImagePlaceholder>
					<Image className='placeholder-icon' />
				</TableImagePlaceholder>
			)}
		</ImageWrapper>
	)
}
