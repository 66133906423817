import styled, { css } from 'styled-components'

export const BeatCoinsCardWrapper = styled.div`
	${({ theme }) => css`
		background-color: ${theme.colors.white};
		width: 151px;
		height: 108px;
		justify-content: center;
		align-items: center;
		display: flex;
		flex-direction: column;
		border-radius: 4px;
	`}
`

export const Title = styled.span`
	${({ theme }) => css`
		font-size: 14px;
		color: ${theme.colors.dark_graffiti};
		font-family: ${theme.fonts.radikal_regular};
	`}
`

export const PointsBadge = styled.div`
	${({ theme }) => css`
		background: ${theme.colors.green};
		padding: 4px 8px;
		border-radius: 13px;
		margin-top: 8px;
	`}
`

export const PointsBadgeText = styled.div`
	${({ theme }) => css`
		color: ${theme.colors.dark_graffiti};
		font-size: 12px;
		font-family: ${theme.fonts.radikal_medium};
	`}
`
