import { routesEnum } from '../../modules/Routes/enum'
import { UserRoles, UserRolesEnum } from '../interfaces/user'

export function redirectRoute(userType: UserRolesEnum[], secret: string) {
	const userAdmin = userType?.includes(UserRoles.ADMIN)

	if (userAdmin && secret) {
		return routesEnum.USERS
	}

	return routesEnum.LOGIN
}
