import styled, { css } from 'styled-components'
import { toRem } from '../../shared/styles/function'

export const EBonusContainer = styled.div``

export const Header = styled.header`
	align-items: center;
	justify-content: space-between;
	margin-top: 32px;
`

export const TitlePage = styled.span`
	${({ theme }) => css`
		color: ${theme.colors.dark_graffiti};
		font-family: ${theme.fonts.radikal_light};
		font-size: ${toRem(24)};
		margin-right: 48px;
	`}
`

export const ContentWrapper = styled.div`
	margin-top: 64px;

	header {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-top: 32px;
	}
`

export const TableWrapper = styled.div`
	margin-top: 16px;
`
