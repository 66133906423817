import styled, { css } from 'styled-components'


interface IStatusProps {
	isActive: boolean
}

export const TabWrapperContainer = styled.div<IStatusProps>`
	${({ theme,isActive }) => css`
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		.title {
			font-size: 24px;
			font-style: normal;
			font-weight: 300;
			line-height: 32px;
			color: ${isActive ? theme.colors.dark_graffiti : theme.colors.greenish_grey_medium};
		}

		.number-value-container {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 4px 8px;
			background-color: ${isActive ? theme.colors.green : theme.colors.light_gray};
			border-radius: 19px;
		}


		.number-value {
			margin-top: 4px;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 22px;
			color: ${isActive ? theme.colors.dark_graffiti :  theme.colors.greenish_grey_medium};
		}
	`}
`

export const TabsContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 40px;
	margin-top: 40px;
`

export const Tab = styled.div`
	display: flex;
	gap: 16px;
	cursor: pointer;
`


export const ActiveBorder = styled.div`
	${({ theme }) => css`
		display: flex;
		margin-top: 20px;
		width: calc(100% - 40px);
		height: 2px;
		background-color: ${theme.colors.green};
	`}
`

export const InactiveBorder = styled.div`
	display: flex;
	margin-top: 20px;
	width: 100%;
	height: 2px;
	background-color: transparent;
`