import { ChangeEvent, createElement, useEffect, useState } from 'react'
import { IRouteParams, IViewProps } from './types'
import { isPast } from 'date-fns'
import { EditPromotionView } from './view'

import { IImageUpload } from '../../shared/interfaces/image'
import { INITIAL_STATE_IMAGE_UPLOAD } from '../../shared/util/image'

import { useHistory, useParams } from 'react-router-dom'
import { IRangeDate } from '../../shared/components/ProductModal/types'
import { Descendant } from 'slate'
import {
	updatePromotion,
	usePromotionDetail,
} from '../../shared/service/promotions.service'

import { handleSuccessToaster } from '../../shared/util/toaster'
import { routesEnum } from '../Routes/enum'
import { useMutationPhotoUpload } from '../../shared/service/user.service'
function EditPromotion(): JSX.Element {
	const { promotionID }: IRouteParams = useParams()
	const { data: Promotion, refetch } = usePromotionDetail(promotionID)
	const [promotionName, setPromotionName] = useState('')
	const [promotionAmount, setPromotionAmount] = useState(0)
	const [canSubmit, setCanSubmit] = useState(true)

	const [dateIsEmpty, setDateIsEmpty] = useState(true)

	const [isLoading, setIsLoading] = useState(false)

	const photoUploadMutation = useMutationPhotoUpload()

	const [descriptionReturnIsEmpty, setDescriptionReturnIsEmpty] =
		useState(false)

	const [regulationReturnIsEmpty, setRegulationReturnIsEmpty] = useState(false)

	const [description, setDescription] =
		useState<Descendant[] | undefined>(undefined)

	const [regulation, setRegulation] =
		useState<Descendant[] | undefined>(undefined)

	const [rangeDate, setRangeDate] = useState<IRangeDate>({})

	function handleChangeRangeDate(range: IRangeDate) {
		setRangeDate(range)
	}

	function handleChangePromotionAmount(
		event: React.ChangeEvent<HTMLInputElement>,
	) {
		const promotionNameAsNumber = Number(event.target.value.replace(/\D/g, ''))
		setPromotionAmount(promotionNameAsNumber)
	}

	const [image, setImage] = useState<IImageUpload>(INITIAL_STATE_IMAGE_UPLOAD)

	const history = useHistory()

	async function handleUpdatePromotion() {
		try {
			setIsLoading(true)

			let artworkURL = ''

			if (image.file) {
				artworkURL = await photoUploadMutation.mutateAsync({ file: image.file })
			}

			const switchResponse = await updatePromotion(
				Promotion?._id || '',
				artworkURL || Promotion?.artworkURL || '',
				promotionName || Promotion?.title || '',
				rangeDate.startDate || Promotion?.startDate || new Date(),
				rangeDate.endDate || Promotion?.endDate || new Date(),
				JSON.stringify(description) || Promotion?.description || '',
				promotionAmount || Promotion?.stockQuantity || 0,
				JSON.stringify(regulation) || Promotion?.regulation || '',
			)

			if (!switchResponse) return

			refetch()
			history.push(routesEnum.PROMOTIONS)
			handleSuccessToaster('Promoção editada com sucesso')
		} finally {
			setIsLoading(false)
		}
	}

	function handlePromotionDescription(newValue: Descendant[]) {
		setDescription(newValue)
	}

	function handlePromotionRegulation(newValue: Descendant[]) {
		setRegulation(newValue)
	}

	function handleChangePromotionName(
		event: React.ChangeEvent<HTMLInputElement>,
	) {
		setPromotionName(event.target.value)
	}

	function handleGoBack() {
		history.goBack()
	}

	async function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
		const selectedImage = event.target?.files?.item(0)

		if (!selectedImage) return

		const imageURL = URL.createObjectURL(selectedImage)

		setImage({ url: imageURL, file: selectedImage, overwrite: true })

		event.target.value = ''
	}

	function cleanImageSeletor() {
		setImage({ file: null, url: '', overwrite: true })
	}

	useEffect(() => {
		if (rangeDate.endDate !== undefined && rangeDate.startDate !== undefined) {
			setDateIsEmpty(false)
		} else {
			setDateIsEmpty(true)
		}
	}, [rangeDate])

	function handleValidateForm() {
		const imageIsEmpty = image.url === ''
		const titleIsEmpty = promotionName.trim() === ''
		const dateIsPast = rangeDate.endDate && isPast(rangeDate.endDate)
		const amountIsZero = promotionAmount === 0
		const hasChanges =
			Promotion &&
			(image.url !== Promotion.artworkURL ||
				promotionName !== Promotion.title ||
				rangeDate.startDate?.toISOString() !==
					new Date(Promotion.startDate).toISOString() ||
				rangeDate.endDate?.toISOString() !==
					new Date(Promotion.endDate).toISOString() ||
				JSON.stringify(description) !== Promotion.description ||
				promotionAmount !== Promotion.stockQuantity ||
				JSON.stringify(regulation) !== Promotion.regulation)
		if (
			imageIsEmpty ||
			titleIsEmpty ||
			dateIsEmpty ||
			dateIsPast ||
			descriptionReturnIsEmpty ||
			amountIsZero ||
			regulationReturnIsEmpty ||
			!hasChanges
		) {
			setCanSubmit(false)
		} else {
			setCanSubmit(true)
		}
	}

	useEffect(handleValidateForm, [
		image,
		promotionName,
		dateIsEmpty,
		rangeDate,
		descriptionReturnIsEmpty,
		promotionAmount,
		regulationReturnIsEmpty,
		Promotion,
		description,
		regulation,
	])

	useEffect(() => {
		setImage((props) => ({ ...props, url: Promotion?.artworkURL || '' }))
		setPromotionName(Promotion?.title || '')
		setRangeDate({
			startDate: new Date(Promotion?.startDate || new Date()),
			endDate: new Date(Promotion?.endDate || new Date()),
		})
		setPromotionAmount(Promotion?.stockQuantity || 0)

		if (Promotion) {
			setDescription(JSON.parse(Promotion?.description))
			setRegulation(JSON.parse(Promotion?.regulation))
		}
	}, [Promotion])

	const viewProps: IViewProps = {
		handleChangePromotionName,
		handleInputChange,
		cleanImageSeletor,
		image,
		promotionName,
		handleGoBack,
		rangeDate,
		handleChangeRangeDate,
		description,
		handlePromotionDescription,
		regulation,
		handlePromotionRegulation,
		handleChangePromotionAmount,
		promotionAmount,
		handleUpdatePromotion,
		canSubmit,
		dateIsEmpty,
		setDescriptionReturnIsEmpty,
		setRegulationReturnIsEmpty,
		isLoading,
	}

	return createElement(EditPromotionView, viewProps)
}

export default EditPromotion
