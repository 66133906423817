import { ChangeEvent, createElement, useEffect, useState } from 'react'
import { RangeKeyDict } from 'react-date-range'
import { IImageUpload } from '../../interfaces/image'
import { ModalAction } from '../../interfaces/modal'
import { IProductRequest } from '../../interfaces/product'
import {
	useMutationCreateProduct,
	useMutationUpdateProduct,
} from '../../service/product.service'
import { useMutationPhotoUpload } from '../../service/user.service'
import { INITIAL_STATE_IMAGE_UPLOAD } from '../../util/image'
import { handleSuccessToaster } from '../../util/toaster'

import { IProductModalProps, IRangeDate, IViewProps } from './types'

import ProductModal from './view'

function ProductModalContainer(props: IProductModalProps): JSX.Element {
	const { onRequestClose, action, currentProduct, refetch, isOpen } = props

	const [image, setImage] = useState<IImageUpload>(INITIAL_STATE_IMAGE_UPLOAD)

	const [productTitle, setProductTitle] = useState('')
	const [description, setDescription] = useState('')

	const [price, setPrice] = useState('')
	const [inventory, setInventory] = useState('')

	const [canSubmit, setCanSubmit] = useState(false)

	const [isLoading, setIsLoading] = useState(false)

	const [rangeDate, setRangeDate] = useState<IRangeDate>({
		startDate: new Date(),
		endDate: new Date(),
	})

	const createProductMutation = useMutationCreateProduct()
	const uploadPhotoMutation = useMutationPhotoUpload()
	const updateProductMutation = useMutationUpdateProduct()

	function handleCleanFields() {
		setProductTitle('')
		setDescription('')
		setInventory('')
		setPrice('')
		setImage(INITIAL_STATE_IMAGE_UPLOAD)
		setRangeDate({
			startDate: new Date(),
			endDate: new Date(),
		})
	}

	function handleChangeRangeDate(range: IRangeDate) {
		setRangeDate(range)
	}

	function handleRequestClose() {
		handleCleanFields()
		onRequestClose()
	}

	async function handleSubmit() {
		try {
			setIsLoading(true)

			const coverImage = image.file
				? await uploadPhotoMutation.mutateAsync({ file: image.file })
				: ''

			const payloadData: IProductRequest = {
				title: productTitle.trim(),
				description: description.trim(),
				inventory: Number(inventory),
				price: Number(price),
			}

			if (rangeDate.startDate && rangeDate.endDate) {
				payloadData.period = {
					startDate: rangeDate.startDate.toISOString(),
					endDate: rangeDate.endDate.toISOString(),
				}
			}

			if (image.overwrite) {
				payloadData.photoURL = coverImage
			}

			if (currentProduct) {
				payloadData.id = currentProduct._id
			}

			if (action === ModalAction.EDIT && currentProduct) {
				const updateProductResponse = await updateProductMutation.mutateAsync(
					payloadData,
				)

				if (!updateProductResponse) return

				handleSuccessToaster('Produto atualizado com sucesso!')
			} else {
				const createProductResponse = await createProductMutation.mutateAsync(
					payloadData,
				)

				if (!createProductResponse) return

				handleSuccessToaster('Produto cadastrado com sucesso!')
			}
		} finally {
			setIsLoading(false)
			handleRequestClose()
		}
	}

	async function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
		const selectedImage = event.target?.files?.item(0)

		if (!selectedImage) return

		const imageURL = URL.createObjectURL(selectedImage)

		setImage({ url: imageURL, file: selectedImage, overwrite: true })

		event.target.value = ''
	}

	function cleanImageSelector() {
		setImage({ file: null, url: '', overwrite: true })
	}

	function handleChangeTitle(event: React.ChangeEvent<HTMLInputElement>) {
		setProductTitle(event.target.value)
	}

	function handleChangeDescription(event: React.ChangeEvent<HTMLInputElement>) {
		setDescription(event.target.value)
	}

	function saveJustNumbers(str: string) {
		return str.replace(/\D+/g, '')
	}

	function handleChangePrice(event: React.ChangeEvent<HTMLInputElement>) {
		setPrice(saveJustNumbers(event.target.value))
	}

	function handleChangeInventory(event: React.ChangeEvent<HTMLInputElement>) {
		setInventory(saveJustNumbers(event.target.value))
	}

	function handleFetch() {
		if (action === ModalAction.EDIT && currentProduct) {
			setImage((props) => ({ ...props, url: currentProduct.photoURL }))
			setProductTitle(currentProduct.title)
			setDescription(currentProduct.description)
			setPrice(String(currentProduct.price))
			setInventory(String(currentProduct.inventory))
			setRangeDate({
				startDate: new Date(currentProduct.period.startDate),
				endDate: new Date(currentProduct.period.endDate),
			})
		}
	}

	function handleCanSubmit() {
		const isTitleEnable = !!productTitle.trim()

		const isDescriptionEnable = !!description.trim()

		const inventoryIsEnable = !!inventory

		const priceIsEnable = !!price

		const isDateValid = !!rangeDate.startDate && !!rangeDate.endDate

		setCanSubmit(
			isTitleEnable &&
				isDescriptionEnable &&
				inventoryIsEnable &&
				priceIsEnable &&
				isDateValid,
		)
	}

	const buttonTitle = action === 'CREATE' ? 'Cadastrar' : 'Salvar Alterações'

	const modalTitle =
		action === 'CREATE' ? 'Novo Produto' : 'Detalhes do Produto'

	useEffect(handleCanSubmit, [productTitle, description, inventory, price])
	useEffect(handleFetch, [currentProduct])

	const viewProps: IViewProps = {
		onRequestClose,
		action,
		currentProduct,
		refetch,
		isOpen,
		image,
		handleInputChange,
		cleanImageSelector,
		productTitle,
		handleChangeTitle,
		description,
		handleChangeDescription,
		price,
		handleChangePrice,
		inventory,
		handleChangeInventory,
		handleSubmit,
		buttonTitle,
		handleRequestClose,
		isLoading,
		rangeDate,
		handleChangeRangeDate,
		canSubmit,
		modalTitle,
	}

	return createElement(ProductModal, viewProps)
}

export default ProductModalContainer
