import styled, { css } from 'styled-components'

export const Container = styled.div`
	padding: 32px 0 85px;
`

export const LoadingWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`

export const ParticipantDrawnCard = styled.div`
	${({ theme }) => css`
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		background-color: ${theme.colors.green};
		border-radius: 4px;
		padding: 64px 48px 24px;
		margin-bottom: 32px;
		
		div.user-image-wrapper {
			position: relative;
			height: 104px;
			margin-bottom: 8px;

			img.user-photo {
				width: 88px;
				height: 88px;
				border-radius: 44px;
				object-fit: cover;
				overflow: hidden;
			}
			img.fest-icon {
				position: absolute;
				top: 40px;
				left: 56px;

				width: 56px;
				height: 64px;
			}
		}

		h1.congrats-title {
			font-family: ${theme.fonts.radikal_regular};
			font-weight: 400;
			font-size: 48px;
			line-height: 56px;
			color: ${theme.colors.pure_black};
			text-align: center;
			margin-bottom: 12px;
		}

		h2.congrats-subtitle {
			font-family: ${theme.fonts.radikal_regular};
			font-weight: 400;
			font-size: 24px;
			line-height: 32px;
			color: ${theme.colors.pure_black};
			text-align: center;
			margin-bottom: 32px;
		}

		div.raffle-date {
			display: flex;
			flex-direction: row;
			align-items: center;
			margin-bottom: 48px;

			img.calendar-icon {
				width: 20px;
				height: 20px;
				margin-right: 4px;
				margin-bottom: 4px;
			}
			span.raffle-date-text {
				font-family: ${theme.fonts.radikal_regular};
				font-weight: 400;
				font-size: 16px;
				line-height: 24px;
				color: ${theme.colors.pure_black};
			}
		}

		img.logo-eclub {
			width: 48px;
			height: 48px;
		}
	`}
`


export const PageSeparator = styled.div`
	background: ${({ theme }) => theme.colors.light_gray};
	height: 1px;
	width: 100vw;
	position: absolute;
	left: 0;
`

export const ButtonsWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;

	padding-top: 32px;

	button:first-child {
		margin-right: 16px;
	}
`
