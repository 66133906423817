import React from 'react'
import { BeatCoinsCard } from '../../shared/components/BeatCoinsCard'
import { Input } from '../../shared/components/Input'
import Table from '../../shared/components/Table'

import * as S from './styles'
import { IViewProps } from './types'

import Loading from '../../shared/components/Loading'

import { getInitials } from '../../shared/components/ImageProfile/util'
import DeleteModal from '../../shared/components/DeleteModal'
import { useTypedSelector } from '../../shared/hooks/useTypedSelector'
import { GoBackButton } from '../../shared/components'

function UserDetail(props: IViewProps) {
	const {
		tableColumns,
		handleGoBack,
		isLoading,
		data,
		tableRows,
		tableLoading,
		navProps,
		extractPage,
		sortBy,
		handleSortColumn,
		handleDeleteUser,
		handleCloseModal,
		handleOpenModal,
		deleteModalOpen,
	} = props

	const { user } = useTypedSelector(['token', 'user'])
	const listIsPresenters =
		user.roles.length === 1 && user.roles.includes('PRESENTER')

	if (isLoading) {
		return (
			<S.LoadingContainer>
				<Loading />
			</S.LoadingContainer>
		)
	}

	return (
		<>
			<S.UsersDetailContainer>
				<S.HeaderWrapper>
					<GoBackButton />
					<header>
						<S.TitlePage>Informações do Ouvinte</S.TitlePage>
					</header>
				</S.HeaderWrapper>

				<S.UserInformationWrapper>
					<S.FormWrapper>
						<S.UserInfoWrapper>
							{data.userDocument.photoURL ? (
								<S.UserImage
									className='img'
									src={data.userDocument.photoURL}
									alt='IMG'
								/>
							) : (
								<S.ImageProfileWrapper>
									<span>{getInitials(data.userDocument.name)}</span>
								</S.ImageProfileWrapper>
							)}

							<S.BeatCoinsCarWrapper>
								<BeatCoinsCard points={data.totalBeatCoins} />
							</S.BeatCoinsCarWrapper>
							{/* CARD DE ACESSOS, VOLTAR QUANDO A FEATURE DE CONTAGEM DE ACESSOS ESTIVER PRONTA !!! */}
							{/* <S.UserCardWrapper>
							<AccessCard access={28} />
						</S.UserCardWrapper> */}
						</S.UserInfoWrapper>

						<S.InputWrapper>
							<div>
								<S.InputContainer>
									<Input value={data.userDocument.name} disabled={true} />
								</S.InputContainer>
								<S.InputSecondContainer>
									<Input value={data.userDocument.email} disabled={true} />
								</S.InputSecondContainer>
							</div>
							<div>
								<S.InputContainer>
									<Input
										value={
											data.userDocument.phoneNumber
												? data.userDocument.phoneNumber
												: 'Número de Telefone'
										}
										disabled={true}
									/>
								</S.InputContainer>
								<S.InputSecondContainer>
									<Input
										value={
											data.userDocument.personalFiscalID
												? data.userDocument.personalFiscalID
												: 'CPF'
										}
										disabled={true}
									/>
								</S.InputSecondContainer>
							</div>
						</S.InputWrapper>
					</S.FormWrapper>
					<S.ListenerInfo>
						<S.TitlePage>Histórico de Transações</S.TitlePage>

						<S.TableWrapper>
							<Table
								columns={tableColumns as any}
								data={tableRows}
								isLoading={tableLoading}
								navProps={navProps}
								extractPage={{
									page: extractPage.page,
									totalDocs: extractPage.totalDocs,
								}}
								sortBy={sortBy}
								getSelectedColumnAcessorAndOrder={handleSortColumn}
							/>
						</S.TableWrapper>
						<S.Spacing></S.Spacing>
						<S.DeleteUserBunttonWrapper>
							{listIsPresenters ? null : (
								<S.DeleteUserBuntton onClick={handleOpenModal}>
									Excluir Ouvinte
								</S.DeleteUserBuntton>
							)}
						</S.DeleteUserBunttonWrapper>
					</S.ListenerInfo>
				</S.UserInformationWrapper>
			</S.UsersDetailContainer>
			{deleteModalOpen ? (
				<DeleteModal
					title='Ouvinte'
					description='Deseja realmente excluir este Ouvinte? Esta ação não poderá ser desfeita após a confirmação de exclusão.'
					handleCloseModal={handleCloseModal}
					handleSubmit={handleDeleteUser}
				/>
			) : null}
		</>
	)
}

export { UserDetail }
