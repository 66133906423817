import React, { useEffect } from 'react'

import { createElement, useState } from 'react'

import {
	dropBanner,
	updateBanner,
	useBannersPage,
} from '../../shared/service/banners.service'

import {} from '../../shared/util/toaster'
import {} from './table'
import { IViewProps } from './types'
import Banners from './view'
import { IBannerResponse } from '../../shared/interfaces/banners'

function BannersContainer(): JSX.Element {
	const [searchString, setSearchString] = useState('')

	const [isUpdateLoading, setIsUpdateLoading] = useState(false)

	const [ordanatedBanners, setOrdanatedBanners] = useState<IBannerResponse[]>(
		[],
	)
	const {
		data: bannerData,
		isLoading,
		refetch,
	} = useBannersPage({
		maxItemsInPage: 10,
		pageIndex: 1,
		searchString: searchString,
		order: 1,
		sort: 'title',
	})

	async function onDragEnd(result: any) {
		if (!bannerData) return
		const banners = bannerData?.docs

		const reorder = (
			list: IBannerResponse[],
			startIndex: number,
			endIndex: number,
		) => {
			const result = Array.from(list)
			const [removed] = result.splice(startIndex, 1)
			result.splice(endIndex, 0, removed)

			return result
		}
		if (!result.destination) {
			return
		}

		if (result.destination.index === result.source.index) {
			return
		}

		const bannerOrdenation = reorder(
			banners,
			result.source.index,
			result.destination.index,
		)

		setOrdanatedBanners(bannerOrdenation)

		setIsUpdateLoading(true)

		const dropBannerResponse = await dropBanner(bannerOrdenation)

		setIsUpdateLoading(false)
		if (!dropBannerResponse) return

		refetch()
	}

	function handleSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
		setSearchString(event.target.value)
	}

	async function handleSwitchChange(banner: IBannerResponse) {
		await handleSwitchRefetch(banner)
	}

	async function handleSwitchRefetch(banner: IBannerResponse) {
		const switchResponse = await updateBanner(
			banner._id,
			banner.title,
			banner.photoURL,
			banner.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE',
		)

		if (!switchResponse) return

		refetch()
	}

	useEffect(() => {
		if (!bannerData) return
		setOrdanatedBanners(bannerData.docs)
	}, [bannerData])

	const viewProps: IViewProps = {
		searchString,
		handleSearchChange,
		isLoading,
		bannerData,
		handleSwitchRefetch,
		refetch,
		onDragEnd,
		isUpdateLoading,
		ordanatedBanners,
	}
	return createElement(Banners, viewProps)
}

export default BannersContainer
