import React from 'react'
import ReactSwitch, { ReactSwitchProps } from 'react-switch'
import { theme } from '../../styles/theme'

export default function Switch({
	...props
}: Pick<ReactSwitchProps, 'onChange' | 'checked' | 'disabled'>) {
	return (
		<ReactSwitch
			checkedIcon={false}
			uncheckedIcon={false}
			onColor={props.disabled ? theme.colors.light_gray : theme.colors.green}
			offColor={theme.colors.light_gray}
			height={22}
			width={38}
			handleDiameter={18}
			{...props}
		/>
	)
}
