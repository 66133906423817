import React from 'react'
import { IButtonRootProps } from './types'
import * as S from './styles'

export function ButtonRoot({
	children,
	theme = 'default',
	shape = 'default',
	spacing,
	isLoading = false,
	style,
	...props
}: IButtonRootProps) {
	let padding: string
	if (typeof spacing === 'string') {
		const [paddingVertical, paddingHorizontal] = spacing.split('x')
		padding = `${paddingVertical}px ${paddingHorizontal}px`
	} else {
		padding = `${spacing[0]}px ${spacing[1]}px ${spacing[2]}px ${spacing[3]}px`
	}
	return (
		<S.ButtonRoot
			buttonTheme={theme}
			buttonShape={shape}
			style={{ ...style, padding, ...(isLoading && { cursor: 'wait' }) }}
			{...props}
			{...(isLoading && { disabled: true })}
		>
			{children}
		</S.ButtonRoot>
	)
}
