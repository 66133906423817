import React from 'react'
import {
	ImageWrapper,
	LiveBadge,
	LiveBadgeWrapper,
	PresenterWrapper,
	TableImagePlaceholder,
} from './styles'
import { Image } from 'react-feather'
import { IPresenters } from '../../shared/interfaces/program'

export function renderTextTable(title: string) {
	return <p>{title}</p>
}

export function renderTextTableBadge(title: string) {
	return (
		<LiveBadgeWrapper>
			<p>{title}</p>

			<LiveBadge>AO VIVO</LiveBadge>
		</LiveBadgeWrapper>
	)
}

export function renderImage(photoURL: string) {
	return (
		<ImageWrapper>
			{photoURL ? (
				<img className='report-image' src={photoURL} alt='profile image' />
			) : (
				<TableImagePlaceholder>
					<Image className='placeholder-icon' />
				</TableImagePlaceholder>
			)}
		</ImageWrapper>
	)
}

export function renderPresenters(presenters: IPresenters[]) {
	return (
		<PresenterWrapper>
			{presenters.map((presenter) => (
				<h4 key={presenter._id}>{presenter.name}</h4>
			))}
		</PresenterWrapper>
	)
}
