import { createElement, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutationRecoverPassword } from '../../shared/service/user.service'
import { handleSuccessToaster } from '../../shared/util/toaster'
import { routesEnum } from '../Routes/enum'
import { IViewProps } from './types'
import { RecoverPassword } from './view'
import { checkEmail } from '../../shared/util/validators'

function RecoveryPasswordContainer(): JSX.Element {
	const [loading, setLoading] = useState(false)
	const [email, setEmail] = useState('')

	const [canSubmit, setCanSubmit] = useState(false)

	const recoverPasswordMutation = useMutationRecoverPassword()

	const history = useHistory()

	function handleEmailChange(event: React.ChangeEvent<HTMLInputElement>) {
		setEmail(event.target.value)
	}

	async function handleSubmit(event: React.SyntheticEvent<Element, Event>) {
		event.preventDefault()
		try {
			setLoading(true)

			const recoverPasswordResponse = await recoverPasswordMutation.mutateAsync(
				{ email },
			)

			if (!recoverPasswordResponse) return

			handleSuccessToaster('E-mail enviado com sucesso!')

			history.push(routesEnum.LOGIN)
		} finally {
			setLoading(false)
		}
	}

	function formValidation() {
		const isEmailValid = checkEmail(email)

		setCanSubmit(isEmailValid)
	}

	useEffect(formValidation, [email])

	const viewProps: IViewProps = {
		loading,
		email,
		handleEmailChange,
		canSubmit,
		handleSubmit,
	}

	return createElement(RecoverPassword, viewProps)
}

export default RecoveryPasswordContainer
