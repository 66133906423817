import { SizeEnum } from './types'
import { font, grid } from './variables'

export function mediaQuery(size: SizeEnum) {
	return (style: string) => {
		if (size === 'lg') {
			return `@media screen and (max-width: ${grid.lg}px) {
				${style}
			}`
		} else if (size === 'md') {
			return `@media screen and (max-width: ${grid.md}px) {
				${style}
			}`
		} else if (size === 'sm') {
			return `@media screen and (max-width: ${grid.sm}px) {
				${style}
			}`
		} else if (size === 'xs') {
			return `@media screen and (max-width: ${grid.xs}px) {
				${style}
			}`
		}
	}
}

export function transition(seconds = 0.3) {
	return `-webkit-transition: all ${seconds}s;
	-moz-transition: all ${seconds}s;
	transition: all ${seconds}s;`
}

export function fontType(type = 0) {
	if (type === -1) {
		return `font-family: 'Light', sans-serif;
		font-weight: 300;
		`
	} else if (type === 0) {
		return `font-family: 'Regular', sans-serif;
		font-weight: 400;
		`
	} else if (type === 1) {
		return `font-family: 'Medium', sans-serif;
		font-weight: 500;
		`
	} else if (type === 2) {
		return `font-family: 'Bold', sans-serif;
		font-weight: 700;
		`
	}
}

export function ellipsis() {
	return `
		display: inline-block;
		text-overflow: ellipsis;
		white-space: nowrap;
		word-wrap: normal;
		max-width: 100%;
		overflow: hidden;
		line-height: 1rem;
	`
}

export function LightenDarkenColor(color: string, amount: number) {
	let usePound = false

	if (color[0] == '#') {
		color = color.slice(1)
		usePound = true
	}

	const num = parseInt(color, 16)

	let r = (num >> 16) + amount

	if (r > 255) r = 255
	else if (r < 0) r = 0

	let b = ((num >> 8) & 0x00ff) + amount

	if (b > 255) b = 255
	else if (b < 0) b = 0

	let g = (num & 0x0000ff) + amount

	if (g > 255) g = 255
	else if (g < 0) g = 0

	return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16)
}

export function toRem(size: number) {
	return `${size / font.baseFontSize}rem`
}
export function removeAccent(text: string) {
	text = text.toLowerCase()
	text = text.replace(new RegExp('[ÁÀÂÃ]', 'gi'), 'a')
	text = text.replace(new RegExp('[ÉÈÊ]', 'gi'), 'e')
	text = text.replace(new RegExp('[ÍÌÎ]', 'gi'), 'i')
	text = text.replace(new RegExp('[ÓÒÔÕ]', 'gi'), 'o')
	text = text.replace(new RegExp('[ÚÙÛ]', 'gi'), 'u')
	text = text.replace(new RegExp('[Ç]', 'gi'), 'c')

	return text
}

export function equateText(str: string) {
	return removeAccent(str.toUpperCase())
}

export function equateArrayText(array: string[]) {
	return array.map((str) => equateText(str))
}

export function getWidthString(span: string | number) {
	if (!span) return

	const width = (Number(span) / grid.cols) * 100

	return `max-width: ${width}%;`
}
