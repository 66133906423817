import React, { ChangeEvent, useState } from 'react'

import * as S from './styles'
import { UploadImageButton } from '../UploadImageButton'
import { Input } from '../Input'
import { IViewProps } from './types'
import { Button } from '../PromotionButton'
import { IImageUpload } from '../../interfaces/image'
import { INITIAL_STATE_IMAGE_UPLOAD } from '../../util/image'
import {
	createBanner,
	updateBanner,
	useMutationBannerUpload,
} from '../../service/banners.service'
import { handleErrorToaster, handleSuccessToaster } from '../../util/toaster'
import { X } from 'react-feather'

function NewBannerModal(props: IViewProps) {
	const {
		bannerTitle,
		action,
		onClose,
		bannerImage,
		bannerID,
		bannerStatus,
		refetch,
		isOpen,
		handleResetData,
	} = props

	const [image, setImage] = useState<IImageUpload>({
		...INITIAL_STATE_IMAGE_UPLOAD,
		url: bannerImage || '',
	})

	const [isLoading, setIsLoading] = useState(false)

	const { mutateAsync: photoUploadMutation } = useMutationBannerUpload()

	const [bannerInputTitle, setBannerInputTitle] = useState(bannerTitle)
	async function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
		const selectedImage = event.target?.files?.item(0)

		if (!selectedImage) return

		const imageURL = URL.createObjectURL(selectedImage)

		setImage({ url: imageURL, file: selectedImage, overwrite: true })

		event.target.value = ''
	}

	function cleanImageSeletor() {
		setImage({ file: null, url: '', overwrite: true })
		handleResetData()
	}

	function handleChangeTitle(event: React.ChangeEvent<HTMLInputElement>) {
		setBannerInputTitle(event.target.value)
	}

	async function handleCreateBanner() {
		setIsLoading(true)
		if (!image.file) return
		const coverImage = image.file
			? await photoUploadMutation({ file: image.file })
			: '' || image.url
		const createBannerResponse = await createBanner(
			bannerInputTitle,
			coverImage,
		)
		if (!createBannerResponse) {
			setIsLoading(false)
			isOpen(false)
			handleErrorToaster('Não foi possível criar o Banner!')
			return
		}

		refetch()
		setIsLoading(false)
		isOpen(false)
		handleSuccessToaster('Banner criado com sucesso!')
	}

	async function handleUpdateBanner() {
		if (!bannerID) return
		if (!bannerStatus) return
		setIsLoading(true)
		if (bannerImage) {
			const updateBannerResponse = await updateBanner(
				bannerID,
				bannerInputTitle || bannerTitle,
				bannerImage as string,
				bannerStatus,
			)

			if (!updateBannerResponse) {
				setIsLoading(false)
				isOpen(false)
				handleErrorToaster('Não foi possível editar o Banner!')
				return
			}
			setIsLoading(false)
			refetch()
			isOpen(false)
			handleSuccessToaster('Banner editado com sucesso!')
		} else {
			const coverImage = image.file
				? await photoUploadMutation({ file: image.file })
				: '' || image.url
			const updateBannerResponse = await updateBanner(
				bannerID,
				bannerInputTitle || bannerTitle,
				coverImage as string,
				bannerStatus,
			)

			if (!updateBannerResponse) {
				setIsLoading(false)
				isOpen(false)
				handleErrorToaster('Não foi possível editar o Banner!')
				return
			}
			setIsLoading(false)
			refetch()
			isOpen(false)
			handleSuccessToaster('Banner editado com sucesso!')
		}
	}

	return (
		<S.ModalWrapper
			// onClick={onClose}
			data-testid='out-of-modal'
		>
			<S.ModalContainer data-testid='modal'>
				<S.TopContainer>
					{action === 'CREATE' ? (
						<S.ModalTitle>Novo Banner</S.ModalTitle>
					) : (
						<S.ModalTitle>Editar Banner</S.ModalTitle>
					)}

					<X
						className='close-icon'
						onClick={onClose}
						data-testid='button-close'
					/>
				</S.TopContainer>

				<S.ModalSeparator />

				<S.UploadImageButtonWrapper>
					<UploadImageButton
						imageSizeLabel='Resolução Mínima 1920x580'
						label='Upload Banner'
						onChange={handleInputChange}
						imageClean={cleanImageSeletor}
						showInfo={true}
						image={image}
					/>
				</S.UploadImageButtonWrapper>
				<S.InputWrapper>
					<Input
						className='title'
						value={bannerInputTitle}
						type='text'
						placeholder='Nome do Banner'
						onChange={handleChangeTitle}
					/>
				</S.InputWrapper>
				<S.ButtonWrapper>
					{action === 'CREATE' ? (
						<Button
							isLoading={isLoading}
							onClick={handleCreateBanner}
							label='Cadastrar'
							data-tedtid='submit-new-banner'
						/>
					) : (
						<Button
							isLoading={isLoading}
							label='Editar'
							onClick={handleUpdateBanner}
							data-tedtid='submit-edit-banner'
						/>
					)}
				</S.ButtonWrapper>
			</S.ModalContainer>
		</S.ModalWrapper>
	)
}

export default NewBannerModal
