import styled, { css } from 'styled-components'
import { toRem } from '../../styles/function'
// import { theme } from '../../shared/styles/theme'

export const ModalWrapper = styled.div`
	width: 100%;
	height: 100%;
	position: fixed;

	display: flex;
	align-items: center;
	justify-content: center;

	background: rgba(0, 0, 0, 0.56);
	top: 0;
	left: 0;
	z-index: 999;
`

export const ModalContainer = styled.div`
	width: 430px;
	height: 469px;
	background-color: white;
	border-radius: 4px;
`

export const TopContainer = styled.div`
	${({ theme }) => css`
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 32px 24px;

		.close-icon {
			color: ${theme.colors.dark_graffiti};
			width: 24px;
			height: 24px;
			cursor: pointer;
			transition: 0.4s;

			:hover{
				color: ${theme.colors.pure_black};
			}
		}
	`};
`

export const ModalTitle = styled.h1`
	${({ theme }) => css`
		font-size: ${toRem(24)};
		color: ${theme.colors.dark_graffiti};
		font-family: ${theme.fonts.radikal_light};
		font-weight: 300;
		
	`};
`
export const ModalSeparator = styled.div`
	background: ${({ theme }) => theme.colors.light_gray};
	height: 1px;
	width: 100%;
`

export const UploadImageButtonWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: 48px;
	padding-bottom: 32px;
	padding-left: 24px;
	padding-right: 24px;
`

export const InputWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0px 24px;
`

export const ButtonWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: 32px;
`
