import React from 'react'

import Modal from 'react-modal'
import {
	ButtonWrapper,
	customStyles,
	DateInputWrapper,
	FormWrapper,
	GridWrapper,
	Header,
	ModalSeparator,
	ModalTitle,
	SubFormWrapper,
	SubtitleTitle,
	UploadImageButtonWrapper,
} from './styles'
import { IViewProps } from './types'

import { X } from 'react-feather'
import { UploadImageButton } from '../UploadImageButton'
import { Input } from '../Input'
import { Button } from '../'
import { ModalAction } from '../../interfaces/modal'
import DateInputPicker from '../DateInputPicker'

function ProductModal(props: IViewProps) {
	const {
		isOpen,
		image,
		handleInputChange,
		cleanImageSelector,
		productTitle,
		handleChangeTitle,
		description,
		handleChangeDescription,
		price,
		handleChangePrice,
		inventory,
		handleChangeInventory,
		handleSubmit,
		buttonTitle,
		handleRequestClose,
		action,
		isLoading,
		rangeDate,
		handleChangeRangeDate,
		canSubmit,
		modalTitle,
	} = props

	return (
		<>
			<Modal
				style={customStyles}
				onRequestClose={handleRequestClose}
				isOpen={isOpen}
			>
				<Header>
					<ModalTitle data-testid='title-modal'>{modalTitle}</ModalTitle>

					<X
						className='close-icon'
						onClick={handleRequestClose}
						data-testid='button-close'
					/>
				</Header>
				<ModalSeparator />

				<UploadImageButtonWrapper>
					<UploadImageButton
						label='Upload Foto do Produto'
						onChange={handleInputChange}
						imageClean={cleanImageSelector}
						showInfo={action === ModalAction.CREATE}
						data-testid='upload-image'
						image={image}
					/>
				</UploadImageButtonWrapper>
				<FormWrapper>
					<Input
						maxLength={23}
						className='title'
						value={productTitle}
						type='text'
						placeholder='Título do Produto'
						onChange={handleChangeTitle}
						data-testid='input-product-name'
					/>
					<Input
						className='description'
						value={description}
						type='text'
						placeholder='Descrição'
						onChange={handleChangeDescription}
						style={{ marginTop: 16 }}
						data-testid='input-product-description'
					/>
					<SubFormWrapper>
						<SubtitleTitle>Regras do Produto</SubtitleTitle>

						<DateInputWrapper>
							<DateInputPicker
								dateRange={rangeDate}
								handleOnChange={handleChangeRangeDate}
								placeholder='Período de Disponibilidade'
								data-testid='input-date-picker'
							/>
						</DateInputWrapper>

						<GridWrapper>
							<div>
								<Input
									className='value'
									value={price}
									type='number'
									placeholder='Valor'
									onChange={handleChangePrice}
									data-testid='input-product-value'
								/>
							</div>

							<div>
								<Input
									className='inventory'
									value={inventory}
									type='number'
									placeholder='Estoque'
									onChange={handleChangeInventory}
									data-testid='input-product-inventory'
								/>
							</div>
						</GridWrapper>

						<ButtonWrapper>
							<Button.Root 
								spacing={'12x24'}
								disabled={!canSubmit}
								isLoading={isLoading}
								onClick={handleSubmit}
								data-testid='submit-modal'
							>
								<Button.Text text={buttonTitle}/>
							</Button.Root>
						</ButtonWrapper>
					</SubFormWrapper>
				</FormWrapper>
			</Modal>
		</>
	)
}

export default ProductModal
