import React from 'react'
import SecondaryInput from '../../shared/components/SecondaryInput'

import { IViewProps } from './types'
import Table from '../../shared/components/Table'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import * as S from './styles'
import DeleteModal from '../../shared/components/DeleteModal'
import { TitlePage } from '../Users/styles'
import { Button } from '../../shared/components'

function Audio(props: IViewProps) {
	const {
		searchString,
		handleSearchChange,
		isLoading,
		handleSortColumn,
		tableRows,
		sortBy,
		tableColumns,
		navProps,
		handleProgramSelectChange,
		programOptions,
		handleDeleteAudios,
		handleOpenDeleteModal,
		handleCloseDeleteModal,
		deleteModalIsActive,
		audioPage,
	} = props

	return (
		<>
			<S.AudioContainer>
				<header>
					<>
						<div>
							<TitlePage data-testid='title-page'>Áudios</TitlePage>
							<SecondaryInput
								className='search-input'
								placeholder='Buscar por ouvinte'
								value={searchString}
								onChange={handleSearchChange}
							/>
						</div>
						<S.SelectContainer>
							<div className='custom-select'>
								<select
									onChange={handleProgramSelectChange}
									name='Programa'
									className='SelectStyle'
									data-testid='select-program'
								>
									<option value=''>Filtrar por programa</option>
									{programOptions?.map((program) => {
										return (
											<option key={program.value} value={program.value}>
												{program.label}
											</option>
										)
									})}
								</select>
								<span className='custom-arrow'>
									<ExpandMoreIcon />
								</span>
							</div>
						</S.SelectContainer>
						<S.ButtonWrapper>
							<Button.Root
								onClick={handleOpenDeleteModal}
								spacing={'12x24'}
								theme='light'
								data-testid='button-descard-selected'
							>
								<Button.Text text='Descartar Selecionados' />

							</Button.Root>
						</S.ButtonWrapper>
					</>
				</header>
				<Table
					isLoading={isLoading}
					getSelectedColumnAcessorAndOrder={handleSortColumn}
					data={tableRows}
					sortBy={sortBy}
					columns={tableColumns as any}
					navProps={navProps}
					extractPage={{ page: audioPage.page, totalDocs: audioPage.totalDocs }}
				/>
			</S.AudioContainer>
			{deleteModalIsActive ? (
				<DeleteModal
					title='Áudio'
					description='Deseja realmente excluir este áudio? Esta ação não poderá ser desfeita após a confirmação de exclusão.'
					handleCloseModal={handleCloseDeleteModal}
					handleSubmit={handleDeleteAudios}
				/>
			) : null}
		</>
	)
}

export { Audio }
