import styled, { css } from 'styled-components'
import { toRem } from '../../shared/styles/function'


export const PurchaseDetailWrapper = styled.div``


export const Header = styled.div`
	display: flex;
	flex-direction: column;

	.close-icon {
		color: ${({ theme }) => theme.colors.dark_graffiti};
		width: 24px;
		height: 24px;
		cursor: pointer;
	}
`
export const ModalTitle = styled.h1`
	${({ theme }) => css`
		font-size: ${toRem(24)};
		color: ${theme.colors.dark_graffiti};
		font-family: ${theme.fonts.radikal_light};
		font-weight: 300;
	`};
`

export const PageWrapper = styled.div`
	flex: 1;
	display:flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
`
export const ProductBannerStatus = styled.div`
	${({theme}) => css`
		 width:652px;
		/* height: 188px;	 */
		background:${theme.colors.white};
		margin-top:${toRem(32)};
		padding: ${toRem(16)};
		border-radius: 4px;
				display:flex;
		align-items: center;
	`}
`
export const ImageWrapper = styled.div`
	${({ theme }) => css`
		width: 96px;
		height: 96px;
		overflow: hidden;
		border-radius: 2px;
		background: ${theme.colors.light_gray};

		img {
			width: 100%;
			height: 100%
		}
	`}
`


export const TableImagePlaceholder = styled.div`
	${({ theme }) => css`
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 2px;
		background: ${theme.colors.off_white};
		border: 1px solid ${theme.colors.light_gray};
		border-radius: 4px;

		.placeholder-icon {
			width: ${toRem(96)};
			height: ${toRem(96)};
			color: ${theme.colors.greenish_grey_light};
		}
	`}
`
export const Title = styled.h2`
	${({theme}) => css`
		margin-left:12px;
		font-size: 18px;
		color: ${theme.colors.dark_graffiti};
	`}
`
export const StatusBar = styled.div`
	${({theme}) => css`
		width:100%;
		padding: ${toRem(16)};
		display:flex;
		align-items: center;
		justify-content: space-between;
		background: ${theme.colors.white};
		width:${toRem(652)};
		margin-top: ${toRem(2)};
	`}
`

export const StatusBarTitle = styled.h4`
	${({theme}) => css`
		font-size: ${toRem(18)};
		color: ${theme.colors.dark_graffiti};
		font-family:${theme.fonts.radikal_regular};
	`}
`

export const StatusBarMinimumTitle = styled.h4`
	${({theme}) => css`
		font-size: ${toRem(14)};
		color: ${theme.colors.dark_graffiti};
		font-family:${theme.fonts.radikal_regular};
	`}
`

interface IRedeemedProps {
	redeemed: boolean
}

export const RedeemedWrapper = styled.div<IRedeemedProps>`
	${({theme,redeemed}) => css`
		padding: 5px 10px 3px 10px;
		border-radius: ${toRem(14)};
		background: ${redeemed ? theme.colors.middle_green : theme.colors.green};
		max-width:${toRem(129)};
	`}
`
export const RedeemedInactiveWrapper = styled.div`
	${({theme}) => css`
		padding: 5px 10px 3px 10px;
		border-radius: ${toRem(14)};
		background: ${theme.colors.light_gray};
		max-width:${toRem(129)};
	`}
`
export const RedeemedTextInactiveWrapper = styled.h5`
${({theme}) => css`
	font-size: 12px;
	color: ${theme.colors.greenish_grey};
	font-family:${theme.fonts.radikal_regular};
`};
`

export const RedeemedTextWrapper = styled.h5<IRedeemedProps>`
${({theme,redeemed}) => css`
	font-size: 12px;
	color: ${redeemed ? theme.colors.green_medium : theme.colors.dark_graffiti};
	font-family:${theme.fonts.radikal_regular};
`};
`
export const RedeemedContentWrapper = styled.div`
	display:flex;
	flex-direction: column;
	align-items: flex-end;
`

export const RedeemedDateText = styled.span`
	${({theme}) => css`
		color: ${theme.colors.green_medium};
		margin-top:${toRem(8)};
		font-size: 12px;
		font-family:${theme.fonts.radikal_regular};
	`}
`

export const ContentWrapper = styled.div`
	margin-top:${toRem(32)};
`
export const ProductPriceFlag = styled.div`
	${({theme}) => css`
		background:${theme.colors.dark_graffiti};
		padding: 5px 10px 5px 6px;
		border-radius:14px;
		display:flex;
		align-items: center;

		img {
			margin-right:${toRem(4)};
		}
	`}
`

export const ProductPriceFlagText = styled.span`
		${({theme}) => css`
				color: ${theme.colors.white};
				font-size: 12px;
				margin-top:2px;
				font-family: ${theme.fonts.radikal_regular};
		`}
`

export const StatusBarDefaultText = styled.span`
	${({theme}) => css`
font-size: 14px;
	color: ${theme.colors.dark_graffiti};
	font-family: ${theme.fonts.radikal_regular};
	`}
`

export const FinishButtonWrapper = styled.div`
	padding: 40px 0px;
	display:flex;
	justify-content: center;
`