import React from 'react'
import { IButtonIconProps, ButtonIconTypes, IconType } from './types'

import { ReactComponent as PanIcon } from '../../../assets/icons/buttonIcons/pan.svg'
import { ReactComponent as QuestionIcon } from '../../../assets/icons/buttonIcons/question-mark.svg'

const icons: { [key in ButtonIconTypes]: IconType } = {
	pan: PanIcon,
	trash: PanIcon,
	question: QuestionIcon,
}

export function ButtonIcon({ name }: IButtonIconProps) {
	const Icon = icons[name]
	return <Icon className='button-icon' />
}
