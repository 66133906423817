import styled, { css } from 'styled-components'

export const RegulationContainer = styled.div``

export const Top = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding-top: 40px;
`

export const TitlePage = styled.h1`
	${({ theme }) => css`
		font-family: ${theme.fonts.radikal_light};
		font-weight: 300;
		font-size: 24px;
		line-height: 32px;
		color: ${theme.colors.dark_graffiti};
		margin-bottom: 16px;
	`}
`

export const TextInfo = styled.p`
	${({ theme }) => css`
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
		color: ${theme.colors.greenish_grey_medium};
		margin-bottom: 40px;
	`}
`

export const TextEditorWrapper = styled.div`
	display: flex;
	width: 100%;
	max-width: 652px;
	align-items: center;
	justify-content: center;
`
export const TextEditorBoxWrapper = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
`

export const ButtonWrapper = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
	margin-top: 32px;
	margin-bottom: 120px;
`
