import React, { useState } from 'react'
import * as S from './styles'
import { IViewProps } from './types'
import TextEditorBox from '../../shared/components/TextEditorBox'
import { Button } from '../../shared/components'

function Regulation(props: IViewProps) {
	const {
		searchString,
		regulation,
		handleRegulation,
		setRegulationReturnIsEmpty,
		cantSubmit,
		handleSubmit,
		isLoading,
	} = props

	return (
		<>
			<S.RegulationContainer>
				<S.Top>
					<S.TitlePage>Regulamento</S.TitlePage>

					<S.TextInfo>
						Área destinada para explicação da dinâmica do E-Club. O conteúdo
						será exibido<br></br>somente no site da Educadora, podendo ser
						acessado no link Regulamento.
					</S.TextInfo>
				</S.Top>

				<S.TextEditorBoxWrapper>
					<S.TextEditorWrapper>
						<TextEditorBox
							minHeight='100%'
							minWidth='880px'
							value={regulation}
							onChange={handleRegulation}
							disabled={false}
							placeholder='Regulamento'
							returnIsEmpty={setRegulationReturnIsEmpty}
						/>
					</S.TextEditorWrapper>
				</S.TextEditorBoxWrapper>

				<S.ButtonWrapper>
					<Button.Root
						disabled={cantSubmit}
						isLoading={isLoading}
						onClick={handleSubmit}
						spacing='12x24'
					>
						<Button.Text text='Salvar Alterações' />
					</Button.Root>
				</S.ButtonWrapper>
			</S.RegulationContainer>
		</>
	)
}

export default Regulation
