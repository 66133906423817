import React, { createElement, useEffect, useState } from 'react'
import { DetailButton } from './styles'
import { renderImage, renderTextTable, withDrawalButton } from './table'
import {
	ETableAudioAcessor,
	IOptionTabProps,
	ITableColumn,
	IViewProps,
} from './types'

import { format, isAfter } from 'date-fns'
import {
	IAcessorAndOrder,
	ITableNavProps,
} from '../../shared/components/Table/types'
import { MoreHorizontal } from 'react-feather'
import { Purchases } from './view'
import {
	useMutationUpdateRedeemProduct,
	usePurchaseData,
	usePurchasePage,
} from '../../shared/service/product.service'
import {
	IExtractDocument,
	PurchaseAnalyticsData,
} from '../../shared/interfaces/extract'
import { useHistory } from 'react-router-dom'
import { Product } from '../../shared/interfaces/product'
import { handleSuccessToaster } from '../../shared/util/toaster'
import { useDebounce } from 'use-debounce'

function PurchasesContainer(): JSX.Element {
	const [searchString, setSearchString] = useState('')
	const [tableRows, setTableRows] = useState<any>([])
	const [tabOption, setTabOption] = useState(PurchaseAnalyticsData.NOT_REDEEMED)
	const [optionsTab, setOptionsTab] = useState<IOptionTabProps[]>([])
	const [searchDebounce] = useDebounce(searchString, 1000)
	const [purchaseModal, setPurchaseModal] = useState(false)
	const [currentProduct, setCurrentProduct] = useState<any>()

	const [sortBy, setSortBy] = useState<IAcessorAndOrder>({
		acessorToSend: 'createdAt',
		acessor: 'createdAt',
		order: -1,
	})

	const [purchasePage, setPurchasePage] = useState({
		page: 1,
		numberOfPages: 0,
		totalDocs: 0,
	})

	const history = useHistory()

	const { data: purchaseCount, refetch: numberRefetch } = usePurchaseData()

	const { data, status, refetch } = usePurchasePage({
		maxItemsInPage: 5,
		searchString: searchDebounce,
		pageIndex: purchasePage.page,
		order: sortBy.order,
		sort: sortBy.acessorToSend,
		type: tabOption,
	})

	const { mutateAsync: updateRedeemProduct } = useMutationUpdateRedeemProduct()

	function handleCloseModal() {
		setPurchaseModal(false)
	}

	function handleOpenModal(product: any) {
		setCurrentProduct(product)

		setPurchaseModal(true)
	}

	function handleRefetch() {
		refetch()
	}

	function handleChangeTab(str: PurchaseAnalyticsData) {
		setTabOption(str)
	}

	function handleListenerChange(event: React.ChangeEvent<HTMLInputElement>) {
		setSearchString(event.target.value)
	}

	const tableColumns: ITableColumn[] = [
		{ Header: '', accessor: 'image', sortType: 'basic' },
		{ Header: 'Produto', accessor: 'product', sortType: 'basic' },
		{ Header: 'Comprador', accessor: 'buyer', sortType: 'basic' },
		{ Header: 'Data da Compra', accessor: 'purchaseDate', sortType: 'basic' },
		{
			Header: 'Prazo de Retirada',
			accessor: 'withdrawalDeadline',
			sortType: 'basic',
		},
		{ Header: '', accessor: 'withdrawalButton', sortType: 'basic' },
		{
			Header: '',
			accessor: 'editIcon',
			sortType: 'basic',
			disableSortBy: true,
		},
	]

	function renderEditIcon(product: IExtractDocument<Product>) {
		function handleAction() {
			history.push(`/purchase/${product._id}`)
		}

		return (
			<DetailButton onClick={handleAction} data-testid='button-details'>
				<MoreHorizontal style={{ strokeWidth: 1.5 }} />
			</DetailButton>
		)
	}

	function handleSortColumn(acessor: string, order: number) {
		let acessorToSend = acessor
		if (order === 0) {
			setSortBy({
				acessorToSend: 'createdAt',
				acessor: 'createdAt',
				order: -1,
			})
			return
		}

		if (acessor === ETableAudioAcessor.name) {
			acessorToSend = 'name'
		}
		setSortBy({
			acessorToSend,
			acessor,
			order,
		})
	}

	function handleGetPage() {
		// eslint-disable-next-line @typescript-eslint/no-extra-semi
		;(async () => {
			await refetch()
			await numberRefetch()
		})()
	}

	async function handleRedeemItem(productID: string) {
		const response = await updateRedeemProduct({ productID })

		if (!response) return

		handleGetPage()

		if (!response.redeemed) {
			setPurchaseModal(false)
			handleSuccessToaster('Produto resgatado com sucesso!')
			refetch()
		}
	}

	const renderTableElements = (extract: any) => {
		const afterDate = isAfter(
			new Date(String(extract.redemptionDeadline)),
			new Date(),
		)

		const isRedeemed = !!extract.redeemed

		function handleClick() {
			handleOpenModal(extract)
		}

		return {
			image: renderImage(extract.referenceTypeID[0].photoURL),
			product: renderTextTable(extract.referenceTypeID[0].title),
			buyer: renderTextTable(extract.user[0].name || ''),
			purchaseDate: renderTextTable(
				// eslint-disable-next-line quotes
				format(new Date(extract.createdAt), "dd/MM/yyyy 'às' HH:mm"),
			),
			withdrawalDeadline: renderTextTable(
				// eslint-disable-next-line quotes
				format(new Date(extract.redemptionDeadline), "dd/MM/yyyy 'às' HH:mm"),
			),
			withdrawalButton: withDrawalButton({
				redeemed: extract.redeemed,
				handleOpenModal: handleClick,
				isActive: !isRedeemed && afterDate,
			}),
			editIcon: renderEditIcon(extract),
		}
	}

	function handleSubmitRedeemed() {
		// eslint-disable-next-line @typescript-eslint/no-extra-semi
		;(async () => {
			await handleRedeemItem(currentProduct._id)
		})()
	}

	function goToPage(pageIndex: number) {
		setPurchasePage((state) => ({ ...state, page: pageIndex + 1 }))
	}

	const navProps: ITableNavProps = {
		nextPage: (pageIndex) => goToPage(pageIndex),
		previousPage: (pageIndex) => goToPage(pageIndex),
		gotoPage: (pageIndex) => goToPage(pageIndex),
		pageCount: purchasePage.numberOfPages,
		pageIndex: purchasePage.page - 1,
		totalDocs: purchasePage.totalDocs,
	}

	function handleData() {
		if (!data) return

		const documentsData = data.pages[0]

		const rows = documentsData.docs.map(renderTableElements)

		setTableRows(rows)
		setPurchasePage({
			numberOfPages: documentsData.totalPages,
			page: documentsData.page || 1,
			totalDocs: documentsData.totalDocs,
		})
	}

	function handleTabMenuOptions() {
		if (!purchaseCount) return
		setOptionsTab([
			{
				title: PurchaseAnalyticsData.NOT_REDEEMED,
				value: purchaseCount.toBeRedeemed,
			},
			{ title: PurchaseAnalyticsData.REDEEMED, value: purchaseCount.redeemed },
			{
				title: PurchaseAnalyticsData.DEADLINE_END,
				value: purchaseCount?.expired,
			},
		])
	}

	useEffect(handleTabMenuOptions, [purchaseCount])
	useEffect(handleData, [data])

	useEffect(() => {
		setPurchasePage((state) => ({
			...state,
			page: 1,
		}))

		refetch()
	}, [tabOption])

	const viewProps: IViewProps = {
		searchString,
		handleListenerChange,
		handleSortColumn,
		tableColumns,
		isLoading: status === 'loading',
		tableRows,
		navProps,
		purchasePage,
		sortBy,
		purchaseCount,
		handleChangeTab,
		tabOption,
		optionsTab,
		handleRefetch,
		purchaseModal,
		handleCloseModal,
		handleGetPage,
		currentProduct,
		handleSubmitRedeemed,
		goToPage,
	}

	return createElement(Purchases, viewProps)
}

export default PurchasesContainer
