import styled, { css } from 'styled-components'

export const Container = styled.div`
    ${({ theme }) => css`
        div.search-input-wrapper {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            padding: 24px 0 16px;
        }

        div.table-placeholder-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: ${theme.colors.white};
            padding: 6px 0 64px;
            border-radius: 4px;
        }
    `}
`

export const Tabs = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: row;

        border-bottom: 1px solid ${theme.colors.off_white};

        button.tab {
            background: none;
            border: none;
            display: flex;
            flex-direction: row;
            align-items: center;

            margin-right: 40px;
            padding-bottom: 8px;

            &:hover {
                cursor: pointer;
            }

            &.active {
                margin-bottom: -1px;
                border-bottom: 2px solid ${theme.colors.green};

                span.tab-title {
                    color: ${theme.colors.dark_graffiti};
                }
            }

            &.no-data span.tab-quantity {
                background-color: ${theme.colors.light_gray};
                color: ${theme.colors.greenish_grey_medium};
            }

            span.tab-title {
                font-family: ${theme.fonts.radikal_light};
                font-weight: 300;
                font-size: 24px;
                line-height: 32px;
                color: ${theme.colors.greenish_grey_medium};
                margin-right: 16px;
            }

            span.tab-quantity {
                font-family: ${theme.fonts.radikal_regular};
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                color: ${theme.colors.dark_graffiti};

                background-color: ${theme.colors.green};
                padding: 4px 8px 2px;
                border-radius: 50px;
                min-width: 25px;
            }
        }
    `}
`
