import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import Header from '../../shared/components/Header'
import { useTypedSelector } from '../../shared/hooks/useTypedSelector'
import { routesData } from '../Routes/data'
import SideNav from '../SideNav'
import { Container, PageWrapper } from './styles'
import { IPageProps } from './types'

export default function Page({ children }: IPageProps): JSX.Element {
	const { user } = useTypedSelector(['user', 'token'])
	const [isOpen, setIsOpen] = useState(false)

	const location = useLocation()

	function handleSetIsOpen() {
		setIsOpen(!isOpen)
	}

	const headerFilterProps = {
		isOpen,
		handleSetIsOpen,
		userName: user.name,
		pagePathName: location.pathname,
	}

	const sideNavProps = {
		isOpen,
		handleSetIsOpen,
		routes: routesData,
	}

	return (
		<PageWrapper>
			<Header {...headerFilterProps} />

			<SideNav {...sideNavProps} />
			<Container>{children}</Container>
		</PageWrapper>
	)
}
