import { createElement, useEffect, useState } from 'react'
import { IViewProps } from './types'
import { Login } from './view'
import { Creators as TokenActions } from '../../shared/store/ducks/token'
import { Creators as UserActions } from '../../shared/store/ducks/user'
import { useDispatch } from 'react-redux'
import { useMutationAuth } from '../../shared/service/auth.service'

import { useHistory } from 'react-router-dom'
import { routesEnum } from '../Routes/enum'
import { checkEmail, checkPassword } from '../../shared/util/validators'

function LoginContainer(): JSX.Element {
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')

	const [loading, setLoading] = useState(false)

	const [canSubmit, setCanSubmit] = useState(false)

	const history = useHistory()

	const dispatch = useDispatch()

	const mutationAuth = useMutationAuth()

	function handleEmailChange(event: React.ChangeEvent<HTMLInputElement>) {
		setEmail(event.target.value)
	}

	function handlePasswordChange(event: React.ChangeEvent<HTMLInputElement>) {
		setPassword(event.target.value)
	}

	async function handleLogin() {
		try {
			setLoading(true)

			const response = await mutationAuth.mutateAsync({ email, password })

			if (!response) return

			const user = response.user

			const userDocument = {
				_id: user._id,
				name: user.name,
				email: user.email,
				status: user.status,
				roles: user.roles,
				photoURL: user.photoURL,
			}

			dispatch(UserActions.setUser(userDocument))
			dispatch(TokenActions.setToken({ accessToken: response.accessToken }))

			history.push(routesEnum.USERS)
		} finally {
			setLoading(false)
		}
	}

	function handleSubmit(event: React.SyntheticEvent<Element, Event>) {
		event.preventDefault()
		handleLogin()
	}

	function formValidation() {
		const isValidEmail = checkEmail(email)

		const passwordIsValid = checkPassword(password)

		setCanSubmit(isValidEmail && passwordIsValid)
	}

	useEffect(formValidation, [email, password])

	const viewProps: IViewProps = {
		email,
		password,
		handleEmailChange,
		handlePasswordChange,
		canSubmit,
		loading,
		handleSubmit,
	}

	return createElement(Login, viewProps)
}

export default LoginContainer
