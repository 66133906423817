import { DayOfWeek, IScheduleDate } from '../../interfaces/program'

export const INITIAL_STATE_IMAGE_UPLOAD = {
	file: null,
	url: '',
	overwrite: false,
}

export const PROGRAM_DATE = {
	day: '',
	timeStart: '',
	timeEnd: '',
}

export function createTimeSchedule(): IScheduleDate {
	return {
		day: DayOfWeek.MONDAY,
		timeStart: '',
		timeEnd: '',
	}
}

export function programDateValidation(data: IScheduleDate[]) {
	if (!data.length) return false

	const result = data.find((program) => {
		return (
			program.timeStart === program.timeEnd ||
			program.timeStart >= program.timeEnd
		)
	})

	return !!result
}
