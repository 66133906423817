import { AxiosError } from 'axios'
import {
	QueryFunctionContext,
	useMutation,
	UseMutationResult,
	useQuery,
	UseQueryResult,
} from 'react-query'
import {
	IAudio,
	IDeleteAudiosPayload,
	IPlayedRequest,
	Played,
} from '../interfaces/audio'
import { IError } from '../interfaces/error'
import { IPaginationResponse, PaginatedRequest } from '../interfaces/pagination'
import { IProgram } from '../interfaces/program'

import { IUser } from '../interfaces/user'
import { QUERY_KEYS } from '../util/reactQuery'
import axiosInstance from './axiosInstance'

export async function deleteAudios(payload: IDeleteAudiosPayload) {
	const response = await axiosInstance.post('/audio/discard/', {
		audios: payload.audiosDeleted,
	})

	return response.data
}

async function fetchProgramsNow(): Promise<IProgram | undefined> {
	const response = await axiosInstance.get('/now')
	return response.data.data.program
}

export function useProgramsNow(): UseQueryResult<IProgram | undefined> {
	return useQuery([QUERY_KEYS.PROGRAM_NOW], async () => fetchProgramsNow(), {
		enabled: true,
	})
}

export type IFetchAudiosRequest = PaginatedRequest<{
	programID: string
}>

export interface IAudioResponse extends Omit<IAudio, 'user' | 'program'> {
	user: IUser
	program: IProgram
	audiosDeleted: IAudio
}

async function fetchAudios(
	context: QueryFunctionContext<[unknown, IFetchAudiosRequest]>,
): Promise<IPaginationResponse<IAudioResponse>> {
	const [_, params] = context.queryKey
	const { maxItemsInPage, pageIndex, searchString, order, sort, programID } = params
	const response = await axiosInstance.post('/audio/get-page', {
		pageIndex,
		searchString,
		order,
		sort,
		maxItemsInPage,
		programID,
	})
	return response.data
}

export function useAudiosPage({
	maxItemsInPage,
	pageIndex,
	searchString,
	order,
	sort,
	programID,
}: IFetchAudiosRequest): UseQueryResult<IPaginationResponse<IAudioResponse>> {
	const result = useQuery(
		[QUERY_KEYS.AUDIOS, {  maxItemsInPage, pageIndex, searchString, order, sort, programID }],
		fetchAudios,
		
	)
	return result
}

async function updatePlayed(payload: IPlayedRequest): Promise<Played> {
	const { audioID } = payload
	const response = await axiosInstance.get(`/audio/played/${audioID}`)

	return response.data
}

export function useMutationUpdatePlayed(): UseMutationResult<
	Played,
	AxiosError<IError>,
	IPlayedRequest
> {
	return useMutation(updatePlayed)
}
