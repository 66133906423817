import { useEffect, useState } from 'react'
import { UserRoles } from '../interfaces/user'
import { useTypedSelector } from './useTypedSelector'

export enum PermissionsTypeEnum {
	LIST = 'list',
	VIEW = 'view',
	EDIT = 'edit',
	DELETE = 'delete',
	ACTIVATE_OR_DEACTIVATE = 'activate/deactivate',
	ACCOMPLISH = 'accomplish',
	RAFFLE = 'raffle',
	EDIT_WITH_PARTICIPANTS = 'editWithParticipants',
}

enum PermissionsModuleEnum {
	PROMOTIONS = 'PROMOTIONS',
}

interface IPermissionsModule {
	module: PermissionsModuleEnum
	permissionsPerRole: { [key in UserRoles]: PermissionsTypeEnum[] }
}

export type UserPermissionsPerModule = {
	[key in PermissionsModuleEnum]: PermissionsTypeEnum[]
}

const permissionsPerModule: IPermissionsModule[] = [
	{
		module: PermissionsModuleEnum.PROMOTIONS,
		permissionsPerRole: {
			[UserRoles.ADMIN]: [
				PermissionsTypeEnum.LIST,
				PermissionsTypeEnum.VIEW,
				PermissionsTypeEnum.EDIT,
				PermissionsTypeEnum.DELETE,
				PermissionsTypeEnum.ACTIVATE_OR_DEACTIVATE,
				PermissionsTypeEnum.ACCOMPLISH,
				PermissionsTypeEnum.RAFFLE,
				PermissionsTypeEnum.EDIT_WITH_PARTICIPANTS,
			],
			[UserRoles.EDITOR]: [
				PermissionsTypeEnum.LIST,
				PermissionsTypeEnum.VIEW,
				PermissionsTypeEnum.EDIT,
				PermissionsTypeEnum.DELETE,
				PermissionsTypeEnum.ACTIVATE_OR_DEACTIVATE,
				PermissionsTypeEnum.ACCOMPLISH,
				PermissionsTypeEnum.RAFFLE,
			],
			[UserRoles.PRESENTER]: [
				PermissionsTypeEnum.LIST,
				PermissionsTypeEnum.VIEW,
			],
		},
	},
]

export function usePermission() {
	const { user } = useTypedSelector(['user'])
	const [userPermissionsPerModule, setUserPermissionsPerModule] =
		useState<UserPermissionsPerModule>({
			[PermissionsModuleEnum.PROMOTIONS]: [],
		})

	useEffect(() => {
		const updatedUserPermissionsPerModule = JSON.parse(
			JSON.stringify(userPermissionsPerModule),
		)
		user.roles.forEach((role) => {
			permissionsPerModule.forEach((permissionPerModule) => {
				const permissionsRole = permissionPerModule.permissionsPerRole[role]
				const filterExistingPermissions = permissionsRole.filter(
					(permission) =>
						!updatedUserPermissionsPerModule[
							permissionPerModule.module
						].includes(permission),
				)
				updatedUserPermissionsPerModule[permissionPerModule.module].push(
					...filterExistingPermissions,
				)
			})
		})
		setUserPermissionsPerModule(updatedUserPermissionsPerModule)
	}, [user])

	return userPermissionsPerModule
}
