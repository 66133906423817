import React, { createElement, useEffect, useMemo, useState } from 'react'
import { useDebounce } from 'use-debounce'
import {
	IAcessorAndOrder,
	ITableNavProps,
} from '../../shared/components/Table/types'
import { IAudioPage } from '../../shared/interfaces/audio'
import {
	IAudioResponse,
	deleteAudios,
	useAudiosPage,
	useMutationUpdatePlayed,
} from '../../shared/service/audio.service'

import { format } from 'date-fns'
import {
	audioRenderAvatar,
	renderAudioPlayer,
	renderCheckbox,
	renderSwitch,
	renderTextTable,
} from './table'
import {
	ETableAudioAcessor,
	ITableColumn,
	ITableRow,
	IViewProps,
} from './types'

import { useProgramsPage } from '../../shared/service/program.service'
import { handleSuccessToaster } from '../../shared/util/toaster'
import { Audio } from './view'
import { useTypedSelector } from '../../shared/hooks/useTypedSelector'

function AudioContainer() {
	const [searchString, setSearchString] = useState('')
	const [toBeDeleted, setToBeDeleted] = useState<string[]>([])
	const [tableRows, setTableRows] = useState<ITableRow[]>([])
	const [searchDebounce] = useDebounce(searchString, 1000)
	const [selectedProgram, setSelectedProgram] = useState('')
	const [deleteModalIsActive, setDeleteModalIsActive] = useState(false)

	const { user } = useTypedSelector(['token', 'user'])
	const listIsPresenters =
		user.roles.length === 1 && user.roles.includes('PRESENTER')
	const [sortBy, setSortBy] = useState<IAcessorAndOrder>({
		acessorToSend: 'createdAt',
		acessor: 'createdAt',
		order: -1,
	})

	const { mutateAsync: updatePlayed } = useMutationUpdatePlayed()

	const [audioPage, setAudioPage] = useState<IAudioPage>({
		page: 1,
		numberOfPages: 0,
		totalDocs: 0,
	})

	const { data: programsList } = useProgramsPage({
		maxItemsInPage: 1000,
		pageIndex: audioPage.page,
		searchString: searchDebounce,
		order: sortBy.order,
		sort: sortBy.acessorToSend,
	})

	const {
		data: audioData,
		isLoading,
		refetch,
	} = useAudiosPage({
		maxItemsInPage: 5,
		pageIndex: audioPage.page,
		searchString: searchDebounce,
		order: sortBy.order,
		sort: sortBy.acessorToSend,
		programID: selectedProgram,
	})

	const programOptions = useMemo(
		() =>
			programsList?.docs.map((program) => ({
				value: program._id,
				label: program.title,
			})),
		[programsList],
	)

	function handleOpenDeleteModal() {
		if (toBeDeleted.length) {
			setDeleteModalIsActive(true)
		}
	}
	function handleCloseDeleteModal() {
		setDeleteModalIsActive(false)
	}

	function handleProgramSelectChange(
		event: React.ChangeEvent<HTMLSelectElement>,
	) {
		setSelectedProgram(event.target.value)
	}

	function handleSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
		setSearchString(event.target.value)
	}

	function handleSortColumn(acessor: string, order: number) {
		let acessorToSend = acessor
		if (order === 0) {
			setSortBy({
				acessorToSend: 'createdAt',
				acessor: 'createdAt',
				order: -1,
			})
			return
		}

		if (acessor === ETableAudioAcessor.name) {
			acessorToSend = 'user.name'
		}
		setSortBy({
			acessorToSend,
			acessor,
			order,
		})
	}

	function goToPage(pageIndex: number) {
		setAudioPage((state) => ({ ...state, page: pageIndex + 1 }))
	}

	async function handleDeleteAudios() {
		if (!toBeDeleted.length) return

		const deleteAudiosResponse = await deleteAudios({
			audiosDeleted: toBeDeleted,
		})

		if (!deleteAudiosResponse) return

		handleCloseDeleteModal()
		handleSuccessToaster('Audios deletados com sucesso')
		refetch()
	}

	function handleSelect(
		event: React.ChangeEvent<HTMLInputElement>,
		audio: IAudioResponse,
	) {
		const isChecked = event.target.checked

		const toBeDeletedCopy = toBeDeleted.map((item) => item)
		if (isChecked) {
			toBeDeletedCopy.push(audio._id)
		} else {
			const itemIndex = toBeDeletedCopy.findIndex((item) => item === audio._id)
			if (itemIndex > -1) {
				toBeDeletedCopy.splice(itemIndex, 1)
			}
		}

		setToBeDeleted(toBeDeletedCopy)
	}

	async function handleSwitchRefetch(audioID: string) {
		const switchResponse = await updatePlayed({
			audioID,
		})

		if (!switchResponse) return

		refetch()
	}

	function parseData() {
		if (!audioData) return
		const audios = audioData.docs

		const rows = audios.map((audio) => {
			return {
				checkBox: listIsPresenters
					? null
					: renderCheckbox({ audio, toBeDeleted, handleSelect }),
				name: audioRenderAvatar(audio),
				program: audio.program.title,
				createdAt: renderTextTable(
					format(new Date(audio.createdAt), 'dd/MM/yyyy'),
				),
				audios: renderAudioPlayer(audio),
				status: listIsPresenters
					? null
					: renderSwitch({ audio, handleSwitchRefetch }),
			}
		})

		setTableRows(rows)
		setAudioPage({
			numberOfPages: audioData.totalPages,
			page: audioData.page || 1,
			totalDocs: audioData.totalDocs,
		})
	}

	const tableColumns: ITableColumn[] = [
		{
			Header: '',
			accessor: 'checkBox',
			disableSortBy: true,
		},
		{
			Header: 'Ouvinte',
			accessor: ETableAudioAcessor.name,
		},
		{
			Header: 'Programa',
			accessor: ETableAudioAcessor.program,
		},
		{
			Header: 'Data de Envio',
			accessor: ETableAudioAcessor.createdAt,
		},
		{
			Header: 'Áudios',
			accessor: ETableAudioAcessor.audios,
			disableSortBy: true,
		},
		{
			Header: listIsPresenters ? '' : 'Reproduzido na Rádio',
			accessor: 'status',
			disableSortBy: true,
		},
	]

	const navProps: ITableNavProps = {
		nextPage: (pageIndex) => goToPage(pageIndex),
		previousPage: (pageIndex) => goToPage(pageIndex),
		gotoPage: (pageIndex) => goToPage(pageIndex),
		pageCount: audioPage.numberOfPages,
		pageIndex: audioPage.page - 1,
		totalDocs: audioPage.totalDocs,
	}

	useEffect(parseData, [audioData, toBeDeleted])

	const viewProps: IViewProps = {
		searchString,
		handleSearchChange,
		handleProgramSelectChange,
		isLoading,
		handleSortColumn,
		tableRows,
		sortBy,
		tableColumns,
		navProps,
		programOptions,
		handleDeleteAudios,
		handleOpenDeleteModal,
		handleCloseDeleteModal,
		deleteModalIsActive,
		audioPage,
	}

	return createElement(Audio, viewProps)
}

export default AudioContainer
