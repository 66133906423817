import React from 'react'
import { ImageProfileWrapper } from './style'
import { IImageProfielProps } from './types'
import { getInitials } from './util'

export function ImageProfile(props: IImageProfielProps) {
	const { userName } = props

	return (
		<ImageProfileWrapper>
			<span>{getInitials(userName)}</span>
		</ImageProfileWrapper>
	)
}
