import React from 'react'
import { formatNumber } from '../../util/number'
import { PageInfoContainer } from './styles'
import { IResultProps } from './types'

function ResultsTableInfo(props: IResultProps) {
	const { totalDocs, page, numberOfLines } = props
	return (
		<PageInfoContainer>
			Mostrando{' '}
			{totalDocs != undefined
				? page * numberOfLines < totalDocs
					? numberOfLines
					: Math.abs(page * numberOfLines - totalDocs - numberOfLines)
				: '0'}{' '}
			resultados de {formatNumber(totalDocs || 0)}
		</PageInfoContainer>
	)
}

export default ResultsTableInfo
