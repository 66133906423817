import React, { useEffect } from 'react'
import { createElement, useState } from 'react'
import { IViewProps } from './types'
import Banners from './view'
import { Descendant } from 'slate'
import {
	useCreateRegulation,
	useFetchRegulation,
} from '../../shared/service/regulation.service'
import {
	handleErrorToaster,
	handleSuccessToaster,
} from '../../shared/util/toaster'
import { useHistory } from 'react-router-dom'
import { error } from 'console'

function RegulationContainer(): JSX.Element {
	const { data: regulationData, status, refetch } = useFetchRegulation()
	const [regulationReturnIsEmpty, setRegulationReturnIsEmpty] = useState(false)
	const [searchString, setSearchString] = useState('')
	const [isLoading, setIsLoading] = useState(false)
	const [cantSubmit, setCantSubmit] = useState(false)
	const [regulation, setRegulation] =
		useState<Descendant[] | undefined>(undefined)

	function handleRegulation(newValue: Descendant[]) {
		setRegulation(newValue)
	}

	const mutation = useCreateRegulation()

	const history = useHistory()
	async function handleSubmit() {
		setIsLoading(true)
		const result = await mutation.mutateAsync({
			regulation: JSON.stringify(regulation),
		})

		if (!result) {
			setIsLoading(false)
			handleErrorToaster('Nao foi possivel salvar o regulamento!')
			return
		} else {
			setIsLoading(false)
			refetch()
			handleSuccessToaster('Regulamento salvo com sucesso!')
			setCantSubmit(true)
		}
	}

	function handleValidateForm() {
		if (
			regulationReturnIsEmpty ||
			JSON.stringify(regulation) === regulationData?.regulation
		) {
			setCantSubmit(true)
		} else {
			setCantSubmit(false)
		}
	}

	useEffect(handleValidateForm, [regulationReturnIsEmpty, regulation])

	useEffect(() => {
		if (status) {
			setRegulation(JSON.parse(regulationData?.regulation || null))
		}
	}, [status])

	const viewProps: IViewProps = {
		searchString,
		regulation,
		handleRegulation,
		setRegulationReturnIsEmpty,
		cantSubmit,
		handleSubmit,
		isLoading,
	}
	return createElement(Banners, viewProps)
}

export default RegulationContainer
