import React from 'react'
import {
	BackgroundImageWrapper,
	FormContainer,
	FormTitle,
	FormWrapper,
	InputWrapper,
	LoginWrapper,
} from './styles'

import EducadoraLogo from '../../assets/icons/educadora-horizontal.svg'

import { Button } from '../../shared/components/PromotionButton'
import { Input } from '../../shared/components/Input'
import { IViewProps } from './types'
import Loading from '../../shared/components/Loading'

import { Link } from 'react-router-dom'
import { routesEnum } from '../Routes/enum'

export function Login(props: IViewProps) {
	const {
		email,
		password,
		handleEmailChange,
		handlePasswordChange,
		canSubmit,
		loading,
		handleSubmit,
	} = props

	return (
		<LoginWrapper>
			<BackgroundImageWrapper>
				<img src={EducadoraLogo} />
			</BackgroundImageWrapper>

			<FormContainer>
				<FormWrapper onSubmit={handleSubmit}>
					<FormTitle>Login</FormTitle>

					<InputWrapper>
						<Input
							className='email'
							value={email}
							type='email'
							placeholder='Email'
							data-testid='input-email'
							onChange={handleEmailChange}
						/>

						<Input
							className='password'
							value={password}
							type='password'
							placeholder='Senha'
							data-testid='input-password'
							onChange={handlePasswordChange}
						/>

						<div>
							<div></div>

							<span>
								<Link to={routesEnum.RECOVERY_PASSWORD}>
									Esqueci minha senha
								</Link>
							</span>
						</div>
					</InputWrapper>

					<div className='button-wrapper'>
						{!loading ? (
							<Button disabled={!canSubmit} type='submit' label='Entrar' data-testid='submit-login'/>
						) : (
							<div className='loading'>
								<Loading />
							</div>
						)}
					</div>
				</FormWrapper>
			</FormContainer>
		</LoginWrapper>
	)
}
