import React from 'react'
import {
	BeatCoinsGenerate,
	BonusWordContainer,
	BonusWordWrapper,
	CardNumber,
	CardTitle,
	HistWrapper,
	RewardCoins,
	RewardLabel,
	RewardWrapper,
	StopButton,
	WordWrapper,
} from './styles'
import { IBonusWordProps } from './types'

import CorrectIcon from '../../../assets/icons/correctIcon.svg'
import CashIcon from '../../../assets/icons/cash.svg'
import { useMutationStopBonus } from '../../service/bonus.service'
import { formatNumber } from '../../util/number'

function BonusWord(props: IBonusWordProps) {
	const { name, reward, hits, totalReward, refetch } = props

	const mutationStopBonus = useMutationStopBonus()

	async function handleStopCurrentGame() {
		const response = await mutationStopBonus.mutateAsync({})

		if (!response) return

		refetch()
	}

	return (
		<BonusWordWrapper>
			<BonusWordContainer>
				<WordWrapper>
					<span>Palavra da vez</span>

					<div>
						<h1>{name}</h1>

						<StopButton onClick={handleStopCurrentGame}>Stop</StopButton>
					</div>
				</WordWrapper>

				<RewardWrapper>
					<RewardLabel>Prêmio</RewardLabel>
					<RewardCoins>{reward} Beatcoins</RewardCoins>
				</RewardWrapper>
			</BonusWordContainer>

			<HistWrapper>
				<img src={CorrectIcon} alt='' />
				<CardTitle>Acertos</CardTitle>
				<CardNumber>{hits}</CardNumber>
			</HistWrapper>

			<BeatCoinsGenerate>
				<img src={CashIcon} alt='' />
				<CardTitle>Beatcoins Gerados</CardTitle>
				<CardNumber>{formatNumber(totalReward)}</CardNumber>
			</BeatCoinsGenerate>
		</BonusWordWrapper>
	)
}

export default BonusWord
