import React, { createElement, useEffect, useState } from 'react'
import {
	useManagersPage,
	useMutationInviteUser,
} from '../../shared/service/user.service'
import { ITableColumn, ITableRow, IViewProps } from './types'
import { Users } from './view'

import {
	renderImage,
	renderRolesTable,
	renderTextTable,
	renderUserStatus,
} from './table'

import { useDebounce } from 'use-debounce'
import { IUser, IUserPage, UserStatus } from '../../shared/interfaces/user'
import { DetailButton, InviteButton } from './styles'

import {
	IAcessorAndOrder,
	ITableNavProps,
} from '../../shared/components/Table/types'
import { ModalAction } from '../../shared/interfaces/modal'
import { handleSuccessToaster } from '../../shared/util/toaster'
import { routesEnum } from '../Routes/enum'
import { useHistory } from 'react-router-dom'
import { useTypedSelector } from '../../shared/hooks/useTypedSelector'
import { MoreHorizontal } from 'react-feather'

function UsersContainer(): JSX.Element {
	const [searchString, setSearchString] = useState('')
	const [tableRows, setTableRows] = useState<ITableRow[]>([])
	const [searchDebounce] = useDebounce(searchString, 1000)
	const { user } = useTypedSelector(['token', 'user'])
	const listIsPresenters =
		user.roles.length === 1 && user.roles.includes('PRESENTER')

	const [modaIsOpen, setModalIsOpen] = useState(false)
	const [modalUser, setModalUser] = useState<IUser | null>(null)

	const [sortBy, setSortBy] = useState<IAcessorAndOrder>({
		acessor: 'name',
		order: 1,
	})

	const [userPage, setUserPage] = useState<IUserPage>({
		page: 1,
		numberOfPages: 0,
		totalDocs: 0,
	})

	const {
		status: userFetchStatus,
		data: usersData,
		refetch,
	} = useManagersPage({
		maxItemsInPage: 5,
		pageIndex: userPage.page,
		searchString: searchDebounce,
		order: sortBy.order,
		sort: sortBy.acessor,
	})

	const inviteUserMutation = useMutationInviteUser()

	const history = useHistory()

	function handleUserChange(event: React.ChangeEvent<HTMLInputElement>) {
		setSearchString(event.target.value)
	}

	function handleModalOpen() {
		history.push(routesEnum.NEW_USER)
	}

	function handleModalClose() {
		setModalIsOpen(false)
		setModalUser(null)
	}

	function paginationRefetch() {
		refetch()
	}

	function renderEditIcon(user: IUser) {
		if (listIsPresenters) {
			return null
		}

		function handleEdit() {
			history.push(`users/editUser/${user._id}`)

			setModalUser(user)
		}

		return (
			<DetailButton onClick={handleEdit} data-testid='button-details'>
				<MoreHorizontal style={{ strokeWidth: 1.5 }} />
			</DetailButton>
		)
	}

	function handleSortColumn(acessor: string, order: number) {
		if (order === 0) {
			setSortBy({
				acessor: 'name',
				order: -1,
			})
			return
		}
		setSortBy({
			acessor,
			order,
		})
	}

	function goToPage(pageIndex: number) {
		setUserPage((state) => ({ ...state, page: pageIndex + 1 }))
	}

	const tableColumns: ITableColumn[] = [
		{ Header: '', accessor: 'image', disableSortBy: true },
		{ Header: 'Nome', accessor: 'name', sortType: 'basic' },
		{ Header: 'E-mail', accessor: 'email', sortType: 'basic' },
		{
			Header: 'Tipo',
			accessor: 'type',
			sortType: 'basic',
			disableSortBy: true,
		},
		{
			Header: 'Status',
			accessor: 'status',
			sortType: 'basic',
			disableSortBy: true,
		},
		{
			Header: '',
			accessor: 'invite',
			sortType: 'basic',
			disableSortBy: true,
		},
		{
			Header: '',
			accessor: 'editIcon',
			sortType: 'basic',
			disableSortBy: true,
		},
	]

	const navProps: ITableNavProps = {
		nextPage: (pageIndex) => goToPage(pageIndex),
		previousPage: (pageIndex) => goToPage(pageIndex),
		gotoPage: (pageIndex) => goToPage(pageIndex),
		pageCount: userPage.numberOfPages,
		pageIndex: userPage.page - 1,
		totalDocs: userPage.totalDocs,
	}

	function renderInviteButton(user: IUser) {
		if (user.status === UserStatus.ACTIVE) {
			return <></>
		}

		async function inviteUser() {
			const inviteUserResponse = await inviteUserMutation.mutateAsync({
				userID: user._id,
			})

			if (!inviteUserResponse) return

			handleSuccessToaster('Convite para o usuário Reenviado com sucesso!')
		}

		return <InviteButton onClick={inviteUser}>Reenviar</InviteButton>
	}

	const renderTableElements = (user: IUser) => {
		return {
			image: renderImage(user.photoURL),
			name: renderTextTable(user.name),
			email: renderTextTable(user.email),
			type: renderRolesTable(user.roles),
			status: renderUserStatus(user.status),
			invite: renderInviteButton(user),

			editIcon: renderEditIcon(user),
		}
	}

	function parseData() {
		if (!usersData) return

		const rows = usersData?.docs.map(renderTableElements)

		if (!rows) return

		setTableRows(rows)

		setUserPage({
			numberOfPages: usersData?.totalPages,
			page: usersData?.page || 1,
			totalDocs: usersData?.totalDocs,
		})
	}

	useEffect(parseData, [usersData, searchDebounce])

	const viewProps: IViewProps = {
		handleUserChange,
		searchString,
		tableColumns,
		tableRows,
		isLoading: userFetchStatus === 'loading',
		handleModalOpen,
		handleModalClose,
		modaIsOpen,
		modalUser,
		modalAction: modalUser ? ModalAction.EDIT : ModalAction.CREATE,
		paginationRefetch,
		handleSortColumn,
		sortBy,
		navProps,
		userPage,
	}

	return createElement(Users, viewProps)
}

export default UsersContainer
