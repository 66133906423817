import React from 'react'

import * as S from './styles'
import { ITabMenuOption } from './types'
import { TAB_MENU_OPTIONS } from '../../util/translate'

export function TabMenu(props:ITabMenuOption) {
  const {currentTab, options, handleChangeTab} = props


  return (
    <S.TabsContainer>
					{options.map((item) => {
						const isActiveItem = currentTab === item.title

						return (
							<S.TabWrapperContainer onClick={() => handleChangeTab(item.title)} key={item.title} isActive={isActiveItem}>
							<S.Tab>
								<span className='title'>{TAB_MENU_OPTIONS[item.title]}</span>
								<div className="number-value-container">
									<span className='number-value'>{item.value}</span>
								</div>
							</S.Tab>
							{isActiveItem ? <S.ActiveBorder /> : <S.InactiveBorder/>}
						</S.TabWrapperContainer>
						)
					})}
				</S.TabsContainer>
  )
}