import styled, { css } from 'styled-components'
import { toRem } from '../../styles/function'

export const PrimaryInputContainer = styled.input`
	${(props) => css`
		padding: 12px 16px;
		width: 100%;
		outline: none;
		border: none;
		border: 1px solid
			${props.disabled ? props.theme.colors.light_gray : props.value
				? props.theme.colors.green
				: props.theme.colors.off_white};
		border-radius: 4px;
		height: 46px;
		font-family: ${props.theme.fonts.radikal_regular};
		color: ${props.disabled ? props.theme.colors.greenish_grey_light : props.theme.colors.greenish_grey};
		background: ${props.disabled ? props.theme.colors.off_white : props.theme.colors.white};
		font-size: ${toRem(14)};
		transition: 0.4s;

		&::placeholder{
			color: ${props.theme.colors.greenish_grey_light};
		}

		&:focus {
			border: 1px solid ${props.theme.colors.green};
		}
	`};
`

export const InputWrapper = styled.div`
	${({ theme }) => css`
		position: relative;
		width: 100%;

		.at-icon {
			position: absolute;
			right: 16px;
			top: 16px;
			width: 16px;
			height: 16px;
			cursor: pointer;
			color: ${theme.colors.greenish_grey_light};
		}

		.lock-icon {
			position: absolute;
			right: 16px;
			top: 16px;
			width: 16px;
			height: 16px;
			cursor: pointer;
			color: ${theme.colors.greenish_grey_light};
		}
	`}
`
export const MaxLengthWrapper = styled.div`
	display:flex;
	justify-content: flex-end;
	margin-top:8px;
`
export const MaxLength = styled.span`
${({theme}) => css`
	font-size:12px;
	color: ${theme.colors.dark_graffiti}
`}
`