import React from 'react'
import { IButtonRootProps, IButtonTextProps } from '../CustomButton/types'
import { Button } from '..'

export default function ModalButton({
	text,
	...buttonProps
}: Omit<IButtonRootProps, 'children'> & IButtonTextProps) {
	return (
		<Button.Root {...buttonProps}>
			<Button.Text text={text} />
		</Button.Root>
	)
}
