import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'

import Modal from 'react-modal'

import 'react-toastify/dist/ReactToastify.css'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file

const appElement = document.getElementById('root') as HTMLElement

Modal.setAppElement(appElement)

const root = ReactDOM.createRoot(appElement)

root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
)
