import React from 'react'

import { IUseModalReturn } from '../../hooks/useModal'
import Modal from '../CustomModal'
import { CounterComponent } from './CounterComponent'

function RaffleCountModal(props: IUseModalReturn): JSX.Element {
	const { isVisible, onConfirm } = props

	return (
		<Modal.Root title='Sorteando ouvinte' isVisible={isVisible}>
			<Modal.AnyElement>
				<CounterComponent onComplete={onConfirm} />
			</Modal.AnyElement>
		</Modal.Root>
	)
}

export default RaffleCountModal
