import React from 'react'
import * as S from './styles'
import { IDeleteModalProps } from './types'
import { X } from 'react-feather'
import { Button } from '../'

function DeleteModal(props: IDeleteModalProps) {
	const { handleCloseModal, handleSubmit, title, description } = props

	const handleOutsideClick = (event: any) => {
		if (event.target.id === 'modal') handleCloseModal()
	}
	return (
		<S.DeleteModalStyles id='modal' data-testid='out-of-modal' onClick={handleOutsideClick}>
			<S.DeleteModalContainer data-testid='modal'>
				<S.Header>
					<S.ModalTitle>Excluir {title}</S.ModalTitle>
					<X onClick={handleCloseModal} className='close-icon' data-testid='button-close'/>
				</S.Header>

				<S.ModalSeparator/>

				<S.Content>
					<p>{description}</p>
				</S.Content>

				<S.ButtonsContainer >
					<Button.Root
							onClick={handleSubmit} 
							spacing={'12x24'}
							theme='delete'
							style={{marginBottom: '16px'}}
							data-testid='button-confirm-delete'
							>
							<Button.Text text={`Excluir ${title}`} />
						</Button.Root>
						<Button.Root
							onClick={handleCloseModal}
							spacing={'12x24'}
							theme='light'
							data-testid='button-cancel'
						>
							<Button.Text text='Manter' />
					</Button.Root>
				</S.ButtonsContainer>

			</S.DeleteModalContainer>
		</S.DeleteModalStyles>
	)
}

export default DeleteModal
