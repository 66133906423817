import React, { useState } from 'react'

import * as S from './styles'

import dragTable from '../../assets/icons/drag-table.svg'

import { Trash2 } from 'react-feather'

import { deleteBanner } from '../../shared/service/banners.service'
import { IRenderSwitchProps } from './types'
import { IBanner, IBannerResponse } from '../../shared/interfaces/banners'
import DeleteModal from '../../shared/components/DeleteModal'
import { handleSuccessToaster } from '../../shared/util/toaster'
import Switch from '../../shared/components/Switch'

export function renderPosition(props: IBannerResponse) {
	return (
		<S.PositionWrapper>
			<S.PositionContainer>
				<S.PositionIcon src={dragTable} alt='drag-n-drop-icon' />

				<S.PositionNumber>{props.positionQueue}</S.PositionNumber>
			</S.PositionContainer>
		</S.PositionWrapper>
	)
}

export function renderbannerInfos(props: IBannerResponse) {
	return (
		<S.TitleWrapper>
			<S.Image src={props.photoURL} alt='banner-image' />
			<S.Title>{props.title}</S.Title>
		</S.TitleWrapper>
	)
}

export function renderSwitch(props: IRenderSwitchProps) {
	const { banner, handleSwitchRefetch } = props
	const isChecked = banner.status === 'ACTIVE' ? true : false

	async function handleSwitchChange() {
		await handleSwitchRefetch(banner)
	}

	return <Switch onChange={handleSwitchChange} checked={isChecked} />
}

export function renderDeleteBanner(props: IBanner, refetch: any) {
	const [deleteModalOpen, setDeleteModalOpen] = useState(false)
	return (
		<S.DeleteWrapper>
			<S.DeleteButton
				onClick={async () => {
					setDeleteModalOpen(true)
				}}
				data-testid='button-delete'
			>
				<Trash2 style={{ strokeWidth: 1.5 }} />
			</S.DeleteButton>
			{deleteModalOpen ? (
				<DeleteModal
					title='Banner'
					description='Deseja realmente excluir este banner? Esta ação não poderá ser desfeita após a confirmação de exclusão.'
					handleCloseModal={() => {
						setDeleteModalOpen(false)
					}}
					handleSubmit={async () => {
						await deleteBanner(props._id)
						refetch()
						handleSuccessToaster('Banner excluído com sucesso')
					}}
				/>
			) : null}
		</S.DeleteWrapper>
	)
}
