import { createGlobalStyle } from 'styled-components'

import RadikalRegular from '../../assets/fonts/radikal.otf'
import Radikalbold from '../../assets/fonts/radikal_bold.otf'
import RadikalLight from '../../assets/fonts/radikal_light.otf'
import RadikalMedium from '../../assets/fonts/radikal_medium.otf'

export const GlobalStyle = createGlobalStyle`
	@font-face {
		font-family: ${({ theme }) => theme.fonts.radikal_light};
		src: url(${RadikalLight}) format('truetype');
		font-weight: 400;
		font-style: normal;
	}

	@font-face {
		font-family: ${({ theme }) => theme.fonts.radikal_regular};
		src: url(${RadikalRegular}) format('truetype');
		font-weight: normal;
    	font-style: normal;
	}

	@font-face {
		font-family: ${({ theme }) => theme.fonts.radikal_medium};
		src: url(${RadikalMedium}) format('truetype');
		font-weight: 500;
		font-style: normal;
	}
	@font-face {
		font-family:${({ theme }) => theme.fonts.radikal_bold};
		src: url(${Radikalbold}) format('truetype');
		font-weight: 700;
    	font-style: normal;
	}


	* {
		margin: 0;
		padding: 0;
		outline: 0;
		box-sizing: border-box;
	}

	body {
		background: ${({ theme }) => theme.colors.background};
	}

	html {
		font-family:${({ theme }) => theme.fonts.radikal_regular};
	}
`
