import React from 'react'
import { IGenericConfirmationModalProps } from './types'
import { Modal } from '..'

function GenericConfirmationModal(props: IGenericConfirmationModalProps) {
	const {
		title,
		description,
		confirmButtonText,
		cancelButtonText,
		isVisible,
		onCancel,
		onConfirm,
		isLoading = false,
	} = props

	return (
		<Modal.Root isVisible={isVisible} title={title} onClose={onCancel}>
			<Modal.ParagraphText text={description} />
			<Modal.ContentSpacing size={56} />
			<Modal.Button
				theme='dark'
				spacing='12x24'
				onClick={onConfirm}
				isLoading={isLoading}
				text={confirmButtonText}
			/>
			<Modal.ContentSpacing size={16} />
			<Modal.Button
				theme='light'
				spacing='12x24'
				onClick={onCancel}
				text={cancelButtonText}
			/>
		</Modal.Root>
	)
}

export default GenericConfirmationModal
