import React from 'react'
import {
	BackgroundImageWrapper,
	FormContainer,
	FormTitle,
	FormWrapper,
	InputWrapper,
	RegisterPassswordWrapper,
} from './styles'
import { IViewProps } from './types'

import EducadoraLogo from '../../assets/icons/educadora-horizontal.svg'
import { Input } from '../../shared/components/Input'
import { Button } from '../../shared/components/PromotionButton'
import Loading from '../../shared/components/Loading'

export function ChangePassword(props: IViewProps) {
	const {
		handleSubmit,
		loading,
		canSubmit,
		primaryPassword,
		secondaryPassword,
		handlePrimaryPasswordChange,
		handleSecondaryPasswordChange,
		handleRegisterPassword,
	} = props

	return (
		<RegisterPassswordWrapper>
			<BackgroundImageWrapper>
				<img src={EducadoraLogo} />
			</BackgroundImageWrapper>

			<FormContainer>
				<FormWrapper onSubmit={handleSubmit}>
					<FormTitle>Nova senha</FormTitle>

					<InputWrapper>
						<Input
							className='password'
							value={primaryPassword}
							type='password'
							placeholder='Senha'
							onChange={handlePrimaryPasswordChange}
						/>

						<Input
							className='secondary-password'
							value={secondaryPassword}
							type='password'
							placeholder='Confirmar Senha'
							onChange={handleSecondaryPasswordChange}
						/>
					</InputWrapper>

					<div className='button-wrapper'>
						{!loading ? (
							<Button
								onClick={handleRegisterPassword}
								disabled={!canSubmit}
								type='submit'
								label='Cadastrar'
							/>
						) : (
							<div className='loading'>
								<Loading />
							</div>
						)}
					</div>
				</FormWrapper>
			</FormContainer>
		</RegisterPassswordWrapper>
	)
}
