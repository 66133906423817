import { AxiosError } from 'axios'

import {
	QueryFunctionContext,
	useInfiniteQuery,
	UseInfiniteQueryResult,
	useMutation,
	UseMutationResult,
	useQuery,
	UseQueryResult,
} from 'react-query'
import { IError } from '../interfaces/error'
import { IPaginationResponse, PaginatedRequest } from '../interfaces/pagination'
import {
	IIProductRedeemProps,
	IProductRequest,
	Product,
} from '../interfaces/product'
import { QUERY_KEYS } from '../util/reactQuery'
import axiosInstance from './axiosInstance'
import { IExtractDocument, IPurchaseNumbers,  PurchaseAnalyticsData } from '../interfaces/extract'

// eslint-disable-next-line @typescript-eslint/ban-types
export type IFetchProductRequest = PaginatedRequest<{}>

async function fetchProducts(
	context: QueryFunctionContext<[unknown, IFetchProductRequest]>,
): Promise<IPaginationResponse<Product>> {
	const [_, params] = context.queryKey
	const { maxItemsInPage, pageIndex, searchString, order, sort } = params
	const response = await axiosInstance.post('/product/get-page', {
		pageIndex,
		searchString,
		order,
		sort,
		maxItemsInPage,
	})

	return response.data
}

export function useProductsPage({
	maxItemsInPage,
	pageIndex,
	searchString,
	order,
	sort,
}: IFetchProductRequest): UseQueryResult<IPaginationResponse<Product>> {
	const result = useQuery(
		[QUERY_KEYS.PRODUCTS, { maxItemsInPage, pageIndex, searchString, order, sort }],
		fetchProducts,
	)
	return result
}

async function createProduct(props: IProductRequest): Promise<Product> {
	const response = await axiosInstance.post('/product', { ...props })

	return response.data
}

export function useMutationCreateProduct(): UseMutationResult<
	Product,
	AxiosError<IError>,
	IProductRequest
> {
	return useMutation(createProduct)
}

async function updateProduct(payload: IProductRequest): Promise<Product> {
	const response = await axiosInstance.put(`/product/${payload.id}`, payload)

	return response.data
}

export function useMutationUpdateProduct(): UseMutationResult<
	Product,
	AxiosError<IError>,
	IProductRequest
> {
	return useMutation(updateProduct)
}

async function changeProductStatus({id}: {id: string}): Promise<Product> {
	const response = await axiosInstance.put(`/product/change-status/${id}`)

	return response.data
}

export function useMutationChangeProductStatus(): UseMutationResult<
	Product,
	AxiosError<IError>,
	{id: string}
> {
	return useMutation(changeProductStatus)
}

async function updateProductRedeem(
	payload: IIProductRedeemProps,
): Promise<IExtractDocument<Product>> {
	const response = await axiosInstance.get(
		`/extract/panel/redeemed/${payload.productID}`,
	)
	return response.data
}

export function useMutationUpdateRedeemProduct(): UseMutationResult<
IExtractDocument<Product>,
	AxiosError<IError>,
	IIProductRedeemProps
> {
	return useMutation(updateProductRedeem)
}



async function fetchPurchases({
	maxItemsInPage,
	pageIndex,
	searchString,
	order,
	sort,
	type
}: IFetchPurchaseRequest): Promise<IPaginationResponse<IExtractDocument<Product>>> {
	const response = await axiosInstance.post('/extract/panel/analytics', {
		pageIndex,
		searchString,
		order,
		sort,
		maxItemsInPage,
		type
	})

	console.log({response})

	return response.data
}


// eslint-disable-next-line @typescript-eslint/ban-types
type IFetchPurchaseRequest = PaginatedRequest<{type: PurchaseAnalyticsData}>


export function usePurchasePage({
	maxItemsInPage,
	pageIndex,
	searchString,
	order,
	sort,
	type
}: IFetchPurchaseRequest): any {
	return useInfiniteQuery(
		[
			QUERY_KEYS.PURCHASES,
			{ maxItemsInPage, pageIndex, searchString, order, sort,type },
		],
		async ({ queryKey }) => {
			const [_key, params] = queryKey

			const { maxItemsInPage, pageIndex, searchString, order, sort, type } =
				params as IFetchPurchaseRequest

			return fetchPurchases({
				maxItemsInPage,
				pageIndex,
				searchString,
				order,
				sort,
				type
			})
		},
	)
}

async function fetchPromotionDetail(): Promise<IPurchaseNumbers> {
	const response = await axiosInstance.get('/extract/panel/analytics/count')

	return response.data
}

export function usePurchaseData(): UseQueryResult<IPurchaseNumbers> {
	return useQuery(
		[QUERY_KEYS.PURCHASE_DATA],
		fetchPromotionDetail,
	)
}

async function fetchPurchase(extractID: string): Promise<IExtractDocument<Product>> {
	const response = await axiosInstance.get(
		`/extract/panel/purchases-detail/${extractID}`,
	)

	return response.data
}

export function usePurchasesPage(extractID: string): UseQueryResult<any> {

	return useQuery([QUERY_KEYS.PURCHASE_DETAIL_DATA], async () => fetchPurchase(extractID))
}