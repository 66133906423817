
import { Editor, Element as SlateElement, Transforms } from 'slate'
import { AllChildrenTypesAndStyles, CustomEditor } from './slate'

export const LIST_TYPES = ['format-bullet-list', 'format-numbered-list']
export const TEXT_ALIGN_TYPES = [
	'format-left',
	'format-center',
	'format-right',
	'format-justified',
]
export const HOTKEYS: { [key: string]: AllChildrenTypesAndStyles } = {
  'mod+b': 'format-bold',
  'mod+i': 'format-italic',
  'mod+u': 'format-underline',
}

export function isBlockActive(
    editor: CustomEditor,
    name: AllChildrenTypesAndStyles,
) {
    const blockType = TEXT_ALIGN_TYPES.includes(name) ? 'align' : 'type'

    const { selection } = editor
    if (!selection) return false

    const [match] = Array.from(
        Editor.nodes(editor, {
            at: Editor.unhangRange(editor, selection),
            match: (n) =>
                !Editor.isEditor(n) &&
                SlateElement.isElement(n) &&
                n[blockType] === name,
        }),
    )

    return !!match
}

export function isMarkActive(editor: CustomEditor, name: AllChildrenTypesAndStyles) {
    const marks = Editor.marks(editor)
    return marks ? marks[name] === true : false
}

export function toggleBlock(editor: CustomEditor, name: AllChildrenTypesAndStyles) {
    const isActive = isBlockActive(editor, name)
    const isList = LIST_TYPES.includes(name)
    const isAlign = TEXT_ALIGN_TYPES.includes(name)

    Transforms.unwrapNodes(editor, {
        match: (n) =>
            !Editor.isEditor(n) &&
            SlateElement.isElement(n) &&
            LIST_TYPES.includes(n.type) &&
            !isAlign,
        split: true,
    })
    let newProperties: Partial<SlateElement>
    if (isAlign) {
        newProperties = {
            align: isActive ? undefined : name,
        }
    } else {
        newProperties = {
            type: isActive
                ? 'format-paragraph'
                : isList
                ? 'format-list-item'
                : name,
        }
    }
    Transforms.setNodes<SlateElement>(editor, newProperties)

    if (!isActive && isList) {
        const block = { type: name, children: [] }
        Transforms.wrapNodes(editor, block)
    }
}

export function toggleMark(editor: CustomEditor, name: AllChildrenTypesAndStyles) {
    const isActive = isMarkActive(editor, name)

    if (isActive) {
        Editor.removeMark(editor, name)
    } else {
        Editor.addMark(editor, name, true)
    }
}