import {
	QueryFunctionContext,
	useMutation,
	UseMutationResult,
	useQuery,
	UseQueryResult,
} from 'react-query'
import { IPaginationResponse } from '../interfaces/pagination'
import { QUERY_KEYS } from '../util/reactQuery'
import axiosInstance from './axiosInstance'
import { IBannerResponse, IFetchBannersRequest } from '../interfaces/banners'
import { AxiosError } from 'axios'
import { IError } from '../interfaces/error'
import { IPhotoUploadPayload } from '../interfaces/user'

async function fetchBanners(
	context: QueryFunctionContext<[unknown, IFetchBannersRequest]>,
): Promise<IPaginationResponse<IBannerResponse>> {
	const [_, params] = context.queryKey
	const { pageIndex, searchString, order, sort, maxItemsInPage } = params
	const response = await axiosInstance.post('/banner/get-page', {
		pageIndex: pageIndex,
		searchString: searchString,
		order,
		sort,
		maxItemsInPage: maxItemsInPage,
	})
	return response.data
}

export function useBannersPage({
	pageIndex,
	maxItemsInPage,
	searchString,
	order,
	sort,
}: IFetchBannersRequest): UseQueryResult<IPaginationResponse<IBannerResponse>> {
	const enabled = !!(pageIndex && maxItemsInPage)
	const result = useQuery(
		[
			QUERY_KEYS.BANNERS,
			{ maxItemsInPage, pageIndex, searchString, order, sort },
		],
		fetchBanners,
		{
			enabled,
		},
	)
	return result
}

export async function createBanner(title: string, photoURL: string) {
	const response = await axiosInstance.post('/banner', {
		title: title,
		photoURL: photoURL,
	})
	return response.data
}

export async function getBanner(bannerID: string): Promise<IBannerResponse> {
	const response = await axiosInstance.get(`/banner/${bannerID}`)
	return response.data
}

export async function updateBanner(
	bannerID: string,
	title: string,
	photoURL: string,
	status: string,
	positionQueue?: number,
): Promise<IBannerResponse> {
	const response = await axiosInstance.put('/banner', {
		bannerID: bannerID,
		title: title,
		photoURL: photoURL,
		status: status,
		positionQueue: positionQueue,
	})
	return response.data
}

export async function dropBanner(
	banners: IBannerResponse[],
): Promise<IBannerResponse[]> {
	const response = await axiosInstance.put('/banner/drop', {
		banners: banners,
	})
	return response.data
}

export async function deleteBanner(bannerID: string): Promise<void> {
	await axiosInstance.delete(`/banner/${bannerID}`)
}

async function photoUpload(payload: IPhotoUploadPayload): Promise<string> {
	const response = await axiosInstance.post(
		'/banner/upload',
		{ file: payload.file },
		{
			headers: { 'Content-Type': 'multipart/form-data' },
		},
	)

	return response.data.file
}

export function useMutationBannerUpload(): UseMutationResult<
	string,
	AxiosError<IError>,
	IPhotoUploadPayload
> {
	return useMutation(photoUpload)
}
