import styled, { css } from 'styled-components'
import { toRem } from '../../shared/styles/function'
import { theme } from '../../shared/styles/theme'

export const AudioContainer = styled.div`
	header {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin-top: 32px;
		margin-bottom: 20px;
		div {
			display: flex;
			align-items: center;

			.search-input {
				margin-left: 48px;
			}
		}
	}
`
export const ImageWrapper = styled.div`
	${({ theme }) => css`
		width: 46px;
		height: 46px;
		overflow: hidden;
		border-radius: 2px;
		background: ${theme.colors.light_gray};

		.report-image {
			width: 100%;
			height: 100%;
		}
	`}
`
export const TableImagePlaceholder = styled.div`
	${({ theme }) => css`
		width: 46px;
		height: 46px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 2px;
		background: ${theme.colors.off_white};
		border: 1px solid ${theme.colors.light_gray};
		border-radius: 4px;

		.placeholder-icon {
			width: ${toRem(20)};
			height: ${toRem(20)};
			color: ${theme.colors.greenish_grey_light};
		}
	`}
`

export const AvatarWrapper = styled.div`
	display: flex;
	align-items: center;
	padding: 16px 0px;
	.imageBox {
		width: 46px;
		height: 46px;
		border-radius: 4px;
		margin-right: 20px;

		display: flex;
		align-items: center;
		justify-content: center;
		img {
			width: 46px;
			height: 46px;
			object-fit: cover;
			border-radius: 4px;
		}
	}
`

export const CheckboxContainer = styled.div`
	display: flex;
	justify-content: center;
	padding-left: 20px;
	.checkbox {
		width: 16px;
		height: 16px;
		cursor: pointer;
	}
`

export const PlayerAudioTrueStyles = styled.div<{ audioPlayed: boolean }>`
	audio {
		border: 1.5px solid
			${({ audioPlayed }) => (audioPlayed ? theme.colors.green : 'transparent')};
		border-radius: 30px;
	}
	audio::-webkit-media-controls-panel {
		background-color: ${({ audioPlayed }) => (audioPlayed ? '#e8fdf1' : '')};
	}
`

export const ButtonWrapper = styled.div`
	margin-left: auto;
`
export const SelectContainer = styled.div`
	display: flex;

	.custom-select {
		position: relative;
		.custom-arrow {
			position: absolute;
			top: 12px;
			left: 277px;
			@media screen and (max-width: 993px) {
				left: 80px;
				top: 12px;
			}
			display: block;
			pointer-events: none;
			color: ${theme.colors.greenish_grey_light};
		}
	}
	.SelectStyle {
		font-family: ${theme.fonts.radikal_regular};
		font-size: 0.875rem;
		appearance: none;
		width: 306px;
		height: 46px;
		@media screen and (max-width: 993px) {
			width: 100px;
		}
		background: #ffffff;

		border-radius: 4px;
		border: 1px solid #ebf0ed;

		cursor: pointer;
		margin-left: 10%;
		padding-left: 10px;

		color: ${theme.colors.greenish_grey_light};
	}
`
