import React from 'react'
import BonusWord from '../../shared/components/BonusWord'
import NewBonusWord from '../../shared/components/NewBonusWord'
import SecondaryInput from '../../shared/components/SecondaryInput'
import Table from '../../shared/components/Table'
import {
	ContentWrapper,
	EBonusContainer,
	Header,
	TableWrapper,
	TitlePage,
} from './styles'
import { IViewProps } from './types'

function EBonus(props: IViewProps) {
	const {
		handleSearchChange,
		searchString,
		tableColumns,
		tableRows,
		isLoading,
		currentData,
		handleRefetchData,
		handleSortColumn,
		sortBy,
		navProps,
		bonusPage,
	} = props

	return (
		<EBonusContainer>
			<Header>
				<TitlePage>E-Bônus</TitlePage>
				{currentData ? (
					<BonusWord
						totalReward={currentData.beatCoins}
						hits={currentData.hits}
						name={currentData?.name}
						reward={currentData.reward}
						refetch={handleRefetchData}
					/>
				) : (
					<NewBonusWord refetch={handleRefetchData} />
				)}
			</Header>
			<ContentWrapper>
				<header>
					<TitlePage data-testid='title-page'>Histórico de Palavras</TitlePage>
					<SecondaryInput
						className='search-input'
						placeholder='Buscar'
						value={searchString}
						onChange={handleSearchChange}
					/>
				</header>
				<TableWrapper>
					<Table
						pageSize={5}
						columns={tableColumns as any}
						data={tableRows}
						isLoading={isLoading}
						getSelectedColumnAcessorAndOrder={handleSortColumn}
						sortBy={sortBy}
						navProps={navProps}
						extractPage={{
							page: bonusPage.page,
							totalDocs: bonusPage.totalDocs,
						}}
					/>
				</TableWrapper>
			</ContentWrapper>
		</EBonusContainer>
	)
}

export default EBonus
