import styled from 'styled-components'
import { css } from 'styled-components'

export const Container = styled.div`
	${({ theme }) => css`
		background-color: ${theme.colors.white};
	`}
`

export const SlateEditorToolsWrapper = styled.div<{ borderActive: boolean }>`
	${({ theme, borderActive }) => css`
		border: 1px solid
			${borderActive ? theme.colors.green : theme.colors.off_white};
		border-radius: 4px 4px 0 0;
		padding: 4px;
	`}
`

interface BoxWrapperProps {
	borderActive: boolean
	minWidth?: string
	minHeight?: string
}
export const SlateTextBoxWrapper = styled.div<BoxWrapperProps>`
	${({ theme, borderActive, minWidth, minHeight }) => css`
		border: 1px solid
			${borderActive ? theme.colors.green : theme.colors.off_white};
		border-radius: 0 0 4px 4px;
		padding: 12px 16px;
		min-height: ${minHeight ? minHeight : '120px'};
		min-width: ${minWidth ? minWidth : '100%'};
	`}
`

export const ElementContainerStyles = styled.div`
	${({ theme }) => css`
		color: ${theme.colors.dark_graffiti};
		line-height: '22px';
		font-size: 14px;
		font-family: ${theme.fonts.radikal_regular};

		strong {
			font-family: ${theme.fonts.radikal_bold};
		}

		li {
			margin-left: 16px;
		}

		.format-left {
			text-align: left;
		}
		.format-center {
			text-align: center;
		}
		.format-right {
			text-align: right;
		}
		.format-justified {
			text-align: justify;
		}
	`}
`
