import React, { createElement, useEffect, useState } from 'react'
import { useDebounce } from 'use-debounce'
import { IBonusPage, IEBonus } from '../../shared/interfaces/ebonus'
import {
	useCurrentEBonusPage,
	useEBonusPage,
} from '../../shared/service/bonus.service'
import { ITableColumn, IViewProps } from './types'

import { format } from 'date-fns'

import {
	IAcessorAndOrder,
	ITableNavProps,
} from '../../shared/components/Table/types'
import EBonus from './view'

function EBonusContainer(): JSX.Element {
	const [searchString, setSearchString] = useState('')
	const [tableRows, setTableRows] = useState<any[]>([])
	const [searchDebounce] = useDebounce(searchString, 1000)

	const [sortBy, setSortBy] = useState<IAcessorAndOrder>({
		acessor: 'startDate',
		order: -1,
	})

	const [bonusPage, setBonusPage] = useState<IBonusPage>({
		page: 1,
		numberOfPages: 0,
		totalDocs: 0,
	})

	const {
		data,
		status,
		refetch: pageRefetch,
	} = useEBonusPage({
		maxItemsInPage: 5,
		pageIndex: bonusPage.page,
		searchString: searchDebounce,
		order: sortBy.order,
		sort: sortBy.acessor,
	})
	const { data: currentData, refetch: currentDataRefetch } =
		useCurrentEBonusPage()

	function handleSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
		setSearchString(event.target.value)
	}

	function handleSortColumn(acessor: string, order: number) {
		if (order === 0) {
			setSortBy({
				acessor: 'name',
				order: -1,
			})
			return
		}
		setSortBy({
			acessor,
			order,
		})
	}

	function goToPage(pageIndex: number) {
		setBonusPage((state) => ({ ...state, page: pageIndex + 1 }))
	}

	const tableColumns: ITableColumn[] = [
		{ Header: 'Palavra Bônus', accessor: 'name', sortType: 'basic' },
		{ Header: 'Start', accessor: 'startDate', sortType: 'basic' },
		{ Header: 'Stop', accessor: 'endDate', sortType: 'basic' },
		{ Header: 'Acertos', accessor: 'hits', sortType: 'basic' },
		{
			Header: 'Beatcoins Gerados',
			accessor: 'beatCoins',
			sortType: 'basic',
		},
	]

	const navProps: ITableNavProps = {
		nextPage: (pageIndex) => goToPage(pageIndex),
		previousPage: (pageIndex) => goToPage(pageIndex),
		gotoPage: (pageIndex) => goToPage(pageIndex),
		pageCount: bonusPage.numberOfPages,
		pageIndex: bonusPage.page - 1,
		totalDocs: bonusPage.totalDocs,
	}

	function handleRefetchData() {
		currentDataRefetch()
		pageRefetch()
	}

	function renderTextTable(str: string) {
		return <p>{str}</p>
	}

	const renderTableElements = (bonus: IEBonus) => {
		function formatDate(date: Date | undefined) {
			return date ? format(new Date(date), 'dd/MM/yyyy - HH:mm') : ''
		}

		return {
			name: renderTextTable(bonus.name.toUpperCase()),
			startDate: renderTextTable(String(formatDate(bonus.startDate))),
			endDate: renderTextTable(String(formatDate(bonus.endDate))),
			hits: renderTextTable(String(bonus.hits || '0')),
			beatCoins: renderTextTable(String(bonus.beatCoins || '0')),
		}
	}

	function parseData() {
		if (!data) return

		const rows = data?.docs.map(renderTableElements)

		if (!rows) return

		setTableRows(rows)

		setBonusPage({
			numberOfPages: data?.totalPages,
			page: data?.page || 1,
			totalDocs: data?.totalDocs,
		})
	}

	useEffect(parseData, [data, searchDebounce])

	const viewProps: IViewProps = {
		searchString,
		handleSearchChange,
		tableColumns,
		tableRows,
		isLoading: status === 'loading',
		currentData,
		handleRefetchData,
		handleSortColumn,
		sortBy,
		navProps,
		bonusPage,
	}

	return createElement(EBonus, viewProps)
}

export default EBonusContainer
