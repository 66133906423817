import {
	QueryFunctionContext,
	useInfiniteQuery,
	UseInfiniteQueryResult,
	useQuery,
	UseQueryResult,
} from 'react-query'
import {
	IActivateUserPayload,
	IDeleteUserPayload,
	IFetchUsersResponse,
	IInviteUserPayload,
	IPhotoUploadPayload,
	IRecoverPasswordPayload,
	IUpdatePasswordPayload,
	IUpdateUserPayload,
	IUser,
	IUserDetail,
	UserPayload,
} from '../interfaces/user'
import { QUERY_KEYS } from '../util/reactQuery'
import axiosInstance from './axiosInstance'

import { AxiosError, AxiosResponse } from 'axios'
import { useMutation, UseMutationResult } from 'react-query'
import { IError } from '../interfaces/error'
import { IPaginationResponse, PaginatedRequest } from '../interfaces/pagination'

async function fetchManagersPage(
	context: QueryFunctionContext<[unknown, IFetchUsersRequest]>,
): Promise<IPaginationResponse<IUser>> {
	const [_, params] = context.queryKey
	const { maxItemsInPage, pageIndex, searchString, order, sort } = params
	const response = await axiosInstance.post('/manager/get-page', {
		pageIndex,
		searchString,
		order,
		sort,
		maxItemsInPage,
	})

	return response.data
}

export function useManagersPage({
	maxItemsInPage,
	pageIndex,
	searchString,
	order,
	sort,
}: IFetchUsersRequest): UseQueryResult<IPaginationResponse<IUser>> {
	const result = useQuery(
		[
			QUERY_KEYS.USERS_PAGE,
			{ maxItemsInPage, pageIndex, searchString, order, sort },
		],
		fetchManagersPage,
	)
	return result
}

async function fetchPresenters(): Promise<IFetchUsersResponse> {
	const response = await axiosInstance.get('/manager/list/presenters')

	return response.data
}

export function usePresentersPage(): UseQueryResult<IFetchUsersResponse> {
	return useQuery([QUERY_KEYS.PRESENTERS], async () => fetchPresenters(), {
		enabled: true,
	})
}

async function inviteUser(payload: IInviteUserPayload) {
	return axiosInstance.get(`manager/re-invite/${payload.userID}`)
}

export function useMutationInviteUser(): UseMutationResult<
	AxiosResponse<any, any>,
	AxiosError<IError>,
	IInviteUserPayload
> {
	return useMutation(inviteUser)
}

async function recoverPassword(payload: IRecoverPasswordPayload) {
	return axiosInstance.post('/manager/recover-password', {
		email: payload.email,
	})
}

export function useMutationRecoverPassword(): UseMutationResult<
	AxiosResponse<any, any>,
	AxiosError<IError>,
	IRecoverPasswordPayload
> {
	return useMutation(recoverPassword)
}

async function createUser(payload: UserPayload) {
	const response = await axiosInstance.post('/manager', payload)

	return response.data
}

export function useMutationCreateUser(): UseMutationResult<
	IUser,
	AxiosError<IError>,
	UserPayload
> {
	return useMutation(createUser)
}

async function photoUpload(payload: IPhotoUploadPayload): Promise<string> {
	const response = await axiosInstance.post(
		'/manager/upload',
		{ file: payload.file },
		{
			headers: { 'Content-Type': 'multipart/form-data' },
		},
	)

	return response.data.file
}

export function useMutationPhotoUpload(): UseMutationResult<
	string,
	AxiosError<IError>,
	IPhotoUploadPayload
> {
	return useMutation(photoUpload)
}

async function updateUser(payload: IUpdateUserPayload): Promise<IUser> {
	const response = await axiosInstance.patch(
		`/manager/${payload.userID}`,
		payload.data,
	)

	return response.data
}

export function useMutationUpdateUser(): UseMutationResult<
	IUser,
	AxiosError<IError>,
	IUpdateUserPayload
> {
	return useMutation(updateUser)
}

async function deleteUser(payload: IDeleteUserPayload) {
	return axiosInstance.delete(`/manager/${payload.userID}`)
}

export function useMutationDeleteUser(): UseMutationResult<
	AxiosResponse<any, any>,
	AxiosError<IError>,
	IDeleteUserPayload
> {
	return useMutation(deleteUser)
}

async function deleteListener(payload: IDeleteUserPayload) {
	return axiosInstance.delete(`/user/${payload.userID}`)
}

export function useMutationDeleteListener(): UseMutationResult<
	AxiosResponse<any, any>,
	AxiosError<IError>,
	IDeleteUserPayload
> {
	return useMutation(deleteListener)
}

async function activateUser(
	payload: IActivateUserPayload,
): Promise<IFetchUsersResponse> {
	return axiosInstance.patch(`/manager/activate/${payload.resetToken}`, {
		password: payload.password,
	})
}

export function useMutationActiveUser(): UseMutationResult<
	IFetchUsersResponse,
	AxiosError<IError>,
	IActivateUserPayload
> {
	return useMutation(activateUser)
}

async function updatePassword(
	payload: IUpdatePasswordPayload,
): Promise<IFetchUsersResponse> {
	const response = await axiosInstance.patch(
		`/manager/password/${payload.resetToken}`,
		{
			password: payload.password,
		},
	)

	return response.data
}

export function useMutationUpdatePassword(): UseMutationResult<
	IFetchUsersResponse,
	AxiosError<IError>,
	IUpdatePasswordPayload
> {
	return useMutation(updatePassword)
}

async function fetchUsers({
	maxItemsInPage,
	pageIndex,
	searchString,
	order,
	sort,
}: IFetchUsersRequest): Promise<IPaginationResponse<IUser>> {
	const response = await axiosInstance.post('/user/get-page', {
		pageIndex,
		searchString,
		order,
		sort,
		maxItemsInPage,
	})

	return response.data
}

// eslint-disable-next-line @typescript-eslint/ban-types
 type IFetchUsersRequest = PaginatedRequest<{}>

export function useCustomerUsersPage({
	maxItemsInPage,
	pageIndex,
	searchString,
	order,
	sort,
	
}: IFetchUsersRequest): UseInfiniteQueryResult<IPaginationResponse<IUser>> {
	return useInfiniteQuery(
		[
			QUERY_KEYS.CUSTOMER_USERS,
			{ maxItemsInPage, pageIndex, searchString, order, sort },
		],
		async ({ queryKey }) => {
			const [_key, params] = queryKey

			const { maxItemsInPage, pageIndex, searchString, order, sort } =
				params as IFetchUsersRequest

			return fetchUsers({
				maxItemsInPage,
				pageIndex,
				searchString,
				order,
				sort,
			})
		},
	)
}

async function fetchCustomersDetail(customerID: string): Promise<IUserDetail> {
	const response = await axiosInstance.get(`/user/detail/${customerID}`)

	return response.data
}

export function useCustomerDetail({
	customerID,
}: {
	customerID: string
}): UseQueryResult<IUserDetail> {
	return useQuery(
		[QUERY_KEYS.CUSTOMER_DETAIL],
		async () => fetchCustomersDetail(customerID),
		{
			enabled: true,
		},
	)
}

async function fetchUserManagerById(userId: string): Promise<IUser> {
	const response = await axiosInstance.get(`/manager/${userId}`)
	return response.data
}

export function useUserManagerById(userId: string): UseQueryResult<IUser> {
	return useQuery(
		[QUERY_KEYS.USER_MANAGER_DETAIL, userId],
		() => fetchUserManagerById(userId),
		{
			enabled: Boolean(userId),
		},
	)
}
