import styled, {css} from 'styled-components'


export const ElementContainerStyles = styled.div`
    ${({ theme }) => css`
        color: ${theme.colors.greenish_grey_medium};
        line-height: '22px';
        font-size: 14px;
        font-family: ${theme.fonts.radikal_regular};

        strong {
            font-family: ${theme.fonts.radikal_bold};
        }

        li {
            margin-left: 16px;
        }

        .format-left {
            text-align: left;
        }
        .format-center {
            text-align: center;
        }
        .format-right {
            text-align: right;
        }
        .format-justified {
            text-align: justify;
        }
    `}
`
