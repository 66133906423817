import { PurchaseAnalyticsData } from '../interfaces/extract'
import { UserRolesEnum, UserStatusEnum } from '../interfaces/user'

export const ROLES_ENUM: { [key in UserRolesEnum]: string } = {
	ADMIN: 'ADMIN',
	PRESENTER: 'APRESENTADOR',
	EDITOR: 'EDITOR',
}

export const ROLES_ENUM_STATUS: { [key in string]: string } = {
	ACTIVE: 'Ativo',
	PENDING: 'Pendente',
	DELETED: 'Deletado',
}

export const TAB_MENU_OPTIONS: { [key in PurchaseAnalyticsData]: string } = {
	REDEEMED: 'Resgatados',
	NOT_REDEEMED: 'A Resgatar',
	DEADLINE_END: 'Expirados',
}
