import styled from 'styled-components'

export const FloatLabelInputWrapper = styled.div`
	position: relative;
	display: flex;

	align-items: center;
	img.icon {
		right: 16px;
		position: absolute;
		width: 20px;
		height: 20px;
	}
`
