import {
	ChangeEvent,
	FormEvent,
	createElement,
	useEffect,
	useState,
} from 'react'
import { Route, Router, useHistory, useParams } from 'react-router-dom'
import {
	PROGRAM_DATE,
	programDateValidation,
} from '../../shared/components/ProgramModal/util'
import { IImageUpload } from '../../shared/interfaces/image'
import { IScheduleDate, ProgramPayload } from '../../shared/interfaces/program'
import { ISelectOption } from '../../shared/interfaces/select'
import {
	useDeleteProgram,
	useMutationUpdateProgram,
	useProgramsDetail,
	useProgramsPage,
} from '../../shared/service/program.service'
import {
	useMutationPhotoUpload,
	usePresentersPage,
} from '../../shared/service/user.service'
import { INITIAL_STATE_IMAGE_UPLOAD } from '../../shared/util/image'
import { unravelArrayJSON } from '../../shared/util/select'
import {
	handleErrorToaster,
	handleSuccessToaster,
} from '../../shared/util/toaster'
import { routesEnum } from '../Routes/enum'
import { IViewProps } from './types'
import { ProgramDetail } from './view'
import { toast } from 'react-toastify'

export interface IRouteParams {
	programID: string
}

function ProgramDetailContainer(): JSX.Element {
	const [isVisible, setIsVisible] = useState(false)
	const handleScroll = () => {
		const scrollTop = window.pageYOffset

		if (scrollTop > 300) {
			setIsVisible(true)
		} else {
			setIsVisible(false)
		}
	}
	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		})
	}
	window.addEventListener('scroll', handleScroll)
	const [image, setImage] = useState<IImageUpload>(INITIAL_STATE_IMAGE_UPLOAD)
	const [artworkImage, setImageArtwork] = useState<IImageUpload>(
		INITIAL_STATE_IMAGE_UPLOAD,
	)
	const [programName, setProgramName] = useState('')
	const [selectedPresenters, setSelectedPresenters] = useState<string[]>([])
	const [description, setDescription] = useState('')
	const [viewErrorsData, setViewErrorsData] = useState<string[]>([])
	const [isLoading, setIsLoading] = useState(false)
	const [canSubmit, setCanSubmit] = useState(false)
	const [programDates, setProgramDates] = useState<IScheduleDate[]>([
		PROGRAM_DATE,
	])

	const { data } = usePresentersPage()

	const updateProgramMutation = useMutationUpdateProgram()
	const photoUploadMutation = useMutationPhotoUpload()

	const history = useHistory()

	const { programID }: IRouteParams = useParams()

	const [deleteModalOpen, setDeleteModalOpen] = useState(false)
	const deleteProgramMutation = useDeleteProgram()
	const {
		data: program,
		status,
		refetch,
	} = useProgramsPage({
		maxItemsInPage: 5,
		pageIndex: 1,
		searchString: '',
		order: 1,
		sort: 'title',
	})

	function handleProgramChange(event: React.ChangeEvent<HTMLInputElement>) {
		setProgramName(event.target.value)
	}

	function handleProgramDescription(
		event: React.ChangeEvent<HTMLInputElement>,
	) {
		setDescription(event.target.value)
	}

	function handleOpenModal() {
		setDeleteModalOpen(true)
		scrollToTop()
	}

	function handleCloseModal() {
		setDeleteModalOpen(false)
	}

	async function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
		const selectedImage = event.target?.files?.item(0)

		if (!selectedImage) return

		const imageURL = URL.createObjectURL(selectedImage)

		setImage({ url: imageURL, file: selectedImage, overwrite: true })

		event.target.value = ''
	}

	async function handleInputArtWorkChange(
		event: ChangeEvent<HTMLInputElement>,
	) {
		const selectedImage = event.target?.files?.item(0)

		if (!selectedImage) return

		const imageURL = URL.createObjectURL(selectedImage)

		setImageArtwork({ url: imageURL, file: selectedImage, overwrite: true })

		event.target.value = ''
	}

	const presentersData: ISelectOption[] = data
		? data.map((presenter) => ({
				value: presenter._id,
				label: presenter.name,
				// eslint-disable-next-line no-mixed-spaces-and-tabs
		  }))
		: []

	function handlePlusProgramDate() {
		setProgramDates([...programDates, PROGRAM_DATE])
	}

	function handleGoBack() {
		history.goBack()
	}

	async function handleSubmit(event: FormEvent<HTMLButtonElement>) {
		event.preventDefault()

		const errorsCount = handleViewError()

		if (errorsCount) return

		try {
			setIsLoading(true)

			let coverImage = ''
			let artWorkImageCover = ''

			if (image.file) {
				coverImage = await photoUploadMutation.mutateAsync({ file: image.file })
			}

			if (artworkImage.file) {
				artWorkImageCover = await photoUploadMutation.mutateAsync({
					file: artworkImage.file,
				})
			}

			const presenters = unravelArrayJSON(selectedPresenters)

			const presentersID = presenters.map((presenter) => presenter.value)

			const payload: ProgramPayload = {
				title: programName,
				presenters: presentersID,
				scheduleDates: programDates,
				description,
			}

			if (image.overwrite) {
				payload.photoURL = coverImage
			}

			if (artworkImage.overwrite) {
				payload.artworkURL = artWorkImageCover
			}

			const updatedProgram = await updateProgramMutation.mutateAsync({
				programID,
				program: payload,
			})

			if (!updatedProgram) return
			// TODO: FIX IT
			handleSuccessToaster('Programa atualizado com sucesso!')
			history.push(routesEnum.PROGRAMS)
		} finally {
			setIsLoading(false)
		}
	}

	function handleViewError() {
		const errors: string[] = []

		const programSchedule = programDateValidation(programDates)

		if (!programName) {
			errors.push('necessário preencher o campo de nome do programa')
		}

		if (!selectedPresenters.length) {
			errors.push('necessário selecionar os presentadores')
		}

		if (programSchedule) {
			errors.push('horário de programas não validos')
		}

		setViewErrorsData(errors)

		return errors.length
	}

	function handleSelectPresentersChange(selectedOptions: string[]) {
		setSelectedPresenters(selectedOptions)
	}

	function cleanImageSeletor() {
		setImage({ ...INITIAL_STATE_IMAGE_UPLOAD, overwrite: true })
	}

	function cleanImageArtWorkSeletor() {
		setImageArtwork({ ...INITIAL_STATE_IMAGE_UPLOAD, overwrite: true })
	}

	function handleLessProgramDate(index: number) {
		const listUpdated = [...programDates]
		listUpdated.splice(index, 1)
		setProgramDates(listUpdated)
	}

	const changeProgramDate = (
		index: number,
		programSelectedDate: IScheduleDate,
	) => {
		programDates[index] = programSelectedDate
		setProgramDates([...programDates])
	}
	function handleDeleteProgram() {
		try {
			deleteProgramMutation.mutate({ programID: programData?._id || '' })
			handleSuccessToaster('Programa deletado com sucesso')
			history.push(routesEnum.PROGRAMS)
			refetch()
		} catch (error) {
			handleErrorToaster('Erro ao deletar programa')
		}
	}

	function handleChangeCanSubmit() {
		const isProgramEnable = !!programName.trim()

		const isPresentersEnable = !!selectedPresenters.length

		const isProgramDateEnable = !!programDates.length

		setCanSubmit(isProgramEnable && isPresentersEnable && isProgramDateEnable)
	}
	const { data: programData } = useProgramsDetail({ programID })

	function handleFetch() {
		if (!programData) return
		const presentersID = programData.presenters.map((presenter) => {
			return JSON.stringify({
				value: presenter._id,
				label: presenter.name,
			})
		})

		setSelectedPresenters(presentersID)
		setProgramName(programData.title)
		setImage((props) => ({ ...props, url: programData.photoURL || '' }))
		setImageArtwork((props) => ({
			...props,
			url: programData.artworkURL || '',
		}))
		setProgramDates(programData.scheduleDates)
		setDescription(programData.description || '')
	}

	useEffect(handleFetch, [programID, programData])
	useEffect(handleChangeCanSubmit, [
		programName,
		selectedPresenters,
		programDates,
	])

	const viewProps: IViewProps = {
		handleProgramChange,
		image,
		handleInputChange,
		cleanImageSeletor,
		handleSelectPresentersChange,
		presentersData,
		selectedPresenters,
		programDates,
		handlePlusProgramDate,
		handleLessProgramDate,
		changeProgramDate,
		description,
		programName,
		handleProgramDescription,
		viewErrorsData,
		canSubmit,
		isLoading,
		handleSubmit,
		handleDeleteProgram,
		handleGoBack,
		handleInputArtWorkChange,
		artworkImage,
		cleanImageArtWorkSeletor,
		deleteModalOpen,
		handleCloseModal,
		handleOpenModal,
	}

	return createElement(ProgramDetail, viewProps)
}

export default ProgramDetailContainer
