import { ChangeEvent, createElement, useEffect, useState } from 'react'
import { IViewProps } from './types'
import { NewUserView } from './view'
import {
	useMutationCreateUser,
	useMutationPhotoUpload,
} from '../../shared/service/user.service'
import { IImageUpload } from '../../shared/interfaces/image'
import { INITIAL_STATE_IMAGE_UPLOAD } from '../../shared/util/image'
import { unravelArrayJSON } from '../../shared/util/select'
import { UserPayload, UserRolesData } from '../../shared/interfaces/user'
import { handleSuccessToaster } from '../../shared/util/toaster'
import { ISelectOption } from '../../shared/interfaces/select'
import { useHistory } from 'react-router-dom'
import { checkEmail } from '../../shared/util/validators'

function NewUser(): JSX.Element {
	const [selectedRoles, setSelectedRoles] = useState<string[]>([])
	const [isPresenter, setIsPresenter] = useState(Boolean)
	const [userName, setUserName] = useState('')
	const [email, setEmail] = useState('')
	const [description, setDescription] = useState('')
	const [facebook, setFacebook] = useState('')
	const [instagram, setInstagram] = useState('')
	const [twitter, setTwitter] = useState('')
	const [maxLength, setMaxLength] = useState(500)
	const [image, setImage] = useState<IImageUpload>(INITIAL_STATE_IMAGE_UPLOAD)
	const [deleteUserModalIsActive, setDeleteUserModalIsActive] = useState(false)

	const history = useHistory()

	const [canSubmit, setCanSubmit] = useState(false)

	const [isLoading, setIsLoading] = useState(false)

	const createUserMutation = useMutationCreateUser()
	const uploadPhotoMutation = useMutationPhotoUpload()

	function handleSelectChange(selectedOptions: string[]) {
		setSelectedRoles(selectedOptions)
	}

	function handleChangeUserName(event: React.ChangeEvent<HTMLInputElement>) {
		setUserName(event.target.value)
	}

	function handleChangeEmail(event: React.ChangeEvent<HTMLInputElement>) {
		setEmail(event.target.value)
	}

	function handleChangeDescription(
		event: React.ChangeEvent<HTMLTextAreaElement>,
	) {
		if (maxLength && event.target.value.length > maxLength) {
			event.target.value = event.target.value.slice(0, maxLength)
		}

		setDescription(event.target.value)
	}

	function handleChangeFacebook(event: React.ChangeEvent<HTMLInputElement>) {
		setFacebook(event.target.value)
	}

	function handleChangeInstagram(event: React.ChangeEvent<HTMLInputElement>) {
		setInstagram(event.target.value)
	}

	function handleChangeTwitter(event: React.ChangeEvent<HTMLInputElement>) {
		setTwitter(event.target.value)
	}

	function handleCleanFields() {
		setUserName('')
		setEmail('')
		setSelectedRoles([])
		setImage(INITIAL_STATE_IMAGE_UPLOAD)
		setDescription('')
		setFacebook('')
		setInstagram('')
		setTwitter('')
	}

	function handleCloseModal() {
		handleCleanFields()
		setDeleteUserModalIsActive(false)
	}

	function handleGoBack() {
		history.goBack()
	}

	async function handleSubmit() {
		try {
			setIsLoading(true)
			const roleParsed = unravelArrayJSON(selectedRoles)

			const rolesID = roleParsed.map((roles) => roles.value)

			const socials = {
				facebook: facebook,
				instagram: instagram,
				twitter: twitter,
			}
			const payloadData: UserPayload = {
				name: userName,
				email: email,
				roles: rolesID,
				description: description,
				socialNetwork: socials,
			}

			const coverImage = image.file
				? await uploadPhotoMutation.mutateAsync({ file: image.file })
				: ''

			if (image.overwrite) {
				payloadData.photoURL = coverImage
			}

			const createUserResponse = await createUserMutation.mutateAsync(
				payloadData,
			)
			if (!createUserResponse) return
			// TODO: FIX IT
			handleSuccessToaster('Usuário criado com sucesso!')
			handleCleanFields()
		} finally {
			handleCloseModal()
			setIsLoading(false)
			history.goBack()
		}
	}

	async function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
		const selectedImage = event.target?.files?.item(0)

		if (!selectedImage) return

		const imageURL = URL.createObjectURL(selectedImage)

		setImage({ url: imageURL, file: selectedImage, overwrite: true })

		event.target.value = ''
	}

	function cleanImageSeletor() {
		setImage({ file: null, url: '', overwrite: true })
	}

	function handleCloseDeleteUserModal() {
		setDeleteUserModalIsActive(false)
	}

	function handleOpenDeleteUserModal() {
		setDeleteUserModalIsActive(true)
	}

	const userRolesSelectData: ISelectOption[] = UserRolesData.map((roles) => {
		return {
			value: roles,
			label: roles,
		}
	})

	function handleValidateFields() {
		const userNameIsValid = !!userName

		const emailIsValid = checkEmail(email)

		const isSelectedRolesValid = !!selectedRoles.length

		setCanSubmit(userNameIsValid && emailIsValid && isSelectedRolesValid)
	}

	useEffect(handleValidateFields, [userName, email, selectedRoles])

	useEffect(() => {
		const selectedPresenter = selectedRoles.some((role) => {
			const parsedRole = JSON.parse(role)
			return parsedRole.value === 'PRESENTER'
		})
		setIsPresenter(selectedPresenter)
	}, [selectedRoles])

	const viewProps: IViewProps = {
		handleSelectChange,
		handleChangeUserName,
		handleInputChange,
		cleanImageSeletor,
		handleOpenDeleteUserModal,
		handleCloseDeleteUserModal,
		handleSubmit,
		handleChangeEmail,
		selectedRoles,
		image,
		userName,
		email,
		facebook,
		instagram,
		twitter,
		userRolesSelectData,
		isLoading,
		canSubmit,
		handleGoBack,
		isPresenter,
		description,
		handleChangeDescription,
		handleChangeFacebook,
		handleChangeInstagram,
		handleChangeTwitter,
	}

	return createElement(NewUserView, viewProps)
}

export default NewUser
