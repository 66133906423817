import React, { Fragment } from 'react'
import Modal from 'react-modal'
import {
	ButtonWrapper,
	customStyles,
	Header,
	InputWrapper,
	ModalSeparator,
	ModalTitle,
	UploadImageButtonWrapper,
} from './styles'
import { IViewProps } from './types'

import { X } from 'react-feather'
import { UploadImageButton } from '../UploadImageButton'
import { Input } from '../Input'
import { Button } from '../'
import Loading from '../Loading'

function ModalMusic(props: IViewProps) {
	const {
		isOpen,
		handleCloseModal,
		image,
		handleInputChange,
		cleanImageSeletor,
		title,
		interpreter,
		canSubmit,
		handleUpdateMusic,
		isLoading,
	} = props

	const showImageInfo = !!image?.file && !!image?.url

	return (
		<Fragment>
			<Modal
				isOpen={isOpen}
				onRequestClose={handleCloseModal}
				style={customStyles}
			>
				<Header>
					<ModalTitle>Detalhes da Música</ModalTitle>

					<X
						className='close-icon'
						onClick={handleCloseModal}
						data-testid='button-close'
					/>
				</Header>
				<ModalSeparator />

				<UploadImageButtonWrapper>
					<UploadImageButton
						label='Upload da Foto da Capa'
						onChange={handleInputChange}
						imageClean={cleanImageSeletor}
						showInfo={showImageInfo}
						image={image}
					/>
				</UploadImageButtonWrapper>

				<InputWrapper>
					<Input value={title} disabled={true} />

					<Input
						value={interpreter}
						style={{ marginTop: 16 }}
						disabled={true}
					/>
				</InputWrapper>

				<ButtonWrapper>
					<Button.Root
						isLoading={isLoading}
						onClick={handleUpdateMusic}
						disabled={!canSubmit}
						spacing='12x24'
					>
						<Button.Text text='Salvar Alterações' />
					</Button.Root>
				</ButtonWrapper>
			</Modal>
		</Fragment>
	)
}

export default ModalMusic
