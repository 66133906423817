import { useMutation, useQuery } from 'react-query'
import axiosInstance from './axiosInstance'
import { IRegulationRequest } from '../interfaces/regulation'

async function createRegulation(reqBody: IRegulationRequest): Promise<any> {
	const response = await axiosInstance.post('/regulation', reqBody)
	return response.data
}

export function useCreateRegulation() {
	return useMutation(createRegulation)
}

async function fetchRegulation() {
	const response = await axiosInstance.get('/regulation')
	return response.data
}

export function useFetchRegulation() {
	return useQuery('regulation', fetchRegulation)
}
