import React from 'react'
import {
	BackgroundImageWrapper,
	FormContainer,
	FormTitle,
	FormWrapper,
	InputWrapper,
	RegisterPasswordWrapper,
} from './styles'

import EducadoraLogo from '../../assets/icons/educadora-horizontal.svg'
import { Input } from '../../shared/components/Input'

import { Button } from '../../shared/components/PromotionButton'
import Loading from '../../shared/components/Loading'
import { IViewProps } from './types'

import { Link } from 'react-router-dom'
import { routesEnum } from '../Routes/enum'
import { ArrowLeft } from 'react-feather'

export function Activate(props: IViewProps) {
	const {
		handleSubmit,
		handlePrimaryPasswordChange,
		handleSecondaryPasswordChange,
		primaryPassword,
		secondaryPassword,
		loading,
		handleRegisterPassword,
		canSubmit,
	} = props

	return (
		<RegisterPasswordWrapper>
			<BackgroundImageWrapper>
				<img src={EducadoraLogo} />
			</BackgroundImageWrapper>

			<FormContainer>
				<FormWrapper onSubmit={handleSubmit}>
					<header>
						<Link to={routesEnum.LOGIN}>
							<ArrowLeft className='arrow-left' />
							Voltar
						</Link>
					</header>

					<FormTitle>Cadastre uma Senha de acesso à sua conta</FormTitle>

					<p>
						Para continuar com seu Login, precisamos que você cadastre uma senha
						de acesso.
					</p>

					<InputWrapper>
						<Input
							className='password'
							value={primaryPassword}
							type='password'
							placeholder='Cadastrar Senha'
							onChange={handlePrimaryPasswordChange}
						/>

						<Input
							className='secondary-password'
							value={secondaryPassword}
							type='password'
							placeholder='Repetir Senha'
							onChange={handleSecondaryPasswordChange}
						/>
					</InputWrapper>

					<div className='button-wrapper'>
						{!loading ? (
							<Button
								onClick={handleRegisterPassword}
								disabled={!canSubmit}
								type='submit'
								label='Cadastrar'
							/>
						) : (
							<div className='loading'>
								<Loading />
							</div>
						)}
					</div>
				</FormWrapper>
			</FormContainer>
		</RegisterPasswordWrapper>
	)
}
