import React from 'react'
import {
	BackgroundImageWrapper,
	FormContainer,
	FormTitle,
	FormWrapper,
	InputWrapper,
	RecoverPasswordWrapper,
} from './styles'
import { IViewProps } from './types'

import { Input } from '../../shared/components/Input'
import { Button } from '../../shared/components/PromotionButton'
import Loading from '../../shared/components/Loading'

import EducadoraLogo from '../../assets/icons/educadora-horizontal.svg'
import { ArrowLeft } from 'react-feather'

import { Link } from 'react-router-dom'
import { routesEnum } from '../Routes/enum'

export function RecoverPassword(props: IViewProps) {
	const { loading, email, handleEmailChange, canSubmit, handleSubmit } = props

	return (
		<RecoverPasswordWrapper>
			<BackgroundImageWrapper>
				<img src={EducadoraLogo} />
			</BackgroundImageWrapper>

			<FormContainer>
				<FormWrapper onSubmit={handleSubmit}>
					<header>
						<Link to={routesEnum.LOGIN}>
							<ArrowLeft className='arrow-left' />
							Voltar
						</Link>
					</header>

					<FormTitle>Recuperar Senha</FormTitle>

					<p>
						Digite seu e-mail abaixo e enviaremos um link para redefinir sua
						senha:
					</p>

					<InputWrapper>
						<Input
							className='email'
							value={email}
							type='email'
							placeholder='Email'
							onChange={handleEmailChange}
							data-testid='input-email'
						/>
					</InputWrapper>

					<div className='button-wrapper'>
						{!loading ? (
							<Button disabled={!canSubmit} type='submit' label='Enviar' data-testid='submit-recover-password'/>
						) : (
							<div className='loading'>
								<Loading />
							</div>
						)}
					</div>
				</FormWrapper>
			</FormContainer>
		</RecoverPasswordWrapper>
	)
}
