import styled, { css } from 'styled-components'

export const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		width: '429px',
		height: '540px',
		padding: '0px',
	},
	overlay: {
		zIndex: 21,
		backgroundColor: 'rgba(0, 0, 0, 0.56)'
	},
}


export const PurchaseModalWrapper = styled.div``

export const PurchaseModHeaderWrapper = styled.div``

export const PurchaseModalTitle = styled.h1``

export const Image = styled.img`
	width:112px;
	height:112px;
`

export const ParagraphContent = styled.p`
	${({theme}) => css`
		text-align: center;
		margin-top:16px;
		line-height: 24px;

		span {
			color: ${theme.colors.green_medium}
		}
	`}
`

export const Button  = styled.button`
${({theme}) => css`
		background: ${theme.colors.green};
		border:none;
		padding: 12px 24px;
		border-radius:4px;
		font-size:12px;
		color: ${theme.colors.dark_graffiti};
		font-family: ${theme.fonts.radikal_regular};
		cursor:pointer;
		margin-top:56px;
`}
`

export const ShawedButton  = styled.button`
${({theme}) => css`
		background: transparent;
		border:none;
		padding: 12px 24px;
		border-radius:4px;
		font-size:12px;
		color: ${theme.colors.dark_graffiti};
		font-family: ${theme.fonts.radikal_regular};
		cursor:pointer;
		/* margin-top:56px */
`}
`