import {
	IAcessorAndOrder,
	ITableNavProps,
} from '../../shared/components/Table/types'
export interface IViewProps {
	searchString: string
	handleListenerChange(event: React.ChangeEvent<HTMLInputElement>): void
	handleSortColumn(acessor: string, order: number): void
	tableColumns: ITableColumn[]
	isLoading: boolean
	tableRows: any
	navProps: ITableNavProps
	usersPage: IUsersPage
	sortBy: IAcessorAndOrder
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ITableColumn {}

export interface IUsersPage {
	page: number
	numberOfPages: number
	totalDocs: number
}
export enum ETableAudioAcessor {
	name = 'name',
	email = 'email',
	createdAt = 'createdAt',
	points = 'points',
	editIcon = 'editIcon',
}
