import React, { useEffect, useState } from 'react'
import { useMutationCreateBonus } from '../../service/bonus.service'
import { handleSuccessToaster } from '../../util/toaster'
import { Button } from '../'
import { Input } from '../Input'
import { NewBonusWordContainer, NewBonusWordWrapper } from './styles'
import { INewBonusWordProps } from './types'

function NewBonusWord(props: INewBonusWordProps) {
	const { refetch } = props

	const [bonusWord, setBonusWord] = useState('')
	const [points, setPoints] = useState('')

	const [disabled, setDisabled] = useState(false)

	const [isLoading, setIsLoading] = useState(false)

	const createBonusMutation = useMutationCreateBonus()

	function handlePrimaryBonusWord(event: React.ChangeEvent<HTMLInputElement>) {
		setBonusWord(event.target.value)
	}

	function cleanFields() {
		setBonusWord('')
		setPoints('')
	}

	async function handleSubmit() {
		try {
			setIsLoading(true)
			const response = await createBonusMutation.mutateAsync({
				name: bonusWord.trim(),
				reward: Number(points),
			})

			if (!response) return

			handleSuccessToaster('Bônus criado com sucesso!')
			cleanFields()
			refetch()
		} finally {
			setIsLoading(false)
		}
	}

	function handlePrimaryChangePoints(
		event: React.ChangeEvent<HTMLInputElement>,
	) {
		setPoints(event.target.value)
	}

	function validation() {
		const isValidPoints = !!points

		const isValidBonusWord = !!bonusWord

		setDisabled(isValidPoints && isValidBonusWord)
	}

	useEffect(validation, [points, bonusWord])

	return (
		<NewBonusWordWrapper>
			<NewBonusWordContainer>
				<p>Configure a Palavra Bônus e sua bonificação</p>

				<form
					onSubmit={(event) => {
						event.preventDefault()
						handleSubmit()
					}}
				>
					<div>
						<Input
							placeholder='Palavra bônus'
							className='bonus-word-input'
							onChange={handlePrimaryBonusWord}
							value={bonusWord.toUpperCase()}
							data-testid='input-bonusword'
						/>
						<Input
							className='points-input'
							placeholder='Pontuação'
							onChange={handlePrimaryChangePoints}
							value={points}
							data-testid='input-pontuation'
						/>

						<Button.Root
							className='submit-button'
							disabled={!disabled}
							isLoading={isLoading}
							type='submit'
							spacing={'12x24'}
							data-testid='submit-new-bonusword'
						>
							<Button.Text text='Cadastrar'/>
						</Button.Root>
					</div>
				</form>
			</NewBonusWordContainer>
		</NewBonusWordWrapper>
	)
}

export default NewBonusWord
