import React, {Fragment} from 'react'
import { IViewProps } from './types'
import { GoBackButton } from '../../shared/components'
import { Image } from 'react-feather'
import * as S from './styles'
import { format } from 'date-fns'
import { Button } from '../../shared/components/Button'

import CashIcon from '../../assets/icons/cash-icon.svg'
import { IUser } from '../../shared/interfaces/user'

import {isAfter} from 'date-fns'
import { PurchaseModal } from '../../shared/components/PurchaseModal'

export function PurchaseDetail(props: IViewProps) {
  const {data,handleSubmitRedeemed,handleOpenModal,purchaseModal,handleCloseModal} = props

  if(!data) return <div/>

  const isRedeemed = !!data.redeemed

  const afterDate = isAfter(new Date(String(data.redemptionDeadline)), new Date()) 

  const user = data.user as IUser

  function handleFlag() {
    if (isRedeemed) {
      return (
        <Fragment>
          <S.RedeemedWrapper redeemed={true}>
            <S.RedeemedTextWrapper redeemed={true}>Produto Resgatado</S.RedeemedTextWrapper>
          </S.RedeemedWrapper>
          {data.redeemedAt && (
            <S.RedeemedDateText>
              Retirado em {format(new Date(data.redeemedAt), 'dd/MM/yyyy \'às\' HH:mm')}
            </S.RedeemedDateText>
          )}
        </Fragment>
      )
    }
  
    if (!afterDate) {
      return (
        <S.RedeemedInactiveWrapper>
          <S.RedeemedTextInactiveWrapper>Resgate Expirado</S.RedeemedTextInactiveWrapper>
        </S.RedeemedInactiveWrapper>
      )
    }
  
    return (
      <Fragment>
        <S.RedeemedWrapper redeemed={false}>
          <S.RedeemedTextWrapper redeemed={false}>A Resgatar</S.RedeemedTextWrapper>
        </S.RedeemedWrapper>
      </Fragment>
    )
  }

  

  return <S.PurchaseDetailWrapper>
    <S.Header>
				<GoBackButton />
				<S.ModalTitle data-testid='title-page'>
          Informações da Compra
				</S.ModalTitle>
			</S.Header>
      <S.PageWrapper>
        <S.ProductBannerStatus>
            <S.ImageWrapper>
          {data.referenceTypeID.photoURL ? (
            <img className='report-image' src={data.referenceTypeID.photoURL} alt='profile image' />
          ) : (
            <S.TableImagePlaceholder>
              <Image className='placeholder-icon' />
            </S.TableImagePlaceholder>
          )}
        </S.ImageWrapper>
        <S.Title>{data.referenceTypeID.title}</S.Title>
        </S.ProductBannerStatus>
        <S.StatusBar>
          <S.StatusBarTitle>Status</S.StatusBarTitle>
          <S.RedeemedContentWrapper>
            {handleFlag()}
          </S.RedeemedContentWrapper>
        </S.StatusBar>

        <S.ContentWrapper>
          <S.StatusBar>
            <S.StatusBarMinimumTitle>Beatcoins Utilizados</S.StatusBarMinimumTitle>
            <S.ProductPriceFlag>
              <img src={CashIcon}/>
                <S.ProductPriceFlagText>{data.referenceTypeID.price}</S.ProductPriceFlagText>
              </S.ProductPriceFlag>
          </S.StatusBar>
          <S.StatusBar>
            <S.StatusBarMinimumTitle>Comprador</S.StatusBarMinimumTitle>
            <S.StatusBarDefaultText>{user.name}</S.StatusBarDefaultText>
          </S.StatusBar>
          <S.StatusBar>
            <S.StatusBarMinimumTitle>Data da Compra</S.StatusBarMinimumTitle>
            <S.StatusBarDefaultText>{format(new Date(data.createdAt), 'dd/MM/yyyy \'às\' HH:mm')}</S.StatusBarDefaultText>
          </S.StatusBar>
          <S.StatusBar>
            <S.StatusBarMinimumTitle>Prazo de Retirada</S.StatusBarMinimumTitle>
            {data.redemptionDeadline && <S.StatusBarDefaultText>{format(new Date(data.redemptionDeadline), 'dd/MM/yyyy')}</S.StatusBarDefaultText>}
          </S.StatusBar>
          <S.StatusBar>
            <S.StatusBarMinimumTitle>CPF</S.StatusBarMinimumTitle>
            <S.StatusBarDefaultText>{user.personalFiscalID}</S.StatusBarDefaultText>
          </S.StatusBar>
          <S.StatusBar>
            <S.StatusBarMinimumTitle>E-mail</S.StatusBarMinimumTitle>
            <S.StatusBarDefaultText>{user.email}</S.StatusBarDefaultText>
          </S.StatusBar>
          <S.StatusBar>
            <S.StatusBarMinimumTitle>Total de Compras no E-Shop</S.StatusBarMinimumTitle>
            <S.StatusBarDefaultText>{data.totalDocs}</S.StatusBarDefaultText>
          </S.StatusBar>
          {!isRedeemed && afterDate ?  (
          <S.FinishButtonWrapper>
            <Button onClick={handleOpenModal} label='Confirmar Entrega' />
          </S.FinishButtonWrapper>
          ) :  (
            <S.FinishButtonWrapper></S.FinishButtonWrapper>
          )}
        </S.ContentWrapper>
      </S.PageWrapper>
      <PurchaseModal handleSubmit={handleSubmitRedeemed} data={data} isActive={purchaseModal} onRequestClose={handleCloseModal} />
  </S.PurchaseDetailWrapper>
}
