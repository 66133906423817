import React, { Fragment } from 'react'

import { Plus, Minus } from 'react-feather'
import { TertiaryButtonContainer } from './styles'
import { ITertiryButtonProps, TiertiaryButtonTheme } from './types'

function TertiaryButton(props: ITertiryButtonProps) {
	const { theme = TiertiaryButtonTheme.PRIMARY, ...rest } = props

	return (
		<TertiaryButtonContainer
			primaryTheme={theme === TiertiaryButtonTheme.PRIMARY}
			{...rest}
		>
			{theme === TiertiaryButtonTheme.PRIMARY ? (
				<Plus className='plus-icon' />
			) : (
				<Minus className='minus-icon' />
			)}
		</TertiaryButtonContainer>
	)
}

export default TertiaryButton
