export enum PromotionStatus {
	ACTIVE = 'ACTIVE',
	INACTIVE = 'INACTIVE',
	DELETED = 'DELETED',
}

export interface IPromotion {
	_id: string
	artworkURL: string
	title: string
	startDate: Date
	endDate: Date
	updatedAt: Date
	stockQuantity: number
	description: string
	regulation: string
	status: PromotionStatus
	participants: string[]
	winners: string[]
	accomplishedAt?: Date
}

export interface IPromotionRequest {
	artworkURL: string
	title: string
	startDate: Date | undefined
	endDate: Date | undefined
	description: string
	stockQuantity: number
	regulation: string
}
