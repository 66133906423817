import {
	IAcessorAndOrder,
	ITableNavProps,
} from '../../shared/components/Table/types'
import { IUserDetail } from '../../shared/interfaces/user'
import { IExtractDocument } from '../../shared/interfaces/extract'

export interface IViewProps {
	tableColumns: ITableColumn[]
	handleGoBack(): void
	isLoading: boolean
	data: IUserDetail
	tableRows: any
	tableLoading: boolean
	navProps: ITableNavProps
	extractPage: IExtractPage
	handleSortColumn(acessor: string, order: number): void
	sortBy: IAcessorAndOrder
	handleDeleteUser: () => Promise<void>
	handleCloseModal(): void
	handleOpenModal(): void
	deleteModalOpen: boolean
}

export interface IExtractPage {
	page: number
	numberOfPages: number
	totalDocs: number
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ITableColumn {}

export interface IRouteParams {
	userID: string
}

export interface ISwitchProps {
	redeemed: boolean
	onChange(): void
	data?: IExtractDocument<string>
}

export enum ETableAudioAcessor {
	date = 'date',
	description = 'description',
	result = 'result',
	rescue = 'rescue',
}
