import React, { useState } from 'react'
import { Button } from '../../shared/components'
import SecondaryInput from '../../shared/components/SecondaryInput'

import dragIcon from '../../assets/icons/drag-table.svg'

import * as S from './styles'
import { IViewProps } from './types'
import NewBannerModal from '../../shared/components/NewBannerModal'
import { renderDeleteBanner, renderSwitch } from './table'
import { DragDropContext, Draggable } from 'react-beautiful-dnd'
import { StrictModeDroppable as Droppable } from '../../helpers/StrictModeDroppable'
import { ModalActionEnum } from '../../shared/interfaces/modal'
import { MoreHorizontal } from 'react-feather'

function Banners(props: IViewProps) {
	const [modalOpen, setModalOpen] = useState(false)
	const [action, setAction] = useState<ModalActionEnum>('CREATE')
	const [bannerTitle, setBannerTitle] = useState('')
	const [bannerImage, setBannerImage] = useState('')
	const [bannerID, setBannerID] = useState('')
	const [bannerStatus, setBannerStatus] = useState('')

	const {
		searchString,
		handleSearchChange,

		handleSwitchRefetch,
		refetch,
		onDragEnd,
		isUpdateLoading,
		ordanatedBanners,
	} = props

	const banners = ordanatedBanners

	function handleResetData() {
		setBannerImage('')
		setBannerTitle('')
	}

	return (
		<>
			<S.BannersContainer>
				<S.Top>
					<S.TitleInputWrapper>
						<S.TitlePage data-testid='title-page'>Banners</S.TitlePage>
						<S.InputWrapper>
							<SecondaryInput
								className='search-input'
								placeholder='Buscar Banner'
								value={searchString}
								onChange={handleSearchChange}
							/>
						</S.InputWrapper>
					</S.TitleInputWrapper>

					<S.ButtonWrapper>
						<Button.Root
							onClick={() => {
								setAction('CREATE')
								setModalOpen(true)
							}}
							spacing={'12x24'}
							data-testid='button-new-banner'
						>
							<Button.Text text='Novo Banner' />
						</Button.Root>
					</S.ButtonWrapper>
				</S.Top>
				<DragDropContext onDragEnd={onDragEnd}>
					<S.BannerContentWrapper>
						{banners?.length ? (
							<S.BannerContentHeader>
								<S.PositionTitle>Posição</S.PositionTitle>
								<S.TitleTitle>Título</S.TitleTitle>
								<S.StatusTitle>Status</S.StatusTitle>
							</S.BannerContentHeader>
						) : null}

						{isUpdateLoading ? null : (
							<Droppable droppableId='dropable'>
								{(provided: any) => (
									<section {...provided.droppableProps} ref={provided.innerRef}>
										<S.BannerContent>
											{banners?.map((banner, index) => {
												return (
													<Draggable
														index={index}
														draggableId={banner._id}
														key={banner._id}
													>
														{(provided) => (
															<S.BannerCard
																{...provided.draggableProps}
																{...provided.dragHandleProps}
																ref={provided.innerRef}
																style={{ ...provided.draggableProps.style }}
															>
																<S.OrderWrapper>
																	<S.DragNDropIcon
																		src={dragIcon}
																		alt='drag n drop icon'
																	/>
																	<S.PositionNumber>
																		{banner.positionQueue}
																	</S.PositionNumber>
																	<S.BannerInfoWrapper>
																		<S.Image src={banner.photoURL} />
																		<S.Title>{banner.title}</S.Title>
																	</S.BannerInfoWrapper>
																</S.OrderWrapper>

																<S.SwitchWrapper>
																	{renderSwitch({
																		banner,
																		handleSwitchRefetch,
																	})}
																</S.SwitchWrapper>
																<S.DeleteEditWrapper>
																	{renderDeleteBanner(banner, refetch)}
																	<S.EditButton
																		onClick={() => {
																			setAction('EDIT')
																			setBannerImage(banner.photoURL)
																			setBannerTitle(banner.title)
																			setBannerStatus(banner.status)
																			setBannerID(banner._id)
																			setModalOpen(true)
																		}}
																		data-testid='button-details'
																	>
																		<MoreHorizontal style={{ strokeWidth: 1.5 }} />
																	</S.EditButton>
																</S.DeleteEditWrapper>
															</S.BannerCard>
														)}
													</Draggable>
												)
											})}
											{provided.placeholder}
										</S.BannerContent>
									</section>
								)}
							</Droppable>
						)}
					</S.BannerContentWrapper>
				</DragDropContext>
			</S.BannersContainer>
			{modalOpen ? (
				<NewBannerModal
					handleResetData={handleResetData}
					isOpen={setModalOpen}
					refetch={refetch}
					bannerID={bannerID}
					bannerStatus={bannerStatus}
					bannerImage={bannerImage}
					bannerTitle={bannerTitle}
					action={action}
					onClose={() => {
						setModalOpen(false)
						setBannerImage('')
						setBannerTitle('')
					}}
				/>
			) : null}
		</>
	)
}

export default Banners
