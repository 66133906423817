import React, { useRef, useEffect } from 'react'
import * as S from './styles'
import { ITextAreaProps } from './types'

function TextArea(props: ITextAreaProps) {
	const { value, onChange, ...rest } = props
	const textAreaRef = useRef<HTMLTextAreaElement>(null)

	const adjustTextAreaHeight = () => {
		const textarea = textAreaRef.current
		if (textarea) {
			textarea.style.height = 'auto'
			textarea.style.height = `${textarea.scrollHeight}px`
		}
	}

	useEffect(() => {
		adjustTextAreaHeight()
	}, [value])

	return (
		<S.TexTAreaWrapper>
			<S.TextArea
				ref={textAreaRef}
				value={value}
				onChange={onChange}
				{...rest}
			/>
		</S.TexTAreaWrapper>
	)
}

export default TextArea
