import React, { useState } from 'react'
import { InputWrapper } from './styles'
import { ISecondaryInputProps } from './types'
import { Search } from 'react-feather'
import { theme } from '../../styles/theme'
import { SecondaryInputContainer } from '../SecondaryInput/styles'

function SecondaryInput(props: ISecondaryInputProps) {
	const { ...rest } = props

	const [isFocused, setFocused] = useState(false)

	function handleOnBlur() {
		setFocused(false)
	}

	function handleOnFocus() {
		setFocused(true)
	}

	return (
		<InputWrapper>
			<SecondaryInputContainer
				activeBorder={isFocused || !!props.value}
				onBlur={handleOnBlur}
				onFocus={handleOnFocus}
				{...rest}
				data-testid='search'
			/>

			<Search
				className='search-icon'
				size={22}
				color={theme.colors.dark_graffiti}
			/>
		</InputWrapper>
	)
}

export default SecondaryInput
