import  { createElement, useState } from 'react'
import {
	IRouteParams,
	IViewProps,
} from './types'
import { useHistory, useParams } from 'react-router-dom'

import { PurchaseDetail } from './view'
import { useMutationUpdateRedeemProduct, usePurchaseData, usePurchasesPage } from '../../shared/service/product.service'
import { handleSuccessToaster } from '../../shared/util/toaster'
import { routesEnum } from '../Routes/enum'

function PurchaseDetailContainer(): JSX.Element {
	const {purchaseID}: IRouteParams = useParams()
	const history = useHistory()

	const { data } = usePurchasesPage(purchaseID)
	const { mutateAsync: updateRedeemProduct } = useMutationUpdateRedeemProduct()
	const { refetch: numberRefetch} = usePurchaseData()
	const [purchaseModal, setPurchaseModal] = useState(false)


	function handleCloseModal() {
		setPurchaseModal(false)
	}

	function handleOpenModal() {
		setPurchaseModal(true)
	}


	async function handleRedeemItem() {
		const response = await updateRedeemProduct({ productID: data._id })

		if (!response) return

		history.push(routesEnum.PURCHASE)

		await numberRefetch()

		if (!response.redeemed) {
			handleSuccessToaster('Produto resgatado com sucesso!')
		}
	}




	function handleSubmitRedeemed(){
		(async () => {
			await	handleRedeemItem()
		})()
	}

	const viewProps: IViewProps = {	data,handleRedeemItem,handleOpenModal,handleCloseModal,handleSubmitRedeemed,purchaseModal}

	return createElement(PurchaseDetail, viewProps)
}

export default PurchaseDetailContainer
