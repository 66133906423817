import ModalButton from './ModalButton'
import ModalRoot from './ModalRoot'
import ModalParagraphText from './ModalParagraphText'
import ModalContentSpacing from './ModalContentSpacing'
import ModalAnyElement from './ModalAnyElement'

const Modal = {
	Root: ModalRoot,
	Button: ModalButton,
	ParagraphText: ModalParagraphText,
	ContentSpacing: ModalContentSpacing,
	AnyElement: ModalAnyElement,
}

export default Modal
