import React from 'react'

import * as S from './styles'
import { ISwitchProps } from './types'
import { formatNumber } from '../../shared/util/number'
import Switch from '../../shared/components/Switch'
import { format } from 'date-fns'

export function renderTextTable(str: string) {
	return <p>{str}</p>
}

export function renderPoints(points: number, type: string) {
	if (
		type === 'EARN_LEGACY_BEATCOINS' ||
		type === 'EARN_EBONUS' ||
		type === 'EARN_LIKE_MUSIC' ||
		type === 'EARN_PLAYED_MUSIC' ||
		type === 'EARN_PLAYED_MUSIC_OLDER_OWNERS' ||
		type === 'EARN_DAILY_ACCESS_SCORE' ||
		type === 'EARN_WELCOME_SCORE'
	) {
		return (
			<S.PointsContainer>
				<S.PointsWrapper>
					<S.PointsLabel>
						{'+ ' + formatNumber(points) + ' Beatcoins'}
					</S.PointsLabel>
				</S.PointsWrapper>
			</S.PointsContainer>
		)
	} else {
		return (
			<S.PointsContainer>
				<S.PointsWrapper>
					<S.PointsLabel>
						{'- ' + formatNumber(points) + ' Beatcoins'}
					</S.PointsLabel>
				</S.PointsWrapper>
			</S.PointsContainer>
		)
	}
}

export function handleDetail(type: string, itemName: string) {
	if (type === 'EARN_EBONUS') {
		return <span>Acertou a Palavra Bônus</span>
	} else if (type === 'EARN_LIKE_MUSIC') {
		return <span>Curtiu uma música</span>
	} else if (type === 'SPEND_ESHOP' && itemName) {
		return <span>Compra do produto “{itemName}”</span>
	}
}

export function renderRescueSwitch(props: ISwitchProps) {
	const { redeemed, onChange, data } = props

	return (
		<S.SwitchWrapper>
			<Switch onChange={onChange} checked={redeemed} />
			{!redeemed ? (
				<S.TextSwitch>A resgatar</S.TextSwitch>
			) : (
				<S.TextSwitch>
					Produto resgatado em<br></br>
					{format(new Date(data?.redeemedAt || ''), 'dd/MM/yyyy,')} às{' '}
					{format(new Date(data?.redeemedAt || ''), 'HH:mm')}
				</S.TextSwitch>
			)}
		</S.SwitchWrapper>
	)
}
