import { PaginatedRequest } from './pagination'
import { IUser } from './user'

export enum ExtractTypeEarn {
	EARN_LEGACY_BEATCOINS = 'EARN_LEGACY_BEATCOINS',
	EARN_EBONUS = 'EARN_EBONUS',
	EARN_LIKE_MUSIC = 'EARN_LIKE_MUSIC',
	EARN_OWNER_MUSIC = 'EARN_OWNER_MUSIC',
	EARN_PLAYED_MUSIC = 'EARN_PLAYED_MUSIC',
	EARN_PLAYED_MUSIC_OLDER_OWNERS = 'EARN_PLAYED_MUSIC_OLDER_OWNERS',
	EARN_WELCOME_SCORE = 'EARN_WELCOME_SCORE',
	EARN_DAILY_ACCESS_SCORE = 'EARN_DAILY_ACCESS_SCORE',
}

export enum ExtractTypeSpend {
	SPEND_OWNER_MUSIC = 'SPEND_OWNER_MUSIC',
	SPEND_ESHOP = 'SPEND_ESHOP',
}


export const ExtractTypeEarnData = [
	'EARN_LEGACY_BEATCOINS',
	'EARN_EBONUS',
	'EARN_LIKE_MUSIC',
	'EARN_PLAYED_MUSIC',
	'EARN_PLAYED_MUSIC_OLDER_OWNERS',
]


export type IFetchExtractPayload = PaginatedRequest<{ userID: string }>

export enum PurchaseAnalyticsData {
  REDEEMED = 'REDEEMED',
  NOT_REDEEMED = 'NOT_REDEEMED',
  DEADLINE_END = 'DEADLINE_END',
}



export interface IExtractDocument<T>  {
	_id: string
	user: IUser | string
	referenceTypeID: T
	type: ExtractTypeEarn | ExtractTypeSpend
	beatcoins: number
	redeemed?: boolean
	redeemedAt?: Date
	redemptionDeadline?: string
	title: string
	description: string
	createdAt: Date
	updatedAt: Date
	totalDocs?: number
}


export interface IPurchaseNumbers {
	expired: number,
	redeemed: number,
	toBeRedeemed: number
}