import React, { useCallback,  useState } from 'react'
import { Descendant, createEditor } from 'slate'
import {
	Editable,
	RenderElementProps,
	RenderLeafProps,
	Slate,
	withReact,
} from 'slate-react'
import { withHistory } from 'slate-history'

import * as S from './styles'
import { Leaf,Element } from '../TextEditorBox'

function RenderTextElement({data} : {data: Descendant[]}) {
  const [editor] = useState(() => withReact(withHistory(createEditor())))

  const renderElement = useCallback(
		(props: RenderElementProps) => (
			<S.ElementContainerStyles>
				<Element {...props} />
			</S.ElementContainerStyles>
		),
		[],
	)
	const renderLeaf = useCallback(
		(props: RenderLeafProps) => <Leaf {...props} />,
		[],
	)

  return (
    <Slate editor={editor} initialValue={data}>
      <Editable
              renderElement={renderElement}
              renderLeaf={renderLeaf}
              readOnly
            />
    </Slate>
  )
}


export {RenderTextElement}