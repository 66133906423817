import { ILoginRequest, ILoginResponse } from '../interfaces/auth'
import axiosInstance from './axiosInstance'
import { AxiosError } from 'axios'
import { useMutation, UseMutationResult } from 'react-query'
import { IError } from '../interfaces/error'

async function userAuth(payload: ILoginRequest): Promise<ILoginResponse> {
	const response = await axiosInstance.post('/auth/panel', {
		email: payload.email,
		password: payload.password,
	})

	return response.data
}

export function useMutationAuth(): UseMutationResult<
	ILoginResponse,
	AxiosError<IError>,
	ILoginRequest
> {
	return useMutation(userAuth)
}
