import { toRem, transition } from '../../shared/styles/function'
import styled, { css } from 'styled-components'
import { RouteWrapperProps } from './types'

const navWidth = 230
export const SideNavWrapper = styled.div`
	${({ theme }) => css`
		.menu-wrapper {
			position: fixed;
			display: flex;
			flex-direction: column;
			z-index: 10;
			top: 0;
			bottom: 0;
			left: 0;
			visibility: hidden;

			.spacer {
				height: 88px;
			}

			.nav-wrapper {
				display: flex;
				flex-direction: column;
				flex: 1;
				width: ${navWidth}px;
				transform: translateX(-${navWidth}px);
				overflow-y: auto;
				${transition()};

				nav {
					flex: 1;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					background: ${theme.colors.dark_graffiti};

					.upper-part {
						padding-top: 16px;
					}

					.bottom-part {
						display: flex;
						justify-content: space-between;
						align-items: center;
						padding: 25px;
						width: 100%;
						background: transparent;
						border: none;
						cursor: pointer;

						img {
							&:hover {
								opacity: 0.7;
							}
							${transition()};
						}
					}

					.nav-bottom {
						li {
							display: flex;
							align-items: center;
							border: none;
							background: none;
							font-size: ${toRem(14)};
							${transition()};
							color: ${theme.colors.greenish_white};
							font-family: ${theme.fonts.radikal_regular};

							&:hover {
								opacity: 0.7;
							}
						}
					}
				}
			}
		}

		.background{
			background-color: rgba(0, 0, 0, 0.56);
			opacity: 0;
			transition: all 0.3s;
		}
		
		&.active {
			.background {
				position: fixed;
				z-index: 10;
				opacity: 1;

				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
			}

			.menu-wrapper {
				visibility: visible;
				.nav-wrapper {
					transform: translateX(0px);
				}
			}
		}
	`}
`

export const RouteWrapper = styled.div<RouteWrapperProps>`
	${(props) => css`
		height: 48px;
		display: flex;
		align-items: center;

		div {
			display: flex;
			align-items: center;
			cursor: pointer;
		}

		.icon {
			color: ${props.isActive
				? props.theme.colors.green
				: props.theme.colors.greenish_white};
			margin-left: 15px;
		}

		.icon-indicator {
			height: 24px;
			width: 2px;
			background: ${props.isActive ? props.theme.colors.green : 'transparent'};
			border-radius: 0px 2px 2px 0px;
		}

		.nav-link {
			color: ${props.isActive
				? props.theme.colors.green
				: props.theme.colors.greenish_white};
			font-size: ${toRem(14)};
			text-decoration: none;
		}
	`}
`

export const LoggoutSeparator = styled.div`
	height: 1px;
	width: 100%;
	background: ${({ theme }) => theme.colors.greenish_grey};
	margin-top: 16px;
	margin-bottom: 16px;
`

export const ButtonRouteWrapper = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;
	width: 100%;
	height: 100%;
`

export const NavButtonHover = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;
	width: 100%;
	height: 100%;
	margin: 10px;
	border-radius: 4px;
	transition: all 0.4s;
	gap: 8px;
	:hover {
		background-color: #717a75;
	}
`

export const UserPathWrapper = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 50px;
`
