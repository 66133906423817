import React from 'react'

import * as S from './styles'
import { IModalParagraphText } from './types'

export default function ModalParagraphText({
	text,
	useDangerouslySetInnerHTML = false,
}: IModalParagraphText) {
	return useDangerouslySetInnerHTML ? (
		<S.ParagraphText>{text}</S.ParagraphText>
	) : (
		<S.ParagraphText dangerouslySetInnerHTML={{ __html: text }} />
	)
}
