import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query'
import { IExtractDocument, IFetchExtractPayload } from '../interfaces/extract'
import { IPaginationResponse } from '../interfaces/pagination'
import { QUERY_KEYS } from '../util/reactQuery'
import axiosInstance from './axiosInstance'

async function fetchExtract({
	maxItemsInPage,
	pageIndex,
	userID,
	order,
	sort
}: IFetchExtractPayload): Promise<IPaginationResponse<IExtractDocument<any>>> {
	const response = await axiosInstance.post('/extract/panel/get-page', {
		pageIndex,
		userID,
		maxItemsInPage,
		order,
		sort
	})

	return response.data
}

export function useExtractPage(params: IFetchExtractPayload): UseInfiniteQueryResult<
IPaginationResponse<IExtractDocument<any>>
> {

	return useInfiniteQuery(
		[QUERY_KEYS.EXTRACT, { ...params }],
		async ({ queryKey }) => {
			const [_key, params] = queryKey

			const { maxItemsInPage, pageIndex, userID,order, sort } =
				params as IFetchExtractPayload

			return fetchExtract({ maxItemsInPage, pageIndex,  userID,order ,sort})
		},
		
	)
}
