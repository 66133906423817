import React, { useCallback, useEffect, useState } from 'react'
import { Descendant, createEditor } from 'slate'
import {
	Editable,
	RenderElementProps,
	RenderLeafProps,
	Slate,
	withReact,
} from 'slate-react'
import { withHistory } from 'slate-history'
import isHotkey from 'is-hotkey'

import * as S from './styles'
import { EditionBar } from './EditionBar'
import { HOTKEYS, toggleMark } from './utils'

interface ITextEditorBoxProps {
	value?: Descendant[]
	onChange: (value: Descendant[]) => void
	placeholder?: string
	disabled?: boolean
	returnIsEmpty?: (isEmpty: boolean) => void
	minWidth?: string
	minHeight?: string
}

export default function TextEditorBox({
	value,
	onChange,
	disabled = false,
	placeholder,
	returnIsEmpty,
	minWidth,
	minHeight,
}: ITextEditorBoxProps) {
	const [initialized, setInitialized] = useState(false)
	const [isEmpty, setIsEmpty] = useState(true)
	const [isFocused, setIsFocused] = useState(false)

	const [editor] = useState(() => withReact(withHistory(createEditor())))

	function handleFocus() {
		setIsFocused((prev) => !prev)
	}

	const renderElement = useCallback(
		(props: RenderElementProps) => (
			<S.ElementContainerStyles>
				<Element {...props} />
			</S.ElementContainerStyles>
		),
		[],
	)
	const renderLeaf = useCallback(
		(props: RenderLeafProps) => <Leaf {...props} />,
		[],
	)

	useEffect(() => {
		if (!initialized && value) {
			setInitialized(true)
			editor.insertNodes(value)
			editor.removeNodes({ at: [0] })
		}
	}, [value])

	useEffect(() => {
		if (value) {
			const totalCharacters = editor.string([]).length
			const isEmpty = totalCharacters === 0
			setIsEmpty(isEmpty)
			returnIsEmpty && returnIsEmpty(isEmpty)
		}
	}, [value])

	const borderActive = isFocused || !isEmpty

	return (
		<Slate
			editor={editor}
			initialValue={[
				{
					type: 'format-paragraph',
					children: [{ text: '' }],
				},
			]}
			onChange={onChange}
		>
			<S.Container>
				<S.SlateEditorToolsWrapper borderActive={borderActive}>
					<EditionBar disabled={disabled} />
				</S.SlateEditorToolsWrapper>
				<S.SlateTextBoxWrapper
					minHeight={minHeight}
					minWidth={minWidth}
					borderActive={borderActive}
					data-testid='textbox'
				>
					<Editable
						style={{ height: minHeight }}
						onFocus={handleFocus}
						onBlur={handleFocus}
						renderElement={renderElement}
						renderLeaf={renderLeaf}
						readOnly={disabled}
						placeholder={placeholder}
						onKeyDown={(event) => {
							for (const hotkey in HOTKEYS) {
								if (isHotkey(hotkey, event as any)) {
									event.preventDefault()
									const mark = HOTKEYS[hotkey]
									toggleMark(editor, mark)
								}
							}
						}}
					/>
				</S.SlateTextBoxWrapper>
			</S.Container>
		</Slate>
	)
}

export const Element = ({
	attributes,
	children,
	element,
}: RenderElementProps) => {
	const classNameForAlignStyle = element.align
	switch (element.type) {
		case 'format-quote':
			return (
				<blockquote className={classNameForAlignStyle} {...attributes}>
					{children}
				</blockquote>
			)
		case 'format-bullet-list':
			return (
				<ul className={classNameForAlignStyle} {...attributes}>
					{children}
				</ul>
			)
		case 'format-h1':
			return (
				<h1 className={classNameForAlignStyle} {...attributes}>
					{children}
				</h1>
			)
		case 'format-h2':
			return (
				<h2 className={classNameForAlignStyle} {...attributes}>
					{children}
				</h2>
			)
		case 'format-list-item':
			return (
				<li className={classNameForAlignStyle} {...attributes}>
					{children}
				</li>
			)
		case 'format-numbered-list':
			return (
				<ol className={classNameForAlignStyle} {...attributes}>
					{children}
				</ol>
			)
		default:
			return (
				<p className={classNameForAlignStyle} {...attributes}>
					{children}
				</p>
			)
	}
}

export const Leaf = ({ attributes, children, leaf }: RenderLeafProps) => {
	if (leaf['format-bold']) {
		children = <strong>{children}</strong>
	}
	if (leaf['format-italic']) {
		children = <em>{children}</em>
	}
	if (leaf['format-underline']) {
		children = <u>{children}</u>
	}
	return <span {...attributes}>{children}</span>
}
