/* eslint-disable */
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import OutlinedInput from '@mui/material/OutlinedInput'
import Select from '@mui/material/Select'

import { InputLabel } from '@mui/material'
import { SelectChipStyles, SelectStyles, SelectWrapper } from './styles'
import { IPrimarySelectProps } from './types'
import { ISelectOption } from '../../interfaces/select'

function SecondarySelect(props: IPrimarySelectProps) {
	const { data, label, value: selectedOptions, onChange, ...rest } = props

	function renderItem(selected: any) {
		const options = selected as string[]

		return (
			<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
				{options.map((value) => {
					const option: ISelectOption = JSON.parse(value)

					return (
						<Chip
							style={SelectChipStyles}
							key={option.value}
							label={option.label}
						/>
					)
				})}
			</Box>
		)
	}

	function handleChange(event: any) {
		const selectedOptions: string[] = event.target.value

		onChange(selectedOptions)
	}

	return (
		<SelectWrapper>
			<FormControl fullWidth className='role-select'>
				{label && (
					<InputLabel id='demo-multiple-chip-label'>{label}</InputLabel>
				)}
				<Select
					{...rest}
					onChange={handleChange}
					style={SelectStyles}
					multiple
					value={selectedOptions}
					input={<OutlinedInput id='select-multiple-chip' label={label} />}
					renderValue={renderItem}
				>
					{data.map((item) => {
						return (
							<MenuItem
								className='item'
								key={item.value}
								value={JSON.stringify(item)}
							>
								{item.label}
							</MenuItem>
						)
					})}
				</Select>
			</FormControl>
		</SelectWrapper>
	)
}

export default SecondarySelect
