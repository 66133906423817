import { AxiosError, AxiosResponse } from 'axios'
import {
	QueryFunctionContext,
	UseMutationResult,
	UseQueryResult,
	useMutation,
	useQuery,
} from 'react-query'
import {
	CreateBonusPayload,
	IEBonus,
	IFetchBonusResponse,
	IFetchCurrentBonusResponse,
} from '../interfaces/ebonus'
import { IError } from '../interfaces/error'
import { IPaginationResponse, PaginatedRequest } from '../interfaces/pagination'
import { QUERY_KEYS } from '../util/reactQuery'
import axiosInstance from './axiosInstance'

// eslint-disable-next-line @typescript-eslint/ban-types
export type IFetchBonusRequest = PaginatedRequest<{}>

async function fetchBonusPage(
	context: QueryFunctionContext<[unknown, IFetchBonusRequest]>,
): Promise<IPaginationResponse<IEBonus>> {
	const [_, params] = context.queryKey
	const { maxItemsInPage, pageIndex, searchString, order, sort } = params
	const response = await axiosInstance.post('/bonusWord/get-page', {
		pageIndex,
		searchString,
		order,
		sort,
		maxItemsInPage,
	})

	return response.data
}

export function useEBonusPage({
	maxItemsInPage,
	pageIndex,
	searchString,
	order,
	sort,
}: IFetchBonusRequest): UseQueryResult<IPaginationResponse<IEBonus>> {
	const result = useQuery(
		[QUERY_KEYS.BONUS_PAGE, { maxItemsInPage, pageIndex, searchString, order, sort }],
		fetchBonusPage,
	)
	return result
}

async function fetchCurrentBonus(): Promise<IFetchBonusResponse> {
	const response = await axiosInstance.get('/bonusWord/current')

	return response.data
}

export function useCurrentEBonusPage(): UseQueryResult<IFetchCurrentBonusResponse> {
	return useQuery([QUERY_KEYS.CURRENT_BONUS], async () => fetchCurrentBonus(), {
		enabled: true,
	})
}

export function useCurrentEBonus(): UseQueryResult<IFetchCurrentBonusResponse> {
	return useQuery([QUERY_KEYS.BONUS_PAGE], async () => fetchCurrentBonus(), {
		enabled: true,
	})
}

async function createBonus(payload: CreateBonusPayload) {
	const response = await axiosInstance.post('/bonusWord/', payload)

	return response.data
}

export function useMutationCreateBonus(): UseMutationResult<
	IEBonus,
	AxiosError<IError>,
	CreateBonusPayload
> {
	return useMutation(createBonus)
}

async function stopBonus() {
	return axiosInstance.get('/bonusWord/stop')
}

export function useMutationStopBonus(): UseMutationResult<
	AxiosResponse<any, any>,
	AxiosError<IError>,
	any
> {
	return useMutation(stopBonus)
}
