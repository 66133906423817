import React from 'react'
import Loading from '../Loading'

import { IButtonProps } from './types'

import * as S from './styles'

export function Button(props: IButtonProps) {
    const { label, isLoading = false, disabled = false, ...rest } = props

    if (isLoading) {
        return <Loading width={100} height={46} />
    }

    return (
        <S.ButtonContainer {...rest} disabled={disabled}>
            <S.Title>{label}</S.Title>
        </S.ButtonContainer>
    )
}
