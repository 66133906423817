import React, { Fragment } from 'react'
import * as S from './styles'
import { Image } from 'react-feather'

export function renderTextTable(str: string) {
	return <p>{str}</p>
}

export function renderImage(photoURL: string) {
	return (
		<S.ImageWrapper>
			{photoURL ? (
				<img className='report-image' src={photoURL} alt='profile image' />
			) : (
				<S.TableImagePlaceholder>
					<Image className='placeholder-icon' />
				</S.TableImagePlaceholder>
			)}
		</S.ImageWrapper>
	)
}


export function withDrawalButton({redeemed, handleOpenModal, isActive}: {redeemed: boolean, handleOpenModal: any, isActive: boolean}) {
	return isActive ?<S.WithDrawButtonWrapper onClick={handleOpenModal} isActive={isActive}>Confirmar Entrega</S.WithDrawButtonWrapper> : <Fragment/>
}