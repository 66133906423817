import React, { useEffect, useState } from 'react'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'

import { ICounterComponentProps } from './types'

export function CounterComponent(props: ICounterComponentProps): JSX.Element {
	const { onComplete } = props

	const [seconds, setSeconds] = useState(5)

	useEffect(() => {
		if (seconds === 0) {
			onComplete()
		}
	}, [seconds])

	useEffect(() => {
		const interval = setInterval(() => {
			setSeconds((seconds) => seconds - 1)
		}, 1000)

		return () => {
			clearInterval(interval)
		}
	}, [])

	return (
		<div
			style={{
				width: '156px',
				height: '156px',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				position: 'relative',
			}}
		>
			<CircularProgressbar
				value={(5 - seconds + 1) * 20}
				maxValue={100}
				strokeWidth={7}
				styles={buildStyles({
					pathColor: `rgba(5, 242, 108, ${(5 - seconds + 1) / 5})`,
					textColor: '#000',
				})}
			/>
			<div
				style={{
					position: 'absolute',
					top: '52%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					fontSize: '80px',
				}}
			>
				{seconds}
			</div>
		</div>
	)
}
