import { ChangeEvent, createElement, useEffect, useState } from 'react'
import { IImageUpload } from '../../interfaces/image'
import { MusicPayload } from '../../interfaces/music'
import { useMutationUpdateMusic } from '../../service/music.service'
import { useMutationPhotoUpload } from '../../service/user.service'
import { INITIAL_STATE_IMAGE_UPLOAD } from '../../util/image'
import { handleSuccessToaster } from '../../util/toaster'

import { IViewProps, MusicModalProps } from './types'

import ModalMusic from './view'

function ModalMusicContainer(props: MusicModalProps): JSX.Element {
	const { currentMusic, isOpen, onRequestClose } = props

	const [image, setImage] = useState<IImageUpload>(INITIAL_STATE_IMAGE_UPLOAD)

	const [title, setTitle] = useState('')
	const [interpreter, setInterpreter] = useState('')
	const [canSubmit, setCanSubmit] = useState(false)
	const [isLoading, setIsLoading] = useState(false)

	function handleCloseModal() {
		onRequestClose()
		cleanImageSeletor()
	}

	const updateMusicMutation = useMutationUpdateMusic()

	const photoUploadMutation = useMutationPhotoUpload()

	async function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
		const selectedImage = event.target?.files?.item(0)

		if (!selectedImage) return

		const imageURL = URL.createObjectURL(selectedImage)

		setImage({ url: imageURL, file: selectedImage, overwrite: true })

		event.target.value = ''
	}

	function cleanImageSeletor() {
		setImage({ file: null, url: '', overwrite: true })
	}

	function fetchData() {
		if (!currentMusic) return

		setTitle(currentMusic.title)
		setInterpreter(currentMusic.interpreter)
		setImage((props) => ({
			...props,
			url: currentMusic.photoURL || '',
		}))
	}

	function validateForm() {
		setCanSubmit(!!currentMusic?.photoURL || !!image.url)
	}

	async function handleUpdateMusic() {
		try {
			setIsLoading(true)

			const coverImage = image.file
				? await photoUploadMutation.mutateAsync({ file: image.file })
				: '' || image.url

			if (!currentMusic?._id) return

			const updatedMusicResponse = await updateMusicMutation.mutateAsync({
				musicID: currentMusic._id,
				musicData: coverImage,
			})

			if (!updatedMusicResponse) return

			handleSuccessToaster('Musica atualizada com sucesso')
		} finally {
			setIsLoading(false)
			handleCloseModal()
		}
	}

	useEffect(fetchData, [currentMusic])
	useEffect(validateForm, [image])

	const viewProps: IViewProps = {
		isOpen,
		handleCloseModal,
		image,
		handleInputChange,
		cleanImageSeletor,
		title,
		interpreter,
		canSubmit,
		handleUpdateMusic,
		isLoading,
	}

	return createElement(ModalMusic, viewProps)
}

export default ModalMusicContainer
