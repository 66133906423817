export const QUERY_KEYS = {
	USERS_PAGE: 'USERS_PAGE',
	USER_PRESENTERS_PAGE: 'USER_PRESENTERS_PAGE',
	PROGRAMS_PAGE: 'PROGRAMS_PAGE',
	PRESENTERS: 'PRESENTERS',
	MUSICS: 'MUSICS',
	BONUS_PAGE: 'BONUS_PAGE',
	CURRENT_BONUS: 'CURRENT_BONUS',
	PRODUCTS: 'PRODUCTS',
	PROGRAM_NOW: 'PROGRAM_NOW',
	USERS: 'USERS',
	CUSTOMER_USERS: 'CUSTOMER_USERS',
	CUSTOMER_DETAIL: 'CUSTOMER_DETAIL',
	EXTRACT: 'EXTRACT',
	AUDIOS: 'AUDIOS',
	BANNERS: 'BANNERS',
	PROGRAM_DETAIL: 'PROGRAM_DETAIL',
	USER_MANAGER_DETAIL: 'USER_MANAGER_DETAIL',
	PROMOTIONS: 'PROMOTIONS',
	PROMOTION_DETAIL: 'PROMOTION_DETAIL',
	PARTICIPANTS_LIST_POST: 'PARTICIPANTS_LIST_POST',
	LAST_WINNER: 'LAST_WINNER',
	PROMOTION_PARTICIPANTS: 'PROMOTION_PARTICIPANTS',
	PROMOTION_CHECK_VALID_PARTICIPANTS: 'PROMOTION_CHECK_VALID_PARTICIPANTS',
	PURCHASES: 'PURCHASES',
	PURCHASE_DATA: 'PURCHASE_DATA',
	PURCHASE_DETAIL_DATA: 'PURCHASE_DETAIL_DATA'
}
