/* eslint-disable */
import { useState } from 'react'
import { Eye, EyeOff } from 'react-feather'
import { theme } from '../../styles/theme'
import {
	InputWrapper,
	MaxLength,
	MaxLengthWrapper,
	PrimaryInputContainer,
} from './styles'
import { IPrimaryInputProps } from './types'

function Input(props: IPrimaryInputProps) {
	const { type, value, maxLength, ...rest } = props

	const [locked, setEye] = useState(false)

	function handleClickOnEye() {
		setEye(!locked)
	}

	return (
		<>
			<InputWrapper>
				<PrimaryInputContainer
					value={value}
					maxLength={maxLength}
					type={type === 'password' && !locked ? 'password' : 'text'}
					{...rest}
				/>

				{type === 'password' && (
					<>
						{locked ? (
							<span className='at-icon' onClick={handleClickOnEye} data-testid='icon-eye'>
								<Eye size={18} color={theme.colors.greenish_grey_light} />
							</span>
						) : (
							<span className='lock-icon' onClick={handleClickOnEye} data-testid='icon-eye-off'>
								<EyeOff size={18} color={theme.colors.greenish_grey_light} />
							</span>
						)}
					</>
				)}
			</InputWrapper>
			{maxLength && (
				<MaxLengthWrapper>
					<MaxLength>
						{value?.toLocaleString().length}/{maxLength}
					</MaxLength>
				</MaxLengthWrapper>
			)}
		</>
	)
}

export { Input }
