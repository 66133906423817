/* eslint-disable */
import {
	ButtonSubtitle,
	ButtonTitle,
	ImagePreviewWrapper,
	UploadImageButtonWrapper,
	UploadImageContainer,
} from './styles'

import { Upload } from 'react-feather'
import { IUploadImageButtonProps } from './types'

import { Trash2 } from 'react-feather'
import formatBytes from '../../util/bytes'

export function UploadImageButton(props: IUploadImageButtonProps) {
	const { onChange, imageClean, showInfo, label, imageSizeLabel, image } = props

	const { url: imageURL, file } = image
	const fileName = file?.name || ''
	const fileSize = file?.size || 0

	const assetInformation = !!showInfo || !!file

	return (
		<UploadImageContainer data-testid='upload-image'>
			{imageURL ? (
				<ImagePreviewWrapper showInfo={assetInformation || !!imageURL}>
					<div className='image-container'>
						<img id='preview-image' src={imageURL} alt='Imagem de capa' />
						{assetInformation && (
							<div className='text-content-wrapper'>
								{fileName ? <h2>{fileName}</h2> : null}
								{fileSize > 0 ? <h3>{formatBytes(fileSize)}</h3> : null}
							</div>
						)}
					</div>

					<button onClick={imageClean}>
						<Trash2 className='trash-icon' />
					</button>
				</ImagePreviewWrapper>
			) : (
				<label htmlFor='image'>
					<UploadImageButtonWrapper>
						<Upload className='upload-icon' />
						<div>
							<ButtonTitle>{label}</ButtonTitle>
							<ButtonSubtitle>
								{imageSizeLabel || 'Resolução Mínima 150x150'}
							</ButtonSubtitle>
						</div>
					</UploadImageButtonWrapper>
				</label>
			)}

			<input
				className='input'
				id='image'
				type='file'
				accept='.png, .jpg, .jpeg'
				onChange={onChange}
			/>
		</UploadImageContainer>
	)
}
