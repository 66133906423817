import styled, { css } from 'styled-components'

export const PageContainer = styled.div`
    ${() => css`
        padding: 32px 0 120px;
        overflow-x: hidden;

        & > .go-back-button {
            margin-bottom: 16px;
        }
    `}
`

export const PromotionDetailsCard = styled.div`
    ${({ theme }) => css`
        background-color: ${theme.colors.white};
        border-radius: 4px;
        padding: 16px;
        padding-right: 32px;

        display: flex;
        flex-direction: row;

        img.promotion-artwork {
            max-width: 282px;
            object-fit: contain;
            border-radius: 4px;
        }

        div.details-container {
            padding:  24px 0 0 24px;
            width: 100%;

            div.card-details-header {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                h1.title {
                    font-family: ${theme.fonts.radikal_regular};
                    font-weight: 400;
                    font-size: 32px;
                    line-height: 40px;
                    color: ${theme.colors.dark_graffiti};
                }
    
                div.edit-button-wrapper {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
    
                    span.edit-button-message {
                        font-family: ${theme.fonts.radikal_medium};
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 18px;
                        color: ${theme.colors.greenish_grey_light};
                        text-align: right;
                        margin-right: 8px;
                    }
                }
            }

            div.period-label {
                display: flex;
                flex-direction: row;
                align-items: center;

                margin: 8px 0 24px;

                img.calendar-icon {
                    margin-right: 4px;
                    margin-bottom: 4px;
                }

                span.period {
                    font-family: ${theme.fonts.radikal_regular};
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: ${theme.colors.dark_graffiti};
                }
            }
        }
    `}
`

export const ChangePromotionStatusCard = styled.div`
    ${({theme}) => css`
        display: flex;
        flex-direction: row;
        align-items: center;

        background-color: ${theme.colors.white};
        border-radius: 4px;
        padding: 24px;

        margin: 8px 0 48px;

        span.card-title {
            font-family: ${theme.fonts.radikal_regular};
            font-weight: 400;
            font-size: 18px;
            line-height: 26px;
            color: ${theme.colors.dark_graffiti};
        }

        span.status-label {
            font-family: ${theme.fonts.radikal_regular};
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: ${theme.colors.dark_graffiti};

            margin-left: auto;
            margin-right: 12px;
        }
    `}
`

export const ActionButtons = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        align-items: center;

        margin-top: 48px;

        div.divider-row {
            position: relative;

            padding: 32px 0;
            
            div.divider {
                width: 100vw;
                height: 1px;
                background-color: ${theme.colors.light_gray};
    
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        span.cant-delete-message {
            font-family: ${theme.fonts.radikal_medium};
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            color: ${theme.colors.greenish_grey_light};

            margin-top: 16px;
        }
    `}
`
