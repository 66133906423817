/* eslint-disable */
import Chip from '@mui/material/Chip'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { PrimarySelectWrapper, SelectChipStyles } from './styles'
import { IPrimarySelectProps } from './types'

export  function DateSelect(props: IPrimarySelectProps) {
	const { label, data, optionMask, value, ...rest } = props

	return (
		<PrimarySelectWrapper>
			<FormControl className='select-container' >
				<InputLabel id='demo-simple-select-autowidth-label'>{label}</InputLabel>
				<Select
					labelId='demo-simple-select-autowidth-label'
					id='demo-simple-select-autowidth'
					autoWidth
					label={label}
					style={{height:48}}
					value={value}
					renderValue={(selected: any) => <Chip style={SelectChipStyles} label={selected.label} />}
					{...rest}
				>
					{data.map((item) => {
						return (
							<MenuItem key={item.value} value={item as any}>
								{optionMask ? optionMask[item.label] : item.label}
							</MenuItem>
						)
					})}
				</Select>
			</FormControl>
		</PrimarySelectWrapper>
	)
}
