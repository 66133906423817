import styled, { css } from 'styled-components'
import { toRem } from '../../styles/function'

interface ISecondaryInputProps {
	activeBorder: boolean
}

export const SecondaryInputContainer = styled.input<ISecondaryInputProps>`
	${(props) => css`
		padding: 12px 16px;
		width: 318px;
		outline: none;
		background: ${props.theme.colors.white};
		border: none;
		border: 1px solid
			${props.activeBorder
				? props.theme.colors.green
				: props.theme.colors.off_white};
		border-radius: 4px;
		height: 46px;
		font-family: ${props.theme.fonts.radikal_regular};
		color: ${props.theme.colors.greenish_grey};
		font-size: ${toRem(14)};
		transition: 0.2s;
		::placeholder {
			color: ${props.theme.colors.greenish_grey_light};
		}
	`};
`

export const InputWrapper = styled.div`
	${(props) => css`
		position: relative;
		width: 318px;

		.search-icon {
			position: absolute;
			right: 16px;
			top: 12px;
			color: ${props.theme.colors.dark_graffiti};
		}
	`}
`
