import React, { Fragment, useEffect, useState } from 'react'
import {
	ITableProps,
	ITableColumn,
	ITableInstance,
	ITableOptions,
	ITableState,
} from './types'
import { LoadingContent, PlaceholderWrapper, TableWrapper } from './styles'
import { usePagination, useSortBy, useTable } from 'react-table'

import Loading from '../Loading'

import { ChevronLeft, ChevronRight, ArrowUp, ArrowDown } from 'react-feather'
import { formatPagination, getVisiblePages } from '../../util/pagination'

import TablePlaceholder from '../../../assets/images/table-placeholder.svg'
import ResultsTableInfo from '../ResultsTableInfo'

function Table<T extends object>(props: ITableProps<T>) {
	const {
		data = [],
		columns,
		navProps = undefined,
		isLoading,
		getSelectedColumnAcessorAndOrder,
		pageSize = 5,
		sortBy,
		onPageChange,
		extractPage,
		placeholder = 'Não há nenhuma informação para exibir'
	} = props

	const tableOptions: ITableOptions<T> = {
		columns,
		data,
		manualSortBy: !!getSelectedColumnAcessorAndOrder,
	}

	const tableInstance: ITableInstance<T> = useTable<T>(
		tableOptions,
		useSortBy,
		usePagination,
	) as ITableInstance<T>

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
		page,
		state,
		gotoPage,
		previousPage,
		nextPage,
		setPageSize,
	} = tableInstance

	let { canPreviousPage, canNextPage, pageCount } = tableInstance

	let { pageIndex } = state as ITableState<T>
	let totalDocs = rows.length

	if (navProps) {
		pageCount = navProps.pageCount
		pageIndex = navProps.pageIndex
		totalDocs = navProps.totalDocs
		canNextPage = navProps ? pageIndex + 1 < pageCount : canNextPage
		canPreviousPage = navProps ? pageIndex > 0 : canPreviousPage
	}

	function getOrder(isSortedDesc: boolean | undefined) {
		return isSortedDesc === undefined ? -1 : isSortedDesc ? 1 : 0
	}

	function getNextOrder(isSortedDesc: boolean | undefined) {
		return isSortedDesc === undefined ? true : isSortedDesc ? false : undefined
	}

	const callPreviousPage = navProps ? navProps.previousPage : previousPage
	const callNextPage = navProps ? navProps.nextPage : nextPage
	const callGoToPage = navProps ? navProps.gotoPage : gotoPage

	useEffect(() => {
		if (!pageSize) return
		setPageSize(pageSize)
	}, [pageSize])

	return (
		<Fragment>
			<Fragment>
				{!isLoading && data.length ? (
					<Fragment>
						<TableWrapper>
							<table {...getTableProps()}>
								<thead>
									{/* TODO: FINISH TYPES */}
									{headerGroups.map((headerGroup: any, headerIndex) => (
										<Fragment key={`${headerGroup.id}_${headerIndex}`}>
											<tr {...headerGroup.getHeaderGroupProps()}>
												{headerGroup.headers.map((column: ITableColumn<T>) => {
													const getColumnHeaderProps = {
														onClick: () => {
															getSelectedColumnAcessorAndOrder &&
																getSelectedColumnAcessorAndOrder(
																	column.id,
																	getOrder(column.isSortedDesc),
																)

															const isDecending = getNextOrder(
																column.isSortedDesc,
															)

															column.toggleSortBy(isDecending, false)
															isDecending === undefined && column.clearSortBy()
														},
													}

													return (
														<Fragment key={`${column.id}_${headerIndex}`}>
															<th
																{...column.getHeaderProps(
																	column.getSortByToggleProps(),
																)}
																{...(getSelectedColumnAcessorAndOrder
																	? getColumnHeaderProps
																	: undefined)}
															>
																<div>
																	<span>
																		{column.render('Header')}
																	</span>
																	<span className='icon-sorting'>
																		{!getSelectedColumnAcessorAndOrder &&
																			column.isSorted
																			? column.isSortedDesc
																			? <ArrowDown width={'14px'} height={'14px'} />
																			: <ArrowUp width={'14px'} height={'14px'}/>
																			: ''
																		}
																		{sortBy &&
																			column.id === sortBy?.acessor &&
																			sortBy?.order !== 0
																			? sortBy?.order === -1
																			? <ArrowDown width={'14px'} height={'14px'}/>
																			: <ArrowUp width={'14px'} height={'14px'}/>
																			: ''
																		}
																	</span>
																</div>
															</th>
														</Fragment>
													)
												})}
											</tr>
										</Fragment>
									))}
								</thead>

								<tbody {...getTableBodyProps()}>
									{page.map((row, rowIndex) => {
										prepareRow(row)
										return (
											<Fragment key={`${row.id}_${rowIndex}`}>
												<tr {...row.getRowProps()}>
													{row.cells.map((cell, cellIndex) => {
														return (
															<Fragment key={`${cell.value}_${cellIndex}`}>
																<td {...cell.getCellProps()}>
																	{cell.render('Cell')}
																</td>
															</Fragment>
														)
													})}
												</tr>
											</Fragment>
										)
									})}
								</tbody>
							</table>
							
							<div className='table-footer'>
								<ResultsTableInfo
									page={navProps ? (extractPage?.page as number) : pageIndex + 1}
									totalDocs={navProps ? extractPage?.totalDocs : data.length}
									numberOfLines={pageSize}
								/>
								<div className='pagination'>
									<button
										className='button'
										onClick={() => {
											callPreviousPage(pageIndex - 1)
											if (onPageChange) onPageChange(pageIndex - 1)
										}}
										disabled={!canPreviousPage}
									>
										<ChevronLeft />
									</button>

									<div className='page-numbers'>
										{getVisiblePages(pageIndex + 1, pageCount).map(
											(x, idx, arr) => {
												return (
													<Fragment key={idx}>
														<span
															className={`page-number ${
																x - 1 === pageIndex ? 'active' : ''
															}`}
															onClick={() => {
																callGoToPage(x - 1)
																if (onPageChange) onPageChange(x - 1)
															}}
														>
															{formatPagination(x, idx, arr)}
														</span>
													</Fragment>
												)
											},
										)}
									</div>
									<button
										className='button'
										onClick={() => {
											callNextPage(pageIndex + 1)
											if (onPageChange) onPageChange(pageIndex + 1)
										}}
										disabled={!canNextPage}
									>
										<ChevronRight />
									</button>
								</div>
							</div>
						</TableWrapper>
					</Fragment>
				) : (
					<div>
						{isLoading ? (
							<LoadingContent>
								<Loading />
							</LoadingContent>
						) : (
							<PlaceholderWrapper>
								<img src={TablePlaceholder} alt='placeholder-image' />

								<p>{placeholder}</p>
							</PlaceholderWrapper>
						)}
					</div>
				)}
			</Fragment>
		</Fragment>
	)
}

export default Table
