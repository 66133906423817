import { ChangeEvent, createElement, useEffect, useState } from 'react'
import { IViewProps } from './types'
import { isPast } from 'date-fns'
import { NewPromotionView } from './view'

import { IImageUpload } from '../../shared/interfaces/image'
import { INITIAL_STATE_IMAGE_UPLOAD } from '../../shared/util/image'

import { useHistory } from 'react-router-dom'
import { IRangeDate } from '../../shared/components/ProductModal/types'
import { Descendant } from 'slate'
import { useCreatePromotion } from '../../shared/service/promotions.service'
import {
	handleErrorToaster,
	handleSuccessToaster,
} from '../../shared/util/toaster'
import { useMutationPhotoUpload } from '../../shared/service/user.service'
function NewPromotion(): JSX.Element {
	const [promotionName, setPromotionName] = useState('')
	const [promotionAmount, setPromotionAmount] = useState(0)
	const [canSubmit, setCanSubmit] = useState(true)
	const [descriptionReturnIsEmpty, setDescriptionReturnIsEmpty] =
		useState(false)

	const [regulationReturnIsEmpty, setRegulationReturnIsEmpty] = useState(false)

	const [dateIsEmpty, setDateIsEmpty] = useState(true)

	const [description, setDescription] =
		useState<Descendant[] | undefined>(undefined)
	const [regulation, setRegulation] =
		useState<Descendant[] | undefined>(undefined)
	const [rangeDate, setRangeDate] = useState<IRangeDate>({})

	function handleChangeRangeDate(range: IRangeDate) {
		setRangeDate(range)
	}

	const [isLoading, setIsLoading] = useState(false)

	const [image, setImage] = useState<IImageUpload>(INITIAL_STATE_IMAGE_UPLOAD)

	const photoUploadMutation = useMutationPhotoUpload()

	const history = useHistory()

	function handlePromotionDescription(newValue: Descendant[]) {
		setDescription(newValue)
	}

	function handlePromotionRegulation(newValue: Descendant[]) {
		setRegulation(newValue)
	}

	function handleChangePromotionName(
		event: React.ChangeEvent<HTMLInputElement>,
	) {
		setPromotionName(event.target.value)
	}

	function handleChangePromotionAmount(
		event: React.ChangeEvent<HTMLInputElement>,
	) {
		const promotionNameAsNumber = Number(event.target.value.replace(/\D/g, ''))
		setPromotionAmount(promotionNameAsNumber)
	}

	function handleCleanFields() {
		setPromotionName('')
		setImage(INITIAL_STATE_IMAGE_UPLOAD)
		setRangeDate({})
		setDescription(undefined)
		setRegulation(undefined)
	}

	function handleGoBack() {
		history.goBack()
	}

	const mutation = useCreatePromotion()

	const handleSubmit = async () => {
		try {
			setIsLoading(true)

			let artworkURL = ''

			if (image.file) {
				artworkURL = await photoUploadMutation.mutateAsync({ file: image.file })
			}

			const newPromotion = {
				artworkURL,
				title: promotionName,
				startDate: rangeDate.startDate,
				endDate: rangeDate.endDate,
				description: JSON.stringify(description),
				stockQuantity: promotionAmount,
				regulation: JSON.stringify(regulation),
			}
			await mutation.mutateAsync(newPromotion)
			history.push('/promotions')
			handleSuccessToaster('Promoção criada com sucesso.')
		} catch (error) {
			handleErrorToaster('Não foi possivel criar a promoção')
		} finally {
			setIsLoading(false)
		}
	}

	async function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
		const selectedImage = event.target?.files?.item(0)

		if (!selectedImage) return

		const imageURL = URL.createObjectURL(selectedImage)

		setImage({ url: imageURL, file: selectedImage, overwrite: true })

		event.target.value = ''
	}

	function cleanImageSeletor() {
		setImage({ file: null, url: '', overwrite: true })
	}

	useEffect(() => {
		if (rangeDate.endDate !== undefined && rangeDate.startDate !== undefined) {
			setDateIsEmpty(false)
		} else {
			setDateIsEmpty(true)
		}
	}, [rangeDate])

	function handleValidateForm() {
		const imageIsEmpty = image.url === ''
		const titleIsEmpty = promotionName.trim() === ''
		const dateIsPast = rangeDate.endDate && isPast(rangeDate.endDate)
		const amountIsZero = promotionAmount === 0
		if (
			imageIsEmpty ||
			titleIsEmpty ||
			dateIsEmpty ||
			dateIsPast ||
			descriptionReturnIsEmpty ||
			amountIsZero ||
			regulationReturnIsEmpty
		) {
			setCanSubmit(false)
		} else {
			setCanSubmit(true)
		}
	}

	useEffect(handleValidateForm, [
		image,
		promotionName,
		dateIsEmpty,
		rangeDate,
		descriptionReturnIsEmpty,
		promotionAmount,
		regulationReturnIsEmpty,
	])

	useEffect(() => {
		handleCleanFields()
	}, [])

	const viewProps: IViewProps = {
		handleChangePromotionName,
		handleInputChange,
		cleanImageSeletor,
		image,
		promotionName,
		handleGoBack,
		rangeDate,
		handleChangeRangeDate,
		description,
		handlePromotionDescription,
		regulation,
		handlePromotionRegulation,
		handleSubmit,
		handleChangePromotionAmount,
		promotionAmount,
		canSubmit,
		setDescriptionReturnIsEmpty,
		setRegulationReturnIsEmpty,
		dateIsEmpty,
		isLoading,
	}

	return createElement(NewPromotionView, viewProps)
}

export default NewPromotion
