import React from 'react'
import {
	ContainerHeaderWrapper,
	HeaderWrapper,
	IconImage,
	ImageWrapper,
	MenuButtonWrapper,
	MenuWrapper,
} from './styles'

import imageLogo from '../../../assets/images/educadora-horizontal-black.svg'

import {ImageProfile} from '../ImageProfile'
import { IHeaderProps } from './types'

import navMenuIcon from '../../../assets/icons/nav-menu-icon.svg'
import close from '../../../assets/icons/x.svg'

function Header(props: IHeaderProps) {
	const { userName, handleSetIsOpen, isOpen, pagePathName } = props

	function handleClickMenuIcon() {
		const isOnTheParticipantDrawnScreen =
			pagePathName.includes('/promotions/drawn/')

		if (!isOpen && isOnTheParticipantDrawnScreen) {
			window.alert(
				'Você ainda não concluiu o sorteio!\nAo sair da página, o ouvinte sorteado será desconsiderado.',
			)
		}
		handleSetIsOpen()
	}

	return (
		<HeaderWrapper>
			<ImageWrapper>
				<MenuWrapper>
					<MenuButtonWrapper isOpen={isOpen} onClick={handleClickMenuIcon} data-testid='button-menu-hamburguer'>
						{!isOpen ? (
							<IconImage src={navMenuIcon} alt='Menu' width={22} height={22} data-testid='icon-hamburguer'/>
						) : (
							<IconImage src={close} alt='close' width={22} height={22} data-testid='icon-close'/>
						)}
					</MenuButtonWrapper>
				</MenuWrapper>
			</ImageWrapper>
			<div className='separator' />
			<ContainerHeaderWrapper>
				<img src={imageLogo} alt='logo-icon' />

				<div>
					<div className='account-info'>
						<ImageProfile userName={userName} />
					</div>
				</div>
			</ContainerHeaderWrapper>
		</HeaderWrapper>
	)
}

export default Header
