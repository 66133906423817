import React, { Fragment } from 'react'
import { format } from 'date-fns'

import imageEmpty from '../../assets/images/placeholder-empty-image.svg'
import festIcon from '../../assets/icons/fest-icon.svg'
import dateIcon from '../../assets/icons/date-icon.svg'
import eclubIcon from '../../assets/icons/educadora-e.svg'

import { Button } from '../../shared/components'
import RaffleCountModal from '../../shared/components/RaffleCountModal'
import Loading from '../../shared/components/Loading'
import { RedoRaffleConfirmationModal } from './RedoRaffleConfirmationModal'

import * as S from './styles'
import { IViewProps } from './types'

function ParticipantDrawn(props: IViewProps) {
	const {
		promotionDetails,
		participantDrawn,
		redoRaffleConfirmationModal,
		isRedoingRaffle,
		raffleCounterModal,
		handleConcludePromotion,
	} = props

	return (
		<Fragment>
			{isRedoingRaffle ? (
				<S.LoadingWrapper>
					<Loading />
				</S.LoadingWrapper>
			) : (
				<S.Container>
					<Fragment>
						<S.ParticipantDrawnCard>
							<div className='user-image-wrapper'>
								<img
									className='user-photo'
									src={participantDrawn.photoURL || imageEmpty}
								/>
								<img className='fest-icon' src={festIcon} />
							</div>
							<h1 className='congrats-title'>
								Parabéns, {participantDrawn.name.trim()}!
							</h1>
							<h2 className='congrats-subtitle'>
								Por ser o ouvinte sorteado na promoção {promotionDetails.title}
							</h2>
							<div className='raffle-date'>
								<img className='calendar-icon' src={dateIcon} />
								<span className='raffle-date-text'>
									Sorteio realizado em {/* eslint-disable-next-line quotes */}
									{format(new Date(), "dd/MM/yyyy, 'às' HH:mm")}
								</span>
							</div>

							<img className='logo-eclub' src={eclubIcon} />
						</S.ParticipantDrawnCard>
						<S.PageSeparator />
						<S.ButtonsWrapper>
							<Button.Root
								theme='dark'
								onClick={redoRaffleConfirmationModal.toggleVisibility}
								spacing={'12x24'}
							>
								<Button.Text text='Refazer Sorteio' />
							</Button.Root>
							<Button.Root
								theme='light'
								onClick={handleConcludePromotion}
								spacing={'12x24'}
							>
								<Button.Text text='Concluir Sorteio' />
							</Button.Root>
						</S.ButtonsWrapper>
					</Fragment>
				</S.Container>
			)}
			<RaffleCountModal {...raffleCounterModal} />
			<RedoRaffleConfirmationModal {...redoRaffleConfirmationModal} />
		</Fragment>
	)
}

export default ParticipantDrawn
