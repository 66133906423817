import { useEffect, useState } from 'react'
import { useDebounce } from 'use-debounce'
import { IPaginationResponse } from '../interfaces/pagination'
import { UseQueryResult } from 'react-query'
import { ITableNavProps } from '../components/Table/types'

export interface IPaginationRequestParamsBase {
	maxItemsInPage: number
	pageIndex: number
	searchString?: string
	sort?: string
	order?: number
}

interface ITableFormatting<ServiceResponseType> {
	// eslint-disable-next-line @typescript-eslint/ban-types
	tableColumns: Array<{}>
	// eslint-disable-next-line @typescript-eslint/ban-types
	tableRowsFormatter: (data: ServiceResponseType[]) => Array<{}>
}

export function useTablePagination<
	ServiceAdditionalParams,
	ServiceResponseType,
>({
	serviceHook,
	serviceAdditionalParams,
	tableFormatting: { tableColumns, tableRowsFormatter },
	serviceParamsBase,
}: {
	serviceHook: (
		params: ServiceAdditionalParams & IPaginationRequestParamsBase,
	) => UseQueryResult<IPaginationResponse<ServiceResponseType>>
	serviceAdditionalParams: ServiceAdditionalParams
	tableFormatting: ITableFormatting<ServiceResponseType>
	serviceParamsBase?: Partial<IPaginationRequestParamsBase>
}) {
	const [searchString, setSearchString] = useState(
		serviceParamsBase?.searchString || '',
	)
	const [searchDebounce] = useDebounce(searchString, 1000)

	const [pageProps, setPageProps] = useState({
		page: serviceParamsBase?.pageIndex ? serviceParamsBase.pageIndex + 1 : 1,
		numberOfPages: 0,
		totalDocs: 0,
	})

	const { data, isLoading } = serviceHook({
		maxItemsInPage: serviceParamsBase?.maxItemsInPage || 10,
		pageIndex: pageProps.page,
		searchString: searchDebounce,
		...serviceAdditionalParams,
	})
	const dataDocs = data?.docs || []

	const tableRows = tableRowsFormatter(dataDocs)

	function goToPage(pageIndex: number) {
		setPageProps((state) => ({ ...state, page: pageIndex + 1 }))
	}

	const navProps: ITableNavProps = {
		nextPage: (pageIndex) => goToPage(pageIndex),
		previousPage: (pageIndex) => goToPage(pageIndex),
		gotoPage: (pageIndex) => goToPage(pageIndex),
		pageCount: pageProps.numberOfPages,
		pageIndex: pageProps.page - 1,
		totalDocs: pageProps.totalDocs,
	}

	useEffect(() => {
		if (!data) return
		setPageProps({
			numberOfPages: data.totalPages,
			page: data.page || 1,
			totalDocs: data.totalDocs,
		})
	}, [data])

	useEffect(() => {
		goToPage(0)
	}, [searchDebounce])

	return {
		searchString,
		setSearchString,
		navProps,
		pageProps,
		tableColumns,
		tableRows,
		isLoading,
	}
}
