import { toast, ToastOptions } from 'react-toastify'

export const ToasterDefaultOptions: ToastOptions = {
	position: 'top-right',
	autoClose: 5000,
	hideProgressBar: false,
	closeOnClick: true,
	pauseOnHover: true,
	draggable: true,
	progress: undefined,
}

export function handleErrorToaster(message = '') {
	toast.error(message, ToasterDefaultOptions)
}

export function handleSuccessToaster(message = '') {
	toast.success(message, ToasterDefaultOptions)
}
