import styled, { css } from 'styled-components'

import BackgroundIMG from '../../assets/images/background-image.png'
import { mediaQuery, toRem } from '../../shared/styles/function'

export const LoginWrapper = styled.div`
	width: 100%;
	height: 100vh;

	background: ${(props) => props.theme.colors.pure_black};
	display: flex;
	justify-content: space-between;
	align-items: center;

	${mediaQuery('md')(`
        flex-direction: column;
	    justify-content: center;
	`)};
`

export const BackgroundImageWrapper = styled.div`
	background-image: url(${BackgroundIMG});

	width: 50%;
	height: 100vh;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	justify-content: center;
	display: flex;

	padding: 134px;

	img {
		width: 318px;
		height: 130.46px;
	}

	${mediaQuery('md')(`
        width: 100%;
		padding: 0;
		align-items: center;
	`)};
`

export const FormContainer = styled.div`
	width: 50%;
	height: 100vh;

	display: flex;
	justify-content: center;
	align-items: center;

	${mediaQuery('md')(`
        width: 90%;
	`)};
`

export const FormWrapper = styled.form`
	background: ${(props) => props.theme.colors.white};
	width: 430px;
	height: 418px;

	padding: 80px 53px;
	border-radius: 4px;
	flex-direction: column;
	justify-content: center;

	.button-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 16px;

		height: 50px;

		${mediaQuery('md')(`
			margin-top: 8px;
		`)};
	}

	${mediaQuery('md')(`
		padding: 40px 26.5px;
		width: 100%;
		height: 350px
	`)};

	${mediaQuery('sm')(`
		padding: 40px 26.5px;
		width: 100%;
		height: 280px
	`)};
`

export const InputWrapper = styled.div`
	${({ theme }) => css`
		margin-top: 32px;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;

		div {
			display: flex;
			justify-content: space-between;
			margin-top: 16px;

			${mediaQuery('md')(`
				margin-top: 8px;
			`)};

			span {
				font-size: ${toRem(12)};
				cursor: pointer;
				font-family: ${theme.fonts.radikal_regular};

				a {
					color: ${theme.colors.dark_graffiti};
				}
			}
		}

		${mediaQuery('md')(`
			margin-top: 0px;
		`)};
	`};
`

export const FormTitle = styled.span`
	font-size: ${toRem(24)};
	font-family: ${({ theme }) => theme.fonts.radikal_light};
	color: ${(props) => props.theme.colors.dark_graffiti};
`
