import React from 'react'
import { IViewProps } from './types'

import * as S from './styles'
import { TitlePage } from '../Users/styles'
import SecondaryInput from '../../shared/components/SecondaryInput'
import Table from '../../shared/components/Table'

function Listeners(props: IViewProps) {
	const {
		searchString,
		handleListenerChange,
		handleSortColumn,
		tableColumns,
		isLoading,
		tableRows,
		navProps,
		usersPage,
		sortBy,
	} = props

	return (
		<S.ListenerWrapper>
			<header>
				<div>
					<TitlePage data-testid='title-page'>Ouvintes</TitlePage>
					<SecondaryInput
						className='search-input'
						placeholder='Buscar Ouvinte'
						value={searchString}
						onChange={handleListenerChange}
					/>
				</div>
			</header>

			<S.TableWrapper>
				<Table
					columns={tableColumns as any}
					data={tableRows}
					isLoading={isLoading}
					navProps={navProps}
					extractPage={{ page: usersPage.page, totalDocs: usersPage.totalDocs }}
					getSelectedColumnAcessorAndOrder={handleSortColumn}
					sortBy={sortBy}
				/>
			</S.TableWrapper>
		</S.ListenerWrapper>
	)
}

export { Listeners }
