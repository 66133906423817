import React from 'react'
import SecondaryInput from '../../shared/components/SecondaryInput'
import Table from '../../shared/components/Table'

import { TableWrapper, TitlePage, UsersContainer } from './styles'
import { IViewProps } from './types'
import { useTypedSelector } from '../../shared/hooks/useTypedSelector'
import DeleteModal from '../../shared/components/DeleteModal'
import { Button } from '../../shared/components'

export function Promotions(props: IViewProps) {
	const {
		handleUserChange,
		searchString,
		tableColumns,
		tableRows,
		isLoading,
		handleModalOpen,
		handleSortColumn,
		sortBy,
		navProps,
		userPage,
		deleteModalOpen,
		handleCloseModal,
		handleSubmit,
	} = props
	const { user } = useTypedSelector(['token', 'user'])
	const listIsPresenters =
		user.roles.length === 1 && user.roles.includes('PRESENTER')
	return (
		<UsersContainer>
			<>
				<header>
					<div>
						<TitlePage data-testid='title-page'>Promoções</TitlePage>
						<SecondaryInput
							className='search-input'
							placeholder='Buscar Promoção'
							value={searchString}
							onChange={handleUserChange}
						/>
					</div>
					{listIsPresenters ? null : (
						<Button.Root onClick={handleModalOpen} spacing='12x24' data-testid='button-new-promotion'>
							<Button.Text text='Nova Promoção' />
						</Button.Root>
					)}
				</header>

				<TableWrapper>
					<Table
						pageSize={5}
						columns={tableColumns as any}
						data={tableRows}
						isLoading={isLoading}
						getSelectedColumnAcessorAndOrder={handleSortColumn}
						sortBy={sortBy}
						navProps={navProps}
						extractPage={{ page: userPage.page, totalDocs: userPage.totalDocs }}
					/>
				</TableWrapper>
			</>
			{deleteModalOpen ? (
				<DeleteModal
					title='Promoção'
					description='Deseja realmente excluir esta Promoção? Esta ação não poderá ser desfeita após a confirmação de exclusão.'
					handleCloseModal={handleCloseModal}
					handleSubmit={handleSubmit}
				/>
			) : null}
		</UsersContainer>
	)
}
