import styled, { css } from 'styled-components'
import { UserStatus } from '../../shared/interfaces/user'
import { toRem } from '../../shared/styles/function'
import { IUserStatusTable } from './types'

export const UsersContainer = styled.div`
	header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 32px;

		div {
			display: flex;
			align-items: center;

			.search-input {
				margin-left: 48px;
			}
		}
	}
`

export const TitlePage = styled.span`
	${({ theme }) => css`
		color: ${theme.colors.dark_graffiti};
		font-family: ${theme.fonts.radikal_light};
		font-size: ${toRem(24)};
	`}
`

export const TableWrapper = styled.div`
	margin-top: 16px;
`

export const DetailButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	background: transparent;
	border: none;
	cursor: pointer;
	padding: 10px;
	border-radius: 50%;
	transition: 0.4s;
	color: ${({theme}) => css`${theme.colors.dark_graffiti}`};

	:hover{
		background-color: ${({theme}) => css`${theme.colors.off_white}`};
	}
`
export const RoleWrapper = styled.div`
	padding: 20px 0px;
	display: flex;

	${({ theme }) => css`
		h4 {
			font-size: ${toRem(12)};
			color: ${theme.colors.dark_graffiti};
			margin: 6px;
			border: 1px solid ${theme.colors.dark_graffiti};
			padding: 4px 10px;
			border-radius: 13px;
			text-transform: lowercase;
			font-family: ${theme.fonts.radikal_regular};

			&::first-letter {
				text-transform: uppercase;
			}
		}
	`}
`

export const ImageWrapper = styled.div`
	${({ theme }) => css`
		width: 46px;
		height: 46px;
		overflow: hidden;
		object-fit: cover;
		border-radius: 4px;
		background: ${theme.colors.light_gray};

		.report-image {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	`}
`
export const TableImagePlaceholder = styled.div`
	${({ theme }) => css`
		width: 46px;
		height: 46px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 2px;
		background: ${theme.colors.off_white};
		border: 1px solid ${theme.colors.light_gray};
		border-radius: 4px;

		.placeholder-icon {
			width: ${toRem(20)};
			height: ${toRem(20)};
			color: ${theme.colors.greenish_grey_light};
		}
	`}
`

export const UserTableStatus = styled.div<IUserStatusTable>`
	border: 1px solid ${({ theme }) => theme.colors.greenish_grey_light};
	display: flex;
	border-radius: 13px;
	justify-content: center;
	align-items: center;
	padding: 3px 8px;

	width: 85px;
	height: 22px;

	h4 {
		font-size: ${toRem(12)};
		color: ${({ theme }) => theme.colors.dark_graffiti};
		font-family: ${({ theme }) => theme.fonts.radikal_regular};

		text-transform: lowercase;

		&::first-letter {
			text-transform: uppercase;
		}
	}

	div {
		width: 8px;
		height: 8px;

		margin-right: 4px;
		border-radius: 8px;

		background: ${(props) =>
			props.status === UserStatus.ACTIVE
				? props.theme.colors.green
				: props.theme.colors.yellow};
	}
`

export const InviteButton = styled.button`
	${({ theme }) => css`
		border: none;
		cursor: pointer;
		background: ${theme.colors.green};
		padding: 4px 8px 2px;
		border-radius: 4px;
		font-size: ${toRem(12)};
		color: ${theme.colors.dark_graffiti};
		font-family: ${theme.fonts.radikal_regular};
	`}
`
export const DeleteWrapper = styled.div``

export const DeleteButton = styled.button`
	${({ theme, disabled }) => css`
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: transparent;
		padding: 10px;
		border: none;
		border-radius: 50%;
		transition: 0.4s;
		cursor: ${disabled ? '' : 'pointer'};
		color: ${disabled ? '' : theme.colors.dark_graffiti};

		${!disabled &&
			css`
				:hover {
				background-color: ${theme.colors.off_white};
				color: ${theme.colors.pure_black};
				}
			`
		}
	`};
`

export const TagWrapper = styled.div`
	${({ theme }) => css`
		display: flex;
		align-items: center;
		justify-content: center;
		max-width: 74px;
		padding: 4px 8px 2px;
		background-color: ${theme.colors.off_white};
		border-radius: 13px;
		p {
			font-size: 12px;
			font-style: normal;
			font-weight: 500;
			line-height: 18px;
			color: ${theme.colors.greenish_grey_medium};
		}
	`}
`

export const TagWrapper2 = styled.div`
	${({ theme }) => css`
		display: flex;
		align-items: center;
		justify-content: center;
		max-width: 74px;
		padding: 4px 8px 2px;
		background-color: transparent;
		border-radius: 13px;
		border: 1px solid ${theme.colors.dark_graffiti};
		p {
			font-size: 12px;
			font-style: normal;
			font-weight: 500;
			line-height: 18px;
		}
	`}
`

export const TagWrapper3 = styled.div`
	${({ theme }) => css`
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: nowrap;
		max-width: 106px;
		padding: 4px 8px 2px;
		background-color: transparent;
		border-radius: 13px;
		border: 1px solid ${theme.colors.dark_graffiti};
		p {
			font-size: 12px;
			font-style: normal;
			font-weight: 500;
			line-height: 18px;
		}
	`}
`

export const FinalizedTagText = styled.p`
	${({ theme }) => css`
		font-size: 12px;
		font-style: normal;
		font-weight: 500;
		line-height: 18px;
		color: ${theme.colors.greenish_grey_medium};
	`}
`
