import styled, { css } from 'styled-components'

export const PageInfoContainer = styled.div`
	${({ theme }) => css`
		color: ${theme.colors.greenish_grey_medium};
		font-size: ${theme.fonts.radikal_light};
		font-size: 14px;
		bottom: 120px;
	`}
`
