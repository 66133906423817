import styled, { css } from 'styled-components'

export const ModalBackground = styled.div`
	width: 100%;
	height: 100%;
	position: fixed;

	display: flex;
	align-items: center;
	justify-content: center;

	background: rgba(0, 0, 0, 0.56);
	color: black;
	top: 0;
	left: 0;
	z-index: 999;
`

export const ModalContainer = styled.div`
	${({ theme }) => css`
		width: 427px;
		height: auto;
		background-color: rgba(255, 255, 255);
		border-radius: 8px;
		border: 0.5px solid ${theme.colors.greenish_grey_light};
	`}
`

export const Header = styled.div`
	${({ theme }) => css`
		display: flex;
		align-items: center;
		justify-content: space-between;

		padding: 40px 24px 32px;
		border-bottom: 1px solid ${({ theme }) => theme.colors.light_gray};

		h1.title {
			font-family: ${theme.fonts.radikal_light};
			font-weight: 300;
			font-size: 24px;
			line-height: 32px;
			color: ${theme.colors.dark_graffiti};
		}

		svg.close-icon {
			color: ${({ theme }) => theme.colors.dark_graffiti};
			width: 24px;
			height: 24px;

			transition: 0.2s;

			&:hover{
				cursor: pointer;
				color: ${({ theme }) => theme.colors.pure_black};
			}
		}`
	}
`

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 48px 24px 56px;
`

export const ParagraphText = styled.p`
	${({ theme }) => css`
		font-family: ${theme.fonts.radikal_regular};
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		color: ${theme.colors.dark_graffiti};
		text-align: center;
	`}
`

export const Spacing = styled.div<{ size: number }>`
	${({ size }) => css`
		width: 100%;
		height: ${size}px;
	`}
`
