import { ITableNavProps } from '../../shared/components/Table/types'
import { IMusic, IMusicPage } from '../../shared/interfaces/music'

export interface IViewProps {
	searchString: string
	handleSearchChange(event: React.ChangeEvent<HTMLInputElement>): void
	navProps: ITableNavProps
	tableColumns: ITableColumn[]
	tableRows: ITableRow[]
	loading: boolean
	handleSortColumn(acessor: string, order: number): void
	handleCloseModal(): void
	modalIsOpen: boolean
	currentMusic: IMusic | null
	sortBy: IAcessorAndOrder
	musicPage: IMusicPage
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ITableColumn {}

export interface IAcessorAndOrder {
	acessor: string
	order: number
}

export interface ITableRow {
	name: string
	interpreter: string
	totalPlayed: string
	editIcon: JSX.Element
}

export enum ETableAudioAcessor {
	image = 'image',
	name = 'name',
	interpreter = 'interpreter',
	totalPlayed = 'totalPlayed',
	editIcon = 'editIcon',
}
