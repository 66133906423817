import styled, { css } from 'styled-components'
import { toRem } from '../../shared/styles/function'
import { theme } from '../../shared/styles/theme'

export const UsersDetailContainer = styled.div`
	header {
		display: flex;
		align-items: center;
		justify-content: space-between;

		div {
			display: flex;
			align-items: center;

			.search-input {
				margin-left: 48px;
			}
		}
	}
`

export const TitlePage = styled.span`
	${({ theme }) => css`
		color: ${theme.colors.dark_graffiti};
		font-family: ${theme.fonts.radikal_light};
		font-size: ${toRem(24)};
	`}
`

export const UserInformationWrapper = styled.div``

export const UserImage = styled.img`
	width: 108px;
	height: 108px;
	border-radius: 54px;
	object-fit: cover;
`

export const FormWrapper = styled.div`
	display: flex;
	margin-top: 56px;
`

export const InputWrapper = styled.div`
	flex: 1;
	justify-content: space-between;
	flex-direction: column;
	display: flex;
	margin-left: 16px;

	div {
		display: flex;
	}
`

export const InputContainer = styled.div`
	width: 306px;
	margin-right: 8px;
`

export const InputSecondContainer = styled.div`
	width: 306px;
`

export const BeatCoinsCarWrapper = styled.div`
	margin-left: 16px;
`
export const UserCardWrapper = styled.div`
	margin-left: 16px;
`

export const UserInfoWrapper = styled.div`
	display: flex;
`

export const ListenerInfo = styled.div`
	margin-top: 56px;
`

export const TableWrapper = styled.div`
	margin-top: 24px;
	margin-bottom: 48px;
	::after {
		border-top: 1px solid #ebf0ed;
	}
`

export const Spacing = styled.div`
	width: 100%;
	height: 1px;
	background-color: #ebf0ed;
	margin-top: -24px;
	position: absolute;
	left: 0;
`

export const HeaderWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`

export const LoadingContainer = styled.div`
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	flex-direction: column;
`

export const ImageProfileWrapper = styled.div`
	${({ theme }) => css`
		background-color: ${theme.colors.greenish_white};
		width: 108px;
		height: 108px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		border-width: 5px;
		border-color: ${theme.colors.off_white};
		border-style: solid;

		span {
			font-size: ${toRem(24)};
			color: ${theme.colors.greenish_grey_light};
			font-weight: 700;
			font-family: ${theme.fonts.radikal_regular};
		}
	`}
`

export const PointsContainer = styled.div`
	display: flex;
`

export const PointsWrapper = styled.div`
	${({ theme }) => css`
		padding: 4px 8px 2px;
		border-radius: 13px;
		border: 1px solid ${theme.colors.dark_graffiti};
	`}
`

export const PointsLabel = styled.span`
	${({ theme }) => css`
		color: ${theme.colors.dark_graffiti};
		font-size: 12px;
		font-family: ${theme.fonts.radikal_regular};
	`}
`

export const ResultsWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid ${theme.colors.dark_graffiti};
	border-radius: 20px;
	width: 70px;
`

export const DeleteUserBunttonWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 120px;
`

export const DeleteUserBuntton = styled.button`
	padding: 12px 24px;
	border: none;
	border-radius: 4px;
	cursor: pointer;
	background-color: #ffffff;
	transition: 0.5s;
	:hover {
		background-color: #ebf0ed;
	}
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	color: ${(props) => props.theme.colors.dark_graffiti};
`
export const TextSwitch = styled.p`
	${({ theme }) => css`
		font-size: 12px;
		font-style: normal;
		font-weight: 500;
		line-height: 18px;
		color: ${theme.colors.greenish_grey_medium};
		margin-left: 8px;
	`}
`

export const SwitchWrapper = styled.div`
	${({ theme }) => css`
		display: flex;
		align-items: center;
	`}
`
