import styled from 'styled-components'
import { css } from 'styled-components'

export const CardAndDividerWrapper = styled.div`
    ${() => css`
        position: relative;
        padding-bottom: 32px;
        margin-bottom: 32px;
    `}
`

export const Divider = styled.div`
    ${({ theme }) => css`
        width: 100vw;
        height: 1px;
        background-color: ${theme.colors.light_gray};

        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    `}
`

export const FinishedPrizeDrawCard = styled.div`
    ${({ theme }) => css`
        background-color: ${theme.colors.dark_graffiti};
        border-radius: 4px;
        padding: 40px 32px;

        display: flex;
        flex-direction: column;
        align-items: flex-start;

        span.title {
            font-family: ${theme.fonts.radikal_regular};
            font-weight: 400;
            font-size: 32px;
            line-height: 40px;
            color: ${theme.colors.white};

            margin-bottom: 12px;
        }

        span.subtitle {
            font-family: ${theme.fonts.radikal_regular};
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;

            color: ${theme.colors.white};
            span.finish-date {
                color: ${theme.colors.green};

                span.finish-date-month {
                    text-transform: capitalize;
                }
            }

            margin-bottom: 24px;
        }

        div.winners {
            display: flex;
            flex-direction: row;
            align-items: center;

            div.winners-images {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding-left: 20px;
                margin-right: 8px;

                img.participant-image {
                    height: 40px;
                    width: 40px;
                    border-radius: 50px;
                    border: 2px solid ${theme.colors.dark_graffiti};
                    margin-left: -20px;
                }
            }

            span.winners-label {
                font-family: ${theme.fonts.radikal_regular};
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: ${theme.colors.white};
            }
        }
    `}
`

export const RaffleCard = styled.div`
    ${({ theme }) => css`
        background-color: ${theme.colors.green};
        border-radius: 4px;
        padding: 32px 40px 32px 32px;

        display: flex;
        flex-direction: row;
        align-items: center;

        overflow: hidden;

        span.title {
            font-family: ${theme.fonts.radikal_regular};
            font-weight: 400;
            font-size: 24px;
            line-height: 32px;
            color: ${theme.colors.pure_black};
            margin-right: auto;
        }

        span.invalid-participants-message {
            font-family: ${theme.fonts.radikal_medium};
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            color: ${theme.colors.pure_black};
            text-align: right;
        }
        
        div.invalid-participants-info-button {
            margin: 0 19px 0 11px;
        }

        div.participants-images-slide {
            margin: -38px 40px -56px 0;

            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-auto-flow: column;
            gap: 12px;

            img.participant-image {
                height: 46px;
                width: 46px;
                border-radius: 4px;
            }
            
            img:nth-child(-n + 3) {
                grid-column: 1;
                margin-top: -18px;
            }
            
            img:nth-child(n + 4) {
                grid-column: 2;
            }

        }
    `}
`
