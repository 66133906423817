/* eslint-disable */
import * as S from './styles'
import { Fragment } from 'react'
import { IViewProps } from './types'
import { UploadImageButton } from '../../shared/components/UploadImageButton'
import { Input } from '../../shared/components/Input'
import SecondarySelect from '../../shared/components/SecondarySelect'
import { Button, GoBackButton } from '../../shared/components'

import TextArea from '../../shared/components/TextArea'

function NewUserView(props: IViewProps) {
	const {
		handleSelectChange,
		handleChangeUserName,

		handleInputChange,
		cleanImageSeletor,
		handleOpenDeleteUserModal,
		handleCloseDeleteUserModal,
		handleSubmit,
		handleChangeEmail,
		selectedRoles,
		image,
		userName,
		email,
		facebook,
		instagram,
		twitter,
		userRolesSelectData,
		isLoading,
		canSubmit,
		handleGoBack,
		isPresenter,
		description,
		handleChangeDescription,
		handleChangeFacebook,
		handleChangeInstagram,
		handleChangeTwitter,
	} = props

	return (
		<Fragment>
			<S.Header>
				<GoBackButton />
				<S.ModalTitle data-testid='title-page'>
					Cadastrar Novo Usuário
				</S.ModalTitle>
			</S.Header>
			<S.ContentContainerWrapper>
				<S.ContentContainer>
					<S.UploadImageButtonWrapper>
						<UploadImageButton
							label='Upload Foto do Usuário'
							onChange={handleInputChange}
							imageClean={cleanImageSeletor}
							showInfo={true}
							data-testid='upload-image'
							image={image}
						/>
					</S.UploadImageButtonWrapper>

					<S.UserTypeWrapper>
						<SecondarySelect
							className='select'
							label='Tipo de Usuário'
							onChange={handleSelectChange}
							data={userRolesSelectData}
							value={selectedRoles}
							data-testid='select-user-type'
						/>
					</S.UserTypeWrapper>
				</S.ContentContainer>
			</S.ContentContainerWrapper>

			<S.ModalSeparator />
			<S.ContentContainerWrapper>
				<S.ContentContainer>
					<S.FormWrapper>
						<Input
							className='name-input'
							value={userName}
							onChange={handleChangeUserName}
							placeholder='Nome do Usuário'
							data-testid='input-user-name'
						/>
						<Input
							className='email-input'
							value={email}
							onChange={handleChangeEmail}
							placeholder='E-mail'
							data-testid='input-user-email'
						/>

						{isPresenter ? (
							<S.PresenterInfosContainer>
								<S.PresenterInfos>Informações do Apresentador</S.PresenterInfos>
								<TextArea
									className='description-input'
									value={description}
									onChange={handleChangeDescription}
									placeholder='Descrição do Apresentador'
									data-testid='input-user-email'
								/>
								<S.InputCaractersContainer>
									<S.InputCaracters>{description.length}/500</S.InputCaracters>
								</S.InputCaractersContainer>
								<S.PresenterInfos>
									Redes Sociais do apresentador (opcional)
								</S.PresenterInfos>
								<Input
									className='facebook-input'
									value={facebook}
									onChange={handleChangeFacebook}
									placeholder='Perfil Facebook'
									data-testid='input-facebook-profile'
								/>
								<Input
									className='instagram-input'
									value={instagram}
									onChange={handleChangeInstagram}
									placeholder='Perfil Instagram'
									data-testid='input-instagram-profile'
								/>
								<Input
									className='twitter-input'
									value={twitter}
									onChange={handleChangeTwitter}
									placeholder='Perfil Twitter'
									data-testid='input-twitter-profile'
								/>
							</S.PresenterInfosContainer>
						) : null}

						<div className='button-wrapper'>
							<Button.Root
								isLoading={isLoading}
								onClick={handleSubmit}
								disabled={!canSubmit}
								spacing={'12x24'}
								data-testid='submit-new-user'
							>
								<Button.Text text='Cadastrar' />
							</Button.Root>
						</div>
					</S.FormWrapper>
				</S.ContentContainer>
			</S.ContentContainerWrapper>
		</Fragment>
	)
}

export { NewUserView }
