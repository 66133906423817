import styled, { css } from 'styled-components'
import { toRem } from '../../styles/function'
import { IPrimaryDateInputProps } from './types'

export const InputWrapper = styled.div`
	${({ theme }) => css`
		position: relative;

		.calendar-icon {
			right: 16px;
			position: absolute;
			top: 12px;

			width: 20px;
			height: 20px;

			color: ${theme.colors.dark_graffiti};
		}
	`}
`

export const PrimaryInputContainer = styled.input<IPrimaryDateInputProps>`
	${(props) => css`
		padding: 12px 16px;
		width: 100%;
		outline: none;
		border: none;
		border: 1px solid
			${props.activeBorder
				? props.theme.colors.green
				: props.theme.colors.off_white};
		border-radius: 4px;
		height: 46px;
		font-family: ${props.theme.fonts.radikal_regular};
		color: ${props.theme.colors.greenish_grey};
		background: ${props.theme.colors.greenish_white};
		font-size: ${toRem(14)};
	`};
`
export const DatePickerWrapper = styled.div`
	.date-picker {
		margin-top: 10px;
	}
`
