import styled from 'styled-components'

export const DateTimeSelectContainer = styled.div`
	display: flex;
	gap:8px;

	div {
		/* width:100% */
	}

	.day-of-week-wrapper{
		min-width:155px;
	}
`
