/* eslint-disable */
import { NumberFormatValues } from 'react-number-format'
import { IScheduleDate } from '../../interfaces/program'
import { DAY_OF_WEEK, dayOfWeekTranslated } from '../../util/date'
import { unravelArray } from '../../util/select'
import { DateSelect } from '../DateSelect'
import { FloatLabelDateInput } from '../FloatLabelDateInput'
import TertiaryButton from '../TertiaryButton'
import { TiertiaryButtonTheme } from '../TertiaryButton/types'
import { DateTimeSelectContainer } from './styles'
import { IDateTimeSelectProps } from './types'

function DateTimeSelect(props: IDateTimeSelectProps) {
	const {
		dayOfWeek,
		timeEnd,
		timeStart,
		handlePlusProgramDate,
		length,
		index,
		removeProgramDate,
		onUpdate,
	} = props

	const updateHandler = (data: Partial<IScheduleDate>) => {
		onUpdate(index, { day: dayOfWeek, timeEnd, timeStart, ...data })
	}

	function handleSelectDayChange(event: any) {
		const {
			target: { value },
		} = event

		updateHandler({ day: value.value })
	}

	function handleSelectTimeChange(date: NumberFormatValues) {
		updateHandler({ timeStart: date.formattedValue })
	}

	function handleSelectTimeEndChange(date: NumberFormatValues) {
		updateHandler({ timeEnd: date.formattedValue })
	}

	const dayOfWeekData = unravelArray(DAY_OF_WEEK)

	function handleSubmit() {
		if (index !== 0) {
			removeProgramDate(index)
		} else {
			handlePlusProgramDate()
		}
	}

	const currentTheme = index === 0
		?  TiertiaryButtonTheme.PRIMARY
		: TiertiaryButtonTheme.SECONDARY

	const dayOfWeekSelected = {
		value: dayOfWeek,
		label: dayOfWeek && dayOfWeekTranslated[dayOfWeek],
	}

	return (
		<DateTimeSelectContainer>
			<div className='day-of-week-wrapper'>
				<DateSelect
					label='Dia da Semana'
					onChange={handleSelectDayChange}
					data={dayOfWeekData}
					value={dayOfWeek ? dayOfWeekSelected : ''}
					optionMask={dayOfWeekTranslated}
				/>
			</div>

			<div className='time-input'>
				<FloatLabelDateInput
					id='clock-input'
					label='Horário'
					setDate={handleSelectTimeChange}
					date={timeStart}
				/>
			</div>

			<div className='time-input'>
				<FloatLabelDateInput
					id='clock-input'
					label='Horário'
					setDate={handleSelectTimeEndChange}
					date={timeEnd}
				/>
			</div>
		<div>
			<TertiaryButton theme={currentTheme} onClick={handleSubmit} />
		</div>

		</DateTimeSelectContainer>
	)
}

export default DateTimeSelect
