import { useQuery, UseQueryResult } from 'react-query'
import {
	IFetchMusicPayload,
	IMusic,
	IUpdateMusicPayload,
} from '../interfaces/music'
import {
	IPaginationResponse,
	PaginatedMusicRequest,
} from '../interfaces/pagination'
import { QUERY_KEYS } from '../util/reactQuery'
import axiosInstance from './axiosInstance'

import { useMutation, UseMutationResult } from 'react-query'
import { AxiosError } from 'axios'
import { IError } from '../interfaces/error'
import { IUser } from '../interfaces/user'

async function fetchMusics(
	payloadData: IFetchMusicPayload,
): Promise<IPaginationResponse<IMusic>> {
	const response = await axiosInstance.post('/music/get-page', payloadData)

	return response.data
}

export function useMusicsPage({
	maxItemsInPage,
	pageIndex,
	searchString,
	order,
	sort,
}: IFetchMusicRequest): UseQueryResult<IPaginationResponse<IMusic>> {
	const payload = {
		maxItemsInPage: maxItemsInPage,
		pageIndex: pageIndex,
		searchString: searchString,
		order: order,
		sort: sort,
	}
	const result = useQuery(
		[
			QUERY_KEYS.MUSICS,
			{ maxItemsInPage, pageIndex, searchString, order, sort },
		],
		async () => fetchMusics(payload),
		{
			enabled: true,
		},
	)
	return result
}

async function updateMusic(payload: IUpdateMusicPayload) {
	const response = await axiosInstance.patch(`/music/${payload.musicID}`, {
		photoURL: payload.musicData,
	})

	return response.data
}

export function useMutationUpdateMusic(): UseMutationResult<
	IMusic,
	AxiosError<IError>,
	IUpdateMusicPayload
> {
	return useMutation(updateMusic)
}
export type IFetchMusicResponse = IUser[]
// eslint-disable-next-line @typescript-eslint/ban-types
export type IFetchMusicRequest = PaginatedMusicRequest<{}>
