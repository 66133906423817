import { DayOfWeekEnum } from '../interfaces/program'

export const DAY_OF_WEEK: DayOfWeekEnum[] = [
	'SUNDAY',
	'MONDAY',
	'TUESDAY',
	'WEDNESDAY',
	'THURSDAY',
	'FRIDAY',
	'SATURDAY',
]

export const HOURS_OF_DAY = [
	'01:00',
	'02:00',
	'03:00',
	'04:00',
	'05:00',
	'06:00',
	'07:00',
	'08:00',
	'09:00',
	'10:00',
	'11:00',
	'12:00',
	'13:00',
	'14:00',
	'15:00',
	'16:00',
	'17:00',
	'18:00',
	'19:00',
	'20:00',
	'21:00',
	'22:00',
	'23:00',
]

export const dayOfWeekTranslated: { [id: string]: string } = {
	SUNDAY: 'Domingo',
	MONDAY: 'Segunda-feira',
	TUESDAY: 'Terça-feira',
	WEDNESDAY: 'Quarta-feira',
	THURSDAY: 'Quinta-feira',
	FRIDAY: 'Sexta-feira',
	SATURDAY: 'Sábado',
}

import { format, parse, parseISO } from 'date-fns'
import BR from 'date-fns/locale/pt-BR'
import { Row } from 'react-table'

export const weekNamesLong = [
	'Domingo',
	'Segunda',
	'Terça',
	'Quarta',
	'Quinta',
	'Sexta',
	'Sábado',
]

export const weekNamesShort = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']

export const monthNamesArray = [
	'Janeiro',
	'Fevereiro',
	'Março',
	'Abril',
	'Maio',
	'Junho',
	'Julho',
	'Agosto',
	'Setembro',
	'Outubro',
	'Novembro',
	'Dezembro',
]

export function sortDate(param: string) {
	return (rowA: Row, rowB: Row, _: string, __: boolean) => {
		//		const a = reverseDate(rowA.values[param])
		//		const b = reverseDate(rowB.values[param])
		const a = rowA.values[param]
		const b = rowB.values[param]

		if (a < b) return -1
		if (a > b) return 1
		return 0
	}
}

/**
 *
 * @param string dd/MM/yyyy
 * @returns yyyy-MM-dd
 */
export function reverseDate(date: string): string {
	const val = date.split('/')
	return `${val[2]}-${val[1]}-${val[0]}`
}

/**
 *
 * @param date yyyy-mm-dd
 */
export function parseDate(date: string): Date {
	return parse(date, 'yyyy-mm-dd', new Date(), { locale: BR })
}

/**
 *
 * @param date
 * @returns dd/MM/yyyy HH:mm
 */
export function formatDate(date: Date): string {
	return format(date, 'dd/MM/yyyy HH:mm', { locale: BR })
}

/**
 *
 * @param date ISODate yyyy-MM-dd'T'HH:mm:ss.SSSxxx
 * @returns dd/MM/yyyy HH:mm
 */
export function parseFormat(date: string): string {
	return format(parseISO(date), 'dd/MM/yyyy HH:mm', { locale: BR })
}
